<template>
  <form class="menububble_prosody-form" @submit.prevent="update">
    <div class="inputs-wrap">
      <span class="bubble-prosody-label">Volume:</span>
      <b-form-select v-model="volumeValue" :options="volumes" size="sm" class="bubble-prosody-input" />
      <span class="bubble-prosody-label ml-2">Rate:</span>
      <b-form-select v-model="rateValue" :options="rates" size="sm" class="bubble-prosody-input" />
    </div>

    <b-button type="submit" variant="primary" size="sm"> <svg-icon name="check" /> </b-button>
  </form>
</template>

<script>
export default {
  props: {
    volume: {
      default: 'default',
    },

    rate: {
      default: null,
    },

    pitch: {
      default: 'default',
    },

    command: {
      required: true,
    },
  },

  data() {
    return {
      volumeValue: this.volume,
      rateValue: this.rate,
    };
  },

  computed: {
    volumes() {
      return [
        {
          value: 'default',
          text: 'Default',
        },
        {
          value: 'silent',
          text: 'Silent',
        },
        {
          value: 'x-soft',
          text: 'X-soft',
        },
        {
          value: 'soft',
          text: 'Soft',
        },
        {
          value: 'medium',
          text: 'Medium',
        },
        {
          value: 'loud',
          text: 'Loud',
        },
        {
          value: 'x-loud',
          text: 'X-loud',
        },
      ];
    },

    rates() {
      return [
        {
          value: null,
          text: 'Normal',
        },
        {
          value: 'x-slow',
          text: 'X-slow',
        },
        {
          value: 'slow',
          text: 'Slow',
        },
        {
          value: 'medium',
          text: 'Medium',
        },
        {
          value: 'fast',
          text: 'Fast',
        },
        {
          value: 'x-fast',
          text: 'X-fast',
        },
      ];
    },
  },

  methods: {
    update() {
      this.command({ volume: this.volumeValue, rate: this.rateValue });

      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menububble_prosody-form {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
  display: flex;

  .btn {
    box-shadow: none;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .inputs-wrap {
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;

    select {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }

  .bubble-prosody-label {
    font-weight: bold;
    font-size: 0.95rem;
    margin-right: 10px;
  }

  .bubble-prosody-input {
    &::placeholder {
      color: $gray-500;
    }
  }
}
</style>
