<template>
  <div class="schedule-wrap">
    <b-row>
      <b-col md="5">
        <div class="chosen-date-label w-100">{{ chosenDate }}</div>
        <PostCalendar :date="scheduleDate" @pick="handleDateChange" />
      </b-col>

      <b-col md="7" class="text-right">
        <div class="chosen-date-label">{{ chosenTime }}</div>

        <b-form-select
          :value="scheduleTimezone"
          @change="updateScheduleTimezone"
          :options="timezones"
          class="schedule-timezone mb-3"
        />

        <PostTimePicker @change="handleDateChange" :date="scheduleDate" :hour-step="1" :minute-step="1" />
      </b-col>
    </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import PostCalendar from '~/components/PostCalendar';
import PostTimePicker from '~/components/PostTimePicker';

export default {
  components: {
    PostCalendar,
    PostTimePicker,
  },

  computed: {
    ...mapState('createPost', {
      scheduleDate: state => state.scheduleDate,
      scheduleTimezone: state => state.scheduleTimezone,
    }),

    timezones() {
      const timezones = moment.tz.names();
      const timezoneNames = timezones
        .map(timezone => ({
          value: timezone,
          text: `${timezone} (GMT ${moment.tz(timezone).format('Z')})`,
        }))
        .sort();

      return [{ value: null, text: 'Select Timezone' }, ...timezoneNames];
    },

    chosenDate() {
      return moment(this.scheduleDate).format('dddd, MMMM Do YYYY');
    },

    chosenTime() {
      return moment(this.scheduleDate).format('hh:mm A');
    },
  },

  methods: {
    handleDateChange(date) {
      this.$store.dispatch('createPost/updateScheduleDate', date);
    },

    updateScheduleTimezone(timezone) {
      this.$store.dispatch('createPost/updateScheduleTimezone', timezone);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.schedule-wrap {
  .schedule-timezone {
    font-size: 0.7rem;
    background-color: #f2f2f2;
    border-radius: 10px;
    border: 2px solid $primary;
    height: 40px;
  }

  .chosen-date-label {
    background-color: #f2f2f2;
    padding: 4px 30px;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: $primary;
    border: 2px solid $primary;
  }
}
</style>
