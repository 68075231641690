<template>
  <div class="PostEditor bg-white shadow-sm">
    <drop
      class="drop"
      :class="{ over, allowed: isDragging }"
      @dragover="over = true"
      @dragleave="over = false"
      @drop="handleDrop"
    >
      <div
        class="post-editor"
        data-post-editor-element="true"
        ref="textNode"
        :contenteditable="!disabled"
        v-html="initialValue"
        @input="inputChange"
      ></div>
    </drop>
    <div class="post-editor-footer">
      <div class="main-btns">
        <PostEditorHashtagDropdown @insert="handleHashtagInsert" />
        <PostEditorUtmDropdown @insert="handleUtmInsert" />
        <b-dropdown
          ref="emojiDropdown"
          class="emoji_dropdown"
          toggle-class="px-0"
          size="sm"
          right
          variant="clear"
          no-caret
          no-flip
        >
          <b-button @click="handleInsertEmojiClick" slot="button-content" variant="clear" class="node-btns py-1 px-2">
            <svg-icon name="wink" />
          </b-button>
          <EmojiList @select="handleSelectEmoji" />
        </b-dropdown>
        <AiPostWriter @push-post="getPushedPost" post-type="social" />
      </div>

      <div class="right-section">
        <div class="char-limit" v-if="charLimit">Character Limit: {{ charCount }}/{{ charLimit }}</div>
      </div>
    </div>
    <div v-if="selectedImages.length" class="selected-images-wrap">
      <div class="selected-images">
        <div
          class="attachment-item"
          v-for="(attachment, i) in selectedImages"
          :key="i + 1"
          @click="viewAttachment(attachment)"
        >
          <div v-if="attachment.thumb" :style="{ backgroundImage: `url(${attachment.thumb})` }" class="img-item">
            <span @click.stop="removeSelectedImage(i)" title="Remove" class="remove-attachment">
              <svg-icon name="close" />
            </span>
            <b-badge v-if="attachment.type === 'video'" class="video-badge" size="sm">
              <svg-icon name="film" />
            </b-badge>
          </div>
          <div v-else-if="attachment.thumb == null" class="attachment-video-wrap">
            <span @click.stop="removeSelectedImage(i)" title="Remove" class="remove-attachment">
              <svg-icon name="close" />
            </span>
            <video class="attachment-video" muted><source type="video/mp4" :src="attachment.url" /></video>
            <b-badge v-if="attachment.type === 'video'" class="video-badge" size="sm">
              <svg-icon name="film" />
            </b-badge>
          </div>
        </div>
      </div>

      <div v-if="linkPreview.images && suggestedImages.length">
        <p class="mb-1">Suggested images ({{ suggestedImages.length }})</p>
        <simplebar class="suggested-images">
          <div
            class="attachment-item"
            v-for="(imageUrl, i) in suggestedImages"
            :key="i + 1"
            @click="addToSelectedImages(imageUrl)"
          >
            <div :style="{ backgroundImage: `url(${imageUrl})` }" class="img-item"></div>
          </div>
        </simplebar>
      </div>
    </div>
    <div v-else-if="linkPreview.title || linkPreviewLoading" class="link-preview">
      <div v-if="linkPreviewLoading" class="text-center p-4"><b-spinner variant="primary" /></div>
      <div v-else class="d-flex">
        <div class="preview-image" :style="{ backgroundImage: `url(${linkPreview.images[0]})` }"></div>
        <div class="preview-meta py-2 px-3">
          <h5>{{ linkPreview.title }}</h5>
          <p>{{ linkPreview.description }}</p>
        </div>

        <b-button @click="removeLinkPreview" variant="clear" size="sm" class="close-link-preview"
          ><svg-icon name="close"
        /></b-button>
      </div>
    </div>

    <b-modal
      id="ViewPhotoModal"
      size="lg"
      body-class="p-0"
      modal-class="editor-view-image-modal"
      content-class="border-0 rounded-0"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <b-button @click="hide" variant="clear" class="view-editor-image-close-btn"><svg-icon name="close"/></b-button>
        <div v-if="activeAttachment.type === 'image' || activeAttachment.type === 'gif'" class="img-wrap">
          <img :src="activeAttachment.url" class="w-100" alt="" />
        </div>
        <div v-if="activeAttachment.type === 'video'" class="img-wrap">
          <VideoPlayer :width="700" :height="400" :src="activeAttachment.url" autoplay="any" />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Drop } from 'vue-drag-drop';
import simplebar from 'simplebar-vue';
import EmojiList from '~/components/EmojiList';
import VideoPlayer from '~/components/VideoPlayer';
import PostEditorUtmDropdown from '~/components/PostEditorUtmDropdown';
import PostEditorHashtagDropdown from '~/components/PostEditorHashtagDropdown';
import AiPostWriter from '~/components/Ai/AiPostWriter';

const isEdge = /Edge\/\d+/.exec(navigator.userAgent) !== null;

export default {
  name: 'post-editor',

  components: {
    Drop,
    AiPostWriter,
    simplebar,
    EmojiList,
    VideoPlayer,
    PostEditorUtmDropdown,
    PostEditorHashtagDropdown,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    selectedImages: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    charLimit: {
      type: Number,
    },
  },

  savedSelection: null,

  data() {
    return {
      over: false,
      initialValue: this.value,
      suggestedImages: [],
      activeAttachment: {},
    };
  },

  computed: {
    charCount() {
      return this.value.length || 0;
    },
    ...mapState({
      workspace: state => state.workspace.current,
      selectedContent: state => state.createPost.selectedContent,
      linkPreview: state => state.createPost.linkPreview,
      linkPreviewLoading: state => state.createPost.linkPreviewLoading,
      isDragging: state => state.postEditor.isDragging,
    }),
  },

  watch: {
    value(nextValue) {
      // innerHTML MUST not be performed if the text did not actually change.
      // otherwise, the caret position will be reset.
      if (nextValue !== this.$refs.textNode.innerHTML) {
        this.$refs.textNode.innerHTML = this.value;
      }
    },

    selectedImages() {
      if (this.linkPreview && this.linkPreview.images) {
        this.suggestedImages = this.linkPreview.images.filter(
          img => !this.selectedImages.find(selected => selected.imageUrl === img),
        );
      }
    },
  },

  methods: {
    getPushedPost(selected = []) {
      this.$emit('input', selected.join(''));
    },

    viewAttachment(attachment) {
      this.activeAttachment = attachment;

      this.$bvModal.show('ViewPhotoModal');
    },

    saveSelection() {
      if (window.getSelection) {
        const selection = window.getSelection();
        if (selection.getRangeAt && selection.rangeCount) {
          let range = selection.getRangeAt(0);

          if (this.isEditorElement(range.startContainer) || this.isEditorElement(range.startContainer.parentNode)) {
            return range;
          }

          this.moveCursorToEnd();

          return selection.getRangeAt(0);
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
      }

      return null;
    },

    restoreSelection(range) {
      if (range) {
        if (window.getSelection) {
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
        } else if (document.selection && range.select) {
          range.select();
        }
      }

      this.savedSelection = null;
    },

    moveCursorToEnd() {
      const el = this.$refs.textNode;

      var sel, range;
      if (window.getSelection && document.createRange) {
        range = document.createRange();
        range.selectNodeContents(el);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        sel.collapseToEnd();
      } else if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.collapse(false);
      }
    },

    insertHTMLCommand(doc, html) {
      var selection,
        range,
        el,
        fragment,
        node,
        lastNode,
        toReplace,
        res = false,
        ecArgs = ['insertHTML', false, html];

      /* Edge's implementation of insertHTML is just buggy right now:
       * - Doesn't allow leading white space at the beginning of an element
       * - Found a case when a <font size="2"> tag was inserted when calling alignCenter inside a blockquote
       *
       * There are likely other bugs, these are just the ones we found so far.
       * For now, let's just use the same fallback we did for IE
       */
      if (!isEdge && doc.queryCommandSupported('insertHTML')) {
        try {
          return doc.execCommand.apply(doc, ecArgs);
        } catch (ignore) {
          // Do nothing
        }
      }

      selection = doc.getSelection();
      if (selection.rangeCount) {
        range = selection.getRangeAt(0);
        toReplace = range.commonAncestorContainer;

        // If the selection is an empty editor element, create a temporary text node inside of the editor
        // and select it so that we don't delete the editor element
        if (this.isEditorElement(toReplace) && !toReplace.firstChild) {
          range.selectNode(toReplace.appendChild(doc.createTextNode('')));
        } else if (
          (toReplace.nodeType === 3 && range.startOffset === 0 && range.endOffset === toReplace.nodeValue.length) ||
          (toReplace.nodeType !== 3 && toReplace.innerHTML === range.toString())
        ) {
          // Ensure range covers maximum amount of nodes as possible
          // By moving up the DOM and selecting ancestors whose only child is the range
          while (
            !this.isEditorElement(toReplace) &&
            toReplace.parentNode &&
            toReplace.parentNode.childNodes.length === 1 &&
            !this.isEditorElement(toReplace.parentNode)
          ) {
            toReplace = toReplace.parentNode;
          }
          range.selectNode(toReplace);
        }
        range.deleteContents();

        el = doc.createElement('div');
        el.innerHTML = html;
        fragment = doc.createDocumentFragment();
        while (el.firstChild) {
          node = el.firstChild;
          lastNode = fragment.appendChild(node);
        }
        range.insertNode(fragment);

        // Preserve the selection:
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          selection.selectRange(doc, range);
        }
        res = true;
      }

      // If we're monitoring calls to execCommand, notify listeners as if a real call had happened
      if (doc.execCommand.callListeners) {
        doc.execCommand.callListeners(ecArgs, res);
      }
      return res;
    },

    selectRange(ownerDocument, range) {
      var selection = ownerDocument.getSelection();

      selection.removeAllRanges();
      selection.addRange(range);
    },

    isEditorElement(element) {
      return element && element.getAttribute && !!element.getAttribute('data-post-editor-element');
    },

    handleInsertEmojiClick() {
      this.savedSelection = this.saveSelection();
    },

    handleHashtagInsert(value) {
      this.$emit('input', value);
    },

    handleUtmInsert(value) {
      this.$emit('input', `${this.value} ${value}`);
    },

    inputChange: function(event) {
      event.preventDefault();
      const value = event.target.innerHTML;
      this.$emit('input', value);
    },

    handleSelectEmoji(emoji) {
      this.restoreSelection(this.savedSelection);
      this.insertHTMLCommand(document, emoji);

      this.savedSelection = this.saveSelection();

      // Don't hide the emoji panel yet
      this.$refs.emojiDropdown.show();
    },

    handleDrop({ type, data, ...params }) {
      this.over = false;
      console.log(type, data, params);

      if (type === 'quote') {
        this.$emit('quoteSelect', data);
      }
      if (type === 'article') {
        this.$emit('articleSelect', data);

        this.$store.dispatch('createPost/fetchLinkPreview', data.url);
      }
      if (type === 'video-link') {
        this.$emit('videoLinkSelect', data);
      }
      if (type === 'image') {
        // eslint-disable-next-line
        const { id, imageUrl, authorName, authorUrl, accountId, ...rest } = data;
        this.$emit('imageSelect', { ...rest, id: 0, downloadId: id, url: imageUrl, platform: params.platform });
      }
      if (type === 'upload' || type === 'video') {
        // eslint-disable-next-line
        const { url, keyword, accountId, ...rest } = data;
        this.$emit('uploadSelect', { ...rest, id: 0, url });
      }
      if (type === 'gif') {
        // eslint-disable-next-line
        const { imageUrl, keyword, accountId, ...rest } = data;
        this.$emit('gifSelect', { ...rest, id: 0, url: imageUrl });
      }
      if (type === 'meme') {
        this.$emit('memeSelect', data);
      }
    },

    addToSelectedImages(imageUrl) {
      this.$emit('suggestedImageSelect', {
        url: imageUrl,
        thumb: imageUrl,
      });
    },

    removeSelectedImage(i) {
      this.$emit('removeSelectedImage', i);
    },

    removeLinkPreview() {
      this.$store.dispatch('createPost/updateLinkPreview', {});

      this.$store.dispatch('createPost/updateSelectedContent', {
        ...this.selectedContent,
        postLink: '',
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.drop.allowed {
  border: 2px dashed darken($primary, 10);
}

.PostEditor {
  .post-editor {
    padding: 15px 20px;
    min-height: 200px;
    outline: 0;
    background-color: $white;
    white-space: pre-wrap;

    a {
      text-decoration: none;
      color: $primary;
    }

    .hashtag {
      color: $primary;
    }
  }

  .post-editor-footer {
    padding: 7px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-section {
      .char-limit {
        font-size: 0.8rem;
        margin-right: 0.7rem;
      }
    }
  }

  .node-btns {
    border-radius: 8px;
    border: 1px solid $secondary;
    background: #f2f2f2;
    margin: 0 2px;
    &:hover {
      background: $secondary;
    }
  }

  .link-preview {
    position: relative;

    .preview-image {
      width: 30%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .preview-meta {
      width: 70%;
    }

    .close-link-preview {
      position: absolute;
      top: 4px;
      right: 0;

      &:focus,
      &:active {
        outline: 0;
        box-shadow: none;
      }

      .icon {
        width: 1.2rem;
        height: 1.2rem;
        padding: 5px;
        border-radius: 50%;
        background-color: rgba($gray-300, 0.3);

        &:hover {
          background-color: $danger;
          fill: #fff;
        }
      }
    }
  }

  .selected-images-wrap {
    overflow: hidden;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .attachment-item {
      border-radius: 5px;
      cursor: pointer;
      background-clip: padding-box;
      overflow: hidden;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 5px;
      border: 1px solid rgba(152, 158, 181, 0.2);
      background-color: $primary;
    }

    .img-item {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      height: 80px;
      width: 80px;
    }

    .attachment-video-wrap {
      display: flex;

      .attachment-video {
        width: 80px;
        height: 80px;
      }
    }
    .video-badge {
      position: absolute;
      bottom: 5px;
      right: 5px;
      padding: 0 0.3em;

      .icon {
        margin-top: 0;
      }
    }

    .selected-images {
      width: 300px;

      .img-item,
      .attachment-video-wrap {
        .remove-attachment {
          opacity: 0;
          background-color: $primary;
          position: absolute;
          padding: 5px;
          display: flex;
          right: 0;
          cursor: pointer;
          border-radius: 1px;
          z-index: 1;

          .icon {
            margin: 0;
            height: 0.4rem;
            width: 0.4rem;
            fill: $white;
          }
        }

        &:hover {
          .remove-attachment {
            opacity: 1;
          }
        }
      }
    }

    .suggested-images {
      overflow-x: auto;
      width: 250px;
      background-color: darken($light, 5);
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 5px;

      .simplebar-content {
        display: flex;
        align-self: flex-start;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
      }

      .img-item {
        flex-shrink: 0;
        background-color: $light;
        margin-bottom: 10px;
      }
    }
  }

  .hashtag_dropdown .dropdown-menu,
  .utm_dropdown .dropdown-menu {
    min-width: 380px;
    margin-top: 8px;
    border: 1px solid rgba(102, 107, 133, 0.05);
    box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.5);

    &:after,
    &:before {
      position: absolute;
      display: inline-block;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    &:before {
      top: -7px;
      left: 10px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #ebebeb;
    }

    &:after {
      top: -6px;
      left: 11px;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
    }

    .b-dropdown-form {
      padding: 15px;
    }

    input {
      font-size: 0.7rem;
      padding: 10px;
      color: #3a4557;
      border-radius: 5px;
      border: 1px solid rgba(152, 158, 181, 0.5);
      background: transparent;

      &:focus {
        border-color: $primary;
        outline: 0;
      }
    }

    label {
      font-size: 14px;
    }
  }

  .emoji_dropdown {
    .btn {
      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      min-width: 350px;
      margin-top: 5px;
      border: 1px solid rgba(102, 107, 133, 0.05);
      box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.5);

      &:after,
      &:before {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
      }

      &:before {
        top: -7px;
        right: 15px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #ebebeb;
      }

      &:after {
        top: -6px;
        right: 16px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
      }
    }
  }
}

.editor-view-image-modal {
  img {
    min-height: 500px;
    @include skeleton-animation($gray-400);
  }
}
.view-editor-image-close-btn {
  position: absolute;
  right: -100px;

  .icon {
    fill: $white;
  }

  &:focus {
    box-shadow: none;
  }
}
</style>
