<template>
  <div class="d-flex">
    <div class="rss-source auto-box shadow-sm rounded-sm">
      <div class="auto-box-inner">
        <h4 class="mb-5">RSS Feed Source</h4>

        <b-form-group label-for="hashtags" class="mt-4">
          <template slot="label">Feed URL</template>
          <b-form-input
            :value="source"
            class="feed-url"
            :state="urlState"
            placeholder="Add feed URL here such as 'http://rss.cnn.com/rss/edition.rss'"
            autocomplete="off"
            @input="handleFeedUrlChange"
          />
          <b-form-invalid-feedback id="input-live-feedback">Please enter a valid RSS feed URL</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isValidUrl } from '~/utils/helpers';

export default {
  name: 'automation-rss-source',

  computed: {
    ...mapState('createAutomation', {
      source: state => state.source,
    }),
    urlState() {
      if (this.source === '') return null;
      return isValidUrl(this.source);
    },
  },

  methods: {
    handleFeedUrlChange(value) {
      this.$store.dispatch('createAutomation/updateSource', value);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.rss-source {
  .feed-url {
    &::placeholder {
      color: $gray-500;
    }
  }
}
</style>
