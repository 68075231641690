<template>
  <div class="mx-5 px-5 py-3">
    <b-row class="account-contents">
      <b-col md="4" sm="12">
        <div class="mb-2 text-primary border border-primary rounded p-2 text-center">
          Customize content to suite your audience
        </div>
        <ChosenPublishAccounts
          :accounts="chosenAccounts"
          :activeAccount="activeAccount"
          @item-click="switchActiveTab"
        />
      </b-col>
      <b-col md="8" sm="12">
        <div v-if="activeAccount" class="active-content-area bg-light p-5 rounded-sm">
          <div class="d-flex align-items-center mb-3">
            <img
              class="mr-2 bg-white shadow-sm p-1 rounded-circle"
              :src="activeAccount.imageUrl"
              alt=""
              style="height: 40px; width: 40px;"
            />
            <div class="font-weight-bold text-truncate">{{ activeAccount.name }}</div>
          </div>

          <b-form-group>
            <b-form-input
              v-model="activeAccountTitle"
              class="rounded-0 mb-3 border-0 shadow-sm"
              placeholder="Title"
              @input="activeContentChange"
            />
          </b-form-group>
          <b-form-group>
            <b-form-textarea
              v-model="activeAccountContent"
              class="rounded-0 mb-3 border-0 shadow-sm"
              placeholder="Description"
              @input="activeContentChange"
            />
          </b-form-group>
        </div>
        <div v-else class="px-4 bg-light p-5 text-center"><h4>No accounts selected.</h4></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import PostEditor from '~/components/PostEditor';
import ChosenPublishAccounts from '~/components/ChosenPublishAccounts';

export default {
  components: {
    // PostEditor,
    ChosenPublishAccounts,
  },

  props: {
    accountPostContents: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeAccount: '',
    };
  },

  computed: {
    ...mapState('publishAccount', {
      publishAccounts: state => state.accounts,
    }),

    chosenAccounts() {
      return this.accountPostContents.map(con => this.publishAccounts.find(a => a.id === con.accountId));
    },

    activeContent: {
      get() {
        const activeContent = this.accountPostContents.find(content => content.accountId === this.activeAccount.id);
        return activeContent || null;
      },
      set(value) {
        const key = Object.keys(value)[0];
        const index = this.accountPostContents.findIndex(con => con.accountId === this.activeAccount.id);
        this.$emit('change', [
          ...this.accountPostContents.slice(0, index),
          {
            ...this.accountPostContents[index],
            [key]: value[key],
          },
          ...this.accountPostContents.slice(index + 1),
        ]);
        // this.accountPostContents[index][key] = value[key];
      },
    },

    activeAccountTitle: {
      get() {
        return this.activeContent && this.activeContent.title ? this.activeContent.title : '';
      },
      set(title) {
        this.activeContent = { title };
      },
    },
    activeAccountContent: {
      get() {
        return this.activeContent && this.activeContent.content ? this.activeContent.content : '';
      },

      set(content) {
        this.activeContent = { content };
      },
    },
  },

  created() {
    if (!this.activeAccount) {
      this.switchActiveTab(this.chosenAccounts[0]);

      this.activeContentChange();
    }
  },

  methods: {
    switchActiveTab(account) {
      this.activeAccount = account;
    },

    activeContentChange() {
      const account = this.chosenAccounts.find(acct => acct.id == this.activeAccount.id);
      account && this.$store.dispatch('validation/validate', { account, postContent: this.activeContent });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.active-content-area {
  background: $white;
  border-radius: 10px;
}
</style>
