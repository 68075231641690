<template>
  <div class="view-post-item shadow-sm">
    <div @click="expanded = !expanded" class="d-flex" :class="`view-post-item-top ${post.status}`">
      <div class="w-100">
        <div class="view-post-account d-flex justify-content-between align-items-center mb-3">
          <div class=" gap-2  d-flex align-items-center">
            <PublishAccountImage
              v-for="account in postAccounts"
              :account="account"
              :key="account.id"
              width="40"
              height="40"
              class="mr-1"
            />

            <div>
              <div class="post-meta fs-12">Created on {{ post.createdAt }}</div>
              <b-badge class="post-status-text px-3" :variant="getStatusVariant(post.status)">{{
                post.status === 'publish' ? 'processing' : post.status
              }}</b-badge>
              <span
                v-if="post.status === 'failed' && post.statusMessage"
                class="post-status-message"
                v-html="post.statusMessage"
              ></span>
              <span v-if="post.status === 'published'">
                [<a :href="post.liveLink" target="_blank">Live Link</a><svg-icon name="external-link" class="ml-1" />]
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div class="viewpost-content mb-3" v-html="displayContent"></div>
        </div>
        <div class="post-footer d-flex justify-content-between align-items-center">
          <!-- <div class="post-meta mt-3">Created on {{ post.createdAt }}</div> -->
          <div v-if="post.id" class="view-post-item-btns">
            <b-button v-if="post.busy" variant="clear" size="sm" class="mr-2">
              <b-spinner variant="primary" small />
            </b-button>
            <template v-if="post.status === 'in-review'">
              <b-button
                v-b-tooltip.hover
                title="Approve"
                @click.stop="$emit('approve', post)"
                variant="outline-success"
                size="sm"
                class="mr-2"
                :disabled="post.busy"
                ><svg-icon name="check" class="mt-0"
              /></b-button>
              <b-button
                v-b-tooltip.hover
                title="Reject"
                @click.stop="$emit('reject', post)"
                variant="outline-danger"
                size="sm"
                class="mr-2"
                :disabled="post.busy"
                ><svg-icon name="close" class="mt-0"
              /></b-button>
            </template>
            <b-button
              v-if="post.type === 'voice'"
              v-b-tooltip.hover
              title="Edit"
              :to="{ name: 'compose-audio', params: { id: post.id } }"
              variant="light"
              size="sm"
              class="border mr-2"
              :disabled="post.status === 'published'"
              ><svg-icon name="edit" class="mt-0"
            /></b-button>
            <b-button
              v-else
              v-b-tooltip.hover
              title="Edit"
              :to="{ name: 'compose', params: { type: post.type, id: post.id } }"
              variant="light"
              size="sm"
              class="border mr-2"
              :disabled="post.status === 'published'"
              ><svg-icon name="edit" class="mt-0"
            /></b-button>
            <b-button
              v-b-tooltip.hover
              title="Delete"
              @click.stop="$emit('delete', post)"
              variant="light"
              size="sm"
              class="border mr-2"
              ><svg-icon name="trash" class="mt-0"
            /></b-button>
            <b-button
              @click.stop="expanded = !expanded"
              v-b-tooltip.hover
              :title="expanded ? 'Collapse' : 'Expand'"
              :variant="expanded ? 'primary' : 'light'"
              size="sm"
              class="border"
              ><svg-icon :name="`chevron-${expanded ? 'up' : 'down'}`" class="mt-0"
            /></b-button>
          </div>
        </div>
      </div>
      <div class="">
        <div class="viewpost-image" v-if="post.featuredImageUrl">
          <img :src="post.featuredImageUrl" :alt="`featured-image-${post.title}`" />
        </div>
      </div>
    </div>

    <div v-show="expanded" class="view-post-item-bottom">
      <template v-if="accountPostContents.length">
        <ViewPostAccountContentTable
          v-if="primaryContent.length"
          name="Primary channel"
          :contents="primaryContent"
          :showStats="post.status === 'published'"
        />
        <ViewPostAccountContentTable
          v-if="secondaryContents.length"
          name="Secondary channels"
          :contents="secondaryContents"
          :showStats="post.status === 'published'"
        />
        <ViewPostAccountContentTable
          v-if="socialContents.length"
          :name="primaryContent.length || secondaryContents.length ? 'Social sharing' : 'Account'"
          :contents="socialContents"
          :showStats="post.status === 'published'"
        />
      </template>
      <div v-else class="text-center p-5"><p class="m-0">You have not chosen any accounts for this post.</p></div>
    </div>
  </div>
</template>

<script>
import { stripTags, truncate } from '~/utils/helpers';

import PublishAccountImage from '~/components/PublishAccountImage';
import ViewPostAccountContentTable from '~/components/ViewPostAccountContentTable';
import { isArray, isEmpty, get } from 'lodash';

export default {
  components: { PublishAccountImage, ViewPostAccountContentTable },

  props: {
    post: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    primaryBlogId: {
      type: Number,
    },
    secondaryBlogIds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expanded: this.open,
    };
  },
  methods: {
    getStatusVariant(status) {
      if (status === 'published') {
        return 'success';
      } else if (status === 'scheduled') {
        return 'warning';
      } else if (status === 'failed') {
        return 'danger';
      } else {
        return 'primary';
      }
    },
  },

  computed: {
    displayContent() {
      const title = this.post.title || truncate(stripTags(this.post.masterContent), 250, true);
      return this.post.type === 'blog' ? title : this.post.masterContent;
    },

    accountPostContents() {
      const arr = this.post && this.post.accountPostContents ? this.post.accountPostContents : [];

      if (
        (!isArray(arr) || isEmpty(arr)) &&
        isArray(get(this.post, 'selectedAccounts')) &&
        this.post.selectedAccounts.length > 0
      ) {
        return this.post.selectedAccounts;
      }

      return arr;
    },

    postAccounts() {
      return this.accountPostContents.map(content => content.publishAccount).filter(val => !!val);
    },

    primaryContent() {
      return this.accountPostContents.filter(account => account.publishVariant === 'primary');
    },
    secondaryContents() {
      return this.accountPostContents.filter(account => account.publishVariant === 'secondary');
    },

    socialContents() {
      return this.accountPostContents.filter(account => account.publishVariant === 'social' || !account.publishVariant);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.view-post-item {
  margin-bottom: 25px;

  .view-post-item-top {
    border-left: 4px solid $primary;
    padding: 15px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
    position: relative;
    z-index: 1;
    cursor: pointer;

    &.published {
      border-left-color: $success;
    }
    &.failed,
    &.rejected {
      border-left-color: $danger;
    }
    &.in-review {
      border-left-color: $warning;
    }

    .viewpost-content {
      width: 90%;
      margin-right: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      font-size: 0.9rem;
      word-break: break-word;
      p {
        margin-bottom: 0.8rem;
      }
    }
  }

  .view-post-item-bottom {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(184, 189, 209, 0.3);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px;

    .post-status-text,
    .post-status-message {
      font-size: 0.8rem;
    }

    .post-status-text {
      text-transform: capitalize;
      vertical-align: middle;
      margin-right: 10px;
    }

    .post-stats {
      text-align: center;

      .post-stat-item {
        min-width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          width: 0.8rem;
          height: 0.8rem;
          margin-bottom: 5px;
        }
      }
    }
  }

  .platform-indicator-icon {
    right: -4px;
    bottom: -2px;
    width: 1.1rem;
    height: 1.1rem;
  }

  .post-meta {
    color: $gray-500;
  }

  .viewpost-image {
    max-width: 150px;
    height: 100%;
    background-color: $gray-200;
    background-size: cover;
    background-position: center;
    border-radius: 5px 5px 0 0;

    img {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
    }
  }
}

.fs-12 {
  font-size: 12px;
}
</style>
