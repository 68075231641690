<template>
  <div class="bg-white p-5">
    <template v-if="!proceedToSave">
      <div class="py-5" :style="{ textAlign: 'center' }">
        <h3>Automation Summary</h3>

        <p class="font-normal">
          You have chosen to run an AI automation based on the parameters you provided. Here are the details:
        </p>
        <table class="w-full table">
          <thead>
            <th>Campaign Name</th>
            <th>Publish Status</th>
            <th>Prompt</th>
            <th>Schedule Type</th>
          </thead>
          <tbody>
            <tr>
              <td class="capitalize font-normal">{{ campaignName }}</td>
              <td class="capitalize font-normal">
                {{ selectedAccounts.map(e => `${e.platform} [${e.publishStatus}]`).join(', ') }}
              </td>
              <td class="capitalize font-normal">{{ aiPostPrompt }}</td>
              <td class="capitalize font-normal">{{ scheduleType }}</td>
            </tr>
          </tbody>
        </table>
        <p class=" font-normal">
          This automation will run continuously, ensuring consistent and efficient execution of your tasks according to
          the specified parameters.
        </p>
        <button @click="$emit('save')" class="btn btn-primary px-5">
          Proceed to Save
        </button>
      </div>
    </template>
    <template v-else>
      <div class="py-5" :style="{ display: 'flex', justifyContent: 'center' }">
        <div>
          <AutomationLoading
            :style="{ transform: 'scale(1.5)' }"
            v-show="loading !== 'error'"
            :loadingTexts="[
              'Sending Automation Requests...',
              'Generating Featured Images....',
              'Generating Captions...',
              'Generating Posts...',
            ]"
          />
          <div
            class="flex pb-5 pt-3 mt-5 items-center"
            v-show="loading === 'success'"
            :style="{ display: 'flex', flexDirection: ' column', justifyContent: 'center' }"
          >
            <p class=" font-normal mb-1">
              <b>Note: </b><i>Your preview would be available when the process is complete</i>
            </p>
            <button class="btn btn-primary w-100 py-3" @click="$emit('viewAutomations')">View Automations</button>
          </div>

          <div v-if="loading === 'error'">
            <b-alert show variant="warning">
              <div class="flex flex-row gap-2 items-center">
                <b-icon icon="exclamation-triangle" scale="1.5"></b-icon>
                <span class=" font-normal text-sm">
                  Something went wrong during your automation setup
                </span>
              </div>
            </b-alert>
            <button @click="$emit('go-back')" class="btn btn-secondary w-100">Go back</button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AutomationLoading from '~/components/Ai/AutomationLoading';

export default {
  name: 'AutomationLoader',
  emits: ['save', 'go-back', 'viewAutomations'],
  props: ['loading', 'proceedToSave'],
  components: { AutomationLoading },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      currentStep: state => state.createAutomation.currentStep,
      campaignName: state => state.createAutomation.campaignName,
      selectedAccounts: state => state.createAutomation.selectedAccounts,
      keywords: state => state.createAutomation.keywords,
      selectedHashtagPreset: state => state.createAutomation.selectedHashtagPreset,
      recurringValue: state => state.createAutomation.recurringValue,
      recurringUnit: state => state.createAutomation.recurringUnit,
      accounts: state => state.createAutomation.accounts,
      aiPostPrompt: state => state.createAutomation.aiPostPrompt,
      scheduleType: state => state.createAutomation.scheduleType,
      isAutomationSaving: state => state.createAutomation.saveBusy,
    }),
  },
};
</script>
