<template>
  <div class="instagram-report-entry">Instagram</div>
</template>

<script>
export default {
  name: 'InstagramReport',
};
</script>

<style lang="scss" scoped>
.instagram-report-entry {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>
