<template>
  <div>
    <b-modal
      id="updateSchedule"
      centered
      hide-footer
      hide-header
      :visible="show && (action === 'update' || action === 'create')"
      @hide="closeScheduleModal"
      size="lg"
    >
      <div>
        <div class="external__modal-button">
          <b-button @click="closeScheduleModal()" class="text-white" variant="clear">
            <svg-icon name="close-alt" size="md" />
          </b-button>
        </div>
        <div class="p-3 schedule__form">
          <h5 class="my-3">{{ action === 'create' ? 'Create' : 'Update' }} Schedule</h5>
          <b-form-group id="export-name" label="Export Name" label-for="export_name_input" class="my-2">
            <b-form-input
              id="export_name_input"
              class="text-capitalize"
              v-model="form.name"
              type="text"
              placeholder="Enter a name for export..."
              required
            ></b-form-input>
          </b-form-group>
          <b-row class="my-2 ">
            <b-col>
              <div class="form_label">
                Export Type
              </div>
              <div class="text-capitalize form-control">
                {{ form.type }}
              </div>
            </b-col>
          </b-row>
          <b-row class="my-2 schedule__account">
            <b-col>
              <div class="form_label">
                Social Accounts
              </div>
              <div class="account__container" v-if="schedule">
                <div
                  class="account__item"
                  v-for="(account, i) in schedule.accounts"
                  @click="selectAccount(account)"
                  :class="{ active: isAccountActive(account) }"
                  :key="account + i"
                >
                  <div class="account__item-status">
                    <svg-icon :name="isAccountActive(account) ? 'check' : 'times'" size="sm" />
                  </div>
                  <div class="account__item-image">
                    <b-avatar class="avatar" size="2.2rem" />
                    <div :class="['account-icon', account.type]">
                      <svg-icon class="icon" :name="account.type" v-if="account.type"></svg-icon>
                    </div>
                  </div>
                  <div class="account__item-name">
                    {{ account.user.name }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="form_label">
                Social Accounts
              </div>
              <b-row class="interval__container align-center">
                <b-col cols="6" v-for="interval in intervals" :key="interval.title" @click="selectInterval(interval)">
                  <div class="interval" :class="{ active: form.interval === interval.slug }">
                    <span class="marker" :class="{ active: form.interval === interval.slug }"></span>
                    <div>
                      <h5 class="text-capitalize font-weight-bold">{{ interval.title }}</h5>
                      <p>
                        {{ interval.description }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="my-2">
            <div class="d-flex justify-between align-center">
              <div class="float-left form_label">
                Email To (Seperate emails with a comma)
              </div>
              <div class="ml-auto form_label">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.copy"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Send a copy to myself
                </b-form-checkbox>
              </div>
            </div>
            <b-form-group class="w-100">
              <vue-tags-input
                id="send_to_emails"
                v-model="form.emailInput"
                :tags="send_to_emails"
                :add-on-key="[13, ',', ';']"
                :allow-edit-tags="true"
                :placeholder="emailInputLength ? 'Add Emails' : 'Enter up to 25 email address...'"
              />
            </b-form-group>
          </div>

          <b-button variant="primary" class="py-2 px-4">
            {{ action === 'create' ? 'Create' : 'Update' }} Schedule
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="deleteSchedule"
      centered
      hide-footer
      hide-header
      :visible="show && action === 'delete'"
      @hide="closeScheduleModal"
    >
      <div class="external__modal-button">
        <b-button @click="closeScheduleModal()" class="text-white" variant="clear">
          <svg-icon name="close-alt" size="md" />
        </b-button>
      </div>
      <div v-if="isDeletingSchedule" class="text-center py-5">
        <spinner size="4" />
      </div>
      <div v-else class="text-center py-5">
        <h4>Delete Schedule?</h4>
        <p>Are you sure you would like to delete this schedule?</p>

        <b-button variant="clear" class="px-4 mr-2" @click="closeScheduleModal">Cancel</b-button>
        <b-button class="px-4" :disabled="isDeletingSchedule" @click="actuallyDeleteSchedule">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Form from 'vform';
export default {
  name: 'UpdateSchedule',

  components: {
    VueTagsInput,
  },

  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'update',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: '',
      isDeletingSchedule: false,
      intervals: [
        {
          id: 1,
          title: 'weekly',
          slug: 'weekly',
          description: "Every Monday you'll be sent a report of the previous week.",
        },
        {
          id: 2,
          title: 'monthly',
          slug: 'monthly',
          description: "On 2nd of every month you'll be sent a report of the previous month.",
        },
      ],
      send_to_emails: [],
      emailInputLength: 25,
      form: new Form({
        name: '',
        type: '',
        accounts: [],
        interval: 'weekly',
        copy: '',
        emailInput: '',
      }),
    };
  },

  created() {
    this.form.keys().forEach(key => {
      this.form[key] = this.schedule[key];
    });
  },

  methods: {
    selectInterval(interval) {
      this.form.interval = interval.slug;
    },
    selectAccount(account) {
      if (!this.form.accounts.includes(account.id)) {
        this.form.accounts.push(account.id);
      } else {
        this.deselectAccount(account.id);
      }
    },
    deselectAccount(value) {
      var filtered = this.form.accounts.filter(function(ele) {
        return ele != value;
      });
      this.form.accounts = filtered;
    },
    isAccountActive(account) {
      return this.form.accounts.includes(account.id);
    },
    closeScheduleModal() {
      this.$emit('close-schedule-modal');
    },

    async actuallyDeleteSchedule() {
      // TODO: implement
    },

    async handleSaveSchedule() {
      // TODO: implement
      if (this.schedule && this.schedule.id) {
        this.handleUpdateSchedule(this.schedule);
      } else {
        this.handleCreateSchedule();
      }
    },

    async handleUpdateSchedule(schedule) {
      // TODO: implement
      console.log(schedule);
    },
    async handleCreateSchedule() {
      // TODO: implement
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
.schedule__form {
  .form_label {
    margin: 6px 0;
    display: inline-block;
  }
  .schedule__account {
    padding: 0;
    .account__container {
      border: 2px solid $gray;
      padding: 2px;
      background: $white;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .account__item {
        display: flex;
        align-items: center;
        margin: 6px;
        padding: 2px 10px;
        border: 3px solid $gray;
        border-radius: 50px;
        cursor: pointer;
        &.active {
          border-color: $primary;
        }
        &-status {
          //
        }
        &-image {
          position: relative;
          flex-wrap: wrap;
          margin: 2px 6px;
          display: inline-flex;
          .account-icon {
            display: inline-flex;
            position: absolute;
            bottom: -6px;
            right: -6px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            padding: 0 0 4px 2px;
            > .icon {
              width: 14px;
              height: 14px;
              margin: 4px 0 0 0;
              color: $white;
            }
            &.facebook {
              background-color: #3b5998;

              > .icon {
                width: 13px;
                height: 13px;
                margin: 3px 0 0 0;
              }
            }

            &.twitter {
              background-color: #3d82b6;
            }
            &.linkedin {
              background-color: #46e1c4;
            }
            &.wordpress {
              background-color: #ae7c31;
            }

            &.medium {
              background-color: #9e3192;
            }
            > .icon {
              display: inline-flex;
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
  .interval__container {
    .interval {
      padding: 10px;
      border-radius: 10px;
      border: 2px solid $gray;
      display: flex;
      align-items: center;
      cursor: pointer;
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
      &.active {
        border-color: $primary;
      }
      .marker {
        width: 1rem;
        height: 0.9rem;
        border: 2px solid $gray;
        border-radius: 50%;
        margin-right: 10px;
        &.active {
          border-color: $primary;
          background: $primary;
          padding: 1px;
        }
      }
    }
  }
  .vue-tags-input {
    max-width: 100% !important;
    border-radius: 10px;
  }
  .form-control {
    &::placeholder {
      color: $gray-400;
      font-size: 0.75rem;
    }
  }
}
</style>
