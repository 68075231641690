<template>
  <form class="menububble__sayas-form" @submit.prevent="update">
    <div class="inputs-wrap">
      <span class="bubble-label">Say as:</span>
      <b-dropdown variant="clear" size="sm" class="sayas-dropdown" offset="20" right>
        <template v-slot:button-content>
          {{ selectedInterpret.label }}
        </template>

        <simplebar data-simplebar-auto-hide="false" class="menu-scroll">
          <b-dropdown-item
            v-for="interpret in interpretations"
            :key="interpret.name"
            :class="{ active: selectedInterpret.name === interpret.name }"
            @click="interpretValue = interpret.name"
          >
            <div class="option-title">{{ interpret.label }}</div>
            <p class="option-description">{{ interpret.description }}</p>
          </b-dropdown-item>
        </simplebar>
      </b-dropdown>

      <template v-if="selectedInterpret.name === 'date'">
        <span class="bubble-label">Format:</span>
        <b-dropdown variant="clear" size="sm" class="sayas-dropdown" offset="20" right>
          <template v-slot:button-content>
            {{ selectedDateFormat.label }}
          </template>

          <simplebar data-simplebar-auto-hide="false" class="menu-scroll">
            <b-dropdown-item
              v-for="format in dateFormats"
              :key="format.name"
              :class="{ active: selectedDateFormat.name === format.name }"
              @click="formatValue = format.name"
            >
              <div class="option-title">{{ format.label }}</div>
              <p class="option-description">{{ format.description }}</p>
            </b-dropdown-item>
          </simplebar>
        </b-dropdown>
      </template>
    </div>

    <b-button type="submit" variant="primary" size="sm">
      <svg-icon name="check" />
    </b-button>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: { simplebar },

  props: {
    active: {
      default: null,
    },

    format: {
      default: null,
    },

    command: {
      required: true,
    },
  },

  data() {
    return {
      interpretValue: this.active,
      formatValue: this.format,
    };
  },

  computed: {
    ...mapState({
      interpretations: state => state.voiceEditor.sayAsInterpretations,
      dateFormats: state => state.voiceEditor.sayAsDateFormats,
    }),

    selectedInterpret() {
      const name = this.interpretValue;

      return this.interpretations.find(i => i.name === name);
    },

    computedFormat() {
      return this.formatValue || 'mdy';
    },

    selectedDateFormat() {
      const name = this.computedFormat || 'mdy';

      return this.dateFormats.find(f => f.name === name);
    },
  },

  methods: {
    update() {
      const interpretAs = { 'interpret-as': this.interpretValue };
      const attrs = this.interpretValue === 'date' ? { format: this.computedFormat, ...interpretAs } : interpretAs;

      this.command(attrs);

      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menububble__sayas-form {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
  display: flex;

  .btn {
    box-shadow: none;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .inputs-wrap {
    padding: 0.3rem 1rem;
  }

  .bubble-label {
    font-weight: bold;
    font-size: 0.95rem;
  }

  .sayas-dropdown {
    .dropdown-toggle {
      box-shadow: none;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
      background-color: #fff;
      border: 1px solid rgba(102, 107, 133, 0.05);
      min-width: 220px;
      border-radius: 5px;
      background-clip: padding-box;

      .menu-scroll {
        max-height: 300px;
        overflow: auto;

        .simplebar-scrollbar:before {
          border-radius: 5px;
          background: $gray-600;
        }
      }

      .dropdown-item {
        line-height: 1.9;
        padding: 0.4rem 0.9rem;

        .option-title {
          font-size: 0.8rem;
          font-weight: bold;
          color: $gray-600;
        }
        .option-description {
          font-size: 0.7rem;
          white-space: normal;
          margin: 0;
          line-height: 1.4;
        }

        &:active {
          background-color: $gray-100;
          color: $gray-800;
        }
      }

      li {
        &.active {
          .dropdown-item {
            background-color: $gray-100;
          }
        }
        &:first-child {
          > a {
            border-radius: 10px 10px 0 0;
          }
        }

        &:last-child {
          > a {
            border-radius: 0 0 10px 10px;
          }
        }
      }
    }
  }
}
</style>
