<template>
  <b-dropdown
    v-b-tooltip.hover.bottom
    title="Select accounts"
    ref="dropdown"
    variant="clear"
    class="custom-dropdown accounts-select"
    toggle-class="select-button"
    boundary="window"
    size="sm"
    right
    no-flip
    no-caret
  >
    <template v-slot:button-content>
      <span class="accounts">
        <template v-if="$apollo.queries.publishAccounts.loading">
          <span class="account" :style="getAccountStyleFor(0)">
            <svg-icon name="linkedin" />
          </span>
          <span class="account" :style="getAccountStyleFor(1)">
            <svg-icon name="twitter" />
          </span>
          <span class="account" :style="getAccountStyleFor(2)">
            <svg-icon name="facebook" />
          </span>
        </template>
        <span class="account" v-for="(account, index) in accounts" :key="account.id" :style="getAccountStyleFor(index)">
          <svg-icon :name="account.type.platform" />
        </span>
      </span>

      <span class="text"> {{ enabled.length }} Accounts Connected </span>

      <svg-icon name="chevron-down" />
    </template>
    <ul class="inner">
      <li
        v-for="account in accounts"
        :key="account.id"
        class="list_item_li"
        :class="{ is_enabled_account: isEnabled(account) }"
        @click="toggleEnableAccount(account)"
      >
        <svg-icon v-if="isEnabled(account)" name="check" />
        <p>{{ account.name }}</p>
        <p class="account_description">{{ account.description }}</p>
      </li>
    </ul>
  </b-dropdown>
</template>

<script>
import { get, find, isEmpty, isArray } from 'lodash';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { createNamespacedHelpers } from 'vuex';

const workspaceHelper = createNamespacedHelpers('workspace');

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      enabled: [],
      publishAccounts: [],
    };
  },

  computed: {
    accounts() {
      return this.publishAccounts;
    },
    selectedAccounts() {
      return this.accounts.filter(({ id }) => this.enabled.includes(id));
    },
    isEnabled() {
      return account => {
        return !!find(this.enabled, accountId => String(get(account, 'id', 0)) === String(accountId));
      };
    },
    getAccountStyleFor() {
      return index => {
        return { transform: `translateX(${index * -10}px)` };
      };
    },
    ...workspaceHelper.mapState({
      workspace: state => state.current,
    }),
  },

  apollo: {
    publishAccounts: {
      query: PUBLISH_ACCOUNTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
  },

  created() {
    this.enabled = this.value;
  },

  watch: {
    publishAccounts(accounts) {
      if (isEmpty(this.enabled) && isArray(accounts)) {
        this.enabled = accounts.map(({ id }) => id);
      }
    },
    enabled: {
      handler(val) {
        this.$emit('input', val);
      },
      immediate: true,
    },
  },

  methods: {
    toggleEnableAccount(account) {
      const isEnabled = this.isEnabled(account);
      const accountId = String(get(account, 'id', -1));

      if (isEnabled) {
        this.enabled = this.enabled.filter(enabledAccountId => accountId !== String(enabledAccountId));
      } else {
        this.enabled.push(parseInt(accountId, 10));
      }

      this.$refs.dropdown.hide(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.select-button {
  background-color: $gray-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;

  background: #f5f9fc;
  min-width: 110px;

  .icon {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0;
  }
}

.accounts-select {
  ::v-deep {
    .dropdown-toggle {
      display: flex;
      flex-wrap: nowrap;
      border: 1px solid #e5e5e5;
      background-color: #f4f4f4;
      padding: 14px 20px;
      border-radius: 10px;
      align-items: center;
      justify-content: flex-start;
      max-width: 300px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #e2e2e2;
      }

      > * {
        padding: 0;

        &:not(:first-child) {
          margin: 0 0 0 10px;
        }
      }

      > .accounts {
        display: inline-flex;
        max-width: 50%;
        flex-shrink: 0;
        flex-grow: 0;

        > .account {
          display: inline-flex;
          width: 39px;
          height: 39px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          background-color: #c4c4c4;
          flex-shrink: 0;

          > .icon {
            width: 20px;
            height: 20px;
          }
        }

        > * {
          &:nth-child(odd) {
            background-color: #c4c4c4;
            color: #e9e9e9;

            > .icon {
              fill: #e9e9e9;
            }
          }

          &:nth-child(even) {
            background-color: #ffffff;
            color: #c4c4c4;

            > .icon {
              fill: #c4c4c4;
            }
          }
        }
      }

      > .text {
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
        display: inline-flex;
        align-content: flex-start;
        justify-content: flex-start;
        max-width: 40%;
        white-space: normal;
        word-wrap: break-word;
      }

      > .icon {
        margin: 0 0 0 auto;
      }
    }

    .inner {
      max-height: 300px;
      overflow: auto;
      padding: 10px 0;
    }

    .dropdown-menu {
      min-width: 300px;
      max-width: 350px;

      &:after,
      &:before {
        display: none;
      }

      li {
        position: relative;

        &.list_item_li {
          padding: 7px 20px;
          padding-right: 30px;
          display: block;
          color: #3a4557;
          font-size: 14px;
          cursor: pointer;

          .icon {
            position: absolute;
            right: 12px;
            color: #1ecb7b;
            top: 50%;
            transform: translateY(-50%);
          }

          &:hover {
            background: #f5f9fc;
          }
        }
      }
    }

    .account_description {
      font-size: 12px;
      color: #989eb5;
    }
  }
}
</style>
