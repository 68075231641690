<template>
  <div class="break-wrap">
    <span :id="`break-${uuid}`" class="pause-indicator">
      <svg-icon name="pause" />
    </span>
    <b-tooltip :target="`break-${uuid}`" variant="primary" triggers="hover" placement="bottom">
      Pause: {{ selectedPause.label }}
    </b-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';

let uuid = 0;

export default {
  props: ['node'],

  computed: {
    ...mapState({
      strengths: state => state.voiceEditor.pauseStrengths,
    }),

    selectedPause() {
      const name = this.node.attrs['strength'];

      return this.strengths.find(o => o.name === name);
    },
  },

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.break-wrap {
  display: inline-block;
}

.break {
  display: inline-block;
  border-bottom: 1px dashed $info;
}
</style>
