<template>
  <div class="timepicker-wrap border border-primary rounded-lg">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center mb-3">
        <div class="hour-wrap" ref="hourList">
          <div @click="isHourPickerOpen = true" class="input hour">{{ formatHour(hour) }}</div>

          <simplebar
            v-show="isHourPickerOpen"
            class="time-picker__list time-picker__list--hours"
            v-click-outside="hideHourPicker"
          >
            <div
              class="time-picker__item"
              v-for="hour in hours"
              :key="`h${hour.number}`"
              @click="selectHour(hour)"
              :class="{ 'time-picker__item--selected': hour.selected, 'time-picker__item--disabled': hour.disabled }"
            >
              {{ formatHour(hour.number) }}
            </div>
          </simplebar>
        </div>
        <div class="sep">:</div>
        <div class="minute-wrap" ref="minuteList">
          <div @click="isMinutePickerOpen = true" class="input minute">{{ pad(minute) }}</div>

          <simplebar
            v-show="isMinutePickerOpen"
            class="time-picker__list time-picker__list--minutes"
            v-click-outside="hideMinutePicker"
          >
            <div
              class="time-picker__item"
              v-for="minute in minutes"
              :key="`m${minute.number}`"
              @click="selectMinute(minute)"
              :class="{
                'time-picker__item--selected': minute.selected,
                'time-picker__item--disabled': minute.disabled,
              }"
            >
              {{ minute.number }}
            </div>
          </simplebar>
        </div>
      </div>
      <div class="d-flex">
        <div @click="selectSuffix('am')" class="prefix" :class="{ active: hour < 12 }">AM</div>
        <div @click="selectSuffix('pm')" class="prefix" :class="{ active: hour >= 12 }">PM</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

function hours(step) {
  return new Array(Math.ceil(24 / step)).fill(null).map((item, index) => index * step);
}

function minutes(step) {
  return new Array(Math.ceil(60 / step)).fill(null).map((item, index) => index * step);
}
function timeComponentIsDisabled(min, max, component) {
  return (min && component < min) || (max && component > max);
}

export default {
  name: 'PostTimePicker',

  components: { simplebar },

  props: {
    date: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      default: 'AM',
    },
    use12Hour: {
      type: Boolean,
      default: true,
    },
    hourStep: {
      type: Number,
      default: 1,
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    minTime: {
      type: String,
      default: null,
    },
    maxTime: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isHourPickerOpen: false,
      isMinutePickerOpen: false,
    };
  },

  computed: {
    hour() {
      return parseInt(moment(this.date).format('H'));
    },
    minute() {
      return parseInt(moment(this.date).format('m'));
    },
    computedSuffix() {
      return this.hour < 12 ? 'AM' : 'PM';
    },
    hours() {
      return hours(this.hourStep)
        .filter(hour => {
          if (!this.use12Hour) {
            return true;
          } else {
            if (this.hour < 12) {
              return hour < 12;
            } else {
              return hour >= 12;
            }
          }
        })
        .map(hour => ({
          number: this.pad(hour),
          selected: hour === this.hour,
          disabled: timeComponentIsDisabled(this.minHour, this.maxHour, hour),
        }));
    },
    minutes() {
      return minutes(this.minuteStep).map(minute => ({
        number: this.pad(minute),
        selected: minute === this.minute,
        disabled: timeComponentIsDisabled(this.minMinute, this.maxMinute, minute),
      }));
    },
    minHour() {
      return this.minTime ? parseInt(this.minTime.split(':')[0]) : null;
    },
    minMinute() {
      return this.minTime && this.minHour === this.hour ? parseInt(this.minTime.split(':')[1]) : null;
    },
    maxHour() {
      return this.maxTime ? parseInt(this.maxTime.split(':')[0]) : null;
    },
    maxMinute() {
      return this.maxTime && this.maxHour === this.hour ? parseInt(this.maxTime.split(':')[1]) : null;
    },
  },

  methods: {
    hideHourPicker(event) {
      const isClickOnFabButton = event.target.closest('.input.hour');
      const isClickOnPanel = event.target.closest('.time-picker__list--hours');

      if (!isClickOnFabButton && !isClickOnPanel) {
        this.isHourPickerOpen = false;
      }
    },
    hideMinutePicker(event) {
      const isClickOnFabButton = event.target.closest('.input.minute');
      const isClickOnPanel = event.target.closest('.time-picker__list--minutes');

      if (!isClickOnFabButton && !isClickOnPanel) {
        this.isMinutePickerOpen = false;
      }
    },
    selectHour(hour) {
      if (hour.disabled) {
        return;
      }

      const time = moment(this.date)
        .hour(hour.number)
        .format('YYYY-MM-DDTHH:mm:ss');
      this.$emit('change', time);

      this.isHourPickerOpen = false;
    },
    selectMinute(minute) {
      if (minute.disabled) {
        return;
      }
      const time = moment(this.date)
        .minute(minute.number)
        .format('YYYY-MM-DDTHH:mm:ss');
      this.$emit('change', time);

      this.isMinutePickerOpen = false;
    },
    selectSuffix(suffix) {
      if (suffix === 'am') {
        if (this.hour >= 12) {
          const time = moment(this.date)
            .hour(this.hour - 12)
            .format('YYYY-MM-DDTHH:mm:ss');
          this.$emit('change', time);
        }
      }
      if (suffix === 'pm') {
        if (this.hour < 12) {
          const time = moment(this.date)
            .hour(this.hour + 12)
            .format('YYYY-MM-DDTHH:mm:ss');
          this.$emit('change', time);
        }
      }
    },
    formatHour(hour) {
      const numHour = Number(hour);
      if (this.use12Hour) {
        if (numHour === 0) {
          return 12;
        }
        if (numHour > 12) {
          return numHour - 12;
        }
        return numHour;
      }
      return hour;
    },
    pad(number) {
      return number < 10 ? '0' + number : number;
    },
  },

  mounted() {
    const selectedHour = this.$refs.hourList.querySelector('.time-picker__item--selected');
    const selectedMinute = this.$refs.minuteList.querySelector('.time-picker__item--selected');
    this.$refs.hourList.scrollTop = selectedHour ? selectedHour.offsetTop - 250 : 0;
    this.$refs.minuteList.scrollTop = selectedMinute ? selectedMinute.offsetTop - 250 : 0;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.timepicker-wrap {
  min-height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background: #f2f2f2;

  .input,
  .sep {
    font-size: 45px;
    font-weight: bold;
    line-height: 1;
  }
  .input {
    padding: 0 5px;
    border: 1px solid transparent;

    &:hover {
      cursor: text;
      border-color: rgba($primary, 0.3);
      background-color: rgba($primary, 0.2);
      border-radius: 5px;
    }
  }
  .sep {
    margin-right: 2px;
    margin-left: 2px;
  }

  .prefix {
    font-size: 25px;
    font-weight: bold;
    padding: 0 15px;
    border-radius: 10px;
    cursor: pointer;

    &:last-of-type {
      margin-left: 15px;
    }

    &.active {
      background: $primary;
      color: $white;
    }
  }
  .hour-wrap,
  .minute-wrap {
    position: relative;
  }
}
.selected-time {
  font-size: 25px;
}
.time-picker__list {
  height: 328px;
  overflow-y: auto;
  width: 80px;
  background-color: $white;
  box-shadow: 0 1px 10px rgba($gray-400, 0.5);
  border-radius: 5px;
  position: absolute;
  z-index: 999;
  top: calc(-328px / 2);
  left: -20px;

  .simplebar-scrollbar {
    width: 6px;
  }

  .simplebar-scrollbar:before {
    border-radius: 5px;
    background: rgba($primary, 0.7);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }
}
.minute-wrap {
  .time-picker__list {
    width: 90px;
    left: -14px;
  }
}
.time-picker__item {
  padding: 10px 0;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}
.time-picker__item:hover {
  font-size: 32px;
}
.time-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}
.time-picker__item--disabled {
  opacity: 0.4;
  cursor: default;
  font-size: 20px !important;
}
</style>
