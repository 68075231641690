<template>
  <simplebar class="planner-day-scroll"><slot /></simplebar>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: { simplebar },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.planner-day-scroll {
  position: relative;
  padding-top: 10px;
  height: 170px;
  overflow: auto;
  background-color: $gray-100;
  box-shadow: inset 0px 8px 4px -9px $gray-300, inset 0px -8px 4px -9px $gray-200;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0.1s;

  &.loading {
    @include skeleton-animation($gray-100, 0.9s);
  }

  .simplebar-scrollbar {
    width: 6px;
  }

  .simplebar-scrollbar:before {
    background: rgba($secondary, 0.7);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }

  &.weekview {
    height: 270px;
  }
}
</style>
