<template>
  <div class="posts-page-wrap">
    <b-container class="mb-5">
      <div class="post__nav">
        <b-button
          class="post__nav-btn"
          :class="[activeStatus === 'all' ? 'post__nav--active' : ' ']"
          variant="clear"
          @click="setActiveStatus('all')"
        >
          <svg-icon name="posts" class="mr-1" size="md" />
          All Posts
        </b-button>
        <b-button
          class="post__nav-btn"
          :class="[activeStatus === 'published' ? 'post__nav--active' : ' ']"
          variant="clear"
          @click="setActiveStatus('published')"
        >
          <svg-icon name="published" class="mr-1" size="md" />
          Published
        </b-button>
        <b-button
          class="post__nav-btn"
          :class="[activeStatus === 'scheduled' ? 'post__nav--active' : ' ']"
          variant="clear"
          @click="setActiveStatus('scheduled')"
        >
          <svg-icon name="calendar" class="mr-1" size="md" />
          Scheduled
        </b-button>
        <b-button
          class="post__nav-btn"
          :class="[activeStatus === 'draft' ? 'post__nav--active' : ' ']"
          variant="clear"
          @click="setActiveStatus('draft')"
        >
          <svg-icon name="edit-alt" class="mr-1" size="md" />
          Drafts
        </b-button>
      </div>

      <b-row class="mt-5">
        <b-col>
          <div v-if="$apollo.queries.singlePosts.loading" class="bg-white shadow-sm rounded text-center py-5">
            <spinner size="4" />
          </div>

          <div v-if="posts && posts.length > 0 && !$apollo.queries.singlePosts.loading">
            <div class="text-right px-2 mb-2">
              <span>Showing 1 to {{ posts.length }}</span>
            </div>
            <div class="mp-item thead">
              <div class="mp-item__content">Content</div>
              <div class="mp-item__accounts">Accounts</div>
              <div class="mp-item__status">Status</div>
              <div class="mp-item__stats">Stats</div>
              <div class="mp-item__actions">Actions</div>
            </div>
            <div v-for="post in posts" :key="post.id" class="mp-item shadow-sm rounded" :class="post.status">
              <div class="prewrap mp-item__content">
                <b-badge class="px-2 border" variant="secondary">{{ capitalize(post.type) }} post</b-badge>
                <div class="text-truncate">{{ getAccountPostContent(post) }}</div>
              </div>
              <div class="mp-item__accounts">
                <PublishAccountHeads :accounts="post.accountPostContents.map(con => con.publishAccount)" />
              </div>
              <div class="mp-item__status">
                <b-badge class="p-2" :variant="post.status == 'published' ? 'secondary' : 'warning'">{{
                  capitalize(post.status)
                }}</b-badge>
              </div>
              <div class="mp-item__stats"></div>
              <div class="mp-item__actions">
                <b-button
                  v-b-tooltip.hover
                  title="View post"
                  :to="{ name: 'post.view', params: { id: post.id } }"
                  variant="clear"
                  size="sm"
                  class="mr-1 view__btn"
                >
                  <svg width="20" height="20" viewBox="0 0 21 16" fill="custom" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.9235 7.6C17.9035 2.91 14.1035 0 10.0035 0C5.90348 0 2.10348 2.91 0.0834848 7.6C0.0284215 7.72617 0 7.86234 0 8C0 8.13766 0.0284215 8.27383 0.0834848 8.4C2.10348 13.09 5.90348 16 10.0035 16C14.1035 16 17.9035 13.09 19.9235 8.4C19.9785 8.27383 20.007 8.13766 20.007 8C20.007 7.86234 19.9785 7.72617 19.9235 7.6ZM10.0035 14C6.82348 14 3.83348 11.71 2.10348 8C3.83348 4.29 6.82348 2 10.0035 2C13.1835 2 16.1735 4.29 17.9035 8C16.1735 11.71 13.1835 14 10.0035 14ZM10.0035 4C9.21236 4 8.439 4.2346 7.7812 4.67412C7.12341 5.11365 6.61072 5.73836 6.30797 6.46927C6.00522 7.20017 5.926 8.00444 6.08034 8.78036C6.23468 9.55628 6.61565 10.269 7.17506 10.8284C7.73447 11.3878 8.4472 11.7688 9.22312 11.9231C9.99905 12.0775 10.8033 11.9983 11.5342 11.6955C12.2651 11.3928 12.8898 10.8801 13.3294 10.2223C13.7689 9.56448 14.0035 8.79113 14.0035 8C14.0035 6.93913 13.5821 5.92172 12.8319 5.17157C12.0818 4.42143 11.0644 4 10.0035 4ZM10.0035 10C9.60792 10 9.22124 9.8827 8.89234 9.66294C8.56345 9.44318 8.3071 9.13082 8.15573 8.76537C8.00435 8.39991 7.96474 7.99778 8.04191 7.60982C8.11908 7.22186 8.30957 6.86549 8.58927 6.58579C8.86898 6.30608 9.22534 6.1156 9.6133 6.03843C10.0013 5.96126 10.4034 6.00087 10.7689 6.15224C11.1343 6.30362 11.4467 6.55996 11.6664 6.88886C11.8862 7.21776 12.0035 7.60444 12.0035 8C12.0035 8.53043 11.7928 9.03914 11.4177 9.41421C11.0426 9.78929 10.5339 10 10.0035 10Z"
                    />
                  </svg>
                </b-button>
                <b-button
                  v-if="post.type === 'voice'"
                  v-b-tooltip.hover
                  title="Edit post"
                  :to="{ name: 'compose-audio', params: { id: post.id } }"
                  size="sm"
                  variant="clear"
                  class="mr-1 edit__btn"
                  :disabled="post.status === 'published'"
                >
                  <svg-icon name="edit" size="md" />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  title="Edit post"
                  :to="{ name: 'compose', params: { type: post.type, id: post.id } }"
                  size="sm"
                  variant="clear"
                  class="mr-1 edit__btn"
                  :disabled="post.status === 'published'"
                >
                  <svg-icon name="edit" size="md" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Delete post"
                  variant="clear"
                  size="sm"
                  class="trash__btn"
                  @click="confirmDeletePost(post)"
                >
                  <svg-icon name="trash" size="md" />
                </b-button>
              </div>
            </div>
            <div v-if="loadingMore" class="text-center mt-3"><spinner size="2" /></div>
          </div>

          <template v-if="posts && posts.length < 1 && !$apollo.queries.singlePosts.loading">
            <div class="bg-white shadow-sm rounded text-center p-5">
              <img class="pt-3" width="300" src="@/assets/images/lost.svg" alt="" />
              <h4 class="pt-5">No posts here yet.</h4>
            </div>
          </template>
        </b-col>
      </b-row>

      <b-modal ref="deleteModal" centered hide-footer hide-header>
        <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
        <div v-else class="text-center py-5">
          <h4>Delete post?</h4>
          <p>Are you sure you would like to delete this post?</p>

          <b-button variant="light" class="mr-2" @click="closeConfirmDeletePost">Cancel</b-button>
          <b-button :disabled="isDeleting" @click="actuallyDeletePost">Delete</b-button>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { capitalize } from 'lodash';
import { stripTags } from '~/utils/helpers';
import { POSTS_QUERY } from '~/graphql/queries';
import { DELETE_POST_MUTATION } from '~/graphql/mutations';
import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';
import PublishAccountHeads from '~/components/PublishAccountHeads';

export default {
  name: 'MyPosts',

  mixins: [InfiniteScrollMixin],

  components: { PublishAccountHeads },

  metaInfo() {
    return { title: 'Dashboard' };
  },

  data: function() {
    return {
      isDeleting: false,
      singlePosts: [],
      postToDelete: null,
      activeStatus: 'all',
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    posts() {
      return this.singlePosts ? this.singlePosts.posts : [];
    },
  },

  apollo: {
    singlePosts: {
      query: POSTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          offset: 0,
          status: this.activeStatus,
        };
      },
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (isRockBottom && this.posts && this.posts.length && this.loadMoreEnabled) {
        this.loadMore();
      }
    },
  },

  mounted() {
    if (this.$apollo.queries.singlePosts) {
      this.$apollo.queries.singlePosts.refetch();
    }
  },

  methods: {
    setActiveStatus(status) {
      this.activeStatus = status;
    },

    getAccountPostContent(post) {
      const first = post.accountPostContents[0];
      return stripTags(first ? first.content : post.masterContent || '--');
    },

    async loadMore() {
      this.offset = this.offset + 15;
      this.loadingMore = true;

      // Fetch more data and transform the original result
      await this.$apollo.queries.singlePosts.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newContents = fetchMoreResult.singlePosts.posts;
          const hasMore = fetchMoreResult.singlePosts.hasMore;
          const total = fetchMoreResult.singlePosts.total;

          this.loadMoreEnabled = hasMore;

          return {
            singlePosts: {
              __typename: previousResult.singlePosts.__typename,
              // Merging the tag list
              posts: [...previousResult.singlePosts.posts, ...newContents],
              total,
              hasMore,
            },
          };
        },
      });

      this.loadingMore = false;
    },

    confirmDeletePost(post) {
      this.postToDelete = post;

      this.$refs.deleteModal.show();
    },

    closeConfirmDeletePost() {
      this.postToDelete = null;

      this.$refs.deleteModal.hide();
    },

    async actuallyDeletePost() {
      this.isDeleting = true;
      await this.$apollo.mutate({
        mutation: DELETE_POST_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.postToDelete.id],
        },
        update: (store, { data: { deleted } }) => {
          if (deleted) {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: POSTS_QUERY,
              variables: {
                workspace: this.workspace.id,
                offset: 0,
                status: this.activeStatus,
              },
            });

            const index = data.singlePosts.posts.findIndex(a => a.id === this.postToDelete.id);
            if (index !== -1) {
              data.singlePosts.posts.splice(index, 1);
            }

            // Write our data back to the cache.
            store.writeQuery({
              query: POSTS_QUERY,
              variables: {
                workspace: this.workspace.id,
                offset: 0,
                status: this.activeStatus,
              },
              data,
            });
          }
        },
      });

      this.isDeleting = false;
      this.closeConfirmDeletePost();
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.post__nav {
  background: #d8f3dc;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-btn {
    background: transparent;
    color: $primary;
    padding: 8px 40px;
    &:hover {
      background: $primary;
      color: #d8f3dc;
      svg {
        fill: #d8f3dc;
      }
    }
  }
  &--active {
    background: $primary;
    color: #d8f3dc;
    svg {
      fill: #d8f3dc;
    }
  }
}
.mp-item {
  display: flex;
  align-items: center;
  background-color: $white;
  margin-bottom: 15px;
  padding: 20px;
  position: relative;
  border-radius: 15px;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   height: 100%;
  //   width: 5px;
  //   background-color: $primary;
  //   border-radius: 20px;
  // }

  &.published {
    &::before {
      background-color: $success;
    }
  }

  &.thead {
    text-transform: uppercase;
    background-color: transparent;
    color: $primary;
    font-weight: bold;
    padding: 10px 20px;

    &::before {
      display: none;
    }
  }
  &__content {
    overflow: hidden;
    width: 100%;
  }

  &__accounts {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__status {
    width: 100%;
    text-align: center;
  }

  &__stats {
    width: 100%;
    text-align: center;
  }

  &__actions {
    width: 100%;
    text-align: center;
    svg {
      fill: #c4c4c4;
    }
    .view__btn {
      &:hover {
        background: #d8f3dc;
        svg {
          fill: $primary;
        }
      }
    }
    .edit__btn {
      &:hover {
        background: #d8f3dc;
        svg {
          fill: $primary;
        }
      }
    }
    .trash__btn {
      &:hover {
        background: #d8f3dc;
        svg {
          fill: red;
        }
      }
    }
  }
}
</style>
