<template>
  <div class="planner-weekview-container">
    <div class="planner-week">
      <template v-for="day in calendar.days">
        <div
          :key="day.dayIdentifier"
          class="week-planner-day"
          :class="{
            'week-planner-day-today': day.currentDay,
            'week-planner-day-past': day.currentOffset < 0,
            'week-planner-day-future': day.currentOffset > 0,
          }"
        >
          <div class="planner-weekday">
            <PlannerDayHeader :day="day" :canAddFrom="canAddFrom" />

            <PlannerDayContainer class="weekview" :class="{ loading }">
              <div v-if="getDayPosts(day).length < 1" class="planner-empty-day">{{ day.dayOfMonth }}</div>
              <PlannerCalendarPost v-for="dayPost in getDayPosts(day)" :key="dayPost.id" :post="dayPost" />
            </PlannerDayContainer>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Calendar, Day } from 'dayspan';
import PlannerDayHeader from '~/components/PlannerDayHeader';
import PlannerDayContainer from '~/components/PlannerDayContainer';
import PlannerCalendarPost from '~/components/PlannerCalendarPost';

export default {
  name: 'plannerDaysView',

  components: { PlannerCalendarPost, PlannerDayHeader, PlannerDayContainer },

  props: {
    calendar: {
      required: true,
      type: Calendar,
    },

    posts: {
      type: Array,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    canAddFrom() {
      return Day.today()
        .toDate()
        .getTime();
    },
  },

  methods: {
    getDayPosts(day) {
      const dayMonth = day.format('YYYY MM DD');

      const posts = this.posts.filter(post => {
        const postDay = moment(post.utcScheduleDate).format('YYYY MM DD');
        return postDay === dayMonth;
      });
      return posts || [];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.planner-weekview-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  margin-right: -1px;

  .planner-week {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .planner-weekday {
    border-right: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    background-color: $white;
    padding-bottom: 15px;

    &:hover {
      .planner-day-header-btn {
        opacity: 1;
      }
    }

    .planner-empty-day {
      font-size: 3em;
      line-height: 1.4;
      color: $gray-200;
      font-weight: lighter;
      position: absolute;
      right: 5px;
      bottom: 0;
      user-select: none;
    }
  }
}
</style>
