<template>
  <div class="d-flex flex-wrap">
    <div v-for="item in items" :key="item + 1" class="dashboard-article-item-skeleten shadow-sm mb-4">
      <skeleton-screen width="100%" height="150px" class="img-skeleton mb-4" />
      <div class="px-4">
        <skeleton-screen width="100%" class="mb-2" />
        <skeleton-screen width="90%" class="mb-2" />
        <skeleton-screen width="98%" class="mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard-article-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 4,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.dashboard-article-item-skeleten {
  margin-right: 10px;
  min-width: 226px;
  height: 272px;
  border-radius: 10px;
  border: 1px solid $gray-200;

  .img-skeleton {
    border-radius: 10px;
  }
}
</style>
