<template>
  <div class="d-flex">
    <div class="rules-filters auto-box shadow-sm rounded-sm" :class="{ collapsed }">
      <div class="auto-box-inner">
        <h4 class="mb-5">Rules And Filters</h4>

        <b-form-group label="Channel type" class="mb-4">
          <b-form-radio-group id="source-options" :checked="source" @change="handleSourceChange" name="source-options">
            <b-form-radio value="youtube">Youtube</b-form-radio>
            <b-form-radio value="dailymotion">Dailymotion</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-row class="mb-4">
          <b-col align-self="center" md="3"> <label for="region">Sorting result</label> </b-col>
          <b-col md="6">
            <b-form-select :value="sorting" :options="sortingOptions" @change="handleSortingChange"> </b-form-select>
          </b-col>
        </b-row>

        <b-form-group label-for="keywords">
          <template slot="label">
            Add Keyword
            <svg-icon
              v-if="source == 'dailymotion'"
              name="help"
              class="help-icon"
              v-b-tooltip.hover
              title="Only a single keyword is allowed for Dailymotion."
            />
          </template>

          <vue-tags-input
            v-model="keywordInput"
            :class="tagInputClasses"
            :tags="keywords"
            :add-on-key="[13, ',', ';']"
            :allow-edit-tags="true"
            :placeholder="tagInputPlaceholder"
            :max-tags="source == 'dailymotion' ? 1 : undefined"
            @tags-changed="handleKeywordsChange"
          />
        </b-form-group>
      </div>
    </div>

    <transition name="preview-slide">
      <div v-if="collapsed" class="video-preview rounded-sm shadow-sm" :class="{ stick: isScrollPassed }">
        <div class="preview-header text-center"><h4>Result Preview</h4></div>
        <simplebar class="preview-scroll">
          <p class="preview-desc">This is the type of content you will get through this automation campaign.</p>

          <div class="preview-items-wrap">
            <div v-if="isPreviewContentsLoading" class="text-center"><b-spinner variant="primary" /></div>
            <div v-else-if="previewContents.videos && previewContents.videos.length > 0 && !isPreviewContentsLoading">
              <div v-for="video in previewContents.videos" :key="video.id" class="preview-item">
                <div class="preview-item-body d-flex">
                  <lazy-background-image :src="video.thumb" class="preview-item-image" />
                  <div class="preview-item-main">
                    <h5 class="preview-item-title">{{ video.title }}</h5>
                    <div class="preview-item-time text-muted">{{ video.publishDate | timeAgo }}</div>
                  </div>
                </div>
                <div v-if="source != 'youtube'" class="preview-stats">
                  <div class="stat-item"><svg-icon name="view" class="mt-0" /> {{ numFormat(video.viewCount) }}</div>
                  <div v-if="video.commentCount != null" class="stat-item">
                    <svg-icon name="thumbs-up" class="mr-1" /> {{ numFormat(video.likeCount) }}
                  </div>
                  <div class="stat-item">
                    <svg-icon name="comments" class="mr-1" /> {{ numFormat(video.commentCount) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </simplebar>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { numFormat } from '~/utils/helpers';
import VueTagsInput from '@johmun/vue-tags-input';
import LazyBackgroundImage from '~/components/LazyBackgroundImage';
import { VIDEO_SEARCH_QUERY } from '~/graphql/queries';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'automation-video-rules-and-filters',

  components: {
    simplebar,
    VueTagsInput,
    LazyBackgroundImage,
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollY: 0,
      keywordInput: '',
    };
  },

  computed: {
    tagInputPlaceholder() {
      if (this.keywords.length) {
        return 'Add keywords';
      } else {
        return 'Enter your main keywords or phrases, e.g. Social Media, Big Data...';
      }
    },

    tagInputClasses() {
      if (this.source == 'dailymotion' && this.keywords.length) {
        return 'vue-tags-input-disabled';
      }
      return '';
    },

    isScrollPassed() {
      return this.scrollY > 157;
    },

    sortingOptions() {
      return this.source === 'youtube'
        ? [
            { value: 'date', text: 'Date' },
            { value: 'rating', text: 'Rating' },
            { value: 'relevance', text: 'Relevance' },
            { value: 'title', text: 'Title' },
            { value: 'videoCount', text: 'Video Count' },
            { value: 'viewCount', text: 'View Count' },
          ]
        : [
            { value: 'recent', text: 'Recent' },
            { value: 'visited', text: 'Visited' },
            { value: 'visited-hour', text: 'Visited-hour' },
            { value: 'visited-today', text: 'Visited-today' },
            { value: 'visited-week', text: 'Visited-week' },
            { value: 'visited-month', text: 'Visited-month' },
            { value: 'relevance', text: 'Relevance' },
            { value: 'random', text: 'Random' },
            { value: 'trending', text: 'Trending' },
            { value: 'old', text: 'Old' },
            // { value: 'live-audience', text: 'Live-audience' },
            { value: 'least-visited', text: 'Least-visited' },
            // { value: 'live-airing-time', text: 'Live-airing-time' },
          ];
    },
    ...mapState('createAutomation', {
      source: state => state.source,
      sorting: state => state.sorting,
      keywords: state => state.keywords,
      previewContents: state => state.previewContents,
      isPreviewContentsLoading: state => state.isPreviewContentsLoading,
    }),
  },

  filters: {
    timeAgo: function(date) {
      return moment(date).fromNow();
    },
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    fetchPosts(params = {}) {
      if (this.keywords.length < 1) {
        return;
      }

      if (!this.$apollo.queries.previewContents) {
        this.$apollo.addSmartQuery('previewContents', {
          query: VIDEO_SEARCH_QUERY,
          variables: {
            source: this.source,
            sort: this.sorting,
            keywords: this.keywords.map(t => t.text),
          },
          update(data) {
            // The returned value will update
            // the vue property 'pingMessage'
            this.$store.dispatch('createAutomation/updatePreviewContents', data.videoSearch);
          },
          watchLoading(isLoading) {
            // isLoading is a boolean
            // countModifier is either 1 or -1
            this.$store.dispatch('createAutomation/updateIsPreviewContentsLoading', isLoading);
          },
        });
      } else {
        this.$apollo.queries.previewContents.refetch(params);
      }
    },

    handleSourceChange(value) {
      if (value === 'youtube') {
        this.$store.dispatch('createAutomation/updateSorting', 'relevance');
      } else {
        this.$store.dispatch('createAutomation/updateSorting', 'recent');

        if (this.keywords.length > 1) {
          this.$store.dispatch('createAutomation/updateKeywords', [this.keywords[0]]);
        }
      }

      this.$store.dispatch('createAutomation/updateSource', value);

      this.fetchPosts({ source: value, sort: this.sorting });
    },

    handleSortingChange(sorting) {
      this.$store.dispatch('createAutomation/updateSorting', sorting);

      this.fetchPosts({ sort: sorting });
    },

    handleKeywordsChange(keywords) {
      this.$store.dispatch('createAutomation/updateKeywords', keywords);

      this.fetchPosts({ keywords: keywords.map(t => t.text) });
    },

    numFormat,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.rules-filters {
  .vue-tags-input {
    max-width: 100% !important;
  }

  .form-control {
    &::placeholder {
      color: $gray-400;
      font-size: 0.75rem;
    }
  }
  .help-icon {
    cursor: pointer;
  }

  .vue-tags-input-disabled {
    .ti-new-tag-input {
      display: none;
    }
  }
}
.video-preview {
  width: 35%;
  height: 100vh;
  background-color: $white;
  position: absolute;
  right: 0;

  &.stick {
    height: calc(100vh - #{$navbar-height + $topbar-height});
    position: fixed;
    top: $navbar-height + $topbar-height;
    border-radius: 0 !important;
  }

  .preview-scroll {
    overflow-y: auto;
    height: 92%;
  }

  .preview-header {
    border-bottom: 1px solid $gray-100;
    padding: 0.7rem;
    color: $gray-500;
    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .preview-desc {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
    text-align: center;
  }

  .preview-items-wrap {
    padding: 1px 1rem;
  }
  .preview-item {
    box-shadow: 0 1px 5px $gray-200;
    margin-bottom: 15px;
    background-color: lighten(azure, 2);

    .preview-item-body {
      // padding: 10px;
      .preview-item-main {
        padding-top: 15px;
        width: 317px;
      }
    }

    .preview-item-image {
      background-size: cover;
      min-width: 100px;
      max-width: 100px;
      height: 90px;
      margin-right: 15px;
    }

    .preview-item-title {
      font-size: 0.8rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .preview-item-domain,
    .preview-item-time,
    .preview-item-content {
      font-size: 0.7rem;
    }

    .preview-item-content {
      .highlighted {
        display: inline-block;
        background-color: rgba($primary, 0.4);
        padding: 0 1px;
      }
    }

    .preview-item-domain,
    .preview-item-time {
      margin-top: 5px;
      color: $gray-100;
    }

    .preview-stats {
      display: flex;
      justify-content: center;
      background-color: darken($light, 5);
      padding: 5px 10px;

      .stat-item {
        font-size: 13px;
        width: 33%;
        text-align: center;
      }
    }
  }
}
.preview-slide-enter-active {
  animation: preview-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.preview-slide-leave-active {
  animation: preview-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes preview-slide-left {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes preview-slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(475px);
  }
}
</style>
