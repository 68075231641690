<template>
  <div class="integrations__container">
    <b-row class="m-2 sub__container">
      <b-col cols="4">
        <div class="sub__container-left">
          <div class="box_heading">
            <div>
              <svg-icon name="link" size="md" class="mr-2" />
              Link Shortener
            </div>
          </div>
          <div class="radio_input_image mt-3">
            <b-form-radio v-model="shortener" name="shortener" value="none" size="lg" @change="handleShortenerChange">
              No Shortening
            </b-form-radio>
            <p class="mb-0 ml-2">Clicks on links will not be tracked</p>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="sub__container-right">
          <div>
            <form
              v-show="!workspace.bitlyConnected"
              ref="bitlyForm"
              method="POST"
              :action="`${apiUrl}/account/ConnectWithBitly`"
              @submit.prevent="connectBitly"
            >
              <input type="hidden" name="workspace" :value="workspace.id" />
              <b-button type="submit" variant="primary" class="shadow font-weight-bold">Connect</b-button>
            </form>
            <div class="d-flex align-items-center" v-if="workspace.bitlyConnected">
              <b-button variant="outline-primary" size="sm" class="py-2 px-5">
                <span class="text-secondary">Connected with: </span> {{ workspace.bitlyUsername }}
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Refresh"
                variant="clear"
                size="sm"
                class="p-2 mx-1"
                @click="connectBitly"
              >
                <svg-icon name="reload" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Remove"
                variant="secondary"
                size="sm"
                class="p-2 mx-1"
                @click="showDisconnectBitlyModal"
              >
                <svg-icon name="trash" />
              </b-button>
            </div>
          </div>
          <div class="radio_input_image mt-3">
            <b-form-radio
              v-model="shortener"
              name="shortener"
              value="bitly"
              size="lg"
              :disabled="!workspace.bitlyConnected"
              @change="handleShortenerChange"
            >
              Bit.ly
            </b-form-radio>
            <p class="mb-0">Shortened links will have aggregated click tracking.</p>
          </div>

          <div class="multi_radio mt-3">
            <p class="ml-2">Default domain</p>
            <div class="d-flex align-items-center">
              <div class="radio_input_image mr-3">
                <b-form-radio
                  v-model="domain"
                  name="domain"
                  value="j.mp"
                  size="sm"
                  :class="{ active: domain === 'j.mp' }"
                  :disabled="shortener !== 'bitly'"
                  @change="handleDomainChange"
                >
                  j.mp
                </b-form-radio>
              </div>
              <div class="radio_input_image mr-3">
                <b-form-radio
                  v-model="domain"
                  name="domain"
                  value="bitly.com"
                  size="sm"
                  :class="{ active: domain === 'bitly.com' }"
                  :disabled="shortener !== 'bitly'"
                  @change="handleDomainChange"
                >
                  bitly.com
                </b-form-radio>
              </div>
              <div class="radio_input_image mr-3">
                <b-form-radio
                  v-model="domain"
                  name="domain"
                  value="bit.ly"
                  size="sm"
                  :class="{ active: domain === 'bit.ly' }"
                  :disabled="shortener !== 'bitly'"
                  @change="handleDomainChange"
                >
                  bit.ly
                </b-form-radio>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="m-2 sub__container-two align-items-center">
      <b-col cols="4">
        <div class="logo-info">
          <img src="~@/assets/images/new/youzign.png" alt="" />
          <h3 class="font-weight-bold">youzign</h3>
        </div>
      </b-col>
      <b-col>
        <div class="form-content">
          <b-form
            v-if="!workspace.youzignProfile"
            @submit.prevent="saveYouzign"
            @keydown="youzignForm.onKeydown($event)"
          >
            <b-form-group label-cols="8" label-cols-lg="4" label="Youzign Public key" label-for="public-key">
              <b-form-input
                v-model="youzignForm.key"
                id="public-key"
                name="key"
                autocomplete="off"
                placeholder="Enter your Youzign API Public key"
              ></b-form-input>
              <has-error :form="youzignForm" field="key" />
            </b-form-group>

            <b-form-group label-cols="8" label-cols-lg="4" label="Youzign API token" label-for="api-token">
              <b-form-input
                v-model="youzignForm.token"
                id="api-token"
                name="token"
                autocomplete="off"
                placeholder="Enter your Youzign API Token"
              ></b-form-input>
              <has-error :form="youzignForm" field="token" />
            </b-form-group>
            <div class="text-right mt-3">
              <b-button
                type="submit"
                variant="primary"
                size="sm"
                class="shadow font-weight-bold py-2 px-4"
                :disabled="savingYouzign"
              >
                <b-spinner v-if="savingYouzign" small />
                Connect
              </b-button>
            </div>
          </b-form>
          <div v-else class="youzign__profile">
            <img
              :src="workspace.youzignProfile.photo"
              class="youzign__profile-img"
              :alt="workspace.youzignProfile.name"
            />
            <h4 class="youzign__profile-name">{{ workspace.youzignProfile.name }}</h4>
            <p class="youzign__profile-bio">{{ workspace.youzignProfile.bio }}</p>

            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="shadow font-weight-bold px-4 mt-2 mb-3"
              @click="showDisconnectYouzignModal"
            >
              Disconnect Account
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="DisconnectBitlyModal" centered hide-header hide-footer>
      <template v-slot:default="{ hide }">
        <div v-if="isDisconnecting" class="text-center p-5"><spinner /></div>
        <div v-else class="text-center p-5">
          <h5 class="font-weight-bold mb-3">Remove Bitly</h5>
          <p>This action cannot be undone</p>

          <b-button variant="clear" size="sm" @click="hide()" class="mr-3"> Cancel </b-button>
          <b-button size="sm" class="px-4" :disabled="isDisconnecting" @click="disconnectBitly">
            <b-spinner v-if="isDisconnecting" small />
            Remove
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="DisconnectYouzignModal" centered hide-header hide-footer>
      <template v-slot:default="{ hide }">
        <div v-if="disconnectingYouzign" class="text-center p-5"><spinner /></div>
        <div v-else class="text-center p-5">
          <h5 class="font-weight-bold mb-3">Disconnect Youzign</h5>
          <p>This action cannot be undone</p>

          <b-button variant="clear" size="sm" @click="hide()" class="mr-3"> Cancel </b-button>
          <b-button size="sm" class="px-4" :disabled="disconnectingYouzign" @click="disconnectYouzign">
            <b-spinner v-if="disconnectingYouzign" small />
            Disconnect
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';

import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import {
  UPDATE_WORKSPACE_LINK_SHORTENER_MUTATION,
  UPDATE_WORKSPACE_BITLY_DOMAIN_MUTATION,
  DISCONNECT_WORKSPACE_BITLY_MUTATION,
  SAVE_YOUZIGN_CREDENTIALS_MUTATION,
  DISCONNECT_YOUZIGN_MUTATION,
} from '~/graphql/mutations';

export default {
  data() {
    return {
      shortener: 'none',
      domain: 'bit.ly',
      apiUrl: process.env.VUE_APP_API_ROOT,
      isDisconnecting: false,

      savingYouzign: false,
      youzignForm: new Form({
        key: '',
        token: '',
      }),
      disconnectingYouzign: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
    }),
  },

  created() {
    this.shortener = this.workspace.linkShortener;
    this.domain = this.workspace.bitlyDomain;

    if (this.$route.query.bitly) {
      Swal.fire({
        type: 'success',
        title: 'Success',
        text: 'Bitly account has been successfully connected',
        confirmButtonText: 'Okay',
      }).then(() => {
        const query = this.removeBitlyQuery();

        this.$router.push({ query });
      });
    }
  },

  methods: {
    ...mapActions({
      updateCurrentWorkspace: 'workspace/updateCurrent',
    }),

    connectBitly() {
      this.$refs.bitlyForm.submit();
    },

    handleShortenerChange: debounce(function(shortener) {
      this.$apollo
        .mutate({
          mutation: UPDATE_WORKSPACE_LINK_SHORTENER_MUTATION,
          variables: { workspace: this.workspace.id, linkShortener: shortener },
          update: (store, { data: { updateWorkspaceLinkShortener: workspace } }) => {
            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace updated successfully',
          });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    }, 1000),

    handleDomainChange: debounce(function(domain) {
      this.$apollo
        .mutate({
          mutation: UPDATE_WORKSPACE_BITLY_DOMAIN_MUTATION,
          variables: { workspace: this.workspace.id, domain },
          update: (store, { data: { updateWorkspaceBitlyDomain: workspace } }) => {
            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace updated successfully',
          });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    }, 1000),

    showDisconnectBitlyModal() {
      this.$bvModal.show('DisconnectBitlyModal');
    },

    showDisconnectYouzignModal() {
      this.$bvModal.show('DisconnectYouzignModal');
    },

    disconnectBitly() {
      this.isDisconnecting = true;

      this.$apollo
        .mutate({
          mutation: DISCONNECT_WORKSPACE_BITLY_MUTATION,
          variables: { workspace: this.workspace.id },
          update: (store, { data: { disconnectWorkspaceBitly: workspace } }) => {
            this.shortener = workspace.linkShortener;

            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.isDisconnecting = false;

          this.$bvModal.hide('DisconnectBitlyModal');

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace updated successfully',
          });
        })
        .catch(() => {
          this.isDisconnecting = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    updateWorkspace(store, workspace) {
      try {
        const data = store.readQuery({
          query: FETCH_WORKSPACES_QUERY,
        });

        const spaceIndex = data.fetchWorkspaces.findIndex(w => w.id === this.workspace.id);
        if (spaceIndex !== -1) {
          data.fetchWorkspaces.splice(spaceIndex, 1, workspace);

          this.updateCurrentWorkspace(workspace);
        }

        // Write our data back to the cache.
        store.writeQuery({
          query: FETCH_WORKSPACES_QUERY,
          data,
        });
      } catch (e) {
        // Do something with this error
      }
    },

    removeBitlyQuery() {
      let query = {};

      for (let key in this.$route.query) {
        if (key != 'bitly') {
          query[key] = this.$route.query[key];
        }
      }

      return query;
    },

    saveYouzign() {
      this.savingYouzign = true;

      this.$apollo
        .mutate({
          mutation: SAVE_YOUZIGN_CREDENTIALS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            key: this.youzignForm.key,
            token: this.youzignForm.token,
          },
          update: (store, { data: { saveYouzignCredentials: workspace } }) => {
            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.savingYouzign = false;
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Youzign connected successfully',
          });

          this.youzignForm.reset();
        })
        .catch(({ graphQLErrors: errors }) => {
          this.savingYouzign = false;

          const invalid = errors.find(err => err.message == 'InvalidCredentials');
          if (invalid) {
            this.youzignForm.errors.set('token', 'The credentials you entered seems to be incorrect.');
          } else {
            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          }
        });
    },

    disconnectYouzign() {
      this.disconnectingYouzign = true;

      this.$apollo
        .mutate({
          mutation: DISCONNECT_YOUZIGN_MUTATION,
          variables: {
            workspace: this.workspace.id,
          },
          update: (store, { data: { disconnectYouzign: workspace } }) => {
            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.disconnectingYouzign = false;

          this.$bvModal.hide('DisconnectYouzignModal');

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Youzign disconnected successfully',
          });
        })
        .catch(() => {
          this.disconnectingYouzign = false;

          this.$bvModal.hide('DisconnectYouzignModal');

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.integrations__container {
  color: $primary;
  .sub__container {
    border-radius: 10px;
    padding: 30px;
    background: $white;
    width: 100%;
    input[type='radio'] + label {
      cursor: pointer;
      position: relative;
      margin-bottom: 0;
      font-weight: bold;
      color: $primary;
      font-size: 18px;
    }

    &-left {
      .box_heading {
        font-weight: bold;
        font-size: 18px;
        color: $primary;
        background: $secondary;
        padding: 10px 15px;
        border-radius: 10px;
        width: 70%;
        text-align: center;
      }
      .radio_input_image {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &-right {
      border-left: 2px solid $primary;
      padding: 10px 40px;
    }
  }
  .sub__container-two {
    border-radius: 10px;
    padding: 40px;
    background: $white;
    width: 100%;
    .logo-info {
      width: 100%;
      background: $secondary;
      padding: 40px 10px;
      // height: 220px;
      text-align: center;
      border-radius: 10px;
    }
    .form-content {
      .form-control {
        &::placeholder {
          color: $gray-500;
        }
      }
      .youzign__profile {
        width: 300px;
        margin: auto;
        text-align: center;

        &-img {
          max-width: 150px;
          max-height: 500px;
          margin-bottom: 15px;
          border-radius: 50%;
        }

        &-name {
          font-weight: bold;
          font-size: 1rem;
        }

        &-bio {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.integrations-settings-page-wrap {
  .ps_box {
    border-radius: 10px;
    background-clip: padding-box;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.5);

    .box_heading {
      font-weight: bold;
      font-size: 18px;
      color: $primary;
      background: $secondary;
      padding: 10px 15px;
      border-radius: 10px;
      width: 26%;
      text-align: center;
    }

    .box_content {
      padding: 0;

      .head {
        padding: 20px 20px;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(152, 158, 181, 0.1);

        .head_left {
          .radio_input_image {
            display: inline-block;
            vertical-align: middle;

            input[type='radio'] + label {
              cursor: pointer;
              position: relative;
              margin-bottom: 0;
              font-weight: bold;
              color: $gray-600;
            }

            p {
              padding-left: 30px;
              color: $gray-600;
              margin-top: 9px;
            }
          }

          .multi_radio {
            padding-left: 30px;
            margin-top: 9px;

            .custom-radio {
              input[type='radio'] + label {
                color: $gray-500;
              }

              &.active {
                input[type='radio'] + label {
                  color: $gray-600;
                }
              }
            }

            p {
              margin-right: 20px;
              color: #989eb5;
            }
          }
        }

        .head_right {
          min-width: 350px;
          text-align: right;
          margin-left: auto;

          .connected {
            color: #989eb5;
            font-weight: 500;

            span {
              color: $gray-600;
              margin-left: 5px;
              font-weight: bold;
            }
          }

          .action_icons {
            text-align: right;
            margin-top: 10px;

            .icon {
              font-size: 12px;
              display: inline-block;
              margin-left: 7px;
              color: #b7bbcb;
              cursor: pointer;
            }
          }
        }
      }
    }
    .form-control {
      &::placeholder {
        color: $gray-500;
      }
    }

    .youzign-profile-wrap {
      width: 300px;
      margin: auto;
      text-align: center;

      .youzign-img {
        max-width: 150px;
        max-height: 500px;
        margin-bottom: 15px;
        border-radius: 50%;
      }

      .youzign-name {
        font-weight: bold;
        font-size: 1rem;
      }

      .youzign-bio {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
