<template>
  <div class="brand__dashboard">
    <WelcomeBox class="mb-4" />
    <b-row class="brand__dashboard-content">
      <b-col class="left-content">
        <div class="tabs-wrap tabs1">
          <div class="tab-navs">
            <div class="tab-nav" :class="{ active: tab1Active === 'posts' }" @click="tab1Switch('posts')">
              <div class="tab-label">Recent Posts</div>
            </div>
            <div class="tab-nav" :class="{ active: tab1Active === 'campaign' }" @click="tab1Switch('campaign')">
              <div class="tab-label">Recent Campaigns</div>
            </div>
          </div>
          <div class="tab-content">
            <div v-if="tab1Active === 'posts'" class="posts-tab">
              <RecentPostsTabContent @flashCreateButton="flashCreateButton" />
            </div>
            <div v-if="tab1Active === 'campaign'" class="campaigns-tab">
              <RecentCampaignsTabContent />
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="right-content">
        <div class="tabs-wrap tabs1">
          <div class="tab-navs">
            <div class="tab-nav" :class="{ active: tab2Active === 'articles' }" @click="tab2Switch('articles')">
              <div class="tab-label">Trending Articles</div>
            </div>
            <div class="tab-nav" :class="{ active: tab2Active === 'quotes' }" @click="tab2Switch('quotes')">
              <div class="tab-label">Trending Quotes</div>
            </div>
          </div>
          <div class="tab-content">
            <div v-if="tab2Active === 'articles'" class="articles-tab">
              <DiscoverArticlesTabContent />
            </div>
            <div v-if="tab2Active === 'quotes'" class="quotes-tab">
              <DiscoverQuotesTabContent />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <DashboardTutorialModal :visible="showTutorialModal" />
  </div>
</template>

<script>
// import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { mapState } from 'vuex';

import WelcomeBox from '~/components/Dashboard/WelcomeBox';
// import ComposeBox from '~/components/Dashboard/ComposeBox';
import RecentPostsTabContent from '~/components/Dashboard/RecentPostsTabContent';
import RecentCampaignsTabContent from '~/components/Dashboard/RecentCampaignsTabContent';
import DiscoverArticlesTabContent from '~/components/Dashboard/DiscoverArticlesTabContent';
import DiscoverQuotesTabContent from '~/components/Dashboard/DiscoverQuotesTabContent';
import DashboardTutorialModal from '~/components/DashboardTutorialModal';

export default {
  name: 'Dashboard',

  components: {
    // simplebar,
    WelcomeBox,
    // ComposeBox,
    RecentPostsTabContent,
    RecentCampaignsTabContent,
    DiscoverArticlesTabContent,
    DashboardTutorialModal,
    DiscoverQuotesTabContent,
  },

  metaInfo() {
    return { title: 'Overview' };
  },

  data() {
    return {
      tab1Active: 'posts',
      tab2Active: 'articles',
      isFlashCreateBtn: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      showTutorialModal: state => state.auth.user.options.onboarding.dashboard,
    }),
  },

  methods: {
    tab1Switch(tab) {
      this.tab1Active = tab;
    },

    tab2Switch(tab) {
      this.tab2Active = tab;
    },

    flashCreateButton() {
      const element = document.querySelector('#dashboar-create-btn');
      window.scrollTo(0, element.offsetTop);

      // Flash the button for 3 seconds
      this.isFlashCreateBtn = true;
      setTimeout(() => (this.isFlashCreateBtn = false), 1000);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.brand__dashboard {
  padding: 0px 20px 10px 30px;
  margin: 0;
  color: $primary;
  &-content {
    width: 100%;
    // height: 100vh;
    // background: #ffffff;
    // padding: 20px 12px;
    margin-bottom: 10px;
    border-radius: 15px;
    .left-content {
      // border-right: 1px solid #e2e2e1;
    }
  }
}
.tabs-wrap {
  border-radius: 10px;

  .tab-navs {
    display: flex;
    padding: 13px 15px;
    background: white;
    border-radius: 10px 10px 0 0;

    .tab-nav {
      flex-grow: 1;
      width: 50%;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      user-select: none;

      .tab-label {
        font-size: 1rem;
        font-weight: bold;
        background-color: transparent;
        color: $white;
        padding: 10px 20px;
        height: 40px;
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 3;
        color: $primary;
      }

      &.active {
        cursor: default;

        .tab-label {
          background-color: $primary;
          color: #d8f3dc;
          border-radius: 8px;
          // box-shadow: 0px -5px 15px $gray-200;
        }
      }
    }
  }

  .tab-content {
    background: transparent;

    .tabs-scroll {
      min-height: 500px;
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      // border-radius: 0 0 8px 8px;
      background: white;

      .simplebar-scrollbar {
        width: 10px;
        opacity: 0.5;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: darken($primary, 30);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
      }
    }
    .no-result-wrap {
      padding: 5rem 0;
    }

    .tab-footer {
      padding: 15px 25px;
      font-size: 0.9rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      border-radius: 0 0 8px 8px;
      background: white;

      .tab-info {
        color: $gray-500;
      }
    }
  }
}
</style>
