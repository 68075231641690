<template>
  <div class="twitter-report-entry">
    <div class="row header">
      <AccountsListComponent v-model="selectedAccountIds" />
      <div class="actions">
        <b-button class="action other-action border" variant="outline-clear">
          <svg-icon name="analytics.sync" />
          <div class="text">sync</div>
        </b-button>
        <b-button class="action date-range border" variant="outline-secondary">
          <svg-icon name="analytics.calendar" />
          <div class="text">Last 30 days</div>
        </b-button>
        <b-dropdown variant="secondary" right class="action export">
          <template #button-content>
            <div class="d-inline-flex align-center m-0 p-0">
              <div class="text"><svg-icon name="analytics.export" class="" /> export</div>
            </div>
          </template>
          <b-dropdown-item href="#">Export as PDF</b-dropdown-item>
          <b-dropdown-item href="#">Send PDF</b-dropdown-item>
          <b-dropdown-item @click="handleShowScheduleModal(schedule, 'create')">Schedule</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- performances view -->
    <Performance :performances="performances" cols="5" />

    <!-- Post Rate -->
    <BarChart :chart-data="getData('twitterReportData.reports.postRate', {})" contentTitle="Post Rate" />

    <!-- Engagement Rate -->
    <DoughnutChart
      :chart-data="getData('twitterReportData.reports.engagementRate', {})"
      contentTitle="Engagement Rate"
    />

    <!-- Post Density - Daily -->
    <BarChart :chart-data="getData('twitterReportData.reports.postDensity', {})" contentTitle="Post Density - Daily" />

    <!-- Audience Growth -->
    <BarChart :chart-data="getData('twitterReportData.reports.audienceGrowth', {})" contentTitle="Audience Growth" />

    <!-- Followers/Following Rate -->
    <DoughnutChart
      :chart-data="getData('twitterReportData.reports.follow', {})"
      contentTitle="Followers/Following Rate"
    />

    <!-- Awareness through Mentins -->
    <BarChart
      :chart-data="getData('twitterReportData.reports.awareness', {})"
      contentTitle="Awareness through Mentins"
    />

    <!-- Schedule Modal -->
    <ScheduleOptions
      v-if="showScheduleModal && scheduleAction"
      :schedule="activeSchedule"
      :action="scheduleAction"
      :show="showScheduleModal"
      @close-schedule-modal="handleHideScheduleModal"
    />
    <!-- Schedule Modal -->
  </div>
</template>

<script>
import { get } from 'lodash';
import AccountsListComponent from '~/components/Reports/partials/AccountsList.vue';
import Performance from '~/components/Reports/partials/Performance.vue';
import BarChart from '~/components/Reports/partials/BarChart.vue';
import DoughnutChart from '~/components/Reports/partials/DoughnutChart.vue';
import ScheduleOptions from '~/components/Reports/Schedule.vue';

export default {
  name: 'FacebookReport',

  components: {
    AccountsListComponent,
    Performance,
    BarChart,
    DoughnutChart,
    ScheduleOptions,
  },

  data() {
    return {
      selectedAccountIds: [],
      scheduleAction: null,
      showScheduleModal: false,
      activeSchedule: null,
      schedule: {
        id: 1,
        name: '',
        interval: 'weekly',
        type: 'group',
        accounts: [
          {
            id: 1,
            type: 'twitter',
            avatar: '',
            name: 'twitter',
            user: {
              name: 'Jeremiah Iro',
            },
          },
        ],
      },
      twitterReportData: {
        reports: {
          postRate: {
            labels: ['10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan'],
            data: [
              {
                videos: ['29', '28', '3', '6', '9', '19', '18'],
              },
              {
                photos: ['5', '9', '8', '15', '19', '18', '25'],
              },
              {
                links: ['4', '7', '0', '11', '12', '14', '23'],
              },
              {
                text: ['14', '23', '19', '18', '13', '16', '19'],
              },
            ],
          },
          engagementRate: {
            labels: ['retweet', 'likes'],
            data: {
              retweet: 1,
              likes: 2,
            },
          },
          postDensity: {
            labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            data: [
              {
                Posts: ['29', '28', '3', '6', '9', '19', '18'],
              },
            ],
          },
          audienceGrowth: {
            labels: [
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
            ],
            data: [
              {
                Fans: ['5', '9', '8', '15', '19', '18', '25', '29', '28', '3', '6', '9'],
              },
            ],
          },
          follow: {
            labels: ['followers', 'following'],
            data: {
              followers: 12,
              following: 21,
            },
          },
          awareness: {
            labels: [
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
            ],
            data: [
              {
                mentions: ['5', '9', '8', '15', '19', '18', '25', '29', '28', '3', '6', '9'],
              },
            ],
          },
        },
      },
    };
  },

  computed: {
    getData() {
      return (key, defaultVal) => {
        return get(this, key, defaultVal);
      };
    },
    performances() {
      return [
        {
          name: 'fans',
          title: 'fans',
          icon: 'analytics.fans',
          value: this.getData('twitterReportData.reports.total.fans', 0),
        },
        {
          name: 'retweets',
          title: 'retweets',
          icon: 'analytics.like',
          value: this.getData('twitterReportData.reports.total.retweets', 0),
        },
        {
          name: 'following',
          title: 'following',
          icon: 'analytics.chat',
          value: this.getData('twitterReportData.reports.total.following', 0),
        },
        {
          name: 'engagement',
          title: 'engagement',
          icon: 'analytics.chat',
          value: this.getData('twitterReportData.reports.total.engagement', 0),
        },
        {
          name: 'followers',
          title: 'followers',
          icon: 'analytics.chat',
          value: this.getData('twitterReportData.reports.total.followers', 0),
        },
      ];
    },
  },
  methods: {
    handleShowScheduleModal(schedule, action) {
      this.activeSchedule = schedule;
      this.scheduleAction = action;
      this.showScheduleModal = true;
    },
    handleHideScheduleModal() {
      this.activeSchedule = null;
      this.scheduleAction = null;
      this.showScheduleModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.twitter-report-entry {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;

  ::v-deep {
    > .row {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 25px;
      border-radius: 10px;
      outline: initial;
      background-color: #ffffff;
      align-items: center;
      justify-content: space-between;

      &:not(:first-child) {
        margin: 20px 0 0 0;
        padding: 40px 25px;
      }

      &.header {
        > .info {
          margin: 0 0 0 auto;
          display: inline-flex;
          flex-shrink: 1;
        }

        > .actions {
          margin: 0 0 0 70px;

          > .action {
            border: none;
            outline: initial;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin: 0;
            padding: 8px;
            font-size: 12px;
            text-transform: capitalize;
            transition: all 0.3s ease-in-out;

            > .icon {
              width: 18px;
              height: 18px;
              fill: #000000;
              stroke: #000000;
            }

            > .text {
              display: inline-flex;
              margin: 0 0 0 10px;
              color: #000000;
            }

            &.other-action {
              border: 1px solid #b6b6b6;
              background-color: #ffffff;
              color: #000000;

              > .icon {
                width: 19px;
                height: 19px;
              }

              &:hover {
                background-color: #e9e9e9;
              }
            }

            &.export {
              padding: 0 !important;
              background-color: #52b788;
              background: linear-gradient(120deg, #52b788 30%, #8dc9ad 90%);
              color: #ffffff;

              > .icon {
                width: 16px;
                height: 16px;
              }

              &:hover {
                background-color: #57bd8d;
                background: linear-gradient(120deg, #57bd8d 30%, #8dc9ad 90%);
              }
            }

            &:not(:first-child) {
              margin: 0 0 0 25px;
            }
          }
        }
      }
    }
  }
}
</style>
