<template>
  <div class="player-wrap">
    <template v-if="shouldRegenerate">
      <b-button
        id="gen-btn"
        variant="primary"
        class="px-5"
        :class="{ exceeded: charExceeded }"
        @click="generateAudio"
        :disabled="genDisabled"
      >
        <b-spinner v-if="isGenerating" class="mr-1" small />
        <svg-icon v-else name="music" class="mr-1" />
        Generate Audio
      </b-button>
      <b-tooltip v-if="charExceeded" target="gen-btn" triggers="hover" variant="danger">
        Character limit exceeded
      </b-tooltip>
    </template>
    <template v-else>
      <b-progress :value="currentProgressBar" :max="90" class="player-progress"></b-progress>

      <div class="player-time">
        <b-badge class="px-3" pill :class="{ busy: isGenerating }">
          {{ currentTime | fancyTimeFormat }} / {{ trackDuration | fancyTimeFormat }}
        </b-badge>
      </div>

      <div class="player-controls">
        <b-button variant="clear" class=" text-primary rewind-btn" :disabled="isGenerating">
          <svg-icon name="rewind" class="mt-0" />
        </b-button>

        <b-button variant="clear" class=" text-primary play-btn" @click="togglePlay" :disabled="isGenerating">
          <b-spinner v-if="isGenerating" />
          <svg-icon v-else :name="isPlaying ? 'pause-alt' : 'play'" class="mt-0" size="md" />
        </b-button>

        <b-button variant="clear" class=" text-primary next-btn" :disabled="isGenerating">
          <svg-icon name="next" class="mt-0" />
        </b-button>
      </div>

      <div class="player-download">
        <b-button variant="outline-primary" size="sm" class="px-4 py-2" :disabled="isGenerating" @click="downloadAudio">
          <svg-icon name="download-alt" class="mr-1" size="md" />
          Download
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { stripTags } from '~/utils/helpers';

import { SAVE_AUDIO_POST_MUTATION } from '~/graphql/mutations';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },

    voiceId: {
      type: String,
      required: true,
    },

    charCount: {
      type: Number,
      required: true,
    },

    audioUrl: {
      type: String,
      default: null,
    },

    shouldRegenerate: {
      type: Boolean,
      required: true,
    },

    isProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPlaying: false,
      isGenerating: false,
      isGenerated: false,

      audio: '',
      currentlyStopped: false,
      currentTime: 0,
      checkingCurrentPositionInTrack: '',
      trackDuration: 0,
      currentProgressBar: 0,

      generatedAudioUrl: '',
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    isEmpty() {
      return stripTags(this.text) === '';
    },

    genDisabled() {
      return this.isGenerating || this.isEmpty;
    },

    charExceeded() {
      return this.charCount > 10000;
    },
  },

  filters: {
    fancyTimeFormat(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
    },
  },

  watch: {
    currentTime() {
      this.currentTime = Math.round(this.currentTime);
    },

    audioUrl(url) {
      if (this.isPlaying) {
        this.stopAudio();
      }

      if (url) {
        this.initAudio(url);

        this.isGenerated = true;
      }
    },

    isProcessing(isProcessing) {
      this.isGenerating = isProcessing;
    },

    isGenerating(isGenerating) {
      this.$emit('generatingChange', isGenerating);
    },

    '$route.params.id'(postId) {
      if (postId == 0) return;

      this.listenForEvents(postId);
    },
  },

  mounted() {
    const postId = parseInt(this.$route.params.id, 10);

    if (postId != 0) {
      this.listenForEvents(postId);
    }
  },

  beforeDestroy() {
    window.Echo.leave(`voice.${this.$route.params.id}`);

    if (this.audio) {
      this.audio.removeEventListener('ended', this.handleEnded);
      this.audio.removeEventListener('loadedmetadata', this.handleEnded);
    }

    clearTimeout(this.checkingCurrentPositionInTrack);
  },

  methods: {
    listenForEvents(postId) {
      window.Echo.private(`voice.${postId}`).listen('SpeechAudioGenerated', async data => {
        this.isGenerating = false;

        this.initAudio(data.audioUrl);

        this.generatedAudioUrl = data.audioUrl;

        this.isGenerated = true;

        this.$notify({
          group: 'main',
          type: 'native',
          title: 'Audio Generated Successfully',
          text: 'You can now listen, share or download your newly generated Audio.',
          duration: 7000,
        });
      });

      window.Echo.private(`voice.${postId}`).listen('SpeechAudioGenerationFailed', () => {
        this.isGenerating = false;

        this.$emit('failed');

        this.$notify({
          group: 'main',
          type: 'native-error',
          title: 'Failed to generate Audio',
          text: 'This may be due to invalid characters/formating. Please try updating your speech and try again.',
          duration: 7000,
        });
      });
    },

    generateAudio() {
      if (this.charExceeded) {
        return;
      }

      if (this.isEmpty) {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Please write some text',
        });
      }

      if (this.isPlaying) {
        this.stopAudio();
      }

      this.isGenerating = true;

      return this.$apollo
        .mutate({
          mutation: SAVE_AUDIO_POST_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: parseInt(this.$route.params.id, 10),
            voice: this.voiceId,
            text: this.text,
          },
        })
        .then(({ data }) => {
          this.$emit('saved', data.saveAudioPost);
          this.$emit('generatingChange', false);

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Speech Saved Successfully',
            text:
              'Your audio is now being generated. This may take a moment. You will not be able to edit your speech until this process is complete.',
            duration: 10000,
          });
        })
        .catch(() => {
          this.isGenerating = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    togglePlay() {
      !this.isPlaying ? this.playSpeech() : this.pauseSpeech();
    },

    playSpeech() {
      if (this.shouldRegenerate) {
        this.generateAudio().then(() => this.playAudio());
      } else if (this.isGenerated) {
        this.playAudio();
      }
    },

    pauseSpeech() {
      this.stopAudio();
    },

    initAudio(url) {
      this.audio = new Audio(url);

      var localThis = this;
      this.audio.addEventListener('loadedmetadata', function() {
        localThis.trackDuration = Math.round(this.duration);
      });

      this.audio.addEventListener('ended', this.handleEnded);

      this.audio.loop = false;
    },

    playAudio() {
      if (!this.isPlaying) {
        this.getCurrentTimeEverySecond(true);
        this.isPlaying = true;
        this.audio.play();
      } else {
        this.stopAudio();
      }
    },

    stopAudio() {
      this.audio.pause();
      this.isPlaying = false;
      this.pausedAudio();
    },

    handleEnded() {
      this.isPlaying = false;
    },

    getCurrentTimeEverySecond() {
      var localThis = this;
      this.checkingCurrentPositionInTrack = setTimeout(
        function() {
          localThis.currentTime = localThis.audio.currentTime;
          localThis.currentProgressBar = (localThis.audio.currentTime / localThis.trackDuration) * 100;
          localThis.getCurrentTimeEverySecond(true);
        }.bind(this),
        1000,
      );
    },

    pausedAudio() {
      clearTimeout(this.checkingCurrentPositionInTrack);
    },

    downloadAudio() {
      const url = this.generatedAudioUrl || this.audioUrl;
      if (this.isGenerating || !url) {
        return;
      }
      window.location.href = `${url}?downloadFile=UntitledAudio`;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.player-wrap {
  background-color: $secondary;
  padding: 20px 10px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  // position: fixed;
  // width: 100%;
  // bottom: 0;
  box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.075);

  .player-progress {
    width: 80%;
    position: absolute;
    top: 0;
    height: 3px;
  }

  #gen-btn {
    &.exceeded {
      opacity: 0.7;
    }
  }

  .player-time,
  .player-download {
    position: absolute;
  }

  .player-time {
    left: 100px;

    .badge {
      &.busy {
        opacity: 0.5;
      }
    }
  }

  .player-download {
    right: 100px;
  }
  .player-controls {
    display: flex;
    align-items: center;
    background: $secondary;

    .play-btn {
      height: 58px;
      width: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rewind-btn,
  .next-btn {
    height: 50px;
    width: 50px;
    box-shadow: none;
  }
  .next-btn {
    margin-left: 5px;
  }
  .rewind-btn {
    margin-right: 5px;
  }
}
</style>
