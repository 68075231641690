export const popular = [
  { code: 'EN', name: 'English' },
  { code: 'ES', name: 'Spanish' },
  { code: 'DE', name: 'German' },
  { code: 'FR', name: 'French' },
  { code: 'IT', name: 'Italian' },
  { code: 'RU', name: 'Russian' },
  { code: 'PT', name: 'Portuguese' },
];

export default [
  { code: 'SQ', name: 'Albanian' },
  { code: 'AR', name: 'Arabic' },
  { code: 'HY', name: 'Armenian' },
  { code: 'EU', name: 'Basque' },
  { code: 'BN', name: 'Bengali' },
  { code: 'BG', name: 'Bulgarian' },
  { code: 'CA', name: 'Catalan' },
  { code: 'KM', name: 'Cambodian' },
  { code: 'ZH', name: 'Chinese (Mandarin)' },
  { code: 'HR', name: 'Croation' },
  { code: 'CS', name: 'Czech' },
  { code: 'DA', name: 'Danish' },
  { code: 'NL', name: 'Dutch' },
  { code: 'EN', name: 'English' },
  { code: 'ET', name: 'Estonian' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finnish' },
  { code: 'FR', name: 'French' },
  { code: 'KA', name: 'Georgian' },
  { code: 'DE', name: 'German' },
  { code: 'EL', name: 'Greek' },
  { code: 'GU', name: 'Gujarati' },
  { code: 'HE', name: 'Hebrew' },
  { code: 'HI', name: 'Hindi' },
  { code: 'HU', name: 'Hungarian' },
  { code: 'IS', name: 'Icelandic' },
  { code: 'ID', name: 'Indonesian' },
  { code: 'GA', name: 'Irish' },
  { code: 'IT', name: 'Italian' },
  { code: 'JA', name: 'Japanese' },
  { code: 'JW', name: 'Javanese' },
  { code: 'KO', name: 'Korean' },
  { code: 'LA', name: 'Latin' },
  { code: 'LV', name: 'Latvian' },
  { code: 'LT', name: 'Lithuanian' },
  { code: 'MK', name: 'Macedonian' },
  { code: 'MS', name: 'Malay' },
  { code: 'ML', name: 'Malayalam' },
  { code: 'MT', name: 'Maltese' },
  { code: 'MI', name: 'Maori' },
  { code: 'MR', name: 'Marathi' },
  { code: 'MN', name: 'Mongolian' },
  { code: 'NE', name: 'Nepali' },
  { code: 'NO', name: 'Norwegian' },
  { code: 'FA', name: 'Persian' },
  { code: 'PL', name: 'Polish' },
  { code: 'PT', name: 'Portuguese' },
  { code: 'PA', name: 'Punjabi' },
  { code: 'QU', name: 'Quechua' },
  { code: 'RO', name: 'Romanian' },
  { code: 'RU', name: 'Russian' },
  { code: 'SM', name: 'Samoan' },
  { code: 'SR', name: 'Serbian' },
  { code: 'SK', name: 'Slovak' },
  { code: 'SL', name: 'Slovenian' },
  { code: 'ES', name: 'Spanish' },
  { code: 'SW', name: 'Swahili' },
  { code: 'SV', name: 'Swedish ' },
  { code: 'TA', name: 'Tamil' },
  { code: 'TT', name: 'Tatar' },
  { code: 'TE', name: 'Telugu' },
  { code: 'TH', name: 'Thai' },
  { code: 'BO', name: 'Tibetan' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TR', name: 'Turkish' },
  { code: 'UK', name: 'Ukranian' },
  { code: 'UR', name: 'Urdu' },
  { code: 'UZ', name: 'Uzbek' },
  { code: 'VI', name: 'Vietnamese' },
  { code: 'CY', name: 'Welsh' },
  { code: 'XH', name: 'Xhosa' },
];

export const popularFull = [
  { code: 'english', name: 'English' },
  { code: 'spanish', name: 'Spanish' },
  { code: 'german', name: 'German' },
  { code: 'french', name: 'French' },
  { code: 'italian', name: 'Italian' },
  { code: 'russian', name: 'Russian' },
  { code: 'portuguese', name: 'Portuguese' },
];

export const langsFull = [
  { code: 'albanian', name: 'Albanian' },
  { code: 'arabic', name: 'Arabic' },
  { code: 'armenian', name: 'Armenian' },
  { code: 'basque', name: 'Basque' },
  { code: 'bengali', name: 'Bengali' },
  { code: 'bulgarian', name: 'Bulgarian' },
  { code: 'catalan', name: 'Catalan' },
  { code: 'cambodian', name: 'Cambodian' },
  { code: 'chinese', name: 'Chinese (Mandarin)' },
  { code: 'croation', name: 'Croation' },
  { code: 'czech', name: 'Czech' },
  { code: 'danish', name: 'Danish' },
  { code: 'dutch', name: 'Dutch' },
  { code: 'english', name: 'English' },
  { code: 'estonian', name: 'Estonian' },
  { code: 'fiji', name: 'Fiji' },
  { code: 'finnish', name: 'Finnish' },
  { code: 'french', name: 'French' },
  { code: 'georgian', name: 'Georgian' },
  { code: 'german', name: 'German' },
  { code: 'greek', name: 'Greek' },
  { code: 'gujarati', name: 'Gujarati' },
  { code: 'hebrew', name: 'Hebrew' },
  { code: 'hindi', name: 'Hindi' },
  { code: 'hungarian', name: 'Hungarian' },
  { code: 'icelandic', name: 'Icelandic' },
  { code: 'indonesian', name: 'Indonesian' },
  { code: 'irish', name: 'Irish' },
  { code: 'italian', name: 'Italian' },
  { code: 'japanese', name: 'Japanese' },
  { code: 'javanese', name: 'Javanese' },
  { code: 'korean', name: 'Korean' },
  { code: 'latin', name: 'Latin' },
  { code: 'latvian', name: 'Latvian' },
  { code: 'lithuanian', name: 'Lithuanian' },
  { code: 'macedonian', name: 'Macedonian' },
  { code: 'malay', name: 'Malay' },
  { code: 'malayalam', name: 'Malayalam' },
  { code: 'maltese', name: 'Maltese' },
  { code: 'maori', name: 'Maori' },
  { code: 'marathi', name: 'Marathi' },
  { code: 'mongolian', name: 'Mongolian' },
  { code: 'nepali', name: 'Nepali' },
  { code: 'norwegian', name: 'Norwegian' },
  { code: 'persian', name: 'Persian' },
  { code: 'polish', name: 'Polish' },
  { code: 'portuguese', name: 'Portuguese' },
  { code: 'punjabi', name: 'Punjabi' },
  { code: 'quechua', name: 'Quechua' },
  { code: 'romanian', name: 'Romanian' },
  { code: 'russian', name: 'Russian' },
  { code: 'samoan', name: 'Samoan' },
  { code: 'serbian', name: 'Serbian' },
  { code: 'slovak', name: 'Slovak' },
  { code: 'slovenian', name: 'Slovenian' },
  { code: 'spanish', name: 'Spanish' },
  { code: 'swahili', name: 'Swahili' },
  { code: 'swedish', name: 'Swedish ' },
  { code: 'tamil', name: 'Tamil' },
  { code: 'tatar', name: 'Tatar' },
  { code: 'telugu', name: 'Telugu' },
  { code: 'thai', name: 'Thai' },
  { code: 'tibetan', name: 'Tibetan' },
  { code: 'tonga', name: 'Tonga' },
  { code: 'turkish', name: 'Turkish' },
  { code: 'ukranian', name: 'Ukranian' },
  { code: 'urdu', name: 'Urdu' },
  { code: 'uzbek', name: 'Uzbek' },
  { code: 'vietnamese', name: 'Vietnamese' },
  { code: 'welsh', name: 'Welsh' },
  { code: 'xhosa', name: 'Xhosa' },
];

export const langSub = [
  {
    code: 'all',
    name: 'All Languages',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'he',
    name: 'Hebrew',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'it',
    name: 'Italiano',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'nl',
    name: 'Netherlands',
  },
  {
    code: 'pl',
    name: 'Polski',
  },
  {
    code: 'pt',
    name: 'Português',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sr',
    name: 'Serbian',
  },
  {
    code: 'sl',
    name: 'Slovenian',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
];
