<template>
  <div class="optimization auto-box shadow-sm rounded-sm">
    <div class="auto-box-inner">
      <h5 class="mb-5">Optimize your posts</h5>

      <h6 class="font-weight-bold">
        Add Hashtags to your posts
        <b-button @click="skipHashtag" variant="link" class="ml-2">Skip</b-button>
      </h6>

      <b-form-group label-for="hashtags" class="mt-4">
        <template slot="label">Select from Saved Hashtags</template>
        <b-form-select :value="selectedHashtagPreset" @change="handleHashtagPresetSelect" id="hashtags">
          <option :value="0">Create New</option>
          <template v-if="hashtagPresets && hashtagPresets.length">
            <option v-for="hashtagPreset in hashtagPresets" :key="hashtagPreset.id" :value="hashtagPreset.id">{{
              hashtagPreset.name
            }}</option>
          </template>
        </b-form-select>
      </b-form-group>

      <template v-if="isEditingHashtagPreset">
        <b-form @submit.prevent="saveHashtagPreset" @keydown="hashtagForm.onKeydown($event)">
          <b-form-group label-for="hashtags" class="mt-4">
            <template slot="label">Name</template>
            <b-form-input
              v-model="hashtagForm.name"
              id="hashtagName"
              placeholder="Enter hashtag name..."
              autocomplete="off"
              required
            />
          </b-form-group>

          <b-form-group label="Hashtags" label-for="hashtags-input">
            <b-form-input
              v-model="hashtagInput"
              @keydown="handleHashtagInputKeydown"
              id="hashtags-input"
              placeholder="Press 'Enter' to add hashtags such as, ‘#DigitalMarketing"
              autocomplete="off"
            ></b-form-input>

            <div class="campaign-hashtag-items d-flex flex-wrap">
              <div
                v-for="(hash, i) in hashtagForm.items"
                :key="i + 1"
                class="campaign-hashtag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
              >
                {{ hash }}
                <span @click="removeHashtagItem(i)" class="hash-item-remove small"> <svg-icon name="close" /> </span>
              </div>
            </div>
          </b-form-group>

          <div class="mt-4">
            Randomly choose
            <input type="number" v-model="hashtagForm.insertCount" class="hashtag-count" required min="1" max="10" />
            hashtag(s) for each post.
          </div>

          <b-form-group label="Placement" label-for="hashtagPlacement" class="mt-4">
            <b-form-radio v-model="hashtagForm.insertType" name="insert_type" value="Append" required>
              Append
            </b-form-radio>
            <b-form-radio v-model="hashtagForm.insertType" name="insert_type" value="Replace" required>
              Replace (if keyword is matched)
            </b-form-radio>
          </b-form-group>

          <div class="text-center mt-5">
            <b-button type="submit" variant="primary" class="px-4" :disabled="hashtagForm.busy">
              <b-spinner v-if="hashtagForm.busy" class="ml-2" small />
              <svg-icon v-else name="check" class="mr-1" />
              Save Hashtag
            </b-button>
          </div>
        </b-form>
      </template>
      <div v-else>
        <div class="border rounded">
          <div class="p-3">
            <div class="hashtag-items d-flex flex-wrap">
              <div
                v-for="(hash, i) in selectedHashtag.items"
                :key="i + 1"
                class="hashtag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
              >
                {{ hash }}
              </div>
            </div>

            <ul class="hashtag-desc-list">
              <li class="mt-2">{{ selectedHashtag.insertCount }} hashtag(s) from above will be randomly chosen</li>
              <li class="mt-2">
                Hashtag(s) will be {{ selectedHashtag.insertType == 'Append' ? 'appended' : 'replaced' }}
              </li>
            </ul>
          </div>
          <div class="text-right">
            <b-button @click="editSelectedHashtag" class="border" variant="light" size="sm">Edit</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';
import { HASHTAG_PRESETS_QUERY } from '~/graphql/queries';
import { SAVE_HASHTAG_PRESET_MUTATION } from '~/graphql/mutations';

export default {
  name: 'Automation-optimization-step',

  data() {
    return {
      hashtagPresets: [],

      hashtagInput: '',
      hashtagForm: new Form({
        id: 0,
        name: '',
        items: [],
        insertCount: 1,
        insertType: 'Append',
      }),

      isEditingHashtagPreset: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      selectedHashtagPreset: state => state.createAutomation.selectedHashtagPreset,
    }),

    selectedHashtag() {
      const defaultHashtag = {
        id: 0,
        items: [],
        insertCount: 1,
        insertType: 'Append',
      };

      if (!this.hashtagPresets.length) {
        return defaultHashtag;
      }
      const selectedHashtag = this.hashtagPresets.find(hashtag => hashtag.id === this.selectedHashtagPreset);
      return selectedHashtag ? selectedHashtag : defaultHashtag;
    },
  },

  apollo: {
    hashtagPresets: {
      query: HASHTAG_PRESETS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
  },

  mounted() {
    if (!this.selectedHashtagPreset) {
      this.isEditingHashtagPreset = true;
    }
  },

  methods: {
    handleHashtagInputKeydown(e) {
      if (e.code == 'Comma' || e.code == 'Enter') {
        e.preventDefault();

        let hashtag = this.hashtagInput;
        if (hashtag.substring(0, 1) !== '#') {
          hashtag = `#${hashtag}`;
        }
        this.hashtagInput = '';

        if (!this.hashtagForm.items.find(h => h === hashtag)) {
          this.hashtagForm.items = [...this.hashtagForm.items, hashtag];
        }
      }
    },

    removeHashtagItem(index) {
      this.hashtagForm.items = [...this.hashtagForm.items.slice(0, index), ...this.hashtagForm.items.slice(index + 1)];
    },

    handleHashtagPresetSelect(id) {
      this.$store.dispatch('createAutomation/updateSelectedHashtagPreset', id);

      if (id === 0) {
        this.hashtagForm.reset();

        this.isEditingHashtagPreset = true;
      } else {
        this.hashtagForm.keys().forEach(key => {
          this.hashtagForm[key] = this.selectedHashtag[key];
        });

        this.isEditingHashtagPreset = false;
      }
    },

    saveHashtagPreset() {
      if (this.hashtagForm.items.length < 1) {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Please add at least one hashtag. Remember press enter or add a comma after each hashtag.',
          duration: 7000,
        });
      }

      this.hashtagForm.busy = true;

      return this.$apollo
        .mutate({
          mutation: SAVE_HASHTAG_PRESET_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.selectedHashtagPreset,
            name: this.hashtagForm.name,
            hashtags: this.hashtagForm.items,
            insertCount: parseInt(this.hashtagForm.insertCount, 10),
            insertType: String(this.hashtagForm.insertType),
          },
          update: (store, { data: { saved } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: HASHTAG_PRESETS_QUERY,
              variables: {
                workspace: this.workspace.id,
              },
            });
            if (this.selectedHashtagPreset === 0) {
              data.hashtagPresets.push(saved);
            } else {
              const index = data.hashtagPresets.findIndex(a => a.id === this.selectedHashtagPreset);

              if (index !== -1) {
                data.hashtagPresets.splice(index, 1, saved);
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: HASHTAG_PRESETS_QUERY,
              variables: {
                workspace: this.workspace.id,
              },
              data,
            });
          },
        })
        .then(async ({ data }) => {
          // Select the newly saved
          this.$store.dispatch('createAutomation/updateSelectedHashtagPreset', data.saved.id);

          this.hashtagForm.busy = false;
          this.isEditingHashtagPreset = false;
        })
        .catch(() => {
          this.hashtagForm.busy = false;

          this.$notify({
            group: 'main',
            type: 'error',
            duration: 2000,
            title: 'An error occurred',
            text: 'Failed to save hashtag. Check your input and try again.',
          });
        });
    },

    editSelectedHashtag() {
      this.isEditingHashtagPreset = true;

      if (this.selectedHashtag.id) {
        this.hashtagForm.keys().forEach(key => {
          this.hashtagForm[key] = this.selectedHashtag[key];
        });
      }
    },

    skipHashtag() {
      this.$store.dispatch('createAutomation/updateSelectedHashtagPreset', 0);
      this.isEditingHashtagPreset = true;
      this.hashtagForm.reset();

      this.$emit('skip');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.optimization {
  .form-control {
    font-size: 0.9rem;

    &::placeholder {
      color: $gray-500;
    }
  }
  .campaign-hashtag-items {
    .campaign-hashtag-item {
      font-size: 0.8rem;
      margin-bottom: 8px;

      .hash-item-remove {
        cursor: pointer;
        font-weight: bold;
        margin-left: 0.3rem;

        .icon {
          width: 0.5rem;
          height: 0.5rem;
          margin-top: 0;
          fill: $gray-800;
        }
      }
    }
  }

  .hashtag-count {
    width: 70px;
    height: 40px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: 2px 7px;
    color: $gray-600;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }
}
</style>
