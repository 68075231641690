<template>
  <editor-menu-bubble
    class="menububble"
    :editor="editor"
    @hide="handleHideBubble"
    v-slot="{ commands, isActive, getMarkAttrs, menu }"
  >
    <div
      class="menububble"
      :class="{ 'is-active': menu.isActive }"
      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
    >
      <SayAsForm
        v-if="sayAsMenuIsActive"
        :active="getMarkAttrs('sayas')['interpret-as']"
        :format="getMarkAttrs('sayas')['format']"
        :command="commands.sayas"
        @hide="hideSayAsMenu"
      />

      <SubForm
        v-else-if="subFormIsActive"
        :alias="getMarkAttrs('sub')['alias']"
        :command="commands.sub"
        @hide="hideSubForm"
      />

      <LangForm
        v-else-if="langFormIsActive"
        :lang="getMarkAttrs('lang')['xml:lang']"
        :command="commands.lang"
        @hide="hideLangForm"
      />

      <ProsodyForm
        v-else-if="prosodyFormIsActive"
        :volume="getMarkAttrs('prosody')['volume']"
        :rate="getMarkAttrs('prosody')['rate']"
        :pitch="getMarkAttrs('prosody')['pitch']"
        :command="commands.prosody"
        @hide="hideProsodyForm"
      />

      <WForm v-else-if="wFormIsActive" :role="getMarkAttrs('w')['role']" :command="commands.w" @hide="hideWForm" />

      <div class="menububble__buttons" v-else>
        <button
          v-b-tooltip.hover
          title="Specify wheather to say the selected text as words, numbers, spelled out, etc"
          class="menububble__button"
          :class="{ 'is-active': isActive.sayas() }"
          @click="showSayAsMenu()"
        >
          <svg-icon name="transfer" />
        </button>

        <button
          v-b-tooltip.hover
          title="Say something else instead of the selected text such as an acronym or abbreviation"
          class="menububble__button"
          :class="{ 'is-active': isActive.sub() }"
          @click="showSubForm(commands.sub)"
        >
          <svg-icon name="sub" />
        </button>

        <button
          v-b-tooltip.hover
          title="Specify a different language for the selected text"
          class="menububble__button"
          :class="{ 'is-active': isActive.lang() }"
          @click="showLangForm(commands.lang)"
        >
          <svg-icon name="language" />
        </button>

        <button
          v-b-tooltip.hover
          title="Control Volume and Speaking Rate"
          class="menububble__button"
          :class="{ 'is-active': isActive.prosody() }"
          @click="showProsodyForm(commands.prosody)"
        >
          <svg-icon name="prosody" />
        </button>

        <button
          v-b-tooltip.hover
          title="Specify Parts of Speech"
          class="menububble__button"
          :class="{ 'is-active': isActive.w() }"
          @click="showWForm(commands.w)"
        >
          <svg-icon name="vowel" />
        </button>

        <div class="bubble-arrow">
          <svg viewBox="0 0 18 7" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 7s2.021-.015 5.253-4.218C6.584 1.051 7.797.007 9 0c1.203-.007 2.416 1.035 3.761 2.782C16.012 7.005 18 7 18 7H0z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </editor-menu-bubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import SayAsForm from './SayAsForm';
import SubForm from './SubForm';
import LangForm from './LangForm';
import ProsodyForm from './ProsodyForm';
import WForm from './WForm';

export default {
  components: { EditorMenuBubble, SayAsForm, SubForm, LangForm, ProsodyForm, WForm },

  props: {
    editor: {
      required: true,
    },
  },

  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false,

      sayAsMenuIsActive: false,
      subFormIsActive: false,
      langFormIsActive: false,
      prosodyFormIsActive: false,
      wFormIsActive: false,
    };
  },

  methods: {
    handleHideBubble() {
      this.hideSayAsMenu();
      this.hideSubForm();
      this.hideLangForm();
      this.hideProsodyForm();
      this.hideWForm();
    },

    hideSayAsMenu() {
      this.sayAsMenuIsActive = false;
    },

    showSayAsMenu() {
      this.sayAsMenuIsActive = true;
    },

    showSubForm() {
      this.subFormIsActive = true;
    },

    hideSubForm() {
      this.subFormIsActive = false;
    },

    showLangForm() {
      this.langFormIsActive = true;
    },

    hideLangForm() {
      this.langFormIsActive = false;
    },

    showProsodyForm() {
      this.prosodyFormIsActive = true;
    },

    hideProsodyForm() {
      this.prosodyFormIsActive = false;
    },

    showWForm() {
      this.wFormIsActive = true;
    },

    hideWForm() {
      this.wFormIsActive = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__buttons {
    background: $primary;
    border-radius: 30px;
    padding: 0.3rem 25px;
    position: relative;

    .bubble-arrow {
      fill: $primary;
      width: 18px;
      height: 7px;
      pointer-events: none;
      bottom: -7px;
      transform-origin: 50% 0;
      margin: 0 3px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      svg {
        position: absolute;
        left: 0;
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    background: transparent;
    border: 0;
    color: $white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba($white, 0.1);
    }

    &.is-active {
      background-color: rgba($white, 0.2);
    }
  }
}
</style>
