<template>
  <div>
    <div class="external__modal-button">
      <b-button @click="closeLeadsFormModal()" class="text-white" variant="clear">
        <svg-icon name="close-alt" size="md" />
      </b-button>
    </div>
    <div class="d-flex justify-content-between">
      <h4>Create New Lead</h4>
    </div>
    <b-form @submit.prevent="saveLeadDetails" v-if="showListForm">
      <b-row>
        <b-col>
          <b-form-group>
            <label for="leads_first_name-field" class="f-14">First Name</label>
            <b-form-input
              id="leads_first_name-field"
              class="auth-input"
              v-model="lead.firstName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="leads_last_name-field" class="f-14">Last Name</label>
            <b-form-input
              id="leads_last_name-field"
              class="auth-input"
              v-model="lead.lastName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label for="leads_email-field" class="f-14">Email</label>
            <b-form-input
              id="leads_email-field"
              class="auth-input"
              v-model="lead.email"
              type="email"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="leads_phone-field" class="f-14">Phone</label>
            <vue-tel-input id="leads_phone-field" v-model="lead.phone" v-bind="bindPhoneProps" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label for="address-field" class="f-14">Address</label>
            <b-form-input
              id="address-field"
              class="auth-input"
              v-model="lead.address"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="title-field" class="f-14">Title</label>
            <b-form-input
              id="title-field"
              class="auth-input"
              v-model="lead.title"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label for="company-field" class="f-14">Company</label>
            <b-form-input
              id="company-field"
              class="auth-input"
              v-model="lead.company"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="domain-field" class="f-14">Domain</label>
            <b-form-input
              id="domain-field"
              class="auth-input"
              v-model="lead.domain"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-center m-2">
        <b-button type="submit" @click="onResetLeadsForm" variant="outline-primary" class="mx-2">
          <svg-icon name="close-alt" size="sm" class="mr-2" />
          Cancel
        </b-button>
        <b-button type="submit" :disabled="savingLead" variant="primary" class="mx-2">
          <b-spinner class="ml-2" v-if="savingLead" small />
          <svg-icon v-else name="check" size="sm" class="mr-2" />
          Save
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Form from 'vform';
import { VueTelInput } from 'vue-tel-input';
import { SAVE_LEAD_MUTATION } from '~/graphql/mutations';
import { FETCH_LEAD_QUERY } from '~/graphql/queries';
import { isArray, forEach, get } from 'lodash';
import Swal from 'sweetalert2';

export default {
  name: 'CreateLead',
  components: { VueTelInput },

  data() {
    return {
      savingLead: false,
      showListForm: true,
      lead: new Form({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        title: '',
        company: '',
        domain: '',
        lists: [],
      }),
      bindPhoneProps: {
        type: 'tel-input',
        mode: 'international',
        defaultCountry: 'US',
        disabledFetchingCountry: false,
        disabled: false,
        placeholder: 'Phone Number',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        autocomplete: 'off',
        name: 'phone',
        wrapperClasses: 'form-input',
      },
    };
  },

  methods: {
    async saveLeadDetails(e) {
      e.preventDefault();

      this.savingLead = true;

      try {
        await this.$apollo.mutate({
          mutation: SAVE_LEAD_MUTATION,
          variables: {
            firstName: this.lead.firstName,
            lastName: this.lead.lastName,
            email: this.lead.email,
            phoneNumber: this.lead.phone,
            address: this.lead.address,
            title: this.lead.title,
            company: this.lead.company,
            domain: this.lead.domain,
          },
          refetchQueries: [
            {
              query: FETCH_LEAD_QUERY,
              variables: {
                folder_id: null,
              },
            },
          ],
        });

        this.onResetLeadsForm(e);
        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Lead created successfully',
        });
      } catch (e) {
        const errors = get(e, 'graphQLErrors');
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'LeadFolderSaveError') {
              errorText = 'A server error occurred saving list';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while saving lead',
        });
      }

      this.savingLead = false;
    },

    closeLeadsFormModal() {
      this.$bvModal.hide('createLeadModal');
    },
    onResetLeadsForm(event) {
      event.preventDefault();
      // Reset our form values
      this.lead.firstName = '';
      this.lead.lastName = '';
      this.lead.email = '';
      this.lead.phone = '';
      this.lead.address = '';
      this.lead.title = '';
      this.lead.company = '';
      this.lead.domain = '';
      this.lead.lists = [];
      // Trick to reset/clear native browser form validation state
      this.showListForm = false;
      this.$nextTick(() => {
        this.showListForm = true;
      });
      this.$bvModal.hide('createLeadModal');
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>;
<style lang="scss">
@import '~@/scss/variables';
.vti__input {
  border: none !important;
}
.vue-tel-input:focus-within,
.vti__dropdown:hover {
  border-color: $primary !important;
  background: inherit !important;
}
.vue-tel-input {
  border: 1.5px solid inherit !important;
  color: $primary !important;
  background: $white !important;
  height: 40px;
  border-radius: 0.5rem !important;
  &:focus,
  &:active {
    border-color: #1b4332;
  }
}
</style>
