<template>
  <div>
    <simplebar class="tabs-scroll">
      <DashboardArticlesSkeleton class="discover__article-wrap" v-if="queryLoading" />
      <div v-if="articles && articles.length > 0 && !queryLoading" class="discover__article-wrap">
        <b-row cols="2" class="discover__article-wrap">
          <b-col cols="6" class="m-0 p-0 w-100" v-for="article in articles" :key="article.id">
            <div class="article-item">
              <lazy-background-image :src="article.image" class="article-img" />
              <div v-if="descape(article.title)" class="article-content-wrap">
                <a href="#" class="article-title" v-html="descape(article.title)" @click.prevent="read(article)"></a>
                <p class="article-desc">{{ article.description }}</p>
                <div class="discover-article-footer">
                  <div class="article-stats">
                    <div :id="`article-${article.id}`" class="article-stats-item">
                      <div class="article-stats-value">{{ numFormat(article.engagement) }}</div>
                      <div class="article-stats-name">
                        <span class="article-icon red-bg">
                          <img src="@/assets/icons/fire.svg" alt="search icon" />
                        </span>
                        Engagement
                      </div>
                    </div>
                    <SharesPopover :target="`article-${article.id}`" :article="article" />
                    <div class="article-stats-item">
                      <div class="article-stats-value">{{ numFormat(article.score) }}</div>
                      <div class="article-stats-name">
                        <span class="article-icon green-bg">
                          <img src="@/assets/icons/leaf.svg" alt="search icon" />
                        </span>
                        Trend score
                      </div>
                    </div>
                    <div v-b-tooltip :title="capitalize(article.sentiment)" class="article-stats-item">
                      <SentimentIcon :sentiment="article.sentiment" />
                      <div class="article-stats-name">
                        <span class="article-icon yellow-bg">
                          <img src="@/assets/icons/wheat.svg" alt="search icon" />
                        </span>
                        Sentiment
                      </div>
                    </div>
                  </div>

                  <b-dropdown
                    class="w-50 mx-auto custom-dropdown"
                    toggle-class="btn-block"
                    variant="outline-primary"
                    size="sm"
                    no-caret
                    no-flip
                    right
                    block
                    offset="-15px"
                    :disabled="getting !== null"
                  >
                    <template slot="button-content">
                      <b-spinner v-if="getting === article.id" small />
                      <svg-icon v-else name="share" size="sm" class="mr-1" />
                      Share
                    </template>
                    <div class="py-2">
                      <b-dropdown-item @click="shareArticleClick(article, 'voice')">
                        <svg-icon name="headphone" class="mr-1" />
                        Audio/Podcast
                      </b-dropdown-item>
                      <b-dropdown-item @click="shareArticleClick(article, 'social')">
                        <svg-icon name="post-alt" class="mr-1" />
                        Social Media
                      </b-dropdown-item>
                      <b-dropdown-item @click="shareArticleClick(article, 'blog')">
                        <svg-icon name="globe" class="mr-1" />
                        Blog Post
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="articles && articles.length < 1 && !queryLoading" class="no-result-wrap text-center">
        <img class="pt-3 mb-5" width="250" src="@/assets/images/not_found.svg" alt="" />
        <h5>Could not load articles</h5>
        <b-button size="sm" class="mt-3 px-4" @click="retryArticlesQuery">
          <svg-icon name="reload" />
          Retry
        </b-button>
      </div>
    </simplebar>
    <div v-if="!queryLoading" class="tab-footer">
      <router-link :to="{ name: 'find.articles' }">Discover more</router-link>
    </div>
  </div>
</template>

<script>
import { decode } from 'he';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { capitalize } from 'lodash';
import { numFormat } from '~/utils/helpers';

import { DASHBOARD_ARTICLES_QUERY, GET_ARTICLE_QUERY } from '~/graphql/queries';

import SharesPopover from '~/components/Articles/SharesPopover';
import SentimentIcon from '~/components/Articles/SentimentIcon';
import LazyBackgroundImage from '~/components/LazyBackgroundImage';
import DashboardArticlesSkeleton from '~/components/DashboardArticlesSkeleton';

export default {
  components: {
    simplebar,
    DashboardArticlesSkeleton,
    LazyBackgroundImage,
    SentimentIcon,
    SharesPopover,
  },

  data() {
    return {
      queryLoading: 0,
      articles: [],
      getting: null,
    };
  },

  apollo: {
    articlesQuery: {
      query: DASHBOARD_ARTICLES_QUERY,
      variables: {
        limit: 4,
      },
      manual: true,
      loadingKey: 'queryLoading',
      result({ data, loading }) {
        if (!loading) {
          this.articles = data.articleSearch.articles;
        }
      },
    },
  },

  methods: {
    read(article) {
      this.$store.dispatch('article/invoke', article);

      this.$router.push({ query: { ...this.$route.query, read: article.id } });
    },

    retryArticlesQuery() {
      this.$apollo.queries.articlesQuery.refetch();
    },

    shareArticleClick(article, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          ...article,
          body: `${this.descape(article.title)} - <a href="${article.url}">${article.url}</a>`,
          postLink: article.url,
        });

        this.$store.dispatch('createPost/fetchLinkPreview', article.url);

        this.$router.push({ name: 'compose', params: { type, id: 0 } });
      } else if (type === 'blog') {
        this.getting = article.id;

        this.$apollo
          .query({
            query: GET_ARTICLE_QUERY,
            variables: {
              id: article.id,
            },
          })
          .then(({ data }) => {
            this.getting = null;
            const articleText = data.getArticle;

            const credits = `<div>
              <br />
              <p><em>This Post was originally published on <a href="${article.url}">${article.domain}</a></em></p>
            </div>`;

            const postContent = `
              <img src="${article.image}" alt="" />
              <hr />
              ${articleText} ${credits}
            `;

            this.$store.dispatch('createPost/updateSelectedContent', {
              ...article,
              body: postContent,
              title: this.descape(article.title),
              postLink: article.url,
            });

            this.$router.push({ name: 'compose', params: { type, id: 0 } });
          })
          .catch(() => {
            this.getting = null;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });
      } else if (type === 'voice') {
        this.getting = article.id;

        this.$apollo
          .query({
            query: GET_ARTICLE_QUERY,
            variables: {
              id: article.id,
            },
          })
          .then(({ data }) => {
            this.getting = null;
            const articleText = data.getArticle;

            const postContent = `
              <p>${article.title}</p>
              ${articleText}
            `;

            this.$store.dispatch('voiceEditor/setPreContent', postContent);

            this.$bvModal.hide('ArticlePrevivewModal');
            this.$router.push({ name: 'compose-audio', params: { type, id: 0 } });
          })
          .catch(() => {
            this.getting = null;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });
      }
    },

    descape(text) {
      return decode(text);
    },

    numFormat,
    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.articles-tab {
  .discover__article-wrap {
    padding: 12px;
    display: flex;

    .article-item {
      margin: 0 6px 12px 6px;
      border-radius: 15px;
      background: #ffffff;

      .article-img {
        height: 150px;
        background-size: cover;
        border-radius: 5px 5px 0 0;
        width: 100%;
      }

      .article-content-wrap {
        padding: 20px;

        .article-title {
          color: $gray-600;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          font-size: 0.9rem;
          font-weight: bold;
          word-break: break-word;
          &:hover {
            text-decoration: none;
            color: inherit;
          }
        }
        .article-desc {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          font-size: 0.6rem;
          word-break: break-word;
        }
      }
    }
  }

  .discover-article-footer {
    .article-icon {
      width: 20px;
      height: 20px;
      padding: 4px;
      display: inline-flex;
      line-height: 45px;
      vertical-align: middle;
      border-radius: 25px;
    }
    .yellow-bg {
      background: #ffc22b;
    }
    .red-bg {
      background: #f8593b;
    }
    .green-bg {
      background: #2ecc71;
    }
    .article-stats {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: center;
      // border-top: 1px solid $gray-200;
      // border-bottom: 1px solid $gray-200;
      // padding: 3px;
      margin-bottom: 15px;

      .article-stats-item {
        text-align: center;
        // border-right: 1px solid $gray-200;
        width: calc(50% - 5px);

        &:last-of-type {
          border-right: 0;
        }

        .article-stats-name {
          font-size: 12px;
          // margin-top: -10px;
          // margin-bottom: 2px;
        }
        .article-stats-value {
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
