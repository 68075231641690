<script>
import { mapState, mapActions } from 'vuex';
import { SETUP_INTENT_QUERY } from '~/graphql/queries';

let stripeInstance;
let cardElement;

let style = {
  base: {
    color: '#000',
  },

  invalid: {
    // All of the error styles go inside of here.
  },
};

export default {
  props: {
    elementSelector: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      focused: false,
      errorMessage: null,
    };
  },

  computed: {
    ...mapState({
      stripeLoaded: state => state.plan.stripeLoaded,
    }),
  },

  created() {
    if (!document.querySelector('#stripe-script')) {
      this.updateStripeLoaded(
        new Promise(resolve => {
          this.loadScript(() => {
            resolve();
          });
        }),
      );
    }
  },

  mounted() {
    this.stripeLoaded &&
      this.stripeLoaded.then(() => {
        stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

        const elements = stripeInstance.elements();
        console.log('stripe key element: ' + elements);
        cardElement = elements.create('card', { style });

        cardElement.on('focus', () => {
          this.focused = true;
          this.$emit('focus');
        });

        cardElement.on('blur', () => {
          this.focused = false;
          this.$emit('blur');
        });

        cardElement.on('ready', () => {
          this.$emit('ready');
          this.loading = false;
        });

        const containerElem = document.querySelector(this.elementSelector);
        containerElem && cardElement.mount(containerElem);
      });
  },

  methods: {
    ...mapActions({
      updateStripeLoaded: 'plan/updateStripeLoaded',
    }),

    loadScript(callback) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.id = 'stripe-script';
      document.getElementsByTagName('head')[0].appendChild(script);
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        // Others
        script.onload = () => {
          callback();
        };
      }
    },

    async setupCard(details) {
      this.errorMessage = null;

      const { data } = await this.$apollo.query({
        query: SETUP_INTENT_QUERY,
        fetchPolicy: 'no-cache',
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      });

      const clientSecret = data.setupIntent.clientSecret;
      return stripeInstance
        .handleCardSetup(clientSecret, cardElement, {
          payment_method_data: {
            billing_details: details,
          },
        })
        .then(({ setupIntent, error }) => {
          if (error) {
            // Display "error.message" to the user...
            if (error.type === 'validation_error') {
              this.errorMessage = error.message;
            } else {
              this.$notify({
                group: 'main',
                type: 'error',
                duration: 5000,
                title: 'An Error Occurred',
                text: `Something went wrong while processing your request. Please try reloading the page`,
              });

              throw new Error('An Error Occurred');
            }
          } else {
            // The card has been verified successfully...
            return setupIntent.payment_method;
          }
        });
    },

    createPaymentMethod(details) {
      this.errorMessage = null;

      return stripeInstance
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: details,
        })
        .then(({ error, paymentMethod }) => {
          // Handle result.error or result.paymentMethod
          if (error) {
            // Display "error.message" to the user...
            if (error.type === 'validation_error') {
              this.errorMessage = error.message;
            } else {
              this.$notify({
                group: 'main',
                type: 'error',
                duration: 5000,
                title: 'An Error Occurred',
                text: `Something went wrong while processing your request. Please try reloading the page`,
              });

              throw new Error('An Error Occurred');
            }
          } else {
            // The payment method has been verified successfully...
            return paymentMethod;
          }
        });
    },
  },

  render(createElement) {
    const children = this.$scopedSlots.default({
      loading: this.loading,
      focused: this.focused,
      errorMessage: this.errorMessage,
      setupCard: this.setupCard,
      createPaymentMethod: this.createPaymentMethod,
    });

    const data = {
      class: 'stripe-element',
    };
    return createElement('div', data, children);
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.card-name-input {
  border: 1px solid $gray-200;
  border-radius: 5px;
  padding: 1.3rem 1rem;
  font-size: 1rem;
  font-family: Arial;
}
.card-input-wrap {
  border: 1px solid $gray-200;
  border-radius: 5px;

  &.focus {
    border-color: $primary;
  }
}
.error-message {
  color: $danger;
  font-size: 0.8rem;
  margin-top: 4px;
  padding-left: 2px;
}
</style>
