<template>
  <b-modal
    modal-class="social-post-tutorial-modal"
    :visible="visible"
    id="SocialPostTutorialModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    @hide="handleOnHide"
  >
    <div class="position-relative">
      <button @click="close()" class="panel-header-button"><svg-icon name="close" /></button>

      <img class="dialog-image" :src="require(`@/assets/images/${activeStep.image}`)" alt="" />
    </div>
    <div class="px-5 text-center">
      <h3 class="font-weight-light mt-3">{{ activeStep.title }}</h3>
      <p class="sub-header-text mt-3">{{ activeStep.description }}</p>
    </div>
    <div class="mb-5 mt-4 text-center">
      <slot name="next-button" :close="close" :activeStep="activeStep">
        <b-button @click="handleNext(false)" variant="primary" class="px-4 mb-4 rounded-pill">Next</b-button>
      </slot>
    </div>

    <ul class="dialog-carousel-controls">
      <li
        v-for="(control, i) in steps"
        :key="i + 1"
        class="dialog-carousel-control-item"
        :class="{ active: i === activeStepIndex }"
        @click="handleNext(i)"
      ></li>
    </ul>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SAVE_USER_OPTIONS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'social-post-tutorial-modal',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activeStepIndex: 0,
    };
  },

  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    ...mapState('auth', {
      options: state => state.user.options,
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    handleNext(index = false) {
      const nextIndex = index !== false ? index : this.activeStepIndex + 1;

      if (nextIndex == this.steps.length) {
        this.activeStepIndex = 0;
      } else {
        this.activeStepIndex = nextIndex;
      }
    },

    close() {
      this.$bvModal.hide('SocialPostTutorialModal');
    },

    handleOnHide() {
      let key;
      if (this.type === 'social' || this.type === 'blog') {
        key = `${this.type}Composer`;
      } else if (this.type === 'image') {
        key = 'imageDesigner';
      } else {
        return;
      }

      this.$apollo
        .mutate({
          mutation: SAVE_USER_OPTIONS_MUTATION,
          variables: {
            options: {
              ...this.options,
              onboarding: {
                ...this.options.onboarding,
                [key]: false,
              },
            },
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        })
        .then(({ data }) => {
          this.updateUser({ user: data.user });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.social-post-tutorial-modal {
  .panel-header-button {
    background-color: rgba($white, 0.3);
    right: 20px;
    top: 20px;
    position: absolute;

    .icon {
      fill: #fff;
      margin-top: 0;
    }
  }
  .sub-header-text {
    font-size: 0.9rem;
    opacity: 0.9;
    width: 75%;
    margin: auto;
  }

  .dialog-carousel-controls {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: -40px;
    display: flex;
    justify-content: center;

    .dialog-carousel-control-item {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 0.2;
      background-color: #fff;
      transition: opacity 0.5s ease-in-out;
      margin: 0 4px;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }

  .modal-dialog {
    min-width: 686px;

    .dialog-image {
      border-radius: 15px 15px 0 0;
      background-color: #53dbdb;
      height: 300px;
      width: 684px;
    }
  }
  .modal-content {
    border: 1px solid transparent;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .modal-body {
      padding: 0;
    }
    .video-player-wrap {
      min-height: 315px;
      width: 70%;
      margin: auto;
      margin-top: -10rem;
      background-color: $gray-100;
    }
  }
}
</style>
