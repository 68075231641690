<template>
  <div class="report-overview-entry">
    <div v-if="activeTab === getTabAt(0).name" class="row header">
      <AccountsListComponent v-model="selectedAccountIds" />
      <div class="info">
        * Stats are measured as per time zone
      </div>
      <div class="actions">
        <b-button class="action date-range border" variant="outline-secondary">
          <svg-icon name="analytics.calendar" />
          <div class="text">Last 30 days</div>
        </b-button>
        <b-dropdown variant="secondary" right class="action export">
          <template #button-content>
            <div class="d-inline-flex align-center m-0 p-0">
              <div class="text"><svg-icon name="analytics.export" class="" /> export</div>
            </div>
          </template>
          <b-dropdown-item href="#">Export as PDF</b-dropdown-item>
          <b-dropdown-item href="#">Send PDF</b-dropdown-item>
          <b-dropdown-item @click="handleShowScheduleModal(schedule, 'create')">Schedule</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="activeTab === getTabAt(0).name" class="row performance">
      <div class="info">
        <div class="title">
          Profiles Performance Summary
        </div>
        <div class="subtitle">
          Key profile performance metrics from the selected time period.
        </div>
      </div>
      <div class="data">
        <div :class="['performance', performance.name]" v-for="performance in performances" :key="performance.name">
          <div class="icon-wrapper">
            <svg-icon :name="performance.icon" />
          </div>
          <div class="data">
            <div class="title">
              {{ performance.title }}
            </div>
            <div class="value">
              {{ performance.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="['row tabs', { 'mt-4': activeTab !== getTabAt(0).name }]">
      <router-link
        v-for="tab in tabs"
        :key="tab.name"
        :class="['tab', tab.name]"
        :to="getTabRoute(tab)"
        exact-active-class="active"
      >
        <div class="icon-wrapper">
          <svg-icon v-if="tab.topIcon" :name="tab.topIcon" class="top-sub" />
          <svg-icon :name="tab.icon" />
        </div>
        <div class="title">
          {{ tab.title }}
        </div>
      </router-link>
    </div>
    <EngagementAndPost
      v-if="activeTab === getTabAt(0).name"
      :chart-data="getData('reportOverviewData.reports.chart', {})"
      :total="getData('reportOverviewData.reports.total', {})"
    ></EngagementAndPost>
    <PostsByEngagement v-else-if="activeTab === 'post-by-engagement'"></PostsByEngagement>
    <SocialPerformance v-else-if="activeTab === 'social-performance'"></SocialPerformance>
    <PlatformsEngagement v-else-if="activeTab === 'overall-platforms-engagement'"></PlatformsEngagement>
    <ScheduleOptions
      v-if="showScheduleModal && scheduleAction"
      :schedule="schedule"
      :action="scheduleAction"
      :show="showScheduleModal"
      @close-schedule-modal="handleHideScheduleModal"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { get } from 'lodash';
import AccountsListComponent from '~/components/Reports/partials/AccountsList.vue';
import LoadingComponent from '~/components/Reports/partials/Loading';
import ErrorComponent from '~/components/Reports/partials/Error';
import { REPORT_QUERY } from '~/graphql/queries';
import ScheduleOptions from '~/components/Reports/Schedule.vue';

const workspaceHelper = createNamespacedHelpers('workspace');

const EngagementAndPost = () => ({
  component: import('~/components/Reports/overview/EngagementAndPost'),
  loading: LoadingComponent,
  error: ErrorComponent,
  delay: 100,
});

const PostsByEngagement = () => ({
  component: import('~/components/Reports/overview/PostsByEngagement'),
  loading: LoadingComponent,
  error: ErrorComponent,
  delay: 100,
});

const SocialPerformance = () => ({
  component: import('~/components/Reports/overview/SocialPerformance'),
  loading: LoadingComponent,
  error: ErrorComponent,
  delay: 100,
});

const PlatformsEngagement = () => ({
  component: import('~/components/Reports/overview/PlatformsEngagement'),
  loading: LoadingComponent,
  error: ErrorComponent,
  delay: 100,
});

export default {
  name: 'ReportOverview',

  data() {
    return {
      scheduleAction: null,
      showScheduleModal: false,
      schedule: {
        id: 1,
        name: '',
        interval: 'weekly',
        type: 'group',
        created_at: '2021-08-14T15:38:35+00:00',
        accounts: [
          {
            id: 1,
            type: 'facebook',
            avatar: '',
            name: 'facebook',
            user: {
              name: 'Jeremiah Iro',
            },
          },
        ],
      },
      tabs: [
        {
          name: 'engagement-and-post',
          title: 'engagement and post',
          icon: 'analytics.paper-plane',
        },
        {
          name: 'post-by-engagement',
          title: 'top post by engagement',
          icon: 'analytics.top-arrows',
        },
        {
          name: 'social-performance',
          title: 'social performance',
          icon: 'analytics.users',
          topIcon: 'analytics.small-zigzag-arrow',
        },
        {
          name: 'overall-platforms-engagement',
          title: 'overall platforms engagement',
          icon: 'analytics.social-engagement',
        },
      ],
      selectedAccountIds: [],
      reportOverviewData: null,
    };
  },

  components: {
    AccountsListComponent,
    EngagementAndPost,
    PostsByEngagement,
    SocialPerformance,
    PlatformsEngagement,
    ScheduleOptions,
  },

  created() {
    const activeTab = this.activeTab;
    if (!activeTab || !this.tabs.find(tab => tab.name === activeTab)) {
      this.$router.replace(this.getTabRoute(this.getTabAt(0)));
    }
  },

  computed: {
    activeTab() {
      return get(this.$route.query, 'active', false);
    },
    getTabRoute() {
      return tab => {
        return { name: this.$route.name, query: { ...this.$route.query, active: tab.name } };
      };
    },
    getTabAt() {
      return index => get(this.tabs, index);
    },
    hasSelectedAccounts() {
      return this.selectedAccountIds.length > 0;
    },
    getData() {
      return (key, defaultVal) => {
        return get(this, key, defaultVal);
      };
    },
    performances() {
      return [
        {
          name: 'posts',
          title: 'total post',
          icon: 'analytics.mailbox',
          value: this.getData('reportOverviewData.reports.total.posts', 0),
        },
        {
          name: 'reactions',
          title: 'reactions',
          icon: 'analytics.like',
          value: this.getData('reportOverviewData.reports.total.reactions', 0),
        },
        {
          name: 'comments',
          title: 'comments',
          icon: 'analytics.chat',
          value: this.getData('reportOverviewData.reports.total.comments', 0),
        },
        {
          name: 'reposts',
          title: 'reposts',
          icon: 'analytics.report',
          value: this.getData('reportOverviewData.reports.total.shares', 0),
        },
      ];
    },
    ...workspaceHelper.mapState({
      workspace: state => state.current,
    }),
  },

  apollo: {
    reportOverviewData: {
      query: REPORT_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          name: 'overview.summary',
          accountIds: this.selectedAccountIds,
        };
      },
      skip() {
        return !this.hasSelectedAccounts;
      },
      update(data) {
        return data;
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    handleShowScheduleModal(schedule, action) {
      this.schedule = schedule;
      this.scheduleAction = action;
      this.showScheduleModal = true;
    },
    handleHideScheduleModal() {
      this.schedule = null;
      this.scheduleAction = null;
      this.showScheduleModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-overview-entry {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;

  ::v-deep {
    > .row {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 25px;
      border-radius: 10px;
      outline: initial;
      background-color: #ffffff;
      justify-content: flex-start;
      align-items: center;

      &:not(:first-child) {
        margin: 20px 0 0 0;
        padding: 40px 25px;
      }

      &.header {
        > .info {
          margin: 0 0 0 auto;
          display: inline-flex;
          flex-shrink: 1;
        }

        > .actions {
          // display: inline-flex;
          margin: 0 0 0 70px;
          // align-items: stretch;
          // justify-content: flex-start;

          > .action {
            border: none;
            outline: initial;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin: 0;
            padding: 8px;
            font-size: 12px;
            text-transform: capitalize;
            transition: all 0.3s ease-in-out;

            > .icon {
              width: 18px;
              height: 18px;
              fill: #000000;
              stroke: #000000;
            }

            > .text {
              display: inline-flex;
              margin: 0 0 0 10px;
              color: #000000;
            }

            &.date-range {
              > .icon {
                width: 19px;
                height: 19px;
              }
            }

            &.export {
              padding: 0 !important;
              background-color: #52b788;
              background: linear-gradient(120deg, #52b788 30%, #8dc9ad 90%);
              color: #ffffff;

              > .icon {
                width: 16px;
                height: 16px;
              }

              &:hover {
                background-color: #57bd8d;
                background: linear-gradient(120deg, #57bd8d 30%, #8dc9ad 90%);
              }
            }

            &:not(:first-child) {
              margin: 0 0 0 25px;
            }
          }
        }
      }

      &.performance {
        align-items: center;
        flex-wrap: nowrap;

        > .info {
          display: inline-flex;
          width: 30%;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-start;
          margin: 0 20px 0 0;

          > * {
            display: inline-flex;
            flex-wrap: wrap;
            white-space: normal;
            word-wrap: break-word;
          }

          > .title {
            font-size: 18px;
            text-transform: capitalize;
            color: #404040;
          }

          > .subtitle {
            font-size: 13px;
            text-transform: lowercase;
            color: #868686;
          }
        }

        > .data {
          margin: 0 0 0 auto;
          display: inline-flex;
          flex-wrap: nowrap;

          > .performance {
            display: inline-flex;
            padding: 12px;
            margin: 0 4px;
            border-radius: 15px;
            border: 1px solid #e5e5e5;
            flex-wrap: nowrap;
            align-items: stretch;
            width: 200px;

            &:not(&:nth-of-type(1)) {
              margin: 0 0 0 20px;
            }

            > * {
              display: inline-flex;
            }

            > .icon-wrapper {
              justify-content: center;
              align-items: center;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              background-color: #d8f3dc;

              > .icon {
                width: 32px;
                height: 32px;
                fill: #52b788;
              }
            }

            > .data {
              margin: 0 0 0 20px;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;

              > * {
                text-transform: capitalize;
                color: #404040;
                font-weight: 500;
                font-size: 14px;
              }

              > .value {
                margin: auto 0 0 0;
              }
            }

            &.posts {
              > .icon-wrapper {
                > .icon {
                  width: 31px;
                  height: 32px;
                }
              }
            }

            &.reactions {
              > .icon-wrapper {
                > .icon {
                  width: 28px;
                  height: 24px;
                }
              }
            }

            &.comments {
              > .icon-wrapper {
                > .icon {
                  width: 26px;
                  height: 24px;
                }
              }
            }

            &.reposts {
              > .icon-wrapper {
                > .icon {
                  width: 25px;
                  height: 14px;
                }
              }
            }
          }
        }
      }

      &.tabs {
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: stretch;

        > .tab {
          display: inline-flex;
          width: 23%;
          padding: 20px 15px;
          margin: 0;
          outline: initial;
          text-decoration: initial;
          background-color: #f6f6f6;
          justify-content: flex-start;
          align-items: center;
          border-radius: 10px;
          transition: all 0.3s ease-in-out;

          &:not(:last-child) {
            margin: 0 25px 0 0;
          }

          > .icon-wrapper {
            display: flex;
            width: 60px;
            height: 60px;
            position: relative;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;

            > .icon {
              fill: #000000;
              position: absolute;
              width: 45px;
              height: 45px;
              transition: all 0.3s ease-in-out;

              &.top-sub {
                top: 0px;
              }
            }
          }

          > .title {
            display: inline-flex;
            margin: 0 0 0 20px;
            font-size: 18px;
            text-transform: capitalize;
            color: #000000;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
          }

          &.engagement-and-post {
            > .icon-wrapper {
              > .icon {
                width: 45px;
                height: 46px;
              }
            }
          }

          &.post-by-engagement {
            > .icon-wrapper {
              > .icon {
                width: 49px;
                height: 50px;
              }
            }
          }

          &.social-performance {
            > .icon-wrapper {
              > .icon {
                width: 45px;
                height: 45px;
                bottom: 2px;

                &.top-sub {
                  width: 54px;
                  height: 33px;
                }
              }
            }
          }

          &.overall-platforms-engagement {
            > .icon-wrapper {
              > .icon {
                width: 40px;
                height: 40px;
              }
            }
          }

          &.active,
          &:not(.active):hover {
            background-color: #1b4332;
            box-shadow: 0px 4px 40px -5px rgba(0, 0, 0, 0.25);

            > .icon-wrapper {
              > .icon {
                fill: #52b788;
                position: absolute;
              }
            }

            > .title {
              color: #ffffff;
            }
          }
        }
      }
    }

    > .engagement-and-post-entry,
    > .posts-by-engagement-entry,
    > .social-performance-entry,
    > .platforms-engagement-entry {
      margin: 30px 0 0 0;
    }
  }
}
</style>
