<template>
  <div class="fabs editor-right-fabs">
    <!--
    <div class="seo button-wrap" :class="{ open: activePanel == 'seo' }">
         <button @click="openPanel('seo')" id="seoBtn" class="link-button fab-button">
        <svg-icon class="button-icon" name="image" />
        SEO
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="seoBtn" target="seoBtn" placement="left"
        >SEO
      </b-tooltip>
      <div v-show="activePanel == 'seo'" class="panel-board">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Search engine optimization (seo)</div>
       <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>        </div>

        <EditorFabImagesPanel @dragged="handleImageDrag" />
      </div>
    </div>
    -->

    <div class="tasks button-wrap" :class="{ open: activePanel == 'tasks', disabled: !isSaved }">
      <button @click="openPanel('tasks')" id="tasksBtn" class="tasks-button fab-button">
        <svg-icon class="button-icon" name="task" />
        Tasks
      </button>
      <b-tooltip
        v-if="isSaved && activePanel == null"
        triggers="hover"
        container="tasksBtn"
        target="tasksBtn"
        placement="right"
      >
        Tasks
      </b-tooltip>
      <b-tooltip v-if="!isSaved" triggers="hover" container="tasksBtn" target="tasksBtn" placement="right">
        disabled
      </b-tooltip>
      <div v-show="activePanel == 'tasks'" class="panel-board">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Tasks ({{ tasksCount }})</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabTasksPanel :postId="postId" @update-tasks-count="tasksCount = $event" />
      </div>
    </div>
    <div class="comments button-wrap" :class="{ open: activePanel == 'comments', disabled: !isSaved }">
      <button @click="openPanel('comments')" id="commentsBtn" class="comments-button fab-button">
        <svg-icon class="button-icon" name="comment" />
        Comments
      </button>
      <b-tooltip
        v-if="isSaved && activePanel == null"
        triggers="hover"
        container="commentsBtn"
        target="commentsBtn"
        placement="right"
      >
        Comments
      </b-tooltip>
      <b-tooltip v-if="!isSaved" triggers="hover" container="commentsBtn" target="commentsBtn" placement="right">
        disabled
      </b-tooltip>
      <div v-show="activePanel == 'comments'" class="panel-board">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Comments ({{ commentsCount }})</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabCommentsPanel :postId="postId" @update-comments-count="commentsCount = $event" />
      </div>
    </div>
    <div class="preview button-wrap" :class="{ open: activePanel == 'preview' }">
      <button @click="openPanel('preview')" id="previewBtn" class="preview-button fab-button">
        <svg-icon class="button-icon" name="preview" />
        Preview
      </button>
      <b-tooltip
        v-if="activePanel == null"
        triggers="hover"
        container="previewBtn"
        target="previewBtn"
        placement="right"
      >
        Preview
      </b-tooltip>
      <div v-show="activePanel == 'preview'" class="panel-board">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Preview</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabTasksPanel :postId="postId" />
      </div>
    </div>
    <div class="activities button-wrap" :class="{ open: activePanel == 'activities', disabled: !isSaved }">
      <button @click="openPanel('activities')" id="activitiesBtn" class="activities-button fab-button">
        <svg-icon class="button-icon" name="activity" />
        Activities
      </button>
      <b-tooltip
        v-if="isSaved && activePanel == null"
        triggers="hover"
        container="activitiesBtn"
        target="activitiesBtn"
        placement="right"
      >
        Activities
      </b-tooltip>
      <b-tooltip v-if="!isSaved" triggers="hover" container="activitiesBtn" target="activitiesBtn" placement="right">
        disabled
      </b-tooltip>
      <div v-show="activePanel == 'activities'" class="panel-board">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Activities ({{ activitiesCount }})</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabActivitiesPanel :postId="postId" @update-activities-count="activitiesCount = $event" />
      </div>
    </div>
    <div
      class="approve button-wrap"
      v-if="isUserClientOrApprover && isSaved && post && !post.isApproved"
      :class="{ disabled: isUpdatingApproval }"
    >
      <button @click="approvePost" id="approveBtn" class="approve-button fab-button">
        <b-spinner v-if="isUpdatingApproval" class="mx-auto my-auto" small />
        <svg-icon v-else class="button-icon" name="check-circle-design" />
      </button>
      <b-tooltip triggers="hover" container="approveBtn" target="approveBtn" placement="right">
        Approve
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import EditorFabTasksPanel from './EditorFabTasksPanel';
import EditorFabCommentsPanel from './EditorFabCommentsPanel';
import EditorFabActivitiesPanel from './EditorFabActivitiesPanel';
import { UPDATE_POST_APPROVED_STATUS_MUTATION } from '~/graphql/mutations';
import { FETCH_POST_QUERY } from '~/graphql/queries';
import { createNamespacedHelpers } from 'vuex';
import { isArray, forEach } from 'lodash';
import Swal from 'sweetalert2';

const workspaceNamespace = createNamespacedHelpers('workspace');
const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  name: 'editor-right-fabs',

  components: {
    EditorFabTasksPanel,
    EditorFabCommentsPanel,
    EditorFabActivitiesPanel,
  },

  props: {
    postId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      activePanel: null,
      tasksCount: 0,
      commentsCount: 0,
      activitiesCount: 0,
      isUpdatingApproval: false,
      fetchPost: null,
    };
  },

  apollo: {
    fetchPost: {
      query: FETCH_POST_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: this.postId,
        };
      },
    },
  },

  mounted() {
    if (this.$apollo.queries.fetchPost) {
      this.$apollo.queries.fetchPost.refetch();
    }
  },

  computed: {
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
    ...accessControlNamespace.mapGetters(['isUserClientOrApprover']),
    isSaved() {
      return parseInt(this.postId, 10) > 0;
    },
    post() {
      return this.fetchPost;
    },
  },

  methods: {
    openPanel(panel) {
      this.activePanel = panel;
    },

    closeAllPanels() {
      this.activePanel = null;
    },

    async approvePost() {
      const status = this.post.isApproved ? 0 : 1;
      this.isUpdatingApproval = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_POST_APPROVED_STATUS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.post.id,
            isApproved: status,
          },
          refetchQueries: [
            {
              query: FETCH_POST_QUERY,
              variables: {
                workspace: this.workspace.id,
                id: this.postId,
              },
            },
          ],
        });

        Swal.fire({
          type: 'success',
          title: 'Success !!!',
          text: status ? 'Post approved succesfully' : 'Post dis-approved succesfully',
        });
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'INVALID_POST') {
              errorText = 'Ooops.. post does not exists';
            }

            if (error.message === 'UNKNOWN_WORKSPACE_MEMBER') {
              errorText = 'Ooops.. user cannot execute such action';
            }
            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error !!!',
          text: errorText || 'An error occurred while saving post approval status',
        });
      }

      this.isUpdatingApproval = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.fabs {
  &.editor-right-fabs {
    right: 0;
    top: 150px;
    padding: 15px 0;
    background: $primary;
    border-radius: 15px 0 0 15px;

    .button-wrap {
      padding: 6px 20px;
      border-radius: 10px 0 0 10px;
      margin: 4px auto;

      > button {
        font-size: 12px !important;
      }
      .panel-board {
        position: absolute;
        z-index: 10;
        background: $primary;
        box-sizing: content-box;
        width: 358px;
        min-height: 420px;
        min-height: 420px;
        right: 99px;
        top: 0;
        border-radius: 25px;
        box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);

        .panel-header {
          padding: 16px;
          color: $secondary;
          &_title {
            padding: 6px 12px;
            text-transform: uppercase;
            background: #2d6a4f;
            color: #d8f3dc;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
          }
          &_button {
            svg {
              fill: $secondary;
            }
          }
        }

        .panel-scroll {
          max-height: 445px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .panel-body {
          color: #d8f3dc;
          padding: 10px 20px;
        }
      }
      .panel-body-top {
        padding: 8px;
        text-align: left;
        color: $white;
        border: 1px solid #b7e4c7;
        border-radius: 10px;
        .input-group {
          color: $primary !important;
          border-radius: 0.3rem !important;
          background-color: #ffffff !important;
          input {
            font-size: 0.9rem;
            height: 40px;
            width: 85%;
            &::placeholder {
              color: $gray-500;
            }
          }
        }
        .input-group-text {
          svg {
            fill: #b7e4c7;
          }
        }
        input,
        .input-group-prepend,
        .input-group-text {
          background-color: transparent;
          border: none;
          outline: none;
          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
            border: none;
            outline: none;
          }
        }
      }

      &:hover {
        background: #2d6a4f;
        border-radius: 0px;
        padding-top: 1rem;
        .fab-button {
          color: $white;
          fill: $white;
          font-size: 14px;
        }
      }

      .fab-button {
        font-size: 14px;
        .tooltip {
          margin-left: 10px;

          .tooltip-inner {
            border-radius: $rounded-pill;
            padding: 5px 15px;

            &:empty {
              display: none;
            }
          }

          .arrow {
            display: none;
          }
        }
      }
      &.open {
        background: #2d6a4f;
        border-radius: 10px 0 0 10px;
        .fab-button {
          color: $white;
          .button-icon {
            fill: $white;
          }

          &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 68px;
            left: -29px;
            top: -6px;
            z-index: 13;
            background: $success;
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
  }
}
</style>
