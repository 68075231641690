<template>
  <section class="has-topwizard">
    <TopWizard :goBackHandler="goBackHandler" goBackText="Automation"> </TopWizard>

    <div class="my-5 mx-5">
      <h4 class="m-0 d-flex align-items-center justify-content-between">
        {{ meta.title }}

        <b-button :to="{ name: 'automation.create' }" variant="success" size="sm" class="px-4" pill>
          <svg-icon name="plus" class="mr-1" />
          Create new
        </b-button>
      </h4>

      <div class="d-flex align-items-center mt-5">
        <b-dropdown
          v-if="selectedIds.length"
          variant="clear"
          text="Select action"
          toggle-class="shadow-sm bg-white px-4"
          class="custom-dropdown mb-2"
          right
        >
          <b-dropdown-item @click="promptConfirmStatusChange('activate')">Activate</b-dropdown-item>
          <b-dropdown-item @click="promptConfirmStatusChange('deactivate')">Deactivate</b-dropdown-item>
          <b-dropdown-item @click="promptConfirmDeleteAutomation()">Delete</b-dropdown-item>
        </b-dropdown>

        <div class="d-flex ml-auto">
          <DropdownAccountChooser type="social" @change="handleAccountFilterChange" />

          <div class="auto-campaign-search-wrap">
            <b-form-input
              type="text"
              placeholder="Search for campaign..."
              class="auto-campaign-search shadow-sm border-0"
              autocomplete="off"
              @keydown.enter="refetchAutomations"
            />
            <b-button variant="clear" size="sm" @clcik="refetchAutomations" class="input-icon text-primary">
              <svg-icon name="search" />
            </b-button>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-sm p-3">
        <div class="p-5 text-center" v-if="$apollo.queries.automations.loading"><b-spinner variant="primary" /></div>
        <b-table
          v-else-if="automations.length"
          borderless
          :fields="fields"
          :items="automations"
          primary-key="id"
          thead-class="border-bottom text-center"
          tbody-class="text-center"
        >
          <!-- A custom formatted header cell for field 'check' -->
          <template v-slot:head(check)="data">
            <b-form-checkbox
              :checked="selectedIds.length === automations.length"
              @change="handleCheckAllChange"
              id="checkAll"
              name="checkAll"
            />
          </template>
          <template v-slot:cell(check)="data">
            <b-form-checkbox v-model="selectedIds" :value="data.item.id" :id="`check${data.item.id}`" />
          </template>
          <template v-slot:cell(status)="data">
            <b-form-checkbox
              :checked="data.item.status === 'active'"
              @change="handleStatusChange($event, data.item)"
              name="check-button"
              class="automation-status-switch"
              switch
            />
          </template>
          <template v-slot:cell(accounts)="data">
            <PublishAccountHeads :accounts="data.item.publishAccounts" />
          </template>
          <template v-slot:cell(actions)="data">
            <div class="auto-list-acitons">
              <b-button v-if="data.item.busy" variant="clear" size="sm" class="mr-2 p-0">
                <b-spinner variant="primary" small />
              </b-button>
              <b-button
                :to="{ name: 'automation.posts', params: { id: data.item.id } }"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="View Posts"
                class="d-inline-block mr-2"
              >
                <svg-icon name="list" class="mt-0" />
              </b-button>
              <b-button
                v-if="$route.params.recipe !== 'csv'"
                @click="editAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Edit"
                class="mr-2 p-0"
              >
                <svg-icon name="edit" class="mt-0" />
              </b-button>
              <b-button
                v-if="$route.params.recipe !== 'csv'"
                @click="duplicateAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Duplicate"
                class="mr-2 p-0"
              >
                <svg-icon name="copy" class="mt-0" />
              </b-button>
              <b-button
                @click="promptConfirmDeleteAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Remove"
                class="p-0"
              >
                <svg-icon name="trash" class="mt-0" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div v-else class="p-5 text-center">
          <img class="pt-3" width="200" src="@/assets/images/lost.svg" alt="" />
          <h5 class="w-75 mx-auto my-4">No results found</h5>

          <b-button :to="{ name: 'automation.create' }" size="sm"
            ><svg-icon name="plus" class="mr-1" /> Add new</b-button
          >
        </div>
      </div>
    </div>

    <b-modal ref="DeleteModal" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete Automation{{ !envokedForDelete ? '(s)' : '' }}?</h4>
        <p>
          Are you sure you would like to delete {{ !envokedForDelete ? 'the selected' : 'this' }} automation{{
            !envokedForDelete ? '(s)' : ''
          }}?
        </p>

        <b-button variant="light" class="mr-2" @click="cancelDeleteAutomation">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="deleteAutomation">Delete</b-button>
      </div>
    </b-modal>

    <b-modal ref="ChangeStatusModal" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <div v-if="statusChangeLoading" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>{{ capitalize(envokedStatusAction) }} Automation(s)?</h4>
        <p>Are you sure you would like to {{ envokedStatusAction }} the selected automation(s)?</p>

        <b-button variant="light" class="mr-2" @click="cancelStatusChange">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="changeAutomationStatus()">{{
          capitalize(envokedStatusAction)
        }}</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { debounce, capitalize } from 'lodash';
import TopWizard from '~/components/TopWizard';
import { AUTOMATIONS_QUERY } from '~/graphql/queries';
import AutomationMetaMixin from '~/mixins/AutomationMetaMixin';
import PublishAccountHeads from '~/components/PublishAccountHeads';
import DropdownAccountChooser from '~/components/DropdownAccountChooser';
import { CHANGE_AUTOMATION_STATUS_MUTATION, REMOVE_AUTOMATION_MUTATION } from '~/graphql/mutations';

export default {
  components: {
    TopWizard,
    PublishAccountHeads,
    DropdownAccountChooser,
  },

  mixins: [AutomationMetaMixin],

  data() {
    return {
      automations: [],
      selectedIds: [],
      envokedForDelete: null,
      isDeleting: false,

      envokedStatusAction: null,
      statusChangeLoading: false,
    };
  },

  computed: {
    fields() {
      const fields = [
        {
          key: 'check',
          class: 'text-left',
        },
        {
          key: 'status',
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'accounts',
        },
        {
          key: 'publishCount',
          label: 'Published Posts',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ];

      // We don't want to show "next refill time" field on csv automations
      // because they aren't refillable
      if (this.$route.params.recipe !== 'csv') {
        return [
          ...fields.slice(0, 4),
          {
            key: 'nextRefillTimeFormatted',
            label: 'Next refill time',
            sortable: true,
          },
          ...fields.slice(4),
        ];
      }

      return fields;
    },
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      listSearchInput: state => state.createAutomation.listSearchInput,
      listSelectedAccountIds: state => state.createAutomation.listSelectedAccountIds,
    }),
  },

  apollo: {
    automations: {
      query: AUTOMATIONS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: this.$route.params.type,
          recipe: this.$route.params.recipe,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        };
      },
    },
  },

  mounted() {
    window.Echo.channel('automations').listen('AutomationPlanned', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, {
          busy: false,
          nextRefillTimeFormatted: automation.nextRefillTimeFormatted,
        });
      }
    });

    window.Echo.channel('automations').listen('AutomationPlanningStarted', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, { busy: true });
      }
    });

    window.Echo.channel('automations').listen('AutomationPlanningFailed', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, { busy: false });
      }
    });
  },

  beforeDestroy() {
    window.Echo.leave('automations');
  },

  methods: {
    goBackHandler() {
      this.$router.push({ name: 'automation.home' });
    },

    updateAutomation(automationId, updates) {
      const data = this.readQueryData();

      const index = data.automations.findIndex(a => automationId === a.id);
      if (index !== -1) {
        data.automations.splice(index, 1, {
          ...data.automations[index],
          ...updates,
        });

        this.writeQueryData(data);
      }
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: AUTOMATIONS_QUERY,
        variables: {
          workspace: this.workspace.id,
          type: this.$route.params.type,
          recipe: this.$route.params.recipe,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        },
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: AUTOMATIONS_QUERY,
        variables: {
          workspace: this.workspace.id,
          type: this.$route.params.type,
          recipe: this.$route.params.recipe,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        },
        data,
      });
    },

    handleCheckAllChange(isChecked) {
      if (isChecked) {
        this.selectedIds = this.automations.map(auto => auto.id);
      } else {
        this.selectedIds = [];
      }
    },

    promptConfirmStatusChange(status) {
      this.envokedStatusAction = status;

      this.$refs.ChangeStatusModal.show();
    },

    cancelStatusChange() {
      if (this.envokedStatusAction) {
        this.envokedStatusAction = null;
      }

      this.$refs.ChangeStatusModal.hide();
    },

    // We use debounce to limit unnecessary calls to the server
    // for example when you toggle status on and immediately toggle it off again
    handleStatusChange: debounce(function(isChecked, automation) {
      const status = isChecked ? 'active' : 'inactive';

      if (automation.status === status) {
        return;
      }

      this.changeAutomationStatus(status, automation);
    }, 1000),

    async changeAutomationStatus(status = null, automation = null) {
      if ((!status && !this.envokedStatusAction) || (!automation && !this.selectedIds.length)) {
        return;
      }

      let automationStatus = status;
      if (this.envokedStatusAction) {
        automationStatus = this.envokedStatusAction === 'activate' ? 'active' : 'inactive';
      }

      // Let's not send the automations that already has our target status
      const ids = this.selectedIds.filter(id => {
        const auto = this.automations.find(a => a.id === id);
        return auto && auto.status !== automationStatus;
      });

      if (ids.length || automation) {
        this.statusChangeLoading = true;

        await this.$apollo
          .mutate({
            mutation: CHANGE_AUTOMATION_STATUS_MUTATION,
            variables: {
              workspace: this.workspace.id,
              ids: automation ? [automation.id] : ids,
              status: automationStatus,
            },
            update: (store, { data: { changed } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: AUTOMATIONS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  search: this.listSearchInput,
                  accountIds: this.listSelectedAccountIds,
                },
              });
              changed.map(auto => {
                const index = data.automations.findIndex(a => auto.id === a.id);

                if (index !== -1) {
                  data.automations.splice(index, 1, auto);
                }
              });
              // Write our data back to the cache.
              store.writeQuery({
                query: AUTOMATIONS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  search: this.listSearchInput,
                  accountIds: this.listSelectedAccountIds,
                },
                data,
              });
            },
          })
          .then(() => {
            this.statusChangeLoading = false;
          })
          .catch(() => {
            this.statusChangeLoading = false;
            this.$refs.ChangeStatusModal.hide();

            Swal.fire({
              type: 'error',
              title: 'OOPS',
              text: 'An error occurred. Please try again',
            });
          });
      }

      if (this.selectedIds.length && !automation) {
        this.$refs.ChangeStatusModal.hide();
        this.selectedIds = [];
      }
    },

    handleAccountFilterChange(accountIds) {
      this.$store.dispatch('createAutomation/updateListSelectedAccounts', accountIds);
    },

    refetchAutomations(e) {
      this.$store.dispatch('createAutomation/updateListSearchInput', e.target.value);
    },

    editAutomation(automation) {
      this.$store.dispatch('createAutomation/sync', automation);

      this.$router.push({ name: 'automation.create' });
    },

    duplicateAutomation(automation) {
      this.$store.dispatch('createAutomation/sync', { ...automation, id: 0, name: `${automation.name} copy` });

      this.$router.push({ name: 'automation.create' });
    },

    promptConfirmDeleteAutomation(automation = null) {
      if (automation) {
        this.envokedForDelete = automation;
      }

      this.$refs.DeleteModal.show();
    },

    cancelDeleteAutomation() {
      if (this.envokedForDelete) {
        this.envokedForDelete = null;
      }

      this.$refs.DeleteModal.hide();
    },

    deleteAutomation() {
      if (!this.envokedForDelete && !this.selectedIds.length) {
        return;
      }

      this.isDeleting = true;

      this.$apollo
        .mutate({
          mutation: REMOVE_AUTOMATION_MUTATION,
          variables: {
            workspace: this.workspace.id,
            ids: this.envokedForDelete ? [this.envokedForDelete.id] : this.selectedIds,
          },
          update: (store, { data: { deleted } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: this.$route.params.type,
                recipe: this.$route.params.recipe,
                search: this.listSearchInput,
                accountIds: this.listSelectedAccountIds,
              },
            });
            if (deleted) {
              if (!this.envokedForDelete) {
                this.selectedIds.forEach(id => {
                  const index = data.automations.findIndex(a => id === a.id);

                  if (index !== -1) {
                    data.automations.splice(index, 1);
                  }
                });
              } else {
                const index = data.automations.findIndex(a => this.envokedForDelete.id === a.id);

                if (index !== -1) {
                  data.automations.splice(index, 1);
                }
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: this.$route.params.type,
                recipe: this.$route.params.recipe,
                search: this.listSearchInput,
                accountIds: this.listSelectedAccountIds,
              },
              data,
            });
          },
        })
        .then(() => {
          const usedCount = parseInt(this.user.subscription.limitsUsage.automations);
          this.$store.dispatch('auth/updateUser', {
            user: {
              ...this.user,
              subscription: {
                ...this.user.subscription,
                limitsUsage: {
                  ...this.user.subscription.limitsUsage,
                  automations: usedCount - 1,
                },
              },
            },
          });

          const count = parseInt(this.workspace.limitsUsage.automations);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              automations: count - 1,
            },
          });

          this.$refs.DeleteModal.hide();
          this.isDeleting = false;
        })
        .catch(e => {
          console.dir(e);
          this.$refs.DeleteModal.hide();
          this.isDeleting = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Could not delete automation. Try again later.',
          });
        });
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

thead th {
  outline: 0;
}

.automation-status-switch {
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $success;
          background-color: $success;
        }
      }
    }
  }
}

.auto-campaign-search-wrap {
  position: relative;

  .auto-campaign-search {
    font-size: 15px;

    &::placeholder {
      color: $gray-400;
    }
  }

  .input-icon {
    position: absolute;
    right: 3px;
    top: 4px;
    cursor: pointer;
    box-shadow: none;
  }
}

.auto-list-acitons {
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
