<template>
  <div :class="$route.params.recipe === 'ai' && 'ai-design'">
    <div class="my-3 text-center">
      <h4 :style="{ fontWeight: '600', fontSize: '32px' }" class=" font-bold capitalize">
        {{ $route.params.recipe === 'ai' ? `AI ${$route.params.type} Post Automations` : meta.title }}
      </h4>
      <p class=" font-normal">
        {{ $route.params.recipe === 'ai' ? 'Create your post at a go with this instant AI tool' : meta.description }}
      </p>
    </div>
    <TopWizard
      :goBackHandler="goBackHandler"
      goBackText="Automations"
      :sequence="sequence"
      :currentStep="currentStep"
      :sidebar="false"
    >
      <template v-slot:wizard-link="{ step }">
        <a
          @click.prevent="setCurrentStep(step.number)"
          class="wiz-step-item hover:text-white hover:!no-underline text-sm flex px-4 !py-3"
          :class="{ 'bg-[---dark-green] text-[---base-green]': currentStep >= step.number }"
          href="#"
        >
          {{ step.number }}. {{ step.name }}
        </a>
      </template>
      <div class="action-buttons" slot="buttons">
        <span id="next-btn-wrap" tabindex="0">
          <b-button
            @click="moveToNext"
            variant="primary"
            class="px-4 m-0 !flex !flex-row items-center act-button next"
            :disabled="isNextDisabled || saveBusy"
          >
            <b-icon v-if="isLastStep" icon="save2" class="mr-1"></b-icon>
            {{ isLastStep ? ($route.params.recipe === 'ai' ? 'Review & Save' : 'Save') : 'Next' }}
            <b-spinner v-if="saveBusy" class="ml-1" small />
            <svg-icon
              v-else-if="!saveBusy && !isLastStep"
              :class="{ 'mt-0': !isLastStep }"
              :name="'arrow-right'"
              class="ml-1"
            />
          </b-button>
        </span>
        <b-tooltip
          v-if="isNextDisabled"
          triggers="hover"
          target="next-btn-wrap"
          variant="info"
          placement="bottom"
          custom-class="error-tip"
          :title="error"
        ></b-tooltip>
      </div>
    </TopWizard>

    <div>
      <b-container class="my-5">
        <transition name="campaign-slide" mode="out-in">
          <AutomationNameAndAccountsStep v-if="currentStep === 1" key="name-accounts" :type="$route.params.type" />

          <template v-else-if="currentStep === 2">
            <AutomationArticleRulesAndFilters
              v-if="$route.params.recipe === 'articles'"
              key="rules-filters-articles"
              :collapsed="currentStep === 2 && keywords.length > 0"
            />
            <AutomationVideoRulesAndFilters
              v-else-if="$route.params.recipe === 'videos'"
              key="rules-filters-videos"
              :collapsed="currentStep === 2 && keywords.length > 0"
            />
            <AutomationRssFeedForm v-else-if="$route.params.recipe === 'rss'" key="rss-source" />
            <AutomationAIForm v-else-if="$route.params.recipe === 'ai'" key="ai-source" @next="moveToNext" />
            <AutomationCsvUploadForm v-else-if="$route.params.recipe === 'csv'" key="csv-upload" />
          </template>

          <AutomationOptimizationStep v-else-if="currentStep === 3" key="optimization" @skip="skipHashtag" />

          <template v-else-if="currentStep === 4">
            <AutomationRssFeedScheduleStep v-if="$route.params.recipe === 'rss'" key="rss-schedule" />
            <AutomationCsvScheduleStep v-else-if="$route.params.recipe === 'csv'" key="csv-finalize" />
            <AutomationScheduleStep v-else key="schedule" />
          </template>

          <template v-else-if="currentStep === 5">
            <AutomationLoader
              @save="moveToNext"
              @viewAutomations="viewAutomations"
              @go-back="regoBack"
              :proceedToSave="proceedToSave"
              :loading="loading"
            />
          </template>
        </transition>
      </b-container>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';
import { isValidUrl } from '~/utils/helpers';
import TopWizard from '~/components/TopWizard';
import AutomationMetaMixin from '~/mixins/AutomationMetaMixin';
import AutomationAIForm from '~/components/AutomationAIForm';
import AutomationLoader from '~/components/AutomationLoader';
import AutomationRssFeedForm from '~/components/AutomationRssFeedForm';
import AutomationCsvUploadForm from '~/components/AutomationCsvUploadForm';
// import PublishAccountsSkeleton from '~/components/PublishAccountsSkeleton';
import AutomationScheduleStep from '~/components/AutomationScheduleStep';
import AutomationCsvScheduleStep from '~/components/AutomationCsvScheduleStep';
import AutomationOptimizationStep from '~/components/AutomationOptimizationStep';
import AutomationRssFeedScheduleStep from '~/components/AutomationRssFeedScheduleStep';
import AutomationVideoRulesAndFilters from '~/components/AutomationVideoRulesAndFilters';
import AutomationNameAndAccountsStep from '~/components/AutomationNameAndAccountsStep';
import AutomationArticleRulesAndFilters from '~/components/AutomationArticleRulesAndFilters';

import app from '~/main';
import { can } from '~/utils/helpers';
import { get } from 'lodash';

export default {
  components: {
    TopWizard,
    AutomationAIForm,
    AutomationLoader,
    AutomationRssFeedForm,
    AutomationScheduleStep,
    AutomationCsvUploadForm,
    // PublishAccountsSkeleton,
    AutomationCsvScheduleStep,
    AutomationOptimizationStep,
    AutomationRssFeedScheduleStep,
    AutomationNameAndAccountsStep,
    AutomationVideoRulesAndFilters,
    AutomationArticleRulesAndFilters,
  },

  mixins: [AutomationMetaMixin],

  async beforeRouteEnter(to, from, next) {
    const automationId = app.$store.state.createAutomation.id;
    if (automationId != 0) {
      return next(); // Allow editing an already created automation.
    }

    const user = app.$store.state.auth.user;
    const workspace = app.$store.state.workspace.current;
    const randomnumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;

    const canView = user && can(user, 'bypass-subscription');
    const limit = parseInt(workspace.limits.automations || '');
    const limitCount = isNaN(limit) ? Infinity : limit;
    const usedCount = parseInt(workspace.limitsUsage.automations || '');

    const hasExceeded = usedCount >= limitCount;

    if (!canView && hasExceeded) {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You have reached the maximum number of automations allowed in your current plan. Please upgrade to create more automations.',
      });

      return next({ name: 'automation.list', query: { rf: randomnumber } });
    }

    next();
  },

  data() {
    return {
      loading: 'idle',
      proceedToSave: false,
      savingAutomation: false,
      error: null,
    };
  },

  computed: {
    sequence() {
      return [
        {
          number: 1,
          name: 'Name / Accounts',
          to: '',
        },
        {
          number: 2,
          name:
            this.$route.params.recipe === 'rss'
              ? 'Feed Source'
              : this.$route.params.recipe === 'csv'
              ? 'Upload CSV File'
              : 'Rules & Filters',
          to: '',
        },
        {
          number: 3,
          name: 'Optimization',
          to: '',
        },
        {
          number: 4,
          name: 'Schedule',
          to: '',
        },
      ];
    },
    isNextDisabled() {
      return this.stepDisabled(this.currentStep);
    },

    isLastStep() {
      if (this.$route.params.recipe === 'ai') {
        return this.currentStep === 5;
      }

      return this.currentStep === this.sequence.length;
    },

    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      currentStep: state => state.createAutomation.currentStep,
      campaignName: state => state.createAutomation.campaignName,
      selectedAccounts: state => state.createAutomation.selectedAccounts,
      source: state => state.createAutomation.source,
      keywords: state => state.createAutomation.keywords,
      selectedHashtagPreset: state => state.createAutomation.selectedHashtagPreset,
      recurringValue: state => state.createAutomation.recurringValue,
      recurringUnit: state => state.createAutomation.recurringUnit,
      accounts: state => state.createAutomation.accounts,
      aiPostPrompt: state => state.createAutomation.aiPostPrompt,
      scheduleType: state => state.createAutomation.scheduleType,
      csvDateFormat: state => state.createAutomation.csvDateFormat,
      saveBusy: state => state.createAutomation.saveBusy,
      csvFile: state => state.createAutomation.csvFile,
    }),

    primaryBlog() {
      return this.selectedAccounts.find(a => a.publishVariant == 'primary') || {};
    },

    secondaryBlogs() {
      return this.selectedAccounts.filter(a => a.publishVariant == 'secondary');
    },
  },

  mounted() {
    if (this.$route.params.recipe === 'videos') {
      this.$store.dispatch('createAutomation/updateSource', 'youtube');
      this.$store.dispatch('createAutomation/updateSorting', 'relevance');
    }
  },

  beforeDestroy() {
    this.$store.dispatch('createAutomation/reset');

    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    ...mapActions({
      updateCurrentWorkspace: 'workspace/updateCurrent',
    }),

    goBackHandler() {
      const route = { name: this.$route.params.recipe == 'csv' ? 'automation.home' : 'automation.list' };
      this.$router.push(route);
    },

    stepDisabled(step) {
      // return false;
      switch (step) {
        case 1: {
          if (this.$route.params.type === 'social') {
            if (!this.campaignName) {
              this.error = 'Please enter a campaign name';
              return true;
            } else if (this.selectedAccounts.length < 1) {
              this.error = 'Please select one or more accounts';
              return true;
            }

            this.error = null;
            return false;
          } else {
            const secBlogInvalid = this.secondaryBlogs.some(blog => this.isBlogValid(blog));
            const primeBlogInvalid = this.isBlogValid(this.primaryBlog);

            if (!this.primaryBlog.accountId) {
              this.error = 'Please choose a primary blog';
              return true;
            } else if (!this.campaignName) {
              this.error = 'Enter a campaign name';
              return true;
            }

            this.error = 'Please set publish author, categories and/or status for all selected accounts';
            return secBlogInvalid || primeBlogInvalid;
          }
        }
        case 2: {
          if (this.$route.params.recipe === 'rss') {
            if (!isValidUrl(this.source)) {
              this.error = 'Please enter a valid RSS feed URL';
              return true;
            }
            this.error = 'Add a Feed URL';
            return !this.source;
          } else if (this.$route.params.recipe === 'csv') {
            if (!this.csvFile) {
              this.error = 'Please select a CSV file';
              return true;
            }

            this.error = '';
            return false;
          } else if (this.$route.params.recipe === 'ai') {
            this.error = 'Please add a description';
            return !this.aiPostPrompt;
          }

          this.error = 'Add at least one keyword';
          return this.keywords.length < 1;
        }
        case 3:
          return false;
        case 4:
          this.error = 'Invalid input';
          return this.recurringValue < 1;
        default:
          return false;
      }
    },

    isBlogValid(blog) {
      let invalidForWP = false;
      if (blog.platform == 'wordpress') {
        invalidForWP =
          !blog.publishAuthorId ||
          !blog.publishCategoryIds ||
          (blog.publishCategoryIds && !blog.publishCategoryIds.length);
      }

      return !blog.publishStatus || invalidForWP;
    },

    /**
     * Moves to the next step by incrementing currentStep by 1
     *
     * @return {void}
     */
    moveToNext() {
      if (this.isLastStep) {
        this.savingAutomation = true;
        this.proceedToSave = true;
        this.loading = 'loading';
        return this.$store
          .dispatch('createAutomation/saveAutomation', {
            workspace: this.workspace.id,
            type: this.$route.params.type,
            recipe: this.$route.params.recipe,
          })
          .then(() => {
            this.$notify({
              group: 'main',
              type: 'native',
              title: 'Automation saved',
              text: 'Your automation is now being processed.',
            });

            const usedCount = parseInt(get(this.user, 'subscription.limitsUsage.automations', 0), 10);

            this.$store.dispatch('auth/updateUser', {
              user: {
                ...this.user,
                subscription: {
                  ...get(this.user, 'subscription', {}),
                  limitsUsage: {
                    ...get(this.user, 'subscription.limitsUsage', {}),
                    automations: usedCount + 1,
                  },
                },
              },
            });

            const count = parseInt(get(this.workspace, 'limitsUsage.automations', 0), 10);

            this.updateCurrentWorkspace({
              ...this.workspace,
              limitsUsage: {
                ...get(this.workspace, 'limitsUsage', {}),
                automations: count + 1,
              },
            });

            if (this.$route.params.recipe !== 'ai') {
              this.viewAutomations();
            }

            this.loading = 'success';
          })
          .catch(e => {
            console.log('error here is ===> ', e);
            console.dir(e);

            this.loading = 'error';

            if (this.$route.params.recipe !== 'ai') {
              Swal.fire({
                type: 'error',
                title: 'OOPS!',
                text: 'Something went wrong while processing your request. Please try again.',
              });
            }
          })
          .finally(() => {
            this.savingAutomation = false;
          });
      }

      this.setCurrentStep(this.currentStep + 1);
    },

    viewAutomations() {
      this.$store.dispatch('createAutomation/reset');
      this.$router.push({ name: 'automation.list' });
    },

    /**
     * Sets the current step
     *
     * @param  {Int} step A step number to move to
     * @return {void}
     */
    setCurrentStep(stepNumber, force = true) {
      // One step at a time
      if (this.currentStep - stepNumber > 1 && !force) return;

      if (stepNumber < this.currentStep || (!this.isNextDisabled && !this.stepDisabled(stepNumber - 1))) {
        this.$store.dispatch('createAutomation/updateCurrentStep', stepNumber);
      }
    },

    skipHashtag() {
      this.moveToNext();
    },

    regoBack() {
      this.loading = 'idle';
      this.proceedToSave = false;
      this.setCurrentStep(this.currentStep - 4, true);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.ai-design {
  background-color: rgba(50, 160, 103, 0.087) !important;
  padding-bottom: 50px;
  padding-top: 70px;
  height: calc(100vh - 0px);
  transform: translateY(-70px);
}

.auto-box {
  padding: 4rem;
  background-color: $white;
  width: 100%;
  transition: all 0.4s ease-out;

  .auto-box-inner {
    width: 70%;
    margin: auto;

    h4,
    h5 {
      font-weight: bold;
    }
  }

  &.collapsed {
    width: 63%;
    padding: 4rem 0.2rem;
    position: absolute;
    left: 0;
    min-height: 700px;

    .auto-box-inner {
      width: 90%;
    }
  }
}
.campaign-slide-enter-active {
  animation: campaign-slide-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.campaign-slide-leave-active {
  animation: campaign-slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes campaign-slide-right {
  0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes campaign-slide-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-150px);
    opacity: 0;
  }
}

.reportrange-text {
  background: #f5f9fc !important;
  border: 1px solid #f5f9fc !important;
  box-shadow: 0 1px 0 0 rgba(152, 158, 181, 0.3);
  font-size: 0.9rem;
}

.daterangepicker .drp-buttons .btn {
  &.btn-success {
    background-color: $primary;
    border-color: $primary;

    &:focus {
      box-shadow: none;
    }
  }
}

.daterangepicker td.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
  background-color: #eee;
}
</style>
