<template>
  <b-row cols="2" class="flex-wrap">
    <b-col v-for="item in items" :key="item + 1">
      <div class="quotes-skeleton">
        <skeleton-screen width="100%" class="mb-2" />
        <skeleton-screen width="90%" class="mb-2" />
        <skeleton-screen width="98%" class="mb-2" />
        <skeleton-screen width="90%" class="mb-2" />
        <skeleton-screen width="98%" class="mb-2" />
      </div>
    </b-col>
  </b-row>
  <!-- <div class="d-flex flex-wrap"> -->
  <!-- <div v-for="item in items" :key="item + 1" class="dashboard-quotes-item-skeleten shadow-sm mb-4">
      <div class="p-4">
        <skeleton-screen width="100%" class="mb-2" />
        <skeleton-screen width="90%" class="mb-2" />
        <skeleton-screen width="98%" class="mb-2" />
        <skeleton-screen width="90%" class="mb-2" />
        <skeleton-screen width="98%" class="mb-2" />
      </div>
    </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  name: 'dashboard-quotes-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 6,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.quotes-skeleton {
  height: 181px;
  border: 1px solid $gray-200;
  margin: 8px 2px;
}
.dashboard-quotes-item-skeleten {
  margin-right: 10px;
  min-width: 226px;
  height: 181px;
  border-radius: 10px;
  border: 1px solid $gray-200;

  .img-skeleton {
    border-radius: 10px;
  }
}
</style>
