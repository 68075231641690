<template>
  <div class="report-entry">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Report',
};
</script>

<style lang="scss" scoped>
.report-entry {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 25px;

  ::v-deep {
    &,
    & * {
      font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif !important;
    }

    > .content {
      display: flex;
      width: 100%;
      max-width: 1500px;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      align-content: flex-start;
    }
  }
}
</style>
