import { Mark } from 'tiptap';
import { removeMark, updateMark } from 'tiptap-commands';

import ViewComponent from './View.vue';

export default class SubMark extends Mark {
  get name() {
    return 'sub';
  }

  get schema() {
    return {
      attrs: {
        alias: {
          default: null,
        },
      },
      inclusive: false,
      excludes: '_',
      parseDOM: [
        {
          tag: 'sub',
          getAttrs: dom => ({
            alias: dom.getAttribute('alias'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'sub',
          {
            alias: node.attrs['alias'],
          },
          0,
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type }) {
    return attrs => {
      if (attrs.alias) {
        return updateMark(type, attrs);
      }

      return removeMark(type);
    };
  }
}
