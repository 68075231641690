<template>
  <div>
    <div
      class="planner-listview-header rounded-top"
      :class="{ 'shadow-sm stick': isScrollPassed, 'mb-2': !isScrollPassed }"
    >
      <b-button-group size="sm">
        <b-button variant="outline-primary" class="list-ctr-btn px-3 mt-0" @click="prev"
          ><svg-icon name="chevron-left"
        /></b-button>
        <b-button variant="outline-primary" class="list-ctr-btn px-3 mt-0" @click="next"
          ><svg-icon name="chevron-right"
        /></b-button>
      </b-button-group>
      <b-badge variant="secondary" class="ml-4 rounded-pill px-4 py-2">
        <b-spinner v-if="isLoading" small />
        <span v-else
          >{{ plannerPosts.length }} posts from {{ calendar.span.start.format('Do MMMM YYYY') }} to
          {{ calendar.span.end.format('Do MMMM YYYY') }}</span
        >
      </b-badge>
    </div>

    <div class="planner-list-container shadow-sm rounded-sm">
      <ul class="steps mb-0 p-0">
        <li v-for="day in calendar.days" :key="day.dayIndentifier" class="planner-listview-item">
          <div class="indicator-wrap col-4"><div class="wheel"></div></div>
          <div class="col-96">
            <div class="head-row">
              <div class="planner-day-hour mr-2">{{ day.format('Do MMMM YYYY') }}</div>
            </div>

            <div class="planner-listview-posts">
              <div class="dayview-empty-day p-4" v-if="!getDayPosts(day).length || isLoading">
                <b-spinner v-if="isLoading" type="grow" />
                <span v-else-if="!getDayPosts(day).length && !isLoading">No Posts</span>
              </div>
              <template v-else>
                <div v-for="dayPost in getDayPosts(day)" :key="dayPost.id" class="step-post">
                  <div v-if="dayPost.campaignId" class="planner-post-campaign-indicator">
                    <div class="campaign-label text-truncate text-info" :title="`Campaign: ${dayPost.campaign.title}`">
                      <svg-icon name="megaphone" class="mr-1" /> {{ dayPost.campaign.title }}
                    </div>
                  </div>
                  <div class="status-icon col-4">
                    <svg-icon v-if="dayPost.status === 'published'" name="check-circle-success" />
                    <svg-icon v-else name="check-circle" />
                  </div>
                  <div class="col post-time">{{ dayPost.utcScheduleTime }}</div>
                  <div class="s-accounts text-center">
                    <span
                      class="sa-photo"
                      v-for="publishAccount in formatPublishAccounts(dayPost.publishAccounts)"
                      :key="publishAccount.id"
                    >
                      <img
                        v-if="!publishAccount.indicator"
                        :src="publishAccount.imageUrl"
                        width="40"
                        alt="Acount photo"
                        class="rounded-circle"
                      />
                      <span class="more-account-photos" v-else-if="publishAccount.indicator">{{
                        publishAccount.more
                      }}</span>
                    </span>
                  </div>
                  <div class="body text-truncate col">
                    <img
                      v-if="getAccountPostImage(dayPost)"
                      :src="getAccountPostImage(dayPost)"
                      width="20"
                      height="20"
                      class="rounded-sm mr-2"
                    />
                    <span>{{ getAccountPostContent(dayPost) }}</span>
                  </div>
                  <div class="actions col-9">
                    <div class="email-actions">
                      <span class="mr-2" @click="editPost(dayPost)"><svg-icon name="edit"/></span>
                      <span class="mr-2" @click="deletePost(dayPost)"><svg-icon name="trash"/></span>
                    </div>
                  </div>
                </div>
              </template>

              <b-dropdown
                :variant="false ? 'light border' : 'primary'"
                class="planner-listview-dropdown"
                toggle-class="planner-listview-add-btn"
                menu-class="planner-dropdown-menu"
                no-caret
                no-flip
                size="sm"
                :disabled="false"
              >
                <template slot="button-content">
                  <svg-icon name="plus" />
                </template>
                <b-dropdown-item @click="createSocialPost(day)">
                  <svg-icon name="network" class="mr-1" />
                  Social post
                </b-dropdown-item>
                <b-dropdown-item @click="createBlogPost(day)">
                  <svg-icon name="wordpress" class="mr-1" />
                  Blog post
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <b-modal id="PlannerDeleteModal" centered hide-footer hide-header>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete post?</h4>
        <p>Are you sure you would like to delete this post?</p>

        <b-button variant="light" class="mr-2" @click="cancelDeletePost">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="actuallyDeletePost">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { Calendar, Day } from 'dayspan';
import { stripTags } from '~/utils/helpers';
import { PLANNER_POSTS_QUERY } from '~/graphql/queries';
import { DELETE_POST_MUTATION } from '~/graphql/mutations';

export default {
  name: 'plannerDaysView',

  data() {
    return {
      plannerPosts: [],
      calendar: this.getCalendar(),
      isLoading: false,
      isDeleting: false,
      scrollY: 0,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      postToDelete: state => state.planner.postToDelete,
    }),

    startDate() {
      return this.calendar.span.start.format('YYYY-MM-DDTHH:mm:ss');
    },

    endDate() {
      return this.calendar.span.end.format('YYYY-MM-DDTHH:mm:ss');
    },

    isScrollPassed() {
      return this.scrollY > 117;
    },
  },

  mounted() {
    this.fetchPosts();

    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    fetchPosts() {
      this.isLoading = true;

      return this.$apollo
        .query({
          query: PLANNER_POSTS_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            workspace: parseInt(this.workspace.id, 10) || 0,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then(({ data }) => {
          this.isLoading = false;

          this.plannerPosts = data.plannerPosts;
        })
        .catch(err => {
          this.isLoading = false;

          console.warn(err);
        });
    },

    next() {
      this.calendar.unselect().next();

      this.fetchPosts();
    },

    prev() {
      this.calendar.unselect().prev();

      this.fetchPosts();
    },

    getCalendar() {
      return Calendar.months(1, Day.today(), 0, { fill: false });
    },

    getDayPosts(day) {
      const dayMonth = day.format('YYYY MM DD');

      const posts = this.plannerPosts.filter(post => {
        const postDay = moment(post.utcScheduleDate).format('YYYY MM DD');
        return postDay === dayMonth;
      });
      return posts || [];
    },

    editPost(post) {
      if (post.campaignId) {
        this.$router.push({ name: 'campaign.posts', params: { id: post.campaign.id } });
      } else {
        this.$router.push({ name: 'compose', params: { type: post.type, id: post.id } });
      }
    },

    deletePost(post) {
      this.$store.dispatch('planner/updatePostToDelete', post);

      this.$bvModal.show('PlannerDeleteModal');
    },

    formatPublishAccounts(publishAccounts) {
      if (publishAccounts.length > 4) {
        // Cut out 3 and add more indicator
        const slicedOut = publishAccounts.slice(0, 3);
        return [
          ...slicedOut,
          {
            id: 'more',
            indicator: true,
            more: `+${publishAccounts.length - slicedOut.length}`,
          },
        ];
      }

      return publishAccounts;
    },

    getAccountPostContent(post) {
      const first = post.accountPostContents[0];
      return stripTags(first ? first.content : post.masterContent);
    },

    getAccountPostImage(post) {
      const first = post.accountPostContents[0];
      const firstAttachment = first ? first.attachments[0] : false;
      return firstAttachment ? firstAttachment.url : '';
    },

    createSocialPost(day) {
      this.$store.dispatch('createPost/updateScheduleDate', day.format('YYYY-MM-DDTHH:mm:ss'));
      this.$store.dispatch('createPost/updateScheduleTimezone', 'UTC');
      this.$store.dispatch('createPost/updatePostStatus', 'scheduled');

      this.$router.push({ name: 'compose', params: { type: 'social', id: 0 } });

      this.$notify({
        group: 'main',
        type: 'native',
        duration: 5000,
        title: 'Schedule date set',
        text: `Sechedule date set to <strong>${day.format('Do of MMMM YYYY')}</strong>`,
      });
    },
    createBlogPost(day) {
      this.$store.dispatch('createPost/updateScheduleDate', day.format('YYYY-MM-DDTHH:mm:ss'));
      this.$store.dispatch('createPost/updateScheduleTimezone', 'UTC');
      this.$store.dispatch('createPost/updatePostStatus', 'scheduled');

      this.$router.push({ name: 'compose', params: { type: 'blog', id: 0 } });

      this.$notify({
        group: 'main',
        type: 'native',
        duration: 5000,
        title: 'Schedule date set',
        text: `Sechedule date set to <strong>${day.format('Do of MMMM YYYY')}</strong>`,
      });
    },

    cancelDeletePost() {
      this.$store.dispatch('planner/updatePostToDelete', null);

      this.$bvModal.hide('PlannerDeleteModal');
    },

    async actuallyDeletePost() {
      this.isDeleting = true;

      if (!this.postToDelete) {
        return;
      }

      await this.$apollo.mutate({
        mutation: DELETE_POST_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.postToDelete.id],
        },
      });

      const postIndex = this.plannerPosts.findIndex(post => post.id === this.postToDelete.id);

      if (postIndex !== -1) {
        this.plannerPosts = [...this.plannerPosts.slice(0, postIndex), ...this.plannerPosts.slice(postIndex + 1)];
      }

      this.isDeleting = false;
      this.cancelDeletePost();

      Swal.fire({
        type: 'success',
        title: 'Success!',
        text: 'Post deleted successfully',
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.planner-listview-header {
  background-color: $white;
  padding: 20px;
  margin-bottom: 10px;

  &.stick {
    position: fixed;
    top: $navbar-height;
    width: 97.8%;
    z-index: 1001;
    animation: slide-down 0.2s steps(50) reverse;
  }

  .list-ctr-btn {
    &:focus {
      box-shadow: none;
    }
  }
}

.planner-list-container {
  overflow: hidden;
  padding: 50px 30px;
  background-color: $white;
}

.dayview-empty-day {
  color: $gray-200;
  font-size: 1.1rem;
  text-transform: uppercase;
  user-select: none;
}

.planner-day-hour {
  padding: 0 0 20px;
  font-weight: 700;
  color: #4a556b;
}
.planner-listview-item {
  padding-bottom: 40px;
  border-left: 1px solid #ccced2;
  display: flex;

  &:last-child {
    border-left: 0;
  }

  .planner-post-campaign-indicator {
    .campaign-label {
      max-width: 135px;
      color: $info;
    }
  }

  .col {
    flex: 1;
  }
  .col-4 {
    flex: 1 1 4%;
    max-width: 4%;
    max-height: 100%;
  }
  .col-9 {
    flex: 1 1 9%;
    max-width: 9%;
    max-height: 100%;
  }
  .col-96 {
    flex: 1 1 96%;
    max-width: 96%;
    max-height: 100%;
  }

  .head-row {
    display: flex;
  }

  .wheel {
    outline: none;
    position: absolute;
    left: -8px;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border: 1px solid #9ba1ad;
    color: #dfe0e3;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    background-clip: padding-box;
  }

  .planner-listview-add-btn {
    background-color: #fefefe;
    filter: alpha(opacity=0);
    transition: all 0.12s;
    color: $gray-600;
    padding: 0.1rem 0.2rem;
    line-height: 1;
    border-color: $gray-600;
    border-radius: 3px;
    opacity: 0;

    &:focus {
      box-shadow: none;
    }

    .icon {
      width: 0.7rem;
      height: 0.7rem;
      margin-top: -2px;
    }
  }
  &:hover {
    .planner-listview-add-btn {
      opacity: 1;
    }
  }
}

.planner-listview-dropdown {
  position: absolute;
  left: 32px;
  bottom: -11px;

  .dropdown-menu {
    border: 1px solid rgba(102, 107, 133, 0.05);
    box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.7);
  }

  &.show {
    .planner-listview-add-btn {
      opacity: 1;
    }
  }
}

.planner-listview-posts {
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 1px 4px #ddd;
  transition: box-shadow 0.2s;
  padding: 6px 0;
  position: relative;
  min-height: 86px;

  &:hover {
    box-shadow: 0 1px 4px #bdbdbd;
  }

  &.loading {
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      linear-gradient($gray-100 100%, transparent 0);
    animation: shine 0.5s infinite;

    @keyframes shine {
      to {
        background-position: 150px 0;
      }
    }
  }

  .step-post {
    position: relative;
    align-items: center;
    margin: 0 15px;
    padding: 20px 0;
    color: #7383a4;
    border-top: 1px solid #dfe0e3;
    outline: none;
    cursor: pointer;
    display: flex;

    &:first-child {
      border-top-color: #fff;
    }

    .status-icon {
      margin-right: 10px;

      .icon {
        height: 1.3rem;
        width: 1.3rem;
      }
    }

    .s-accounts {
      color: #111;
      font-weight: 600;
      font-size: 16px;
      max-width: 150px;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .sa-photo {
        display: inline-block;
        height: 40px;
        width: 40px;
        margin-left: -10px;
        background-color: white;
        border-radius: 50%;

        &:first-of-type {
          margin-left: 0;
        }

        img {
          border: 1px solid $gray-200;
          padding: 2px;
          box-shadow: 0px 0px 10px rgba($gray-300, 0.54);
        }

        .more-account-photos {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          background-color: $gray-500;
          color: $white;
          border-radius: 50%;
          border: 2px solid $white;
        }
      }
    }
    .post-time {
      max-width: 110px;
    }
    .body {
      color: #4a556b;
      font-size: 15px;
    }

    .email-actions {
      opacity: 0;
    }

    &:hover {
      .email-actions {
        opacity: 1;
      }
    }
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
</style>
