<template>
  <div class="workspace_header fixed-top py-2" :class="{ colored_header: isSticky }">
    <b-row class="align-items-center m-0 p-0">
      <b-col col md="3">
        <div class="header__data-welcome d-flex align-items-center text-left pl-4">
          <router-link :to="{ name: 'workspaces' }" class="item-link text-dark">
            <div class="logo-box">
              <img src="@/assets/images/logo/logo-block-black-n.png" />
            </div>
          </router-link>
          <!--<h6 class="font-weight-bold mb-0 pb-0">
            <router-link :to="{ name: 'workspaces' }" class="item-link text-dark">
              Welcome
              <span>{{ user.firstName }}</span>
              <svg-icon name="wink" size="sm" class="ml-2" />
            </router-link>
          </h6>-->
        </div>
      </b-col>
      <b-col col class="">
        <div class="header__data-info">
          <b-row class="justify-content-between align-items-center">
            <b-col col md="">
              <b-button-group class="w-100">
                <b-button
                  class="btn-sm btn-left px-3 py-2 relative text-white"
                  style="background-color: #634cd4;"
                  variant="success"
                >
                  <a href="https://app.useagencyai.com/" target="_blank" class="item-link text-white" title="AI Tools">
                    AI Tools
                  </a>
                  <div class="barge">Premium Bonus</div>
                </b-button>
                <b-button class="btn-sm px-3" variant="danger">
                  <router-link class="item-link text-white" title="Client Finder" :to="{ name: 'client.finder' }" exact>
                    Client Finder
                  </router-link>
                </b-button>

                <b-button class="btn-sm px-3" variant="success">
                  <!--<a
                    href="https://doc.clickup.com/4555986/p/h/4b16j-26060/db8a468b152f6c1"
                    target="_blank"
                    class="item-link text-white"
                    title="DFY Assets"
                  >
                    Assets
                  </a> -->

                  <router-link class="item-link text-white " title="Agency" :to="{ name: 'dfy.assets' }" exact>
                    Agency
                  </router-link>
                </b-button>
                <!-- 
                <b-button class="btn-sm btn-right px-3 text-white" variant="success">
                  <router-link class="item-link text-white" title="Mentorship" :to="{ name: 'bonus.index' }" exact>
                    Mentorship
                  </router-link>
                </b-button>
              -->
              </b-button-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col col md="3">
        <div class="d-flex justify-content-end">
          <div class="user__data d-flex align-items-center">
            <div class="user__data-notification">
              <NotificationsMenu />
            </div>
            <div class="user">
              <UserMenu :user="user" :authBusy="authBusy" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import Stats from './Stats.vue';
import UserMenu from '../General/Menus/UserMenu.vue';
import { mapState } from 'vuex';
import NotificationsMenu from '../General/Menus/NotificationMenu.vue';

export default {
  components: { NotificationsMenu, UserMenu }, //Stats
  data() {
    return {
      isSticky: false,
      scrollPosition: 0,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
      authBusy: state => state.busy,
    }),

    isDashboard() {
      return (
        this.$route.name === 'workspaces' ||
        this.$route.name === 'bonus.index' ||
        this.$route.name === 'niches.index' ||
        this.$route.name === 'client.finder'
      );
    },
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    showGroupPage() {},
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.logo-box {
  height: 50px;
  & img {
    width: auto;
    height: 100%;
  }
}
.workspace_header {
  background: transparent;
  color: #000000;
  height: $navbar-height;
  &.colored_header {
    background-color: $light;
  }
  .stat {
    background: $gray;
    border-radius: 0 0 30px 30px;
    padding: 4px;
    height: $navbar-height;
    // margin-top: -8px;
  }
  .btn-left,
  .btn-right {
    font-size: 12px;
    font-weight: bold;
  }
  .btn-right {
    border-radius: 0 10px 10px 0 !important;
  }
  .btn-left {
    border-radius: 10px 0 0 10px !important;
  }
  .user__data {
    &-notification {
      // border-right: 2px solid #000000;
      margin: 0 10px;
      // padding: 0 25px 0 0;
      line-height: 50px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.item-link {
  color: #fff;
  &:hover {
    text-decoration: none;
  }
}

.relative {
  position: relative;
}
.barge {
  font-size: 10px;
  background: #ff0000;
  color: yellow;
  border-radius: 10px;
  display: inline-block;
  padding: 3px 10px;
  position: absolute;
  left: -5px;
  top: -5px;
  // transform: rotate(-25deg);
}
</style>
