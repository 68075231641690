<template>
  <div class="searchable-dropdown-wrap">
    <b-dropdown
      ref="dropdown"
      variant="clear"
      class="custom-dropdown"
      toggle-class="searchable-dropdown-input"
      menu-class="searchable-dropdown-menu"
      block
      no-caret
    >
      <template v-slot:button-content>
        <div class="selected-text">{{ selectedText }}</div>
        <svg-icon name="chevron-down" size="sm" />
      </template>
      <li role="presentation" class="search-input-wrap">
        <b-form-input v-model="searchInput" size="sm" placeholder="Search by name"></b-form-input>
      </li>
      <li role="presentation">
        <simplebar class="select-options-scroll">
          <ul class="select-options">
            <li v-for="option in filteredOptions" :key="option.value" role="presentation">
              <a
                role="menuitem"
                target="_self"
                href="#"
                class="dropdown-item"
                @click.prevent="selectItem(option.value)"
              >
                {{ option.text }}
              </a>
            </li>
          </ul>
        </simplebar>
      </li>
    </b-dropdown>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: { simplebar },

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchInput: '',
    };
  },

  computed: {
    selectedText() {
      const selected = this.options.find(o => o.value == this.value);

      return selected ? selected.text : '';
    },

    filteredOptions() {
      if (this.searchInput) {
        return this.options.filter(option => option.text.toLowerCase().includes(this.searchInput.toLowerCase()));
      } else {
        return this.options;
      }
    },
  },

  methods: {
    selectItem(value) {
      this.$emit('input', value);
      this.$refs.dropdown.hide(true);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.searchable-dropdown-wrap {
  position: relative;
  .searchable-dropdown-input {
    border-radius: 0.3rem;
    border: 1px solid $gray-200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;

    .selected-text {
      font-size: 1rem;
      font-weight: 500;
      min-height: 24px;
    }

    .icon {
      margin-top: 0;
      font-size: 12px;
    }
  }

  .select-options-scroll {
    max-height: 400px;
    overflow: auto;

    .simplebar-vertical {
      width: 7px;
      background: $gray-100;
      border-radius: 5px;
    }

    .simplebar-scrollbar {
      width: 5px;
      right: 1px;
    }

    .simplebar-scrollbar:before {
      border-radius: 5px;
      background: rgba($gray-500, 0.8);
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      /* When hovered, remove all transitions from drag handle */
      opacity: 1;
      transition: opacity 0s linear;
    }
  }

  .select-options {
    margin: 0.125rem 0 0;
    padding: 0;
    font-size: 1rem;
    color: #576f7f;
    text-align: left;
    list-style: none;

    li {
      a {
        padding: 7px 20px;
        font-size: 14px;
        line-height: 1.8;
      }

      &:first-child {
        > a {
          border-radius: 0;
        }
      }
    }
  }

  .searchable-dropdown-menu {
    margin-top: 5px;

    &:after,
    &:before {
      display: none;
    }
  }

  .search-input-wrap {
    padding: 10px 10px;
    border-bottom: 1px solid $gray-200;

    .form-control {
      border-radius: 20px;
      border: 1px solid $gray-200;
      padding: 5px 15px;

      &::placeholder {
        color: $gray-500;
      }
    }
  }
}
</style>
