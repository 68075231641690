<template>
  <div>
    <simplebar class="tabs-scroll">
      <DashboardQuotesSkeleton class="quote-items-wrap" v-if="queryLoading" />
      <b-row cols="2" class="quote-items-wrap" v-if="quotes && quotes.length > 0 && !queryLoading">
        <b-col cols="6" class="m-0 p-0 w-100" v-for="quote in quotes" :key="quote.id">
          <div class="quote-item">
            <div v-if="quote.body" class="quote-content-wrap">
              <div class="quote-content">{{ quote.body }}</div>

              <hr class="w-50 mr-0 my-2" />
              <div class="text-right small font-weight-bold">{{ quote.author }}</div>
            </div>
          </div>
        </b-col>
      </b-row>

      <div v-if="quotes && quotes.length < 1 && !queryLoading" class="no-result-wrap text-center">
        <img class="pt-3 mb-5" width="250" src="@/assets/images/not_found.svg" alt="" />
        <h5>Could not load quotes</h5>
        <b-button size="sm" class="mt-3 px-4" @click="retryQuotesQuery">
          <svg-icon name="reload" />
          Retry
        </b-button>
      </div>
    </simplebar>
    <div v-if="!queryLoading" class="tab-footer">
      <router-link :to="{ name: 'find.quotes' }">Discover more</router-link>
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { DASHBOARD_QUOTES_QUERY } from '~/graphql/queries';
import DashboardQuotesSkeleton from '~/components/DashboardQuotesSkeleton';

export default {
  components: { simplebar, DashboardQuotesSkeleton },

  data() {
    return {
      queryLoading: 0,
      quotes: [],
      // quotes: [
      //   {
      //     id: 1,
      //     image: require('@/assets/images/new/business.png'),
      //     author: 'Lorem Ipsum Odolor',
      //   },
      //   {
      //     id: 2,
      //     image: require('@/assets/images/new/business.png'),
      //     author: 'Lorem Ipsum Odolor',
      //   },
      //      {
      //     id: 3,
      //     image: require('@/assets/images/new/business.png'),
      //     author: 'Lorem Ipsum Odolor',
      //   },
      // ]
    };
  },

  apollo: {
    quotesQuery: {
      query: DASHBOARD_QUOTES_QUERY,
      manual: true,
      loadingKey: 'queryLoading',
      result({ data, loading }) {
        if (!loading) {
          this.quotes = data.quoteSearch.quotes;
        }
      },
    },
  },

  methods: {
    retryQuotesQuery() {
      this.$apollo.queries.quotesQuery.refetch();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.quotes-tab {
  .quote-items-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 1.25rem 1.8rem;
    .quote-item {
      // width: 226px;
      margin: 8px;
      border: 1px solid $primary;
      border-radius: 7px;
      background: $gray;
      transition: all 0.2s ease-in-out;
      .quote-content-wrap {
        padding: 20px;
        .quote-content {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          font-size: 0.9rem;
          word-break: break-word;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
