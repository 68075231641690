<template>
  <div>
    <div v-for="item in items" :key="item + 1" class="shadow-sm p-3 mb-3">
      <skeleton-screen width="100%" class="mb-1" height="10px" />
      <div class="d-flex justify-content-center mb-1">
        <skeleton-screen height="10px" width="60%" />
      </div>
      <div class="d-flex justify-content-start">
        <skeleton-screen height="10px" width="80%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editor-fabs-comments-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 4,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>
