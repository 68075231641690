<template>
  <b-dropdown ref="utmDropdown" class="utm_dropdown" toggle-class="px-0" size="sm" variant="clear" no-caret no-flip>
    <b-button slot="button-content" id="insertUtm" variant="clear" class="node-btns py-1 px-2">
      <svg-icon name="target" />
    </b-button>
    <b-tooltip target="insertUtm" triggers="hover">Add UTM</b-tooltip>
    <b-dropdown-form @submit.prevent="saveAndApplyUtmPreset" @keydown="utmForm.onKeydown($event)" class="py-0">
      <div class="utm-wrap py-3">
        <b-form-group label="Select from saved UTM presets" label-for="presets">
          <b-form-select id="presets" v-model="utmForm.id" @change="handleUtmPresetSelect">
            <option :value="0">Create New</option>
            <template v-if="utmPresets && utmPresets.length">
              <option v-for="utmPreset in utmPresets" :key="utmPreset.id" :value="utmPreset.id">{{
                utmPreset.name
              }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-if="isEditingUtmPreset">
          <b-form-group label="URL" label-for="utmurl">
            <b-form-input v-model="utmLink" id="utmurl" placeholder="Add Link" required></b-form-input>
          </b-form-group>

          <b-form-group label="Campaign Name" label-for="utmname">
            <b-form-input
              v-model="utmForm.name"
              id="utmname"
              placeholder="Product, promo code, or slogan (e.g. spring_sale"
              required
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label="Campaign Medium" label-for="utmmedium">
            <b-form-input
              v-model="utmForm.medium"
              id="utmmedium"
              placeholder="Marketing Medium (e.g. social)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Campaign Source" label-for="utmsource">
            <b-form-checkbox v-model="utmForm.autoAddSocialChannel">
              Automatically add social channel's name as a source
            </b-form-checkbox>
            <b-form-input
              v-model="utmForm.source"
              id="utmsource"
              placeholder="The referrer (e.g. facebook, twitter)"
              :required="!utmForm.autoAddSocialChannel"
              :disabled="utmForm.autoAddSocialChannel"
            ></b-form-input>
          </b-form-group>

          <div class="text-center">
            <b-button variant="primary" :disabled="utmForm.busy" type="submit">Save &amp; Apply</b-button>
          </div>
        </template>
        <div v-else>
          <div class="border rounded">
            <div class="p-3">
              <label>URL</label>
              <p class="small">{{ utmLink }}</p>

              <label>Campaign Name</label>
              <p class="small">{{ selectedUtm.name }}</p>

              <label>Campaign Medium</label>
              <p class="small">{{ selectedUtm.medium }}</p>

              <label>Campaign Source</label>
              <p class="small mb-0">{{ selectedUtm.source }}</p>
            </div>
            <div class="text-right">
              <b-button @click="editSelectedUtm" class="border" variant="light" size="sm">Edit</b-button>
            </div>
          </div>

          <div class="text-center mt-3">
            <b-button variant="primary" @click="applySelectedUtm">Apply</b-button>
          </div>
        </div>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';

import { UTM_PRESETS_QUERY } from '~/graphql/queries';
import { SAVE_UTM_PRESET_MUTATION } from '~/graphql/mutations';

export default {
  data() {
    return {
      utmPresets: [],
      isEditingUtmPreset: true,
      utmLink: '',
      utmForm: new Form({
        id: 0,
        name: '',
        medium: '',
        source: '',
        autoAddSocialChannel: false,
      }),
    };
  },

  apollo: {
    utmPresets: {
      query: UTM_PRESETS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    selectedUtm() {
      const defaultUtm = {
        id: 0,
        autoAddSocialChannel: false,
      };

      if (!this.utmPresets.length) {
        return defaultUtm;
      }
      const selectedUtm = this.utmPresets.find(utm => utm.id === this.utmForm.id);
      return selectedUtm ? selectedUtm : defaultUtm;
    },
  },

  methods: {
    applySelectedUtm() {
      this.insertUtmIntoEditor();
    },

    async saveAndApplyUtmPreset() {
      this.utmForm.busy = true;

      const { data } = await this.$apollo.mutate({
        mutation: SAVE_UTM_PRESET_MUTATION,
        variables: {
          workspace: this.workspace.id,
          id: this.utmForm.id,
          name: this.utmForm.name,
          medium: this.utmForm.medium,
          source: this.utmForm.source,
          autoAddSocialChannel: this.utmForm.autoAddSocialChannel,
        },
      });

      this.utmForm.busy = false;

      this.isEditingUtmPreset = false;

      this.utmForm.id = data.saved.id;

      this.insertUtmIntoEditor();
    },

    insertUtmIntoEditor() {
      const params = {
        utm_medium: this.utmForm.medium,
        utm_campaign: this.utmForm.name,
        utm_source: this.utmForm.source,
      };

      const esc = encodeURIComponent;
      const query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

      const value = ` <a href="${this.utmLink}?${query}">
        ${this.utmLink}?${query}
      </a>`;

      this.$emit('insert', value);

      this.$refs.utmDropdown.hide();

      this.$apollo.queries.utmPresets.refetch();
    },

    editSelectedUtm() {
      this.isEditingUtmPreset = true;
    },

    handleUtmPresetSelect(id) {
      this.utmForm.keys().forEach(key => {
        this.utmForm[key] = this.selectedUtm[key] !== undefined ? this.selectedUtm[key] : '';
      });

      if (id === 0) {
        this.isEditingUtmPreset = true;
      } else {
        this.isEditingUtmPreset = false;
      }
    },
  },
};
</script>
