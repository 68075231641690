<template>
  <div class="say-as-wrap">
    <div ref="content" :id="`popover-${uuid}`" class="say-as"></div>
    <b-tooltip :target="`popover-${uuid}`" variant="primary" triggers="hover" placement="bottom">
      Say as: {{ selectedInterpret.label }}
    </b-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';

let uuid = 0;

export default {
  props: ['node', 'updateAttrs', 'view', 'options', 'getPos', 'selected'],

  computed: {
    ...mapState({
      interpretations: state => state.voiceEditor.sayAsInterpretations,
    }),

    selectedInterpret() {
      const name = this.node.attrs['interpret-as'];

      return this.interpretations.find(o => o.name === name);
    },
  },

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.say-as-wrap {
  display: inline-block;
}

.say-as {
  display: inline-block;
  border-bottom: 1px dashed $info;
}
</style>
