<template>
  <div class="position-relative">
    <img class="rounded-circle" :src="account.imageUrl" :width="width" :height="height" alt="" />
    <img
      v-if="account.type.platform == 'wordpress'"
      class="platform-indicator-icon"
      src="@/assets/images/wordpress-icon-small.png"
    />
    <img
      v-else-if="account.type.platform == 'medium'"
      class="platform-indicator-icon"
      src="@/assets/images/medium-block-icon-small.png"
    />
    <img
      v-else-if="account.type.platform == 'podbean'"
      class="platform-indicator-icon"
      src="@/assets/images/podbean-symbol-black-50.png"
    />
    <svg-icon v-else-if="account.type.platform == 'tumblr'" class="platform-indicator-icon tumblr-icon" name="tumblr" />
    <svg-icon v-else class="platform-indicator-icon" :name="`${account.type.platform}-colored`" />
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 'auto',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.platform-indicator-icon {
  &.tumblr-icon {
    width: 1.1rem !important;
    height: 1.1rem !important;
    fill: $primary;
  }
}
</style>
