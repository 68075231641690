<template>
  <div class="panel-scroll">
    <div class="panel-body activities">
      <div class="activities" v-if="hasActivities">
        <Activity v-for="activity in orderedActivities" :key="activity.id" :activity="activity" />
      </div>
      <div v-else-if="isLoadingActivities" class="text-center loader">
        <EditorFabActivitiesSkeleton />
      </div>
    </div>
  </div>
</template>

<script>
import { isArray, orderBy } from 'lodash';
import { POST_ACTIVITIES_WITH_ANALYTICS_QUERY } from '~/graphql/queries';
import EditorFabActivitiesSkeleton from './EditorFabActivitiesSkeleton';
import Activity from './partials/Activity';

export default {
  name: 'editor-fabs-activities-panel',

  props: {
    postId: {
      type: Number || String,
      required: true,
    },
  },

  components: {
    EditorFabActivitiesSkeleton,
    Activity,
  },

  data() {
    return {
      activities: [],
      hasMore: true,
      apiRoot: process.env.VUE_APP_API_ROOT,
      totalActivity: 0,
    };
  },

  apollo: {
    activities: {
      query: POST_ACTIVITIES_WITH_ANALYTICS_QUERY,
      variables() {
        return {
          postId: this.postId,
        };
      },
      update({ postActivitiesWithAnalytics: { postActivities, analytics } }) {
        this.hasMore = !postActivities.length;
        this.totalActivity = analytics.total;
        return postActivities;
      },
    },
  },

  computed: {
    isLoadingActivities() {
      return this.$apollo.queries.activities.loading;
    },
    hasActivities() {
      return !this.isLoadingActivities && isArray(this.activities) && this.activities.length > 0;
    },
    orderedActivities() {
      return orderBy(this.activities, ['date'], ['asc']);
    },
  },

  created() {
    this.$apollo.queries.activities.refetch({
      postId: this.postId,
    });

    window.Echo.private(`post.${this.postId}.activity`).listen('made_new_activity', activityData => {
      console.log('i made new activity', activityData);
      this.activities.push(activityData);
    });
  },

  beforeDestroy() {
    window.Echo.private(`post.${this.postId}.activity`).stopListening('made_new_activity');
  },

  watch: {
    totalActivity: {
      handler(val) {
        this.$emit('update-activities-count', val);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-body.activities {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  > * {
    display: flex;
    width: 100%;
  }

  > .activities {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
  }

  > .loader {
    width: 100%;
    margin: 20px 0 0 0;
    display: block;
  }
}
</style>
