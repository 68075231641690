<template>
  <div class="PublishAccountHeads justify-content-center">
    <span class="sa-photo" v-for="publishAccount in limtedAccounts" :key="publishAccount.id">
      <img
        v-if="!publishAccount.indicator"
        :src="publishAccount?.imageUrl"
        width="40"
        height="40"
        alt="Account photo"
        class="rounded-circle"
        style="max-width: 100%; width: 40px; height: 40px;"
      />
      <span class="more-account-photos" v-else-if="publishAccount.indicator">{{ publishAccount.more }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },

  computed: {
    limtedAccounts() {
      if (this.accounts.length > 2) {
        // Cut out 3 and add more indicator
        const slicedOut = this.accounts.slice(0, 2);
        return [
          ...slicedOut,
          {
            id: 'more',
            indicator: true,
            more: `+${this.accounts.length - slicedOut.length}`,
          },
        ];
      }

      return this.accounts;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.PublishAccountHeads {
  color: #111;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  .sa-photo {
    display: inline-block;
    height: 40px;
    width: 40px;
    margin-left: -10px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;

    &:first-of-type {
      margin-left: 0;
    }

    img {
      border: 1px solid $gray-200;
      padding: 2px;
      box-shadow: 0px 0px 10px rgba($gray-300, 0.54);
    }

    .more-account-photos {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background-color: #52b788;
      color: #d8f3dc;
      border-radius: 50%;
    }
  }
}
</style>
