<template>
  <div class="schedule__report">
    <h5 class="p-2">My Reports</h5>
    <div v-if="data">
      <b-row cols="4" class="d-flex flex-wrap">
        <b-col v-for="(report, i) in reports" :key="i">
          <div class="schedule__report-container py-3 my-2 text-center">
            <div class="schedule__header">
              <svg-icon name="pie-chart" size="lg" />
            </div>
            <p>{{ report.title }}</p>
            <div class="account-item" v-for="(account, i) in report.accounts" :key="account + i">
              <b-avatar class="avatar" size="2.2rem" />
              <div :class="['account-icon', account.type]">
                <svg-icon class="icon" :name="account.type" v-if="account.type"></svg-icon>
              </div>
            </div>
            <div class="m-2">
              <p class="">Created: {{ report.created_at | dateTime }}</p>
              <div>
                <b-badge variant="secondary" class="mx-2 p-2 text-capitalize">
                  {{ report.interval }}
                </b-badge>
              </div>
              <div class="text-right action_btn mt-2">
                <b-button
                  variant="clear"
                  class="px-2 py-1 mx-1 border"
                  v-b-tooltip.hover
                  title="Edit Schedule"
                  @click="handleShowScheduleModal(report, 'update')"
                >
                  <svg-icon name="pencil" />
                </b-button>
                <b-button
                  variant="clear"
                  class="px-2 py-1 border"
                  v-b-tooltip.hover
                  title="Delete Schedule"
                  @click="handleShowScheduleModal(report, 'delete')"
                >
                  <svg-icon name="trash" />
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else class="no-result-wrap text-center">
      <img class="pt-3 mb-5" width="250" src="@/assets/images/blank_canvas.svg" alt="" />
      <h5 class="">You have not exported any report yet.</h5>
    </div>

    <!-- Schedule Modal -->
    <ScheduleOptions
      v-if="showScheduleModal && scheduleAction"
      :schedule="activeSchedule"
      :action="scheduleAction"
      :show="showScheduleModal"
      @close-schedule-modal="handleHideScheduleModal"
    />
    <!-- Schedule Modal -->
  </div>
</template>

<script>
import ScheduleOptions from '~/components/Reports/Schedule.vue';
import moment from 'moment';

export default {
  name: 'ScheduleReport',
  components: {
    ScheduleOptions,
  },
  data() {
    return {
      activeSchedule: null,
      scheduleAction: null,
      showScheduleModal: false,
      data: true,
      reports: [
        {
          id: 1,
          name: 'lorem',
          interval: 'weekly',
          type: 'group',
          created_at: '2021-08-14T15:38:35+00:00',
          accounts: [
            {
              id: 1,
              type: 'facebook',
              avatar: '',
              name: 'facebook',
              user: {
                name: 'Jeremiah Iro',
              },
            },
          ],
        },
        {
          id: 2,
          name: 'lorem',
          interval: 'monthly',
          type: 'linkedin',
          created_at: '2021-08-14T15:38:35+00:00',
          accounts: [
            {
              id: 1,
              type: 'linkedin',
              avatar: '',
              name: 'linkedin',
              user: {
                name: 'Jeremiah Iro',
              },
            },
          ],
        },
      ],
    };
  },

  filters: {
    dateTime(value) {
      if (!value) return '';

      return moment(value).format('ddd MMMM DD, YYYY');
    },
  },

  methods: {
    handleShowScheduleModal(schedule, action) {
      this.activeSchedule = schedule;
      this.scheduleAction = action;
      this.showScheduleModal = true;
    },
    handleHideScheduleModal() {
      this.activeSchedule = null;
      this.scheduleAction = null;
      this.showScheduleModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
.schedule__report {
  width: 100%;
  margin-bottom: 20px;
  &-container {
    background: $white;
    border-radius: 10px;
    text-align: center;
    .schedule__header {
      margin: 0 auto;
      width: 75px;
      height: 75px;
      line-height: 75px;
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      color: $primary;
      background: $gray;
      svg {
        fill: $primary;
      }
    }
    .action_btn {
      opacity: 0;
    }
    &:hover {
      .action_btn {
        opacity: 1;
      }
    }
  }
  .account-item {
    position: relative;
    flex-wrap: wrap;
    margin: 2px;
    display: inline-flex;
    .account-icon {
      display: inline-flex;
      position: absolute;
      bottom: -6px;
      right: -6px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      padding: 0 0 4px 2px;
      > .icon {
        width: 14px;
        height: 14px;
        margin: 4px 0 0 0;
        color: $white;
      }
      &.facebook {
        background-color: #3b5998;

        > .icon {
          width: 13px;
          height: 13px;
          margin: 3px 0 0 0;
        }
      }

      &.twitter {
        background-color: #3d82b6;
      }
      &.linkedin {
        background-color: #46e1c4;
      }
      &.wordpress {
        background-color: #ae7c31;
      }

      &.medium {
        background-color: #9e3192;
      }
      > .icon {
        display: inline-flex;
        transform: scale(0.8);
      }
    }
  }
}
</style>
