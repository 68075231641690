<template>
  <div class="fabs video-fabs">
    <div class="images button-wrap" :class="{ open: activePanel == 'articles' }">
      <button @click="openPanel('articles')" id="videoBtn" class="link-button fab-button">
        <svg-icon class="button-icon" name="popular" />
        Articles
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="videoBtn" target="videoBtn" placement="right">
        Articles
      </b-tooltip>
      <div v-show="activePanel == 'articles'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Articles</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabArticlesPanel @dragged="handleArticleDrag" />
      </div>
    </div>

    <div class="articles button-wrap" :class="{ open: activePanel == 'quotes' }">
      <button @click="openPanel('quotes')" id="quoteBtn" class="articles-button fab-button">
        <svg-icon class="button-icon" name="quote-alt" />
        Quotes
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="quoteBtn" target="quoteBtn" placement="right"
        >Quotes</b-tooltip
      >
      <div v-show="activePanel == 'quotes'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Quotes</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabQuotesPanel @dragged="handleQuoteDrag" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditorFabQuotesPanel from './EditorFabQuotesPanel';
import EditorFabArticlesPanel from './EditorFabArticlesPanel';

export default {
  name: 'voice-editor-fabs',

  components: {
    EditorFabQuotesPanel,
    EditorFabArticlesPanel,
  },

  computed: {
    ...mapState('postEditor', {
      activePanel: state => state.openFabPanel,
    }),
  },

  methods: {
    openPanel(panel) {
      this.$store.dispatch('postEditor/updateOpenFabPanel', panel);
    },

    closeAllPanels() {
      this.$store.dispatch('postEditor/updateOpenFabPanel', null);
    },

    hidePanel(event) {
      const isClickOnFabButton = event.target.closest('.fab-button');
      const isClickOnPanel = event.target.closest('.panel-board');
      const isClickOnFabModal = event.target.closest('.persist-panel');

      if (!isClickOnFabButton && !isClickOnPanel && !isClickOnFabModal) {
        this.closeAllPanels();
      }
    },

    handleQuoteDrag(quote) {
      this.$emit('draggedQuote', quote);

      this.closeAllPanels();
    },

    handleArticleDrag(article) {
      this.$emit('draggedArticle', article);

      this.closeAllPanels();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.fabs {
  &.video-fabs {
    background: $primary;
    left: 0;
    top: 160px;
    border-radius: 0 25px 25px 0;
    padding: 20px 0;

    .button-wrap {
      padding: 6px 20px;

      .panel-board {
        position: absolute;
        z-index: 10;
        background: $primary;
        min-height: 100px;
        box-sizing: content-box;
        width: 358px;
        left: 99px;
        top: 0;
        border-radius: 6px;
        box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
        border-radius: 25px;

        .panel-header {
          padding: 16px;
          &_title {
            padding: 6px 12px;
            text-transform: uppercase;
            background: #2d6a4f;
            color: #d8f3dc;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
          }
          &_button {
            svg {
              fill: $secondary;
            }
          }
        }

        .panel-scroll {
          max-height: 445px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .panel-body {
          color: #d8f3dc;
          padding: 10px 20px;
        }
        .panel-body-top {
          padding: 8px;
          text-align: left;
          color: $white;
          border: 1px solid #b7e4c7;
          border-radius: 10px;
          .input-group {
            color: $primary !important;
            border-radius: 0.3rem !important;
            background-color: #ffffff !important;
            input {
              font-size: 0.9rem;
              height: 40px;
              width: 85%;
              &::placeholder {
                color: $gray-500;
              }
            }
          }
          .input-group-text {
            svg {
              fill: #b7e4c7;
            }
          }
          input,
          .input-group-prepend,
          .input-group-text {
            background-color: transparent;
            border: none;
            outline: none;
            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
              border: none;
              outline: none;
            }
          }
        }
      }
      &:hover {
        background: #2d6a4f;
        .fab-button {
          color: $white;
          fill: $white;
        }
      }

      .fab-button {
        font-size: 12px;
        .tooltip {
          margin-left: 10px;

          .tooltip-inner {
            border-radius: $rounded-pill;
            padding: 6px 15px;

            &:empty {
              display: none;
            }
          }

          .arrow {
            display: none;
          }
        }
      }

      &.open {
        background: #2d6a4f;
        .fab-button {
          color: $white;
          .button-icon {
            fill: $white;
          }

          &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 68px;
            right: -29px;
            top: -6px;
            z-index: 13;
            background: $success;
            border-radius: 6px 0 0 6px;
          }
        }
      }
    }
  }
}
</style>
