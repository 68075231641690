<template>
  <div class="alert-container">
    <b-alert :show="showAlert" dismissible fade variant="light" class="border border-success content">
      <h4 class="alert-heading">BlockBuster Training Webinar!</h4>
      <p>
        The Unconventional 3-Step System We Use To Average $10,513+ Every 24 Hours With An Automated ‘eServices’ Store
      </p>
      <p>
        Discover How To Instantly Tap Into This Exploding New Mega-Trend With ZERO Amazon, Shopify, Physical Inventory
        Or Paid Advertising
      </p>
      <hr />
      <div class="text-center">
      <a href="https://usemarshal.co/go/asigowebby" target="_blank" class="mb-0 btn btn-primary"
        ><small>Register for the Session Here 🔥</small></a
      >
    </div>
    </b-alert>
  </div>
  <!-- <b-row class="banner__container py-4 px-2 align-items-center">
    <b-col cols="8" class="banner__container-heading">
      <h4>
        [SocialAgency360 Training] Unleash AI Employees & Transform Your Business! (77 AI Staff for any Tasks)
      </h4>
    </b-col>
    https://attendee.gotowebinar.com/register/2005556919384645209
    <b-col cols="4" class="banner__container-action">
      <a href="https://usemarshal.co/aistaff-replay" target="_blank"><small>Watch Replay!</small></a>
      <p class="mt-3">Watch the Webinar Replay</p>
    </b-col>
  </b-row> -->
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
    };
  },

  mounted() {
    this.checkAndShowAlert();
  },

  methods: {
    checkAndShowAlert() {
      let then = new Date(1689903977732);
      let new_date = new Date(then.getTime() + 4 * 24 * 60 * 60 * 1000);
      let now = Date.now();

      if (now < new_date) {
        setTimeout(() => {
          this.showAlert = true;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.alert-container {
  position: fixed;
  width: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
  .content {
    box-shadow: 0 0 5px 1px #42c589;
    color: #000;
  }
}
.banner__container {
  background-image: url('~@/assets/images/banner-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 6px;
  &-heading {
    h4 {
      font-weight: bold;
      font-size: 19px;
    }
    p {
      background-image: url('~@/assets/images/banner-badge.png');
      background-repeat: no-repeat;
      color: #000;
      min-width: 40px;
      font-weight: bold;
      font-size: 12px;
      padding: 10px 10px 10px 14px;
      float: right;
      margin-right: 20px;
    }
  }
  &-action {
    text-align: center;
    a {
      background-color: #ee1818;
      color: #fff;
      border-radius: 6px;
      padding: 10px 16px;
      font-weight: bold;
      font-size: 1rem;
      &:hover {
        background-color: rgb(194, 23, 23) 818;
        color: #fff;
        text-decoration: none;
        box-shadow: 20px #ee1818;
      }
    }
    p {
      font-size: 8px;
    }
  }
}
</style>
