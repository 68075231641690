<template>
  <div class="leads__settings">
    <b-row no-gutter>
      <SideBar @sort-list="sortLeadsList()" @create-list="handleShowCreateListModal()" />
      <b-col md="10" class="p-4">
        <div class="d-flex align-center f-12">
          <div>
            <!-- <b-button
              variant="secondary"
              class="pl-2 pr-4 d-flex"
              @click="allSelected ? clearSelected : selectAllRows"
            >
              <div class="row-select mr-2" :class="{ active: allSelected }">
                <span aria-hidden="true" v-if="allSelected">&check;</span>
                <span aria-hidden="true" v-else>&nbsp;</span>
              </div>
              All
            </b-button> -->

            <div v-if="allSelected">
              <b-button variant="secondary" class="pl-2 pr-4 d-flex" @click="clearSelected">
                <div class="row-select active mr-2">
                  <span aria-hidden="true" class="">&check;</span>
                </div>
                All
              </b-button>
            </div>
            <div v-else>
              <b-button variant="secondary" class="pl-2 pr-4 d-flex" @click="selectAllRows">
                <div class="row-select mr-2">
                  <span aria-hidden="true" class="">&nbsp;</span>
                </div>
                All
              </b-button>
            </div>
          </div>
          <!-- <div class="mx-4 mt-1">
            <p class="text-muted mt-2">Showing 1 to 15</p>
          </div> -->
          <div class="ml-2">
            <b-button class="p-2 rounded-full" variant="primary" @click="showLeadsForm = !showLeadsForm">
              <svg-icon name="add-circle" size="md" />
            </b-button>
          </div>
        </div>
        <div class="pt-2 leads-table">
          <b-table
            v-if="leads.length"
            :busy="isLeadsLoading"
            :items="leads"
            :fields="fields"
            borderless
            selectable
            hover
            responsive="sm"
            ref="selectableLeadsTable"
            select-mode="multi"
            @row-selected="onRowSelected"
          >
            <template #head()="scope">
              <span class="">{{ scope.label.toUpperCase() }}</span>
            </template>
            <template #cell(select)="{ rowSelected }">
              <template v-if="rowSelected">
                <div class="row-select active">
                  <span aria-hidden="true" class="">&check;</span>
                </div>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <div class="row-select">
                  <span aria-hidden="true" class="">&nbsp;</span>
                </div>
                <span class="sr-only">Not selected</span>
              </template>
            </template>

            <!-- A custom formatted column -->
            <template #cell(image)="data">
              <b-avatar :src="data.image" size="2rem" />
            </template>
            <!-- {{ quote.body | truncate(100, '...') }} -->
            <template #cell(name)="data">
              <div class="text-truncate">{{ getFullName(data) | truncate(20, '...') }}</div>
            </template>
          </b-table>
          <div v-else class="no-result-wrap text-center">
            <img class="pt-3 mb-5" width="250" src="@/assets/images/blank_canvas.svg" alt="" />
            <h5 class="">You have not created any leads yet.</h5>
            <b-button class="py-2 px-4 rounded" variant="outline-primary" @click="showLeadsForm = !showLeadsForm">
              <svg-icon name="add-circle" size="md" /> Create Lead
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Modals -->

    <!-- Create New List -->
    <b-modal v-model="showCreateListModal" hide-header hide-footer content-class="p-3" id="createListModal" centered>
      <div class="external__modal-button">
        <b-button @click="closeCreateListModal()" class="text-white" variant="clear">
          <svg-icon name="close-alt" size="md" />
        </b-button>
      </div>
      <div class="text-center">
        <h4>Create New List</h4>
      </div>
      <b-form-group v-if="showListForm">
        <label for="leads_list-field" class="f-14">List Name</label>
        <b-form-input
          id="leads_list-field"
          class="auth-input"
          v-model="listForm.name"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>
      <div class="text-center m-2">
        <b-button type="submit" @click="onResetLeadsForm" variant="outline-primary" class="mx-2">
          <svg-icon name="close-alt" size="sm" class="mr-2" />
          Cancel
        </b-button>
        <b-button type="submit" :disabled="savingLeadsList" @click="saveLeadsList" variant="primary" class="mx-2">
          <b-spinner class="ml-2" v-if="savingLeadsList" small />
          <svg-icon v-else name="check" size="sm" class="mr-2" />
          Save
        </b-button>
      </div>
    </b-modal>

    <!-- Create New Lead -->
    <b-modal
      v-model="showLeadsForm"
      hide-header
      hide-footer
      content-class="p-3"
      id="createLeadModal"
      centered
      size="lg"
    >
      <CreateLead />
    </b-modal>
    <!-- Modals -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SideBar from '~/components/Lead/SideBar.vue';
import CreateLead from '~/components/Lead/CreateLead.vue';
import { SAVE_FOLDER_MUTATION } from '~/graphql/mutations';
import { FETCH_FOLDER_QUERY, FETCH_LEAD_QUERY } from '~/graphql/queries';
import { isArray, forEach } from 'lodash';
import Swal from 'sweetalert2';

export default {
  name: 'edit-proile-modal',
  components: { SideBar, CreateLead },

  data() {
    return {
      listForm: {
        name: '',
      },
      showLeadsForm: false,
      showCreateListModal: false,
      showListForm: true,
      savingLeadsList: false,
      showAddLeadModal: false,
      isLeadsLoading: false,
      fields: ['select', 'image', { key: 'name', label: 'Full Name' }, 'email', 'domain', 'title', 'company', 'phone'],
      leads: [],
      hasMore: false,
      selectedLeads: [],
      allSelected: false,
    };
  },

  apollo: {
    leads: {
      query: FETCH_LEAD_QUERY,
      variables() {
        return {
          folder_id: null,
        };
      },
      update({ fetchLead }) {
        this.hasMore = !fetchLead.length;
        return fetchLead.map(lead => ({
          image: '',
          name: {
            first: lead.firstName,
            last: lead.lastName,
          },
          email: lead.email,
          title: lead.title || '',
          phone: lead.phoneNumber,
          domain: lead.domain,
          company: lead.company,
        }));
      },
    },
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  methods: {
    async saveLeadsList(e) {
      e.preventDefault();

      this.savingLeadsList = true;

      try {
        await this.$apollo.mutate({
          mutation: SAVE_FOLDER_MUTATION,
          variables: {
            name: this.listForm.name,
            purpose: 'lead',
          },
          refetchQueries: [
            {
              query: FETCH_FOLDER_QUERY,
              variables: {
                purpose: 'lead',
              },
            },
          ],
        });

        this.showCreateListModal = false;
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'LeadFolderSaveError') {
              errorText = 'A server error occurred saving list';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while saving list',
        });
      }

      this.savingLeadsList = false;
    },
    onResetLeadsForm(event) {
      event.preventDefault();
      // Reset our form values
      this.listForm.name = '';
      // Trick to reset/clear native browser form validation state
      this.showListForm = false;
      this.$nextTick(() => {
        this.showListForm = true;
      });
      this.$bvModal.hide('createListModal');
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    closeCreateListModal() {
      this.$bvModal.hide('createListModal');
    },
    handleShowCreateListModal() {
      this.showCreateListModal = !this.showCreateListModal;
    },
    sortLeadsList(e) {
      // TODO: update this
      console.log(e);
    },
    getFullName(data) {
      return data.value.last + ' ' + data.value.first;
    },
    isChecked(rowSelected) {
      return rowSelected ? true : false;
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.allSelected = true;
      this.$refs.selectableLeadsTable.selectAllRows();
    },
    clearSelected() {
      this.allSelected = false;
      this.$refs.selectableLeadsTable.clearSelected();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.leads__settings {
  padding: 2px 18px;
  .row-select {
    border: 2px solid $primary;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    padding: 0 0px 6px 6px;
    &.active {
      color: $secondary;
      background: $primary;
    }
  }
  .leads-table {
    tbody {
      background: $white;
    }
  }
}
</style>
