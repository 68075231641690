<template>
  <div
    class="planner-day-header"
    :class="{
      today: day.currentDay,
      'planner-light-forecolor': !day.inCalendar,
    }"
  >
    <div class="planner-day-header-label">{{ day.dayOfMonth }}</div>

    <template v-if="isUserAdminInWorkspace() || isUserCollaboratorInWorkspace()">
      <b-dropdown
        :id="`day${day.dayIdentifier}`"
        :variant="disabled ? 'light border' : 'secondary'"
        class="planner-compose-dropdown"
        toggle-class="planner-day-header-btn"
        menu-class="planner-dropdown-menu"
        offset="9"
        no-caret
        no-flip
        size="sm"
        right
        :disabled="disabled"
      >
        <template slot="button-content">
          <svg-icon name="plus" />
        </template>
        <b-dropdown-item @click="createSocialPost">
          <svg-icon name="network" class="mr-1" />
          Social post
        </b-dropdown-item>
        <b-dropdown-item @click="createBlogPost">
          <svg-icon name="wordpress" class="mr-1" />
          Blog post
        </b-dropdown-item>
      </b-dropdown>
      <b-tooltip v-if="disabled" :target="`day${day.dayIdentifier}`">Can't add post. This date has passed.</b-tooltip>
    </template>
  </div>
</template>

<script>
import { Day } from 'dayspan';
import { createNamespacedHelpers } from 'vuex';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  props: {
    day: {
      type: Day,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    canAddFrom: {
      type: Number,
    },
  },

  computed: {
    disabled() {
      return this.day.toDate().getTime() < this.canAddFrom;
    },
    ...accessControlNamespace.mapGetters(['isUserCollaboratorInWorkspace', 'isUserAdminInWorkspace']),
  },

  methods: {
    createSocialPost() {
      this.$store.dispatch('createPost/updatePostStatus', 'scheduled');
      this.$store.dispatch('createPost/updateScheduleDate', this.day.format('YYYY-MM-DDTHH:mm:ss'));
      this.$store.dispatch('createPost/updateScheduleTimezone', 'UTC');

      this.$router.push({ name: 'compose', params: { type: 'social', id: 0 } });

      this.$notify({
        group: 'main',
        type: 'native',
        duration: 5000,
        title: 'Schedule date set',
        text: `Sechedule date set to <strong>${this.day.format('Do of MMMM YYYY')}</strong>`,
      });
    },
    createBlogPost() {
      this.$store.dispatch('createPost/updatePostStatus', 'scheduled');
      this.$store.dispatch('createPost/updateScheduleDate', this.day.format('YYYY-MM-DDTHH:mm:ss'));
      this.$store.dispatch('createPost/updateScheduleTimezone', 'UTC');

      this.$router.push({ name: 'compose', params: { type: 'blog', id: 0 } });

      this.$notify({
        group: 'main',
        type: 'native',
        duration: 5000,
        title: 'Schedule date set',
        text: `Sechedule date set to <strong>${this.day.format('Do of MMMM YYYY')}</strong>`,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.planner-day-header {
  padding: 7px 10px;
  background-color: #fff;
  border-bottom: 1px solid $gray-100;
  display: flex;
  justify-content: space-between;
  user-select: none;

  &.today {
    color: $secondary;
    font-weight: bold;
  }

  .planner-day-header-btn {
    opacity: 0;
    border-radius: 3px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0.1s;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      margin-top: 0;
    }
  }

  .planner-compose-dropdown {
    .dropdown-menu {
      border: 1px solid rgba(102, 107, 133, 0.05);
      box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.7);

      &:after,
      &:before {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
      }

      &:before {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }

      &:after {
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
      }

      &[x-placement='bottom-end'] {
        margin-top: 17px;

        &:before {
          top: -10px;
          right: 10px;
          left: initial;
          border-bottom: 10px solid #ebebeb;
        }

        &:after {
          top: -9px;
          right: 11px;
          left: initial;
          border-bottom: 9px solid #fff;
        }
      }
      &[x-placement='top-end'] {
        margin-top: -7px;

        &:before {
          bottom: -10px;
          top: initial;
          right: 10px;
          left: initial;
          border-top: 10px solid #ebebeb;
          border-bottom: transparent;
        }

        &:after {
          bottom: -9px;
          top: initial;
          right: 11px;
          left: initial;
          border-top: 9px solid #fff;
          border-bottom: transparent;
        }
      }
    }

    &.show {
      .planner-day-header-btn {
        opacity: 1;
      }
    }
  }
}
</style>
