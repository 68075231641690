<template>
  <div class="flex justify-center transition-all-0.3 items-center pt-5 gap-2">
    <Loader />
    <div>
      <TransitionGroup name="list" as="ul" class="hollow-list transition-all-0.3">
        <li v-for="(i, idx) in info" :key="idx">{{ i }}</li>
      </TransitionGroup>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader';
export default {
  name: 'AutomationLoading',
  components: { Loader },
  props: {
    loadingTexts: Array,
    default: () => [],
  },
  data: () => ({
    loading: true,
    interval: null,
    info: [],
  }),
  mounted() {
    if (!this.loadingTexts.length) return;

    this.info = [this.loadingTexts[0]];

    const vm = this;
    let key = 0;

    this.interval = setInterval(() => {
      if (key < vm.loadingTexts.length) {
        key = key + 1;
        if (vm.loadingTexts[key]) {
          vm.info.push(vm.loadingTexts[key]);
        }
      } else {
        key = 0;
        vm.info = [vm.loadingTexts[0]];
      }
    }, 1000);
  },
  beforeDestroy() {
    this.interval = null;
  },
};
</script>
<style lang="scss">
@import '~@/scss/variables';

.list-enter-active,
.list-leave-active {
  transition: opacity 1s;
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
}

.hollow-list {
  list-style: none; /* Remove default list style */
  padding: 0;
}

.hollow-list li {
  position: relative;
  padding-left: 20px; /* Space for the custom marker */
  margin-bottom: 5px;
  font-weight: 500;
}

.transition-all-0\.3,
.transition-all-0\.3 * {
  transition: all 0.3s;
}

.hollow-list li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border: 3px solid $primary; /* Hollow circle */
  border-radius: 50%;
}
</style>
