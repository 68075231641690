<template>
  <div class="brand-settings-page-wrap">
    <b-row no-gutters>
      <BrandSettingsSidebar />

      <b-col md="10">
        <div class="p-4">
          <router-view></router-view>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BrandSettingsSidebar from '~/components/Settings/BrandSettingsSidebar';

export default {
  components: {
    BrandSettingsSidebar,
  },
};
</script>
