import { Mark } from 'tiptap';
import { removeMark, updateMark } from 'tiptap-commands';

import ViewComponent from './View.vue';

export default class SayAsMark extends Mark {
  get name() {
    return 'sayas';
  }

  get schema() {
    return {
      attrs: {
        'interpret-as': {
          default: null,
        },
        format: {
          default: null,
        },
      },
      inclusive: false,
      excludes: '_',
      parseDOM: [
        {
          tag: 'say-as',
          getAttrs: dom => ({
            'interpret-as': dom.getAttribute('interpret-as'),
            format: dom.getAttribute('format'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'say-as',
          {
            'interpret-as': node.attrs['interpret-as'],
            format: node.attrs['format'],
          },
          0,
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type }) {
    return attrs => {
      if (attrs['interpret-as'] || attrs.format) {
        return updateMark(type, attrs);
      }

      return removeMark(type);
    };
  }
}
