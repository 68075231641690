<template>
  <Content title="" class="my-3 w-100">
    <b-row :cols="cols" class="w-100">
      <b-col :class="['performance', performance.name]" v-for="performance in performances" :key="performance.name">
        <div class="performance-content">
          <div class="icon-wrapper">
            <svg-icon :name="performance.icon" />
          </div>
          <div class="data">
            <div class="title">
              {{ performance.title }}
            </div>
            <div class="value">
              {{ performance.value }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </Content>
</template>

<script>
import Content from '~/components/Reports/partials/Content';

export default {
  name: 'Performance',
  components: {
    Content,
  },
  props: {
    performances: {
      type: Array,
      required: true,
    },
    cols: {
      type: String,
      default: '4',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.performance-content {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 12px;
  margin: 6px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  &:not(&:nth-of-type(1)) {
    margin: 0 0 0 20px;
  }

  > .icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #d8f3dc;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    > .icon {
      width: 28px;
      height: 28px;
      fill: #52b788;
      margin-top: 4px;
    }
  }

  > .data {
    margin: 0 0 0 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    > * {
      text-transform: capitalize;
      color: #404040;
      font-weight: 500;
      font-size: 14px;
    }

    > .value {
      margin: auto 0 0 0;
    }
  }
}
</style>
