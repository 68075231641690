var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white p-5"},[(!_vm.proceedToSave)?[_c('div',{staticClass:"py-5",style:({ textAlign: 'center' })},[_c('h3',[_vm._v("Automation Summary")]),_c('p',{staticClass:"font-normal"},[_vm._v(" You have chosen to run an AI automation based on the parameters you provided. Here are the details: ")]),_c('table',{staticClass:"w-full table"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',{staticClass:"capitalize font-normal"},[_vm._v(_vm._s(_vm.campaignName))]),_c('td',{staticClass:"capitalize font-normal"},[_vm._v(" "+_vm._s(_vm.selectedAccounts.map(e => `${e.platform} [${e.publishStatus}]`).join(', '))+" ")]),_c('td',{staticClass:"capitalize font-normal"},[_vm._v(_vm._s(_vm.aiPostPrompt))]),_c('td',{staticClass:"capitalize font-normal"},[_vm._v(_vm._s(_vm.scheduleType))])])])]),_c('p',{staticClass:"font-normal"},[_vm._v(" This automation will run continuously, ensuring consistent and efficient execution of your tasks according to the specified parameters. ")]),_c('button',{staticClass:"btn btn-primary px-5",on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Proceed to Save ")])])]:[_c('div',{staticClass:"py-5",style:({ display: 'flex', justifyContent: 'center' })},[_c('div',[_c('AutomationLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading !== 'error'),expression:"loading !== 'error'"}],style:({ transform: 'scale(1.5)' }),attrs:{"loadingTexts":[
            'Sending Automation Requests...',
            'Generating Featured Images....',
            'Generating Captions...',
            'Generating Posts...',
          ]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading === 'success'),expression:"loading === 'success'"}],staticClass:"flex pb-5 pt-3 mt-5 items-center",style:({ display: 'flex', flexDirection: ' column', justifyContent: 'center' })},[_vm._m(1),_c('button',{staticClass:"btn btn-primary w-100 py-3",on:{"click":function($event){return _vm.$emit('viewAutomations')}}},[_vm._v("View Automations")])]),(_vm.loading === 'error')?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('div',{staticClass:"flex flex-row gap-2 items-center"},[_c('b-icon',{attrs:{"icon":"exclamation-triangle","scale":"1.5"}}),_c('span',{staticClass:"font-normal text-sm"},[_vm._v(" Something went wrong during your automation setup ")])],1)]),_c('button',{staticClass:"btn btn-secondary w-100",on:{"click":function($event){return _vm.$emit('go-back')}}},[_vm._v("Go back")])],1):_vm._e()],1)])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',[_vm._v("Campaign Name")]),_c('th',[_vm._v("Publish Status")]),_c('th',[_vm._v("Prompt")]),_c('th',[_vm._v("Schedule Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-normal mb-1"},[_c('b',[_vm._v("Note: ")]),_c('i',[_vm._v("Your preview would be available when the process is complete")])])
}]

export { render, staticRenderFns }