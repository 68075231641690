<template>
  <div class="pt-3">
    <div class="voice-editor bg-white">
      <div v-if="busy" class="text-center p-5"><spinner variant="primary" /></div>
      <template v-else>
        <MenuBar :editor="editor" :voice-id="voiceId" :perched="isScrollPassed" @voiceChange="handleVoiceChange" />
        <MenuBubble :editor="editor" />

        <AiPostWriter
          postType="speech"
          @post="aiPostGrabber"
          style="border: 1px solid #576f7f3e; border-radius: 5px; margin-bottom: 2rem;"
        />
        <drop
          class="drop"
          :class="{ over, allowed: isDragging }"
          @dragover="over = true"
          @dragleave="over = false"
          @drop="handleDrop"
        >
          <editor-content :editor="editor" />
        </drop>
      </template>
    </div>

    <!-- <div class="bg-white mt-4 w-50 mx-auto p-4">{{ ssml }}</div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Drop } from 'vue-drag-drop';
import { Editor, EditorContent } from 'tiptap';

import { History, Placeholder } from 'tiptap-extensions';

import { SayAs, Sub, Lang, Prosody, W } from './Marks';
import { Text, Paragraph, Break, AmazonDomain } from './Nodes';

import AiPostWriter from '../../Ai/AiPostWriter.vue';

import { MenuBar, MenuBubble } from './Components';

import { GET_ARTICLE_QUERY } from '~/graphql/queries';

export default {
  components: { Drop, EditorContent, MenuBar, MenuBubble, AiPostWriter },

  props: {
    speech: {
      type: String,
      default: '',
    },

    voiceId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ssml: '',
      editor: null,
      scrollY: 0,
      over: false,
      busy: false,
    };
  },

  computed: {
    isScrollPassed() {
      return this.scrollY > 15;
    },

    ...mapState('postEditor', {
      isDragging: state => state.isDragging,
    }),
  },

  watch: {
    speech(newValue) {
      if (newValue !== this.editor.getHTML()) {
        this.editor.setContent(newValue, true);
      }
    },

    disabled(isDisabled) {
      this.editor.setOptions({
        editable: !isDisabled,
      });
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        new Text(),
        new Paragraph(),
        new SayAs(),
        new Break(),
        new Sub(),
        new Lang(),
        new Prosody(),
        new W(),
        new AmazonDomain(),
        new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: 'Do the writing, let us do the talking ...',
          showOnlyWhenEditable: true,
          showOnlyCurrent: false,
        }),
        new History(),
      ],
      content: this.speech,
      onUpdate: ({ getHTML }) => {
        this.ssml = getHTML();
        this.$emit('change', {
          speech: this.ssml,
          count: this.editor.state.doc.textContent.length,
        });
      },
      editorProps: {
        attributes: {
          class: 'JMvoice',
        },
      },
    });

    const preContent = this.$store.state.voiceEditor.preContent;
    if (preContent && !this.speech) {
      this.editor.setContent(preContent, true);
      this.$store.dispatch('voiceEditor/setPreContent', '');
    }

    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    this.editor.destroy();

    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    aiPostGrabber(messages = []) {
      this.speech = messages.join('');
    },
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    handleVoiceChange(value) {
      this.$emit('voiceChange', value);
    },

    handleDrop({ type, data: article }) {
      if (type === 'article') {
        this.busy = true;

        this.fetchArticle(article.id)
          .then(articleText => {
            const content = `
              <p>${article.title}</p>
              ${articleText}
            `;

            this.editor.setContent(content, true);

            this.busy = false;
          })
          .catch(() => {
            this.busy = false;
          });
      } else if (type === 'quote') {
        const existing = this.ssml;
        const quote = `
          <p>${article.body}</p>
          ${article.author}
        `;
        const content = existing != '' || existing != '<p></p>' ? `${existing}${quote}` : quote;

        this.editor.setContent(content, true);
      }
    },

    fetchArticle(id) {
      return this.$apollo
        .query({
          query: GET_ARTICLE_QUERY,
          variables: {
            id,
          },
        })
        .then(({ data }) => {
          return data.getArticle;
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.voice-editor {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  width: 750px;
  min-height: 300px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 25px;
  margin: auto;
  margin-top: 5.8rem;
  margin-bottom: 100px;

  .drop.allowed {
    border: 2px dashed darken($primary, 10);
  }

  .JMvoice {
    min-height: 300px;

    p.is-editor-empty:first-child::before {
      content: attr(data-empty-text);
      float: left;
      color: $gray-500;
      pointer-events: none;
      font-size: 1.1rem;
      height: 0;
      font-style: italic;
    }
  }
}
</style>
