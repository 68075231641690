<template>
  <div class="name-accounts auto-box shadow-sm rounded-sm" :class="{ widen: type == 'blog' }">
    <div class="auto-box-inner">
      <h5 class="mb-5">Name and Accounts</h5>

      <b-form-group label="Campaign name" label-for="campaign-name">
        <b-form-input
          id="campaign-name"
          :value="campaignName"
          @input="updateCampaignName"
          autocomplete="off"
          trim
        ></b-form-input>
      </b-form-group>

      <SocialAccountChooser v-if="type == 'social'" :selectedAccounts="selectedAccounts" @sync="syncSelectedAccounts" />
      <BlogAccountChooser
        v-else-if="type == 'blog'"
        :selectedAccounts="selectedAccounts"
        @update="updateSelectedAccounts"
        @sync="syncSelectedAccounts"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BlogAccountChooser from '~/components/BlogAccountChooser';
import SocialAccountChooser from '~/components/SocialAccountChooser';

export default {
  components: { BlogAccountChooser, SocialAccountChooser },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('createAutomation', {
      campaignName: state => state.campaignName,
      selectedAccounts: state => state.selectedAccounts,
    }),

    primaryBlog() {
      return this.selectedAccounts.find(a => a.publishVariant == 'primary') || {};
    },

    secondaryBlogs() {
      return this.selectedAccounts.filter(a => a.publishVariant == 'secondary');
    },
  },

  methods: {
    updateCampaignName(value) {
      this.$store.dispatch('createAutomation/updateCampaignName', value);
    },

    updateSelectedAccounts(update) {
      this.$store.dispatch('createAutomation/updateSelectedAccounts', update);
    },

    syncSelectedAccounts(params) {
      const { account, publishVariant, publishAuthorId, publishCategoryIds, publishStatus } = params;

      const publishingParam = {
        accountId: account.id,
        publishVariant,
        platform: account.type.platform,
        publishAuthorId,
        publishCategoryIds,
        publishStatus,
      };

      const index = this.selectedAccounts.findIndex(a => a.accountId === account.id);

      let selected;
      if (publishVariant == 'primary') {
        const filteredAccounts = this.selectedAccounts.filter(con => con.publishVariant != 'primary');

        if (index !== -1) {
          if (this.primaryBlog.accountId === account.id) {
            // If this account is already the primary blog de-select everything
            selected = [];
          } else {
            // If this account is somewhere else, add and updated version, then remove the old one
            selected = [publishingParam, ...filteredAccounts.filter(con => con.accountId != account.id)];
          }
        } else {
          selected = [publishingParam, ...filteredAccounts];
        }
      } else {
        if (index === -1) {
          selected = [...this.selectedAccounts, publishingParam];
        } else {
          selected = [...this.selectedAccounts.slice(0, index), ...this.selectedAccounts.slice(index + 1)];
        }
      }

      this.updateSelectedAccounts(selected);
    },
  },
};
</script>

<style lang="scss">
.error-tip {
  .tooltip-inner {
    text-align: left;
  }
}

.auto-box {
  &.widen {
    .auto-box-inner {
      width: 80%;

      .add-social-account,
      .social-account-box {
        width: 185px;
        height: 170px;
      }
    }
  }
}
</style>
