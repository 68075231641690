<template>
  <div>
    <simplebar class="tabs-scroll">
      <template v-if="posts.length > 0 && !$apollo.queries.postsQuery.loading">
        <div class="post__items-wrap">
          <div v-for="post in posts" :key="post.id" class="post__items-wrap-item shadow-sm">
            <b-container fluid>
              <b-row>
                <b-col
                  cols="4"
                  class="item-img"
                  v-if="post.featuredImageUrl"
                  :style="{ backgroundImage: `url(${post.featuredImageUrl})` }"
                >
                </b-col>
                <b-col class="item-details">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="item-author d-flex">
                      <b-avatar class="avatar" size="2rem" :src="getAccountPhoto(post)"></b-avatar>
                      <div>
                        <p class="author-name">{{ getAccountEmail(post) }}</p>
                        <p class="post-time m-2 p-0">{{ post.createdAt }}</p>
                      </div>
                    </div>
                    <div class="">
                      <b-button
                        v-b-tooltip
                        title="View post"
                        size="sm"
                        variant="outline-primary"
                        class="item-more-info px-2 mr-1 mt-0"
                        :to="{ name: 'post.view', params: { id: post.id } }"
                      >
                        Read More
                      </b-button>
                    </div>
                  </div>

                  <div class="detail-title">
                    {{ post.title || 'Untitled Post' }}
                  </div>
                  <div class="detail-desc" v-if="getAccountPostContent(post)">
                    {{ getAccountPostContent(post) | truncate(80, '...') }}
                  </div>
                  <div class="d-flex justify-content-between detail-meta">
                    <div class="detail-tag">
                      {{ post.tag }}
                    </div>
                    <div class="detail-timestamp">
                      {{ getTimeAgo(post.created_at) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </template>
      <div v-else-if="posts.length === 0 && !$apollo.queries.postsQuery.loading" class="no-result-wrap text-center">
        <img class="my-4" width="250" src="@/assets/images/lost.svg" alt="" />
        <h5 class="">You have no Posts yet.</h5>
        <b-button size="sm" class="mt-3 px-4" @click="flashCreateButton">
          <svg-icon name="plus" class="mr-1" />
          Create Some
        </b-button>
      </div>
      <DashboardArticlesSkeleton v-else class="post__items-wrap" />
    </simplebar>
    <div class="tab-footer">
      <router-link :to="{ name: 'posts' }">View All</router-link>
      <div v-if="posts" class="tab-info">{{ posts.length }} / {{ workspace.postsCount }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { stripTags } from '~/utils/helpers';

import { DASHBOARD_POSTS_QUERY } from '~/graphql/queries';

import DashboardArticlesSkeleton from '~/components/DashboardArticlesSkeleton';

export default {
  components: { simplebar, DashboardArticlesSkeleton },

  data() {
    return {
      posts: [],
      // posts: [
      //   {
      //     id: 1,
      //     title: 'loremIpm',
      //     featuredImageUrl: require('@/assets/images/new/business.png'),
      //     description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque odio, maxime ullam repellat eligendi veniam nostrum assumenda et voluptatem, eum esse est magni quod aspernatur ducimus inventore atque impedit molestiae.',
      //     created_at: '12th Jan, 2021',
      //     tag: 'business',
      //     author:
      //     {
      //       id: 1,
      //       email: 'admin@tronweb.co',
      //       photo: require('@/assets/icons/new/avatar.png'),
      //     }
      //   },
      //   {
      //     id: 2,
      //     title: 'lorem Ipsum dolor sit',
      //     featuredImageUrl: require('@/assets/images/new/business.png'),
      //     description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque odio, maxime ullam repellat eligendi veniam nostrum assumenda et voluptatem, eum esse est magni quod aspernatur ducimus inventore atque impedit molestiae.',
      //     created_at: '12th Jan, 2021',
      //     tag: 'politics',
      //     author: {
      //       id: 2,
      //       email: 'admin@tronweb.co',
      //       photo: require('@/assets/icons/new/avatar.png'),
      //     }
      //   },
      //   {
      //     id: 3,
      //     title: 'lorem Ipsum dolor sit',
      //     featuredImageUrl: require('@/assets/icons/new/avatar.png'),
      //     description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque odio, maxime ullam repellat eligendi veniam nostrum assumenda et voluptatem, eum esse est magni quod aspernatur ducimus inventore atque impedit molestiae.',
      //     created_at: '12th Jan, 2021',
      //     tag: 'entertainment',
      //     author: {
      //       id: 3,
      //       email: 'admin@tronweb.co'
      //     }
      //   },
      // ],
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  apollo: {
    postsQuery: {
      query: DASHBOARD_POSTS_QUERY,
      manual: true,
      variables() {
        return {
          workspace: this.workspace.id,
          postsLimit: 5,
        };
      },
      result({ data, loading }) {
        if (!loading) {
          this.posts = data.singlePosts.posts;
        }
      },
    },
  },

  mounted() {
    if (this.$apollo.queries.postsQuery) {
      this.$apollo.queries.postsQuery.refetch();
    }
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  methods: {
    getTimeAgo(time) {
      return moment(time).fromNow();
    },
    flashCreateButton() {
      this.$emit('flashCreateButton');
    },

    getAccountPhoto(post) {
      const first = post.publishAccounts[0];
      return first ? first.imageUrl : '';
    },

    getAccountEmail(post) {
      const first = post.publishAccounts[0];
      return first ? first.email : '';
    },

    capitalize(post) {
      return post
        .split('')
        .map((e, i) => (i === 0 ? e.toUpperCase() : e))
        .join('');
    },

    getAccountPostContent(post) {
      const first = post.accountPostContents[0];
      return this.capitalize(stripTags(first ? first.content : '' || post.masterContent || `${post.type} post`));
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.post__items-wrap {
  // padding: 1.25rem 1.8rem;
  padding: 15px 15px 5px;
  margin: 15px;
  background: #f8f8f8;
  // background: #812c2c;
  height: 100%;

  // border-top: 1px solid $gray-100;

  &-item {
    // border: 1px solid $gray-200;
    margin-bottom: 20px;

    .item-img {
      background: #d8f3dc;
      border-radius: 10px 0 0 10px;
      text-align: center;
      vertical-align: middle;
      background-size: cover;
    }
    .item-details {
      border-radius: 10px;
      background: #ffffff;
      padding: 20px;
      padding-bottom: 10px;
      .detail-meta {
        margin-bottom: 4px;
      }
      .detail-tag {
        text-transform: capitalize;
        font-size: 0.9rem;
        font-weight: bold;
      }
      .detail-timestamp {
        font-size: 0.7rem;
        color: $gray-500;
        margin-top: 5px;
      }
      .detail-title {
        text-transform: capitalize;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 4px;
        margin-top: 4px;
      }
      .detail-desc {
        margin-bottom: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 0.9rem;
        word-break: break-word;
      }
      &:hover {
        .item-more-info {
          opacity: 1;
        }
      }
    }
    .item-author {
      margin-bottom: 10px;
      .author-name {
        font-size: 0.8rem;
        color: $gray-500;
        margin: 0;
        padding: 0;
      }
      .post-time {
        font-size: 0.6rem;
        color: $gray-500;
        margin-top: -5px;
      }
    }
    .item-more-info {
      font-size: 0.7rem;
      // color: $gray-500;
      // opacity: 0;
      margin-top: -10px;
      &:hover {
        text-decoration: none;
        // color: $gray-500;
      }
    }
  }
}

.dashboard-article-item-skeleten {
  width: 100%;
}
</style>
