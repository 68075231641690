<template>
  <div class="mx-5 px-5 py-3">
    <b-row class="account-contents">
      <b-col md="4" sm="12">
        <div class="mb-2 text-primary border border-primary rounded p-2 text-center">
          Customize content to suite your audience
        </div>
        <simplebar class="publish-channels-wrap" :class="{ stick: isScrollPassed }">
          <div class="publish-channel">
            <h6 class="spaced-heading">Primary channel</h6>
            <ChosenPublishAccounts
              :accounts="primaryBlog"
              :activeAccount="activeAccount"
              @item-click="switchActiveTab"
            />
          </div>
          <div v-if="secondaryBlogs.length" class="publish-channel">
            <h6 class="spaced-heading">Secondary channels</h6>
            <ChosenPublishAccounts
              :accounts="secondaryBlogs"
              :activeAccount="activeAccount"
              @item-click="switchActiveTab"
            />
          </div>
          <div v-if="socialAccounts.length" class="publish-channel">
            <h6 class="spaced-heading">Social sharing</h6>
            <ChosenPublishAccounts
              :accounts="socialAccounts"
              :activeAccount="activeAccount"
              @item-click="switchActiveTab"
            />
          </div>
        </simplebar>
      </b-col>
      <b-col md="8" sm="12">
        <div v-if="activeAccount" class="active-content-area px-5 py-4 rounded-sm">
          <div class="d-flex align-items-center mb-3">
            <img
              class="mr-2 bg-white shadow-sm p-1 rounded-circle"
              :src="activeAccount.imageUrl"
              alt=""
              style="height: 40px; width: 40px;"
            />
            <div class="font-weight-bold text-truncate">{{ activeAccount.name }}</div>
          </div>

          <DesignFabUploadsPanel v-if="isPrimaryBlogPlatform('wordpress')" @uploadSelect="handleFeaturedImageChange" />

          <div
            v-if="featuredImageUrl"
            class="d-flex align-items-flex-start mb-3 w-100 flex-column justify-content-start"
          >
            <div class="font-weight-bold text-truncate w-100">
              Featured image
            </div>
            <img
              class="mr-2 bg-white shadow-sm p-1"
              :src="featuredImageUrl"
              alt=""
              style="height: 200px; width: 400px;"
            />
          </div>

          <b-form-input
            v-if="supportsTitle"
            v-model="activeAccountTitle"
            @input="activeContentChange"
            type="text"
            class="rounded-0 mb-3 border-0 shadow-sm"
            placeholder="Post title"
          />

          <PostEditor
            v-if="activePublishVariant == 'social'"
            v-model="activeAccountContent"
            :selectedImages="activeAccountImages"
            @input="activeContentChange"
            :charLimit="activeAccountCharLimit"
            @removeSelectedImage="handleRemoveSelectedImage"
          ></PostEditor>
          <div v-else class="editor-wrap bg-white shadow-sm p-4">
            <RichTextEditor
              :content="activeAccountContent"
              @input="activeContentChange"
              :placeholder="`Write content for ${capitalize(activeAccount.type.platform)}`"
              :charLimit="activeAccountCharLimit"
            />
          </div>

          <b-form-input
            v-if="supportsPostLink"
            v-model="activeAccountPostLink"
            class="rounded-0 mt-3 border-0 shadow-sm"
            type="text"
            placeholder="Post URL"
          />
        </div>
        <div v-else class="px-4 bg-light p-5 text-center">
          <h4>You have not selected any account to share to yet.</h4>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { capitalize } from 'lodash';
import PostEditor from '~/components/PostEditor';
import RichTextEditor from '~/components/RichTextEditor';
import EditPostContentMixin from '~/mixins/EditPostContentMixin';
import ChosenPublishAccounts from '~/components/ChosenPublishAccounts';
import DesignFabUploadsPanel from '~/components/DesignFabs/DesignFabUploadsPanel';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  mixins: [EditPostContentMixin],

  components: {
    simplebar,
    PostEditor,
    RichTextEditor,
    ChosenPublishAccounts,
    DesignFabUploadsPanel,
  },

  data() {
    return {
      scrollY: 0,
    };
  },

  computed: {
    isScrollPassed() {
      return this.scrollY > 110;
    },

    primaryContent() {
      return this.accountPostContents.find(account => account.publishVariant === 'primary');
    },
    secondaryContents() {
      return this.accountPostContents.filter(account => account.publishVariant === 'secondary');
    },

    socialContents() {
      return this.accountPostContents.filter(account => account.publishVariant === 'social');
    },

    primaryBlog() {
      return this.chosenAccounts.filter(account => account.id === this.primaryContent.accountId);
    },

    secondaryBlogs() {
      return this.chosenAccounts.filter(account =>
        this.secondaryContents.map(con => con.accountId).includes(account.id),
      );
    },

    socialAccounts() {
      return this.chosenAccounts.filter(account => this.socialContents.map(con => con.accountId).includes(account.id));
    },

    isPrimaryBlogPlatform() {
      return platformName => {
        if (this.primaryBlog && this.primaryBlog[0]) {
          return this.primaryBlog[0].type.platform === platformName;
        }

        return false;
      };
    },
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    handleRemoveSelectedImage(attachementIndex) {
      if (this.activeAccountImages[attachementIndex]) {
        this.activeAccountImages = [
          ...this.activeAccountImages.slice(0, attachementIndex),
          ...this.activeAccountImages.slice(attachementIndex + 1),
        ];

        // Trigger re-validation
        this.activeContentChange();
      }
    },

    handleFeaturedImageChange(image) {
      this.updateFeaturedImageLink(image.url);
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.active-content-area {
  background: $white;
  border-radius: 10px;
}

.spaced-heading {
  border-bottom: 1px solid $gray-200;
  padding: 1rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 0.7rem;
  font-weight: bold;
  background: $secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;
  text-align: center;
}

.publish-channels-wrap {
  overflow-y: auto;
  max-height: calc(100vh - #{($navbar-height + $topbar-height + 10px)});
  // width: 277px;

  &.stick {
    position: fixed;
    top: ($navbar-height + $topbar-height + 10px);
    left: 121px;
  }

  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    border-radius: 5px;
    background: rgba($primary, 0.5);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }

  // .publish-channel {
  //   width: 94%;
  //   padding-right: 10px;
  // }
}

.active-content-area {
  background-color: darken($light, 1);
}
</style>
