<template>
  <div class="bg-white shadow-sm rounded blog__container">
    <div class="blog__container-header">
      <div class="header-title my-2">
        <div class="title">Blog &amp; Websites</div>
      </div>
    </div>
    <b-row class="blog__container-body mt-3">
      <b-col :cols="publishAccounts.length > 0 ? 5 : 6">
        <div class="container-left">
          <div class="sub__container">
            <div class="sub__container-content">
              <h2>Connect</h2>
              <h4>A Website Or Blog.</h4>
            </div>
          </div>
          <div class="container-actions">
            <SocialButtons type="blog" :accounts="['Wordpress', 'Medium']" />
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="container-right">
          <div class="right-header" v-if="publishAccounts && publishAccounts.length > 0">
            Connected Accounts
          </div>
          <div v-if="$apollo.queries.publishAccounts.loading" class="bg-white text-center p-5">
            <spinner />
          </div>
          <div v-if="!$apollo.queries.publishAccounts.loading && publishAccounts && publishAccounts.length > 0">
            <b-row class="accounts__container-list" v-for="publishAccount in publishAccounts" :key="publishAccount.id">
              <b-col class="bordered-left w-100" cols="5">
                <div class="d-flex align-items-center w-100">
                  <b-avatar class="mr-1" :src="publishAccount.imageUrl" size="2rem"></b-avatar>
                  <div class="w-100">
                    <div class="account_type font-weight-bold w-75 text-truncate">{{ publishAccount.name }}</div>
                  </div>
                </div>
              </b-col>
              <b-col cols="4" class="bordered-left text-center">
                <div class="account_type">
                  <p>
                    <svg-icon :name="`${publishAccount.type.platform}`" class="mr-1" size="sm" />
                    {{ publishAccount.type.displayName }}
                  </p>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="account-action">
                  <b-button
                    variant="secondary"
                    size="xs"
                    class="p-2"
                    @click="confirmDeletePublishAccount(publishAccount)"
                  >
                    <svg-icon name="trash" class="mr-1" />
                    Remove
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          

          <div v-if="publishAccounts && !publishAccounts.length" class="text-center p-5">
            <img class="pt-3" width="150" src="@/assets/images/account.svg" alt="" />
            <h5 class="my-5">You have not connected any blog or website yet.</h5>
          </div>
        </div>
      </b-col>

      <b-modal ref="deletePublishAccountModal" centered hide-header hide-footer>
        <div v-if="isDeletingPublishAccount" class="text-center p-5"><spinner /></div>
        <div v-else class="text-center p-5">
          <h4>Remove connected Account?</h4>
          <p>Are you sure you would like to remove this account?</p>

          <b-button variant="clear" @click="closeConfirmDeletePublishAccount" class="mr-3"> Cancel </b-button>
          <b-button @click="actuallyDeletePublishAccount" :disabled="isDeletingPublishAccount">
            <b-spinner v-if="isDeletingPublishAccount" small />
            Remove
          </b-button>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { DELETE_PUBLISH_ACCOUNT } from '~/graphql/mutations';
import SocialButtons from '../../../components/Buttons/SocialButtons';

export default {
  components: { SocialButtons },

  data: function() {
    return {
      apiUrl: process.env.VUE_APP_API_ROOT,

      mediumPublications: [],
      mediumAccountId: null,

      isDeletingPublishAccount: false,
    };
  },

  apollo: {
    publishAccounts: {
      query: PUBLISH_ACCOUNTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: 'blog',
        };
      },
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
    }),
  },

  created() {
    this.$store.dispatch('publishAccount/updateQueryType', 'blog');
  },

  methods: {

    confirmDeletePublishAccount(publishAccount) {
      this.publishAccountToDelete = publishAccount;

      this.$refs.deletePublishAccountModal.show();
    },

    closeConfirmDeletePublishAccount() {
      this.publishAccountToDelete = null;

      this.$refs.deletePublishAccountModal.hide();
    },

    async actuallyDeletePublishAccount() {
      this.isDeletingPublishAccount = true;
      await this.$apollo.mutate({
        mutation: DELETE_PUBLISH_ACCOUNT,
        variables: {
          workspace: this.workspace.id,
          ids: [this.publishAccountToDelete.id],
        },
        update: (store, { data: { deleted } }) => {
          if (deleted) {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PUBLISH_ACCOUNTS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'blog',
              },
            });

            const index = data.publishAccounts.findIndex(a => a.id === this.publishAccountToDelete.id);
            if (index !== -1) {
              data.publishAccounts.splice(index, 1);
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: PUBLISH_ACCOUNTS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'blog',
              },
              data,
            });
          }
        },
      });

      const count = parseInt(this.workspace.limitsUsage.blogs);
      this.$store.dispatch('workspace/updateCurrent', {
        ...this.workspace,
        limitsUsage: {
          ...this.workspace.limitsUsage,
          blogs: count - 1,
        },
      });

      this.isDeletingPublishAccount = false;
      this.closeConfirmDeletePublishAccount();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.blog__container {
  padding: 30px;
  &-header {
    .header-title {
      overflow: hidden;
      text-align: left;
      color: #d8f3dc;
      display: flex;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: $primary;
        background: $secondary;
        padding: 10px 15px;
        border-radius: 10px;
        // width: 18%;
        text-align: center;
      }
      &:after {
        background-color: $secondary;
        content: '';
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 100%;
        left: 0.5em;
        top: 23px;
        margin-right: -20%;
      }
    }
  }
  &-body {
    .container-left {
      background: $secondary;
      border-radius: 20px;
      .sub__container {
        height: 260px;
        border-radius: 20px;
        background: $primary;
        position: relative;
        background-image: url('../../../assets/images/new/blog_img.png');
        background-size: cover;
        background-position: top center;
        &-content {
          position: absolute;
          top: 0;
          bottom: 0;
          height: 260px;
          color: $white;
          padding: 40px 30px;
          h3 {
            font-weight: bold;
          }
        }
      }
      .container-actions {
        padding: 40px 30px;
      }
      .accounts__container {
        .title {
          color: $primary;
          font-size: 20px;
        }
        &-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          color: #000;
          margin-bottom: 6px;
          .bordered-left {
            border-right: 1px solid #95d5b2;
            height: 80px;
            width: 100%;
          }
          .account-type {
            line-height: 65px;
            vertical-align: middle;
          }
          .account-action {
            display: none;
          }
          &:hover {
            background: $secondary;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
            .user_action {
              display: flex;
              align-items: center;
            }
            .user_email {
              svg {
                fill: $primary;
              }
            }
          }
        }
      }
    }
    .container-right {
      background: #f2f2f2;
      border-radius: 10px;
      padding: 20px;
      color: $primary;
      .right-header {
        color: $primary;
        font-weight: bold;
        font-size: 18px;
      }
      .accounts__container {
        margin: 10px auto;
        &-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          color: #000;
          margin-bottom: 6px;
          .bordered-left {
            border-right: 1px solid #95d5b2;
            height: 40px;
            width: 100%;
          }
          .account_type {
            line-height: 35px;
            vertical-align: middle;
          }
          &:hover {
            background: $secondary;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
            .account_type {
              svg {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
