<template>
  <div class="panel-scroll">
    <div class="panel-body comments">
      <div class="input-wrapper">
        <textarea class="input" v-model="newComment" placeholder="Write a comment"></textarea>
        <button class="save" @click="addComment" :disabled="!newComment">
          Add comment
        </button>
      </div>
      <div class="comments" v-if="hasComments">
        <Comment v-for="comment in orderedComments" :key="comment.id" :comment="comment" />
      </div>
      <div v-else-if="isLoadingComments" class="text-center loader">
        <EditorFabCommentsSkeleton />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { isArray, forEach, orderBy } from 'lodash';
import { POST_COMMENTS_WITH_ANALYTICS_QUERY } from '~/graphql/queries';
import { ADD_POST_COMMENT_MUTATION } from '~/graphql/mutations';
import EditorFabCommentsSkeleton from './EditorFabCommentsSkeleton';
import Comment from './partials/Comment';

export default {
  name: 'editor-fabs-comments-panel',

  props: {
    postId: {
      type: Number || String,
      required: true,
    },
  },

  components: {
    EditorFabCommentsSkeleton,
    Comment,
  },

  data() {
    return {
      comments: [],
      hasMore: true,
      newComment: null,
      totalComment: 0,
    };
  },

  apollo: {
    comments: {
      query: POST_COMMENTS_WITH_ANALYTICS_QUERY,
      variables() {
        return {
          postId: this.postId,
        };
      },
      update({ postCommentsWithAnalytics: { postComments, analytics } }) {
        this.hasMore = !postComments.length;
        this.totalComment = analytics.total;
        return postComments;
      },
    },
  },

  computed: {
    isLoadingComments() {
      return this.$apollo.queries.comments.loading;
    },
    hasComments() {
      return !this.isLoadingComments && isArray(this.comments) && this.comments.length > 0;
    },
    orderedComments() {
      return orderBy(this.comments, ['date'], ['desc']);
    },
  },

  watch: {
    totalComment: {
      handler(val) {
        this.$emit('update-comments-count', val);
      },
      immediate: true,
    },
  },

  created() {
    this.$apollo.queries.comments.refetch({
      postId: this.postId,
    });

    window.Echo.private(`post.${this.postId}.activity`)
      .listen('made_new_comment', commentData => {
        this.comments.push(commentData);
      })
      .listen('deleted_comment', ({ id }) => {
        this.comments = this.comments.filter(({ id: commentId }) => String(commentId) !== String(id));
      })
      .listen('updated_comment_message', ({ id, message }) => {
        const comment = this.comments.find(({ id: commentId }) => String(commentId) === String(id));
        comment.message = message;
      });
  },

  beforeDestroy() {
    window.Echo.private(`post.${this.postId}.activity`)
      .stopListening('made_new_comment')
      .stopListening('deleted_comment')
      .stopListening('updated_comment_message');
  },

  methods: {
    async addComment() {
      const newComment = this.newComment;
      this.newComment = '';

      try {
        await this.$apollo.mutate({
          mutation: ADD_POST_COMMENT_MUTATION,
          variables: {
            postId: this.postId,
            message: newComment,
          },
          refetchQueries: [
            {
              query: POST_COMMENTS_WITH_ANALYTICS_QUERY,
              variables: {
                postId: this.postId,
              },
            },
          ],
        });
      } catch ({ graphQLErrors: errors }) {
        this.newComment = newComment;
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'ERROR_ADDING_POST_COMMENT') {
              errorText = 'A server error occurred adding post comment';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while saving post comment',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
.panel-body.comments {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  > * {
    display: flex;
    width: 100%;
  }

  > .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;
    padding: 0;

    > .input {
      background-color: #fafafa;
      border: 0.5px solid #f0f0f0;
      border-radius: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      align-content: flex-start;
      padding: 10px;
      min-height: 120px;
      display: flex;
      width: 100%;
    }

    > .save {
      display: flex;
      width: auto;
      margin: 20px 0 0 0;
      border-radius: 5px;
      background-color: $secondary;
      border: 1px solid #dedede;
      outline: initial;
      color: $primary;
      font-weight: bold;
      text-transform: capitalize;
      padding: 8px 25px;
      transition: all 0.3s ease-in-out;

      &:disabled {
        pointer-events: none;
        background-color: #cfcfcf;
        border: 1px solid #e4e4e4;
        color: #2e2e2e;
      }
    }
  }

  > .comments {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    width: 100%;
  }

  > .loader {
    width: 100%;
    margin: 20px 0 0 0;
    display: block;
  }
}
</style>
