<template>
  <div class="d-flex">
    <div class="csv-upload auto-box shadow-sm rounded-sm">
      <div class="auto-box-inner">
        <h4 class="mb-5">Upload CSV File</h4>

        <div class="desc">
          <p class="mb-2">Import a CSV file with the date, time & text of your new posts.</p>
          <p>Here’s how your CSV file should look like. <a :href="templateUrl">Download CSV file template</a>.</p>
        </div>
        <b-form-group label-for="hashtags" class="mt-4">
          <template slot="label">Upload your CSV file</template>
          <b-form-file
            :value="source"
            class="csv-file"
            placeholder="Choose or drop a CSV file here..."
            browse-text="Upload"
            accept=".csv"
            @input="handleCsvFileChange"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isValidUrl } from '~/utils/helpers';

export default {
  name: 'automation-csv-upload',

  data() {
    return {
      templateUrl: process.env.VUE_APP_AUTOMATION_TEMPLATE,
    };
  },

  computed: {
    ...mapState('createAutomation', {
      source: state => state.source,
    }),
    urlState() {
      if (this.source === '') return null;
      return isValidUrl(this.source);
    },
  },

  methods: {
    handleCsvFileChange(file) {
      this.$store.dispatch('createAutomation/updateCsvFile', file);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.csv-upload {
  .desc {
    color: $gray-500;
  }
}
</style>
