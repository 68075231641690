<template>
  <div class="post-composer" :class="{ fixed }">
    <div
      v-if="$apollo.queries.fetchPost && $apollo.queries.fetchPost.loading"
      class="w-50 text-center my-5 mx-auto p-5 bg-white shadow-sm"
    >
      <spinner size="4" />
    </div>
    <template v-else>
      <TopWizard
        :sequence="sequence"
        :currentStep="currentStep"
        :goBackText="goBackText"
        :goBackHandler="goBackHandler"
        :sidebar="false"
      >
        <template v-slot:wizard-link="{ step }">
          <a
            @click.prevent="setCurrentStep(step.number)"
            class="wiz-step-item"
            :class="{ active: currentStep >= step.number }"
            href="#"
          >
            <span class="marker"></span> {{ step.name }}
          </a>
        </template>

        <div class="action-buttons" slot="buttons">
          <span id="next-btn-wrap" class="go-back-inner" tabindex="0">
            <b-button
              v-if="isUserAdminInWorkspace() || isUserCollaboratorInWorkspace()"
              @click="moveToNext"
              variant="primary"
              class="px-4 m-0 act-button d-flex flex-row align-items-center next"
              :disabled="isNextDisabled"
            >
              <span class="back-label">{{ isLastStep ? 'Save' : 'Next' }}</span>
              <b-spinner v-if="postSaving" class="ml-1" small />
              <svg-icon
                v-else
                :name="isLastStep ? 'check' : 'arrow-right-alt'"
                class="ml-1"
                :class="{ 'mt-0': !isLastStep }"
              />
            </b-button>
          </span>
          <b-tooltip
            v-if="isNextDisabled"
            triggers="hover"
            target="next-btn-wrap"
            variant="primary"
            placement="bottom"
            custom-class="error-tip"
            :title="error"
          ></b-tooltip>
        </div>
      </TopWizard>

      <div class="pt-5">
        <transition name="editor-slide">
          <div v-if="currentStep === 1" key="content">
            <SocialPostEditPage v-if="type === 'social'" class="mt-5" :post-id="id" />
            <BlogPostEditPage v-if="type === 'blog'" class="my-5" :post-id="id" />
          </div>

          <div v-if="currentStep === 2" key="accounts" class="single-posts-content-wrap">
            <b-container class="pt-5">
              <SocialAccountChooser
                v-if="type == 'social'"
                :selectedAccounts="accountPostContents"
                @sync="syncAccountPostContents"
              />
              <BlogAccountChooser
                v-else-if="type == 'blog'"
                :selectedAccounts="accountPostContents"
                @update="updatePostContents"
                @sync="syncAccountPostContents"
              />
            </b-container>
          </div>

          <div
            v-if="currentStep === 3 && (isUserAdminInWorkspace() || isUserCollaboratorInWorkspace())"
            key="customize"
            class="customize-wrap"
          >
            <SocialPostCustomizer v-if="type === 'social'" />
            <BlogPostCustomizer v-if="type === 'blog'" />
          </div>

          <section
            v-if="currentStep === 4 && (isUserAdminInWorkspace() || isUserCollaboratorInWorkspace())"
            key="schedule"
            class="schedule-page-wrap"
          >
            <b-container>
              <simplebar class="tabs-scroll">
                <div v-if="canNotChangeStatus" class="scheduler bg-white rounded-lg mx-auto">
                  <PostScheduleChooser />
                </div>
                <PostStatusChooser v-else />
              </simplebar>
            </b-container>
          </section>
        </transition>
      </div>

      <CarouselTutorialModal
        :type="type"
        :steps="tutorials"
        :visible="showTutorialModal"
        v-if="isUserAdminInWorkspace() || isUserCollaboratorInWorkspace()"
      >
        <template v-slot:next-button="{ close, activeStep }">
          <b-button v-if="activeStep.id === 4" @click="close" variant="success" class="px-4 mb-4 rounded-pill"
            >Let's Go</b-button
          >
        </template>
      </CarouselTutorialModal>
    </template>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';
import TopWizard from '~/components/TopWizard';
import { FETCH_POST_QUERY } from '~/graphql/queries';
import BlogPostEditPage from '~/components/BlogPostEditPage';
import PostStatusChooser from '~/components/PostStatusChooser';
import BlogPostCustomizer from '~/components/BlogPostCustomizer';
import SocialPostEditPage from '~/components/SocialPostEditPage';
import BlogAccountChooser from '~/components/BlogAccountChooser';
import PostScheduleChooser from '~/components/PostScheduleChooser';
import SocialAccountChooser from '~/components/SocialAccountChooser';
import SocialPostCustomizer from '~/components/SocialPostCustomizer';
import CarouselTutorialModal from '~/components/CarouselTutorialModal';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    TopWizard,
    BlogPostEditPage,
    BlogPostCustomizer,
    PostStatusChooser,
    BlogAccountChooser,
    SocialAccountChooser,
    SocialPostEditPage,
    PostScheduleChooser,
    SocialPostCustomizer,
    CarouselTutorialModal,
    simplebar,
  },

  data() {
    return {
      fetchPost: {},
      onBackBtn: false,
      showTutorialModal: false,
      error: null,
    };
  },

  computed: {
    goBackText() {
      return this.currentStep === 1 ? 'Dashboard' : 'Go Back';
    },

    tutorials() {
      return this.type === 'social'
        ? [
            {
              id: 1,
              title: 'Welcome to Social Post Composer ',
              description:
                'Compose fresh content for your social media from an intuitive editor. Embed content from your favorite sources. Find Articles, GIFs, Images, Quotes and more and simply drag and drop to the editor',
              image: 'step-1.gif',
            },
            {
              id: 2,
              title: 'Find Topics in Realtime...',
              description:
                'Find contents across the web to share on your social media. Use a keyword, to discover Articles, GIF, Images, Images etc and simply drag and drop to the editor',
              image: 'step-2.gif',
            },
            {
              id: 3,
              title: 'Laser Target Formating ',
              description: 'Optionally Format your content further to each social media and publish',
              image: 'step-3.gif',
              btnText: 'Next',
              btnVariant: 'primary',
            },
            {
              id: 4,
              title: 'Set Hashtags and UTM',
              description: 'Get more Visibility and tracking by setting hastags and UTM even more power',
              image: 'step-4.gif',
            },
          ]
        : [
            {
              id: 1,
              title: 'Welcome to SocialMediaAgency360 Editor',
              description:
                'Compose fresh content for your Blog/Website from an intuitive editor and powerful editor. Embed content from your favorite sources and find Articles, GIFs, Images, Quotes and more that you can simply drag and drop to the editor',
              image: 'step-1.gif',
            },
            {
              id: 2,
              title: 'Find Topics in Realtime…',
              description:
                'Find contents across the web to share on your social media. Use a keyword, to discover Articles, GIF, Images, Images etc and simply drag and drop to the editor',
              image: 'step-2.gif',
            },
            {
              id: 3,
              title: 'Power Formatting ',
              description: 'Use our Robust editor to create powerful contents with colors, typography and more',
              image: 'step-3.gif',
            },
            {
              id: 4,
              title: 'Share and Create Ebook',
              description:
                'Share your Content in 1 click to your blog/ Website or simply use our ebook generator to export your content as a PDF ebook',
              image: 'step-4.gif',
            },
          ];
    },

    canNotChangeStatus() {
      return (
        (this.parentType && this.parentType == 'automation') ||
        (this.parentType && this.parentType == 'campaign') ||
        this.isUserCollaboratorInWorkspace()
      );
    },

    sequence() {
      if (this.isUserAdminInWorkspace() || this.isUserCollaboratorInWorkspace()) {
        return [
          {
            number: 1,
            name: 'Create Post',
            to: '',
          },
          {
            number: 2,
            name: 'Select Accounts',
            to: '',
          },
          {
            number: 3,
            name: 'Customize',
            to: '',
          },
          {
            number: 4,
            name: this.canNotChangeStatus ? 'Schedule' : 'Publish',
            to: '',
          },
        ];
      }

      return [
        {
          number: 1,
          name: 'View Post',
          to: '',
        },
      ];
    },

    isNextDisabled() {
      return this.stepDisabled(this.currentStep);
    },

    isLastStep() {
      return this.currentStep === this.sequence.length;
    },

    ...mapState({
      workspace: state => state.workspace.current,
      parentType: state => state.createPost.parentType,
      postStatus: state => state.createPost.postStatus,
      postSaving: state => state.createPost.postSaving,
      currentStep: state => state.createPost.currentStep,
      selectedImages: state => state.createPost.selectedImages,
      selectedContent: state => state.createPost.selectedContent,
      accountPostContents: state => state.createPost.accountPostContents,
      onboarding: state => state.auth.user.options.onboarding,
    }),

    ...mapGetters('validation', {
      hasError: 'hasError',
    }),

    primaryBlog() {
      return this.accountPostContents.find(a => a.publishVariant == 'primary') || {};
    },

    secondaryBlogs() {
      return this.accountPostContents.filter(a => a.publishVariant == 'secondary');
    },

    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),
  },

  created() {
    if (this.id !== 0) {
      this.$apollo.addSmartQuery('fetchPost', {
        query: FETCH_POST_QUERY,
        variables: {
          workspace: this.workspace.id,
          id: this.id,
        },
        result({ data, loading }) {
          if (!loading) {
            this.$store.dispatch('createPost/sync', data.fetchPost);
          }
        },
      });
    }

    const key = `${this.type}Composer`;
    if (this.id === 0 && this.onboarding[key] === true) {
      this.showTutorialModal = true;
    }
  },

  beforeDestroy() {
    // Not called on page reload. Good!
    this.$store.dispatch('createPost/reset');
    this.$store.dispatch('validation/reset');
  },

  methods: {
    stepDisabled(step) {
      switch (step) {
        case 1:
          this.error = 'Please add some content to the editor';
          return !this.selectedContent || (this.selectedContent && !this.selectedContent.body);
        case 2: {
          if (this.type === 'social') {
            this.error = 'Please choose at least one account';
            return this.accountPostContents.length < 1;
          } else {
            const secContentInvalid = this.secondaryBlogs.some(con => this.isContentValid(con));
            const primeContentInvalid = this.isContentValid(this.primaryBlog);

            if (!this.primaryBlog.accountId) {
              this.error = 'Please Select a primary blog';
              return true;
            }

            this.error = 'Please set publish author, categories and/or status for all selected accounts';
            return secContentInvalid || primeContentInvalid;
          }
        }
        case 3:
          this.error = 'Please fix all content errors, or go back and deselect accounts with errors';
          return this.hasError();
        case 4:
          return this.accountPostContents.length < 1;
        default:
          return false;
      }
    },

    isContentValid(con) {
      let invalidForWP = false;
      if (con.platform == 'wordpress') {
        invalidForWP =
          !con.publishAuthorId || !con.publishCategoryIds || (con.publishCategoryIds && !con.publishCategoryIds.length);
      }

      return !con.publishStatus || invalidForWP;
    },

    goBackHandler() {
      if (this.currentStep === 1) {
        return this.$emit('close');
      }

      this.setCurrentStep(this.currentStep - 1);
      window.scrollTo(0, 0);
    },

    /**
     * Moves to the next step by incrementing currentStep by 1
     *
     * @return {void}
     */
    async moveToNext() {
      if (this.isLastStep) {
        return this.savePost();
      }

      this.setCurrentStep(this.currentStep + 1);
      window.scrollTo(0, 0);
    },

    /**
     * Sets the current step
     *
     * @param  {Int} step A step number to move to
     * @return {void}
     */
    setCurrentStep(stepNumber) {
      if (stepNumber < this.currentStep || (!this.isNextDisabled && !this.stepDisabled(stepNumber - 1))) {
        this.$store.dispatch('createPost/updateCurrentStep', stepNumber);
      }
    },

    updatePostContents(update) {
      this.$store.dispatch('createPost/updateAccountPostContents', update);
    },

    syncAccountPostContents(params) {
      const { account, publishVariant } = params;
      let accountPostContents = [];

      const index = this.accountPostContents.findIndex(con => con.accountId === account.id);

      if (publishVariant == 'primary') {
        accountPostContents = this.syncPrimaryContent(account, index);
      } else {
        if (index === -1) {
          accountPostContents = this.appendPostContent(params);
        } else {
          accountPostContents = this.dropPostContent(account, index);
        }
      }

      this.updatePostContents(accountPostContents);
    },

    syncPrimaryContent(account, index) {
      let postContents;
      const filteredAccounts = this.accountPostContents.filter(con => con.publishVariant != 'primary');

      const contentItem = this.buildContentItem({
        account,
        publishVariant: 'primary',
        platform: account.type.platform,
        publishStatus: account.type.publishStatuses[0].value,
      });

      this.$store.dispatch('validation/validate', { account, postContent: contentItem });

      if (index !== -1) {
        if (this.primaryBlog.accountId === account.id) {
          // If this account is already the primary blog de-select everything
          postContents = [];
        } else {
          // If this account is somewhere else, add and updated version, then remove the old one
          postContents = [contentItem, ...filteredAccounts.filter(con => con.accountId != account.id)];
        }
      } else {
        postContents = [contentItem, ...filteredAccounts];
      }

      return postContents;
    },

    appendPostContent(params) {
      const contentItem = this.buildContentItem(params);

      this.$store.dispatch('validation/validate', { account: params.account, postContent: contentItem });

      return [...this.accountPostContents, contentItem];
    },

    buildContentItem({ account, publishVariant, ...rest }) {
      const isSocial = this.type === 'blog' && publishVariant === 'social';
      const isSecondary = this.type === 'blog' && publishVariant === 'secondary';

      const title = this.selectedContent.title || '';
      const content = isSocial ? '{PrimaryBlogTitle} - {PrimaryBlogLink}' : this.selectedContent.body;
      const postLink = isSecondary ? '{PrimaryBlogLink}' : this.selectedContent.postLink || '';

      const attachments = this.selectedImages.map(image => ({
        ...image,
        id: 0,
        saved: false,
      }));

      return {
        accountId: account.id,
        content,
        title,
        postLink,
        attachments,
        publishVariant,
        ...rest,
      };
    },

    dropPostContent(account, index) {
      this.$store.dispatch('validation/dropError', account.id);
      this.$store.dispatch('validation/dropWarning', account.id);

      return [...this.accountPostContents.slice(0, index), ...this.accountPostContents.slice(index + 1)];
    },

    savePost() {
      return this.$store
        .dispatch('createPost/savePost', {
          workspace: this.workspace.id,
          id: this.id,
          type: this.type,
          status: this.postStatus,
          publishNow: true,
        })
        .then(saved => {
          this.$emit('saved', saved);
        })
        .catch(() => {
          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Something went wrong while processing your request. Please try again.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.error-tip {
  .tooltip-inner {
    text-align: left;
  }
}

.scheduler {
  width: 70%;
}

.post-composer {
  padding-top: $topbar-height;
  height: 100%;

  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1031;
    background: $light;

    .topbar.top {
      top: 0;
    }

    .fabs.editor-fabs {
      top: 95px;
    }
  }
}

.single-posts-content-wrap,
.customize-wrap {
  margin: 20px auto;
}

.customize-wrap {
  min-height: 100vh;
}

.schedule-label {
  font-size: 0.9rem;
}
</style>
