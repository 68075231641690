<template>
  <div class="payment-methods">
    <div class="bg-white shadow-sm">
      <div v-if="$apollo.queries.paymentMethods.loading" class="text-center p-5">
        <spinner variant="primary" size="4" />
      </div>
      <div v-else-if="!$apollo.queries.paymentMethods.loading && paymentMethods.length < 1" class="text-center p-5">
        <img class="my-4" width="250" src="@/assets/images/credit_card_payment.svg" alt="" />

        <h5>You have not added any payment method yet.</h5>
        <b-button v-b-modal.AddPaymentMethodModal variant="primary" class="px-4 mt-4 shadow">
          Add New Card
        </b-button>
      </div>
      <div v-else class="p-3">
        <h5 class="payment-method-heading">
          My Payment Methods
          <b-button v-b-modal.AddPaymentMethodModal variant="outline-dark" class="px-4">Add New Card</b-button>
        </h5>
        <b-table borderless :items="paymentMethods" :fields="fields">
          <template v-slot:cell(method)="data">
            <strong>{{ capitalize(data.item.brand) }}</strong> ending in <strong>****{{ data.item.last4 }}</strong>
          </template>
          <template v-slot:cell(expires)="data">
            <div class="text-muted">{{ pad(data.item.expMonth) }}/{{ data.item.expYear }}</div>
          </template>
          <template v-slot:cell(default)="data">
            <b-form-radio
              v-model="defaultPaymentMethod"
              :value="data.item.id"
              name="default"
              size="lg"
              @change="changeDefaultPaymentMethod"
            >
            </b-form-radio>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              size="sm"
              variant="outline-dark"
              class="px-4"
              :disabled="data.item.id === defaultPaymentMethod"
              @click="showDeleteModal(data.item)"
            >
              <svg-icon name="trash" />
              Delete
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal
      id="DeletePaymentMethodModal"
      content-class="rounded-sm"
      body-class="text-center p-5"
      hide-header
      hide-footer
      centered
      @hide="onHideDeletePaymentMethodModal"
    >
      <template v-slot:default="{ hide }">
        <div v-if="deleting" class="p-5">
          <spinner variant="primary" size="5"></spinner>
          <div class="font-weight-bold mt-2">Working on it...</div>
        </div>
        <template v-else>
          <h4 class="font-weight-bold">Delete Payment Method?</h4>
          <p>Are you sure you want to delete this payment method?</p>

          <div class="mt-4">
            <b-button variant="clear" class="px-4 mr-2" @click="hide()">Cancel</b-button>
            <b-button variant="primary" class="px-4" @click="deletePaymentMethod">Delete</b-button>
          </div>
        </template>
      </template>
    </b-modal>

    <b-modal id="AddPaymentMethodModal" content-class="rounded-sm" body-class="p-5" hide-header hide-footer centered>
      <template v-slot:default="{ hide }">
        <StripeElement element-selector="#card-element">
          <template v-slot:default="{ loading, focused, createPaymentMethod, errorMessage }">
            <h4 class="font-weight-bold mb-3">Add New Card</h4>
            <b-form-group>
              <b-form-input
                v-model="nameOnCard"
                :state="nameOnCardError"
                class="card-name-input shadow-sm"
                placeholder="Name on card"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div v-if="loading" class="card-input-wrap text-center shadow-sm p-2">
              <b-spinner variant="primary" small />
            </div>
            <div v-show="!loading">
              <div id="card-element" class="card-input-wrap shadow-sm p-3" :class="{ focus: focused }"></div>
              <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            </div>

            <div class="text-right mt-4">
              <b-button variant="clear" class="px-4 mr-2" @click="hide()">Cancel</b-button>
              <b-button variant="primary" class="px-4" :disabled="savingCard" @click="saveNewCard(createPaymentMethod)">
                <b-spinner v-if="savingCard" small />
                Save Card
              </b-button>
            </div>
          </template>
        </StripeElement>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { debounce, capitalize } from 'lodash';
import StripeElement from '~/components/StripeElement';
import { PAYMENT_METHODS_QUERY } from '~/graphql/queries';
import {
  ADD_PAYMENT_METHOD_MUTATION,
  DELETE_PAYMENT_METHOD_MUTATION,
  UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION,
} from '~/graphql/mutations';

export default {
  components: { StripeElement },

  data() {
    return {
      nameOnCard: '',
      nameOnCardError: null,
      savingCard: false,

      paymentMethods: [],
      fields: [
        {
          key: 'method',
          label: 'Payment method',
        },
        {
          key: 'expires',
        },
        {
          key: 'default',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      deleting: false,
      envokedForDelete: null,
      defaultPaymentMethod: '',
    };
  },

  apollo: {
    paymentMethods: {
      query: PAYMENT_METHODS_QUERY,
      context: {
        uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
      },
      update(data) {
        const paymentMethod = data.paymentMethods.find(p => p.default === true);
        if (paymentMethod) {
          this.defaultPaymentMethod = paymentMethod.id;
        }

        return data.paymentMethods;
      },
    },
  },

  methods: {
    pad(num) {
      const padding = '0';
      num = num + '';
      return num.length >= 2 ? num : new Array(2 - num.length + 1).join(padding) + num;
    },

    async saveNewCard(createPaymentMethod) {
      if (!this.nameOnCard) {
        this.nameOnCardError = false;

        return;
      }

      this.savingCard = true;
      const paymentMethod = await createPaymentMethod({ name: this.nameOnCard });

      if (!paymentMethod) {
        this.savingCard = false;
        return;
      }

      this.addPaymentMethod(paymentMethod)
        .then(({ data }) => {
          this.savingCard = false;
          this.nameOnCard = '';

          if (data.addPaymentMethod) {
            Swal.fire({
              type: 'success',
              title: 'Success!',
              text: 'Your card has been added successfully.',
            });

            this.$bvModal.hide('AddPaymentMethodModal');
          }
        })
        .catch(() => {
          this.savingCard = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Unable to add Card. Please check the card details or try another card.',
            confirmButtonText: 'Okay',
          });
        });
    },

    addPaymentMethod(paymentMethod) {
      return this.$apollo.mutate({
        mutation: ADD_PAYMENT_METHOD_MUTATION,
        variables: {
          id: paymentMethod.id,
        },
        update: (store, { data: { addPaymentMethod } }) => {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: PAYMENT_METHODS_QUERY,
          });
          if (addPaymentMethod) {
            data.paymentMethods.unshift(addPaymentMethod);
          }
          // Write our data back to the cache.
          store.writeQuery({
            query: PAYMENT_METHODS_QUERY,
            data,
          });
        },
      });
    },

    // We use debounce to limit unnecessary calls to the server
    // for example when you toggle status on and immediately toggle it off again
    changeDefaultPaymentMethod: debounce(function(id) {
      this.$apollo
        .mutate({
          mutation: UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION,
          variables: { id },
          update: (store, { data: { updated } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PAYMENT_METHODS_QUERY,
            });
            if (updated) {
              data.paymentMethods.forEach((pMethod, index) => {
                data.paymentMethods.splice(index, 1, {
                  ...pMethod,
                  default: pMethod.id === id,
                });
              });
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: PAYMENT_METHODS_QUERY,
              data,
            });
          },
        })
        .then(() => {
          // Then
        })
        .catch(() => {
          // Catch
        });
    }, 1000),

    showDeleteModal(paymentMethod) {
      this.envokedForDelete = paymentMethod;
      this.$bvModal.show('DeletePaymentMethodModal');
    },

    onHideDeletePaymentMethodModal() {
      this.envokedForDelete = null;
    },

    deletePaymentMethod() {
      this.deleting = true;

      this.$apollo
        .mutate({
          mutation: DELETE_PAYMENT_METHOD_MUTATION,
          variables: {
            id: this.envokedForDelete.id,
          },
          update: (store, { data: { deleted } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PAYMENT_METHODS_QUERY,
            });
            if (deleted) {
              const index = data.paymentMethods.findIndex(a => this.envokedForDelete.id === a.id);

              if (index !== -1) {
                data.paymentMethods.splice(index, 1);
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: PAYMENT_METHODS_QUERY,
              data,
            });
          },
        })
        .then(({ data: { deleted } }) => {
          this.deleting = false;
          this.$bvModal.hide('DeletePaymentMethodModal');

          if (deleted) {
            Swal.fire({
              type: 'success',
              title: 'Done!',
              text: 'Payment method deleted successfully.',
              confirmButtonText: 'Okay',
            });
          } else {
            this.alertError();
          }
        })
        .catch(() => {
          this.deleting = false;
          this.$bvModal.hide('DeletePaymentMethodModal');

          this.alertError();
        });
    },

    alertError() {
      Swal.fire({
        type: 'error',
        title: 'OOPS!',
        text: 'Could not delete payment method. Try again later.',
        confirmButtonText: 'Okay',
      });
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.payment-method-heading {
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  border-bottom: 1px solid $gray-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
