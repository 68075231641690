<template>
  <div class="facebook-report-entry">
    <div class="row header">
      <AccountsListComponent v-model="selectedAccountIds" />
      <div class="actions">
        <b-button class="action other-action border" variant="outline-clear">
          <svg-icon name="analytics.sync" />
          <div class="text">sync</div>
        </b-button>
        <b-button class="action date-range border" variant="outline-secondary">
          <svg-icon name="analytics.calendar" />
          <div class="text">Last 30 days</div>
        </b-button>
        <b-dropdown variant="secondary" right class="action export">
          <template #button-content>
            <div class="d-inline-flex align-center m-0 p-0">
              <div class="text"><svg-icon name="analytics.export" class="" /> export</div>
            </div>
          </template>
          <b-dropdown-item href="#">Export as PDF</b-dropdown-item>
          <b-dropdown-item href="#">Send PDF</b-dropdown-item>
          <b-dropdown-item @click="handleShowScheduleModal(schedule, 'create')">Schedule</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- performances view -->
    <Performance :performances="performances" cols="4" />

    <!-- Audience Growth -->
    <BarChart :chart-data="getData('facebookReportData.reports.fans', {})" contentTitle="Audience Growth" />
    <!-- Published Post With Engagement -->
    <BarChart
      :chart-data="getData('facebookReportData.reports.engagement', {})"
      contentTitle="Published Post With Engagement"
    />
    <!-- Post Rate -->
    <BarChart :chart-data="getData('facebookReportData.reports.postRate', {})" contentTitle="Post Rate" />
    <!-- Post Type -->
    <DoughnutChart :chart-data="getData('facebookReportData.reports.postType', {})" contentTitle="Post Type" />
    <!-- Post Density - Daily -->
    <BarChart :chart-data="getData('facebookReportData.reports.postDensity', {})" contentTitle="Post Density - Daily" />
    <!-- TODO: Fans Age Here if need be -->
    <!-- Post Impression by Day -->
    <BarChart
      :chart-data="getData('facebookReportData.reports.postDailyImpression', {})"
      contentTitle="Post Impression by Day"
    />
    <!-- Impression Breakdown By Day -->
    <DoughnutChart
      :chart-data="getData('facebookReportData.reports.postImpressions', {})"
      contentTitle="Impression Breakdown By Day"
    />
    <!-- TODO: Video Analytics Here if need be -->
    <!-- Active User by Hours & Day -->
    <div class="d-flex justify-content-between align-center">
      <div class="w-100 mr-1">
        <BarChart
          :chart-data="getData('facebookReportData.reports.activeUser.hourly', {})"
          contentTitle="Active User By Hours"
        />
      </div>
      <div class="w-100 ml-1">
        <BarChart
          :chart-data="getData('facebookReportData.reports.activeUser.daily', {})"
          contentTitle="Active User By Day"
        />
      </div>
    </div>
    <!-- Engagement Rate -->
    <BarChart :chart-data="getData('facebookReportData.reports.postEngagement', {})" contentTitle="Engagemenet Rate" />
    <!-- Activities Breakdown By Day -->
    <DoughnutChart
      :chart-data="getData('facebookReportData.reports.postActvities', {})"
      contentTitle="Activities Breakdown By Day"
    />
    <!-- Engagement by Day/Time -->

    <!-- Schedule Modal -->
    <ScheduleOptions
      v-if="showScheduleModal && scheduleAction"
      :schedule="activeSchedule"
      :action="scheduleAction"
      :show="showScheduleModal"
      @close-schedule-modal="handleHideScheduleModal"
    />
    <!-- Schedule Modal -->
  </div>
</template>

<script>
import AccountsListComponent from '~/components/Reports/partials/AccountsList.vue';
import Performance from '~/components/Reports/partials/Performance.vue';
import BarChart from '~/components/Reports/partials/BarChart.vue';
import DoughnutChart from '~/components/Reports/partials/DoughnutChart.vue';
import { get } from 'lodash';
import ScheduleOptions from '~/components/Reports/Schedule.vue';

export default {
  name: 'FacebookReport',

  components: {
    AccountsListComponent,
    Performance,
    BarChart,
    DoughnutChart,
    ScheduleOptions,
  },

  data() {
    return {
      selectedAccountIds: [],
      scheduleAction: null,
      showScheduleModal: false,
      activeSchedule: null,
      schedule: {
        id: 1,
        name: '',
        interval: 'weekly',
        type: 'group',
        accounts: [
          {
            id: 1,
            type: 'facebook',
            avatar: '',
            name: 'facebook',
            user: {
              name: 'Jeremiah Iro',
            },
          },
        ],
      },
      facebookReportData: {
        reports: {
          total: {
            fans: 2,
            impressions: 2,
            engagement: 2,
            reactions: 2,
            clicks: 2,
            feedback: 2,
            sentiment: 2,
            post: 2,
          },
          fans: {
            labels: [
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
              '10 Jan',
            ],
            data: [
              {
                Fans: ['5', '9', '8', '15', '19', '18', '25', '29', '28', '3', '6', '9'],
              },
            ],
          },
          engagement: {
            labels: ['10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan'],
            data: [
              {
                Comments: ['5', '9', '8', '15', '19', '18', '25', '29', '28', '3', '6', '9'],
              },
              {
                Reactions: ['4', '7', '0', '11', '12', '14', '23', '19', '18', '13', '16', '19'],
              },
            ],
          },
          postRate: {
            labels: ['10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan'],
            data: [
              {
                videos: ['29', '28', '3', '6', '9', '19', '18'],
              },
              {
                photos: ['5', '9', '8', '15', '19', '18', '25'],
              },
              {
                links: ['4', '7', '0', '11', '12', '14', '23'],
              },
              {
                text: ['14', '23', '19', '18', '13', '16', '19'],
              },
            ],
          },
          postType: {
            labels: ['videos', 'photos', 'links', 'text'],
            data: {
              videos: 29,
              photos: 12,
              links: 9,
              text: 19,
            },
          },
          postDensity: {
            labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            data: [
              {
                Posts: ['29', '28', '3', '6', '9', '19', '18'],
              },
            ],
          },
          postDailyImpression: {
            labels: ['10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan'],
            data: [
              {
                'Viral Impression': ['29', '28', '3', '6', '9', '19', '18'],
              },
              {
                'Paid Impression': ['5', '9', '8', '15', '19', '18', '25'],
              },
              {
                'Organic Impression': ['4', '7', '0', '11', '12', '14', '23'],
              },
            ],
          },
          postImpressions: {
            labels: ['viral', 'paid', 'organic'],
            data: {
              viral: 29,
              paid: 12,
              organic: 9,
            },
          },
          activeUser: {
            hourly: {
              labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              data: [
                {
                  count: [
                    '23',
                    '19',
                    '18',
                    '13',
                    '16',
                    '19',
                    '4',
                    '7',
                    '0',
                    '11',
                    '12',
                    '14',
                    '13',
                    '16',
                    '19',
                    '4',
                    '7',
                    '0',
                    '11',
                    '12',
                    '14',
                    '23',
                  ],
                },
              ],
            },
            daily: {
              labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              data: [
                {
                  count: ['29', '28', '3', '6', '9', '19', '18'],
                },
              ],
            },
          },
          postEngagement: {
            labels: ['10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan', '10 Jan'],
            data: [
              {
                comments: ['29', '28', '3', '6', '9', '19', '18'],
              },
              {
                reactions: ['9', '1', '36', '6', '9', '1', '1'],
              },
              {
                shares: ['2', '8', '31', '3', '9', '9', '8'],
              },
              {
                clicks: ['21', '2', '13', '5', '9', '11', '12'],
              },
            ],
          },
          postActvities: {
            labels: ['clicks', 'reactions', 'comments', 'shares'],
            data: {
              clicks: 29,
              reactions: 12,
              comments: 9,
              shares: 9,
            },
          },
        },
      },
    };
  },

  computed: {
    getData() {
      return (key, defaultVal) => {
        return get(this, key, defaultVal);
      };
    },
    performances() {
      return [
        {
          name: 'fans',
          title: 'fans',
          icon: 'analytics.fans',
          value: this.getData('facebookReportData.reports.total.fans', 0),
        },
        {
          name: 'impressions',
          title: 'impressions',
          icon: 'analytics.like',
          value: this.getData('facebookReportData.reports.total.impressions', 0),
        },
        {
          name: 'engagement',
          title: 'engagement',
          icon: 'analytics.chat',
          value: this.getData('facebookReportData.reports.total.engagement', 0),
        },
        {
          name: 'reactions',
          title: 'reactions',
          icon: 'analytics.like',
          value: this.getData('facebookReportData.reports.total.reactions', 0),
        },
        {
          name: 'clicks',
          title: 'clicks',
          icon: 'analytics.mailbox',
          value: this.getData('facebookReportData.reports.total.clicks', 0),
        },
        {
          name: 'feedback',
          title: 'feedback',
          icon: 'analytics.chat',
          value: this.getData('facebookReportData.reports.total.feedback', 0),
        },
        {
          name: 'sentiment',
          title: 'sentiment',
          icon: 'analytics.like',
          value: this.getData('facebookReportData.reports.total.sentiment', 0),
        },
        {
          name: 'count',
          title: 'post count',
          icon: 'analytics.chat',
          value: this.getData('facebookReportData.reports.total.post', 0),
        },
      ];
    },
  },
  methods: {
    handleShowScheduleModal(schedule, action) {
      this.activeSchedule = schedule;
      this.scheduleAction = action;
      this.showScheduleModal = true;
    },
    handleHideScheduleModal() {
      this.activeSchedule = null;
      this.scheduleAction = null;
      this.showScheduleModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.facebook-report-entry {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;

  ::v-deep {
    > .row {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 25px;
      border-radius: 10px;
      outline: initial;
      background-color: #ffffff;
      align-items: center;
      justify-content: space-between;

      &:not(:first-child) {
        margin: 20px 0 0 0;
        padding: 40px 25px;
      }

      &.header {
        > .info {
          margin: 0 0 0 auto;
          display: inline-flex;
          flex-shrink: 1;
        }

        > .actions {
          margin: 0 0 0 70px;

          > .action {
            border: none;
            outline: initial;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin: 0;
            padding: 8px;
            font-size: 12px;
            text-transform: capitalize;
            transition: all 0.3s ease-in-out;

            > .icon {
              width: 18px;
              height: 18px;
              fill: #000000;
              stroke: #000000;
            }

            > .text {
              display: inline-flex;
              margin: 0 0 0 10px;
              color: #000000;
            }

            &.other-action {
              border: 1px solid #b6b6b6;
              background-color: #ffffff;
              color: #000000;

              > .icon {
                width: 19px;
                height: 19px;
              }

              &:hover {
                background-color: #e9e9e9;
              }
            }

            &.export {
              padding: 0 !important;
              background-color: #52b788;
              background: linear-gradient(120deg, #52b788 30%, #8dc9ad 90%);
              color: #ffffff;

              > .icon {
                width: 16px;
                height: 16px;
              }

              &:hover {
                background-color: #57bd8d;
                background: linear-gradient(120deg, #57bd8d 30%, #8dc9ad 90%);
              }
            }

            &:not(:first-child) {
              margin: 0 0 0 25px;
            }
          }
        }
      }
    }
  }
}
</style>
