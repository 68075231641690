<template>
  <div class="skeleton-wrap d-flex flex-wrap">
    <div
      v-for="item in items"
      :key="item + 1"
      class="publish-account-skeleton border p-3 rounded-sm"
      :class="{ [itemClass]: itemClass }"
    >
      <skeleton-screen width="50px" height="50px" class="sas mb-3" />
      <skeleton-screen width="80%" height="17px" class="sas" style="margin-bottom: 0.7rem" />
      <skeleton-screen width="60%" height="14px" class="sas" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'publish-accounts-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 8,
    },

    itemClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.skeleton-wrap {
  margin-right: -20px;

  .publish-account-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 206px;
    height: 200px;
    margin-right: 20px;
    margin-bottom: 30px;

    .sas {
      align-self: center;
    }
  }
}
</style>
