<template>
  <div class="content-entry">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div v-if="description" class="description">
        {{ description }}
      </div>
      <slot name="header"></slot>
    </div>
    <div :class="['content', { hasPadding: hasContentPadding }]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticContentPartial',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    hasContentPadding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-entry {
  width: 100%;
  display: inline-flex;
  padding: 25px 0;
  border-radius: 10px;
  outline: initial;
  background-color: #ffffff;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
  align-content: flex-start;

  > .header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0 25px;

    > * {
      width: 100%;
      display: flex;

      &:not(:first-child) {
        margin: 4px 0 0 0;
      }
    }

    > .title {
      font-size: 22px;
      text-transform: capitalize;
      color: #000000;
    }

    > .description {
      font-size: 14px;
      color: #868686;
      text-transform: lowercase;
    }
  }

  > .content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 20px 0 0 0;
    width: 100%;

    &.hasPadding {
      padding: 0 25px;
    }
  }
}
</style>
