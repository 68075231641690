<template>
  <div class="blog-account-chooser mt-4">
    <publish-accounts-skeleton :itemCount="12" v-if="$apollo.queries.publishAccounts.loading" />
    <div v-if="blogAccounts.length && !$apollo.queries.publishAccounts.loading">
      <b-row>
        <b-col cols="3">
          <div class="blog-account-heading">
            Primary Blog
          </div>
        </b-col>
        <b-col>
          <p>
            This should be your main blogging platform, where your post will be published first. You can only select 1
            primary blog.
          </p>
        </b-col>
      </b-row>
      <div class="accounts-wrap mb-4">
        <AddPublishAccount platformType="blog" queryType="all" tag="box" />
        <BlogAccountSelectBox
          v-for="publishAccount in blogAccounts"
          :key="publishAccount.id"
          :publishAccount="publishAccount"
          :selected="primaryBlog.accountId == publishAccount.id"
          :publishStatus="getBlogPublishStatus(publishAccount)"
          :selectedCategories="getBlogCategories(publishAccount)"
          :selectedAuthor="getBlogAuthor(publishAccount)"
          @toggle="togglePrimaryBlog(publishAccount)"
          @statusChange="handleBlogStatusChange($event, publishAccount)"
          @categoriesChange="handleBlogCategoriesChange($event, publishAccount)"
          @authorChange="handleBlogAuthorChange($event, publishAccount)"
        />
      </div>

      <div class="secondary-channel" :class="{ disabled: !primaryBlog.accountId }">
        <b-row>
          <b-col cols="3">
            <div class="blog-account-heading">
              Secondary Channel
            </div>
          </b-col>
          <b-col>
            <p>
              Secondary channels help in spreading your new blog post to multiple channels. Our publishing system takes
              care of all the necessary measures to not affect your SEO such as using rel canonical tag. You can select
              multiple Secondary channels here.
            </p>
          </b-col>
        </b-row>
        <div class="accounts-wrap">
          <AddPublishAccount platformType="blog" queryType="all" tag="box" />
          <BlogAccountSelectBox
            v-for="publishAccount in secondaryBlogAccounts"
            :key="publishAccount.id"
            :publishAccount="publishAccount"
            :selected="secondaryBlogs.map(b => b.accountId).includes(publishAccount.id)"
            :publishStatus="getBlogPublishStatus(publishAccount)"
            :disabled="!primaryBlog.accountId"
            :selectedCategories="getBlogCategories(publishAccount)"
            :selectedAuthor="getBlogAuthor(publishAccount)"
            @toggle="toggleSecBlogSelection(publishAccount)"
            @statusChange="handleBlogStatusChange($event, publishAccount)"
            @categoriesChange="handleBlogCategoriesChange($event, publishAccount)"
            @authorChange="handleBlogAuthorChange($event, publishAccount)"
          />
        </div>
      </div>

      <div class="social-sharing mt-4" :class="{ disabled: !primaryBlog.accountId }">
        <b-row>
          <b-col cols="3">
            <div class="blog-account-heading">
              Social Sharing
            </div>
          </b-col>
          <b-col>
            <p>
              After your blog post is published on your primary blog it will be shared in the social account(s) you
              choose below.
            </p>
          </b-col>
        </b-row>
        <SocialAccountChooser
          :selectedAccounts="selectedAccounts"
          :accounts="sharingAccounts"
          queryType="all"
          :disabled="!primaryBlog.accountId"
          @sync="handelSocialSync"
        />
      </div>
    </div>
    <div v-if="!blogAccounts.length && !$apollo.queries.publishAccounts.loading" class="shadow-sm text-center p-5">
      <img class="my-2" width="250" src="@/assets/images/account.svg" alt="" />
      <h6 class="my-4">You have not connected any blog account yet.</h6>
      <AddPublishAccount platformType="blog" queryType="all" class="px-4" size="sm">
        <svg-icon name="plus" /> Add Account
      </AddPublishAccount>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import AddPublishAccount from '~/components/AddPublishAccount';
import SocialAccountChooser from '~/components/SocialAccountChooser';
import BlogAccountSelectBox from '~/components/BlogAccountSelectBox';
import PublishAccountsSkeleton from '~/components/PublishAccountsSkeleton';

export default {
  components: {
    PublishAccountsSkeleton,
    BlogAccountSelectBox,
    SocialAccountChooser,
    AddPublishAccount,
  },

  props: {
    selectedAccounts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      publishAccounts: [],
    };
  },

  apollo: {
    publishAccounts: {
      query: PUBLISH_ACCOUNTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: 'all',
        };
      },
      result({ data, loading }) {
        if (!loading) {
          this.$store.dispatch('publishAccount/updateAccounts', data.publishAccounts);
        }
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    blogAccounts() {
      return this.publishAccounts.filter(account => account.type.platformType == 'blog');
    },

    secondaryBlogAccounts() {
      return this.blogAccounts.filter(account => this.primaryBlog.accountId !== account.id);
    },

    sharingAccounts() {
      return this.publishAccounts.filter(account => account.type.platformType == 'social');
    },

    primaryBlog() {
      return this.selectedAccounts.find(con => con.publishVariant == 'primary') || {};
    },

    secondaryBlogs() {
      return this.selectedAccounts.filter(con => con.publishVariant == 'secondary');
    },
  },

  methods: {
    togglePrimaryBlog(account) {
      this.$emit('sync', {
        account,
        publishVariant: 'primary',
        platform: account.type.platform,
        publishStatus: account.type.publishStatuses[0].value,
      });
    },

    toggleSecBlogSelection(account) {
      this.$emit('sync', {
        account,
        publishVariant: 'secondary',
        platform: account.type.platform,
        publishStatus: account.type.publishStatuses[0].value,
      });
    },

    getBlogAuthor(account) {
      const select = this.selectedAccounts.find(con => con.accountId == account.id);

      return select ? select.publishAuthorId : undefined;
    },

    getBlogCategories(account) {
      const select = this.selectedAccounts.find(con => con.accountId == account.id);

      return select && select.publishCategoryIds ? select.publishCategoryIds : undefined;
    },

    getBlogPublishStatus(account) {
      const select = this.selectedAccounts.find(con => con.accountId == account.id);

      return select ? select.publishStatus : undefined;
    },

    handleBlogStatusChange(publishStatus, account) {
      const index = this.selectedAccounts.findIndex(con => con.accountId === account.id);
      if (index !== -1) {
        this.$emit('update', [
          ...this.selectedAccounts.slice(0, index),
          {
            ...this.selectedAccounts[index],
            publishStatus,
          },
          ...this.selectedAccounts.slice(index + 1),
        ]);
      }
    },

    handleBlogCategoriesChange(ids, account) {
      const index = this.selectedAccounts.findIndex(con => con.accountId === account.id);

      if (index !== -1) {
        this.$emit('update', [
          ...this.selectedAccounts.slice(0, index),
          { ...this.selectedAccounts[index], publishCategoryIds: ids },
          ...this.selectedAccounts.slice(index + 1),
        ]);
      }
    },

    handleBlogAuthorChange(id, account) {
      const index = this.selectedAccounts.findIndex(con => con.accountId === account.id);
      if (index !== -1) {
        this.$emit('update', [
          ...this.selectedAccounts.slice(0, index),
          { ...this.selectedAccounts[index], publishAuthorId: id },
          ...this.selectedAccounts.slice(index + 1),
        ]);
      }
    },

    handelSocialSync(sync) {
      this.$emit('sync', sync);
    },
  },
};
</script>

<style lang="scss">
.blog-account-chooser {
  .skeleton-wrap {
    .publish-account-skeleton {
      width: 250px;
      height: 270px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  .blog-account-heading {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    background: #d8f3dc;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #1b4332;
  }

  .add-publish-account,
  .publish-account-item {
    width: 145px;
  }

  .accounts-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
  }

  .secondary-channel,
  .social-sharing {
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
</style>
