<template>
  <div class="blog-account-box" :class="{ active: selected, disabled }">
    <div class="blog-account-box-inner">
      <div class="blog-account-box-front" @click="handleToggle">
        <img
          width="50"
          height="50"
          class="blog-account-box-img rounded-circle shadow-sm mb-2"
          :src="publishAccount.imageUrl"
          alt=""
        />
        <div class="account-name">{{ publishAccount.name }}</div>
        <div class="account-type">{{ publishAccount.type.displayName }}</div>
      </div>
      <div class="blog-account-box-back">
        <div class="d-flex align-items-center">
          <img
            height="40"
            width="40"
            class="blog-account-box-img rounded-circle shadow-sm mr-2"
            :src="publishAccount.imageUrl"
            alt=""
          />
          <div class="d-flex flex-column">
            <div class="account-name mt-0">{{ publishAccount.name }}</div>
            <div class="account-type mt-0 mw-100">{{ publishAccount.type.displayName }}</div>
          </div>
        </div>

        <template v-if="publishAccount.type.platform == 'wordpress'">
          <div v-if="fetchingDetails" class="loading">
            <skeleton-screen width="100%" height="30px" class="my-4 rounded-sm" />
            <skeleton-screen width="100%" height="30px" class="my-3 rounded-sm" />
          </div>
          <template v-else>
            <b-form-group label-size="sm" label="Select author" class="mt-2">
              <b-form-select :value="selectedAuthor" size="sm" @change="handleAuthorChange">
                <option :value="0">Select author</option>
                <option v-for="author in authors" :key="author.id" :value="author.id">{{ author.name }}</option>
              </b-form-select>
            </b-form-group>

            <b-form-group label-size="sm" label="Select category(s)" class="mt-2">
              <Checkselect
                placeholder="Categories"
                :selected="selectedCategories"
                :options="categories"
                @change="handleCategoriesChange"
              />
            </b-form-group>
          </template>
        </template>

        <b-form-group
          label-size="sm"
          label="Publish status"
          class="mt-2"
          :class="{ 'mt-3': publishAccount.type.platform != 'wordpress' }"
        >
          <b-form-select :value="publishStatus" size="sm" @change="handlePublishStatusChange">
            <option value="">Select status</option>
            <option v-for="status in publishAccount.type.publishStatuses" :key="status.value" :value="status.value">
              {{ status.text }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-button @click="handleToggle" variant="outline-danger" class="blog-account-box-deselect px-2" size="sm">
          Deselect
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Checkselect from '~/components/Checkselect';
import SkeletonScreen from '~/components/SkeletonScreen';
import { FETCH_WORDPRESS_AUTHORS_AND_CATEGORIES_QUERY } from '~/graphql/queries';

export default {
  components: { Checkselect, SkeletonScreen },

  props: {
    publishAccount: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    publishStatus: {
      type: String,
      default: '',
    },

    selectedAuthor: {
      type: Number,
      default: 0,
    },

    selectedCategories: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetchingDetails: false,
    };
  },

  computed: {
    accountData() {
      const data = this.$store.getters['publishAccount/accountData'](this.publishAccount.id);
      return data ? data : {};
    },

    authors() {
      return this.accountData.authors ? this.accountData.authors : [];
    },

    categories() {
      return this.accountData.categories ? this.accountData.categories : [];
    },
  },

  methods: {
    handleToggle() {
      if (!this.selected && this.publishAccount.type.platform === 'wordpress') {
        this.fetchingDetails = true;

        this.$apollo
          .query({
            query: FETCH_WORDPRESS_AUTHORS_AND_CATEGORIES_QUERY,
            variables: {
              id: this.publishAccount.id,
            },
          })
          .then(({ data }) => {
            this.fetchingDetails = false;

            this.$store.dispatch('publishAccount/updateAccountData', {
              id: this.publishAccount.id,
              data: {
                authors: data.wordpressAuthorsAndCategories.authors,
                categories: data.wordpressAuthorsAndCategories.categories,
              },
            });
          })
          .catch(() => {
            this.fetchingDetails = false;
          });
      }
      this.$emit('toggle');
    },

    handlePublishStatusChange(status) {
      this.$emit('statusChange', status);
    },

    handleCategoriesChange(ids) {
      this.$emit('categoriesChange', ids);
    },

    handleAuthorChange(id) {
      this.$emit('authorChange', id);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.blog-account-box {
  margin-right: 15px;
  margin-bottom: 15px;
  width: 250px;
  height: 350px;
  position: relative;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .blog-account-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    transform-style: preserve-3d;

    .blog-account-box-front,
    .blog-account-box-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      border: 1px solid $gray-200;
      border-radius: 0.5rem;
      box-shadow: 0 1px 5px $gray-200;
      transition: transform 0.3s;
    }

    .blog-account-box-front {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .blog-account-box-back {
      transform: rotateY(180deg);
      padding: 20px;

      .blog-account-box-deselect {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 0.1rem 0.25rem;
        opacity: 0;
      }
    }

    &:hover {
      .blog-account-box-front {
        cursor: pointer;
        border-color: $primary;
      }

      .blog-account-box-back {
        .blog-account-box-deselect {
          opacity: 1;
        }
      }
    }

    .blog-account-box-img {
      padding: 3px;
    }

    .account-name {
      margin-top: 10px;
      font-weight: bold;
      font-size: 17px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .account-type {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-500;
      margin-top: 3px;
      font-size: 17px;
    }
  }

  &.active .blog-account-box-inner {
    transform: rotateY(180deg);

    .blog-account-box-back {
      border-color: $primary;
      border-width: 2px;

      &::after {
        content: '';
        border-right: 20px solid $primary;
        border-left: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-top: 20px solid $primary;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
      }
      &::before {
        position: absolute;
        top: 2px;
        right: 3px;
        content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7ZmlsbDojZmZmZmZmOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNC41MDIsNzUuNDk2Yy05Ljk5Ny05Ljk5OC0yNi4yMDUtOS45OTgtMzYuMjA0LDBMMTYxLjU5NCwzODIuMjAzTDQzLjcwMiwyNjQuMzExYy05Ljk5Ny05Ljk5OC0yNi4yMDUtOS45OTctMzYuMjA0LDAKCQkJYy05Ljk5OCw5Ljk5Ny05Ljk5OCwyNi4yMDUsMCwzNi4yMDNsMTM1Ljk5NCwxMzUuOTkyYzkuOTk0LDkuOTk3LDI2LjIxNCw5Ljk5LDM2LjIwNCwwTDUwNC41MDIsMTExLjcKCQkJQzUxNC41LDEwMS43MDMsNTE0LjQ5OSw4NS40OTQsNTA0LjUwMiw3NS40OTZ6Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
        width: 15px;
        height: 15px;
        z-index: 10;
      }
    }
  }
}
</style>
