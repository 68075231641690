<template>
  <div class="account-settings">
    <div>
      <WorkspaceHeader />
      <div class="account-settings__page-wrap">
        <b-row no-gutters v-if="!isLeadsPage">
          <AccountSettingsSidebar />
          <b-col md="10">
            <div class="p-4">
              <router-view></router-view>
            </div>
          </b-col>
        </b-row>
        <div v-else>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingsSidebar from '~/components/Settings/AccountSettingsSidebar';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';

export default {
  components: {
    AccountSettingsSidebar,
    WorkspaceHeader,
  },
  computed: {
    isLeadsPage() {
      return this.$route.name === 'settings.leads';
    },
  },
};
</script>
<style lang="scss">
@import '~@/scss/variables';
.account-settings {
  // background: $gray-200;
  &__page-wrap {
    padding-top: ($topbar-height * 1.5);
  }
}
</style>
