import { isEmpty } from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';

const EditPostContentMixin = {
  data() {
    return {
      activeAccount: null,
    };
  },

  computed: {
    ...mapGetters({
      hasError: 'validation/hasError',
    }),

    ...mapState('validation', {
      errors: state => state.errors,
    }),

    ...mapState('publishAccount', {
      publishAccounts: state => state.accounts,
    }),

    ...mapState('createPost', {
      accountPostContents: state => state.accountPostContents,
      selectedImages: state => state.selectedImages,
      featuredImageUrl: state => state.featuredImageLink,
    }),

    chosenAccounts() {
      return this.accountPostContents.map(con => this.publishAccounts.find(a => a.id === con.accountId));
    },

    activeContent: {
      get() {
        const activeContent = this.accountPostContents.find(content => content.accountId === this.activeAccount.id);
        return activeContent || null;
      },
      set(value) {
        const key = Object.keys(value)[0];
        const index = this.accountPostContents.findIndex(con => con.accountId === this.activeAccount.id);
        this.accountPostContents[index][key] = value[key];
      },
    },

    activePublishVariant() {
      return this.activeContent && this.activeContent.publishVariant ? this.activeContent.publishVariant : '';
    },

    activeAccountPostLink: {
      get() {
        return this.activeContent && this.activeContent.postLink ? this.activeContent.postLink : '';
      },
      set(postLink) {
        this.activeContent = { postLink };
      },
    },

    activeAccountTitle: {
      get() {
        return this.activeContent && this.activeContent.title ? this.activeContent.title : '';
      },
      set(title) {
        this.activeContent = { title };
      },
    },

    activeAccountContent: {
      get() {
        return this.activeContent && this.activeContent.content ? this.activeContent.content : '';
      },
      set(content) {
        this.activeContent = { content };
      },
    },

    activeAccountImages: {
      get() {
        return this.activeContent ? this.activeContent.attachments : [];
      },
      set(attachments) {
        this.activeContent = { attachments };
      },
    },

    activeAccountCharLimit() {
      const rules = !isEmpty(this.activeAccount) ? this.activeAccount.type.rules.content : false;
      if (rules) {
        const ruleParts = rules.split('|');
        const index = ruleParts.findIndex(value => /^max:/.test(value));

        if (index !== -1) {
          const rule = ruleParts[index].split(':');
          return Number(rule[1]);
        }
      }
      return 0;
    },

    supportsTitle() {
      return this.activeAccount.type.platform == 'wordpress' || this.activeAccount.type.platform == 'medium';
    },

    supportsPostLink() {
      return (
        this.activeAccount.type.platform == 'pinterest' ||
        this.activeAccount.type.platform == 'wordpress' ||
        this.activeAccount.type.platform == 'medium'
      );
    },
  },

  created() {
    if (!this.activeAccount) {
      this.switchActiveTab(this.chosenAccounts[0]);
    }
  },

  methods: {
    switchActiveTab(account) {
      this.activeAccount = account;
    },

    activeContentChange() {
      const account = this.chosenAccounts.find(acct => acct.id == this.activeAccount.id);
      account && this.$store.dispatch('validation/validate', { account, postContent: this.activeContent });
    },

    ...mapActions({
      updateFeaturedImageLink: 'createPost/updateFeaturedImageLink',
    }),
  },
};

export default EditPostContentMixin;
