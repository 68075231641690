import { Mark } from 'tiptap';
import { removeMark, updateMark } from 'tiptap-commands';

import ViewComponent from './View.vue';

export default class ProsodyMark extends Mark {
  get name() {
    return 'prosody';
  }

  get schema() {
    return {
      attrs: {
        volume: {
          default: 'default',
        },
        rate: {
          default: null,
        },
      },
      inclusive: false,
      excludes: '_',
      parseDOM: [
        {
          tag: 'prosody',
          getAttrs: dom => ({
            volume: dom.getAttribute('volume'),
            rate: dom.getAttribute('rate'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'prosody',
          {
            volume: node.attrs['volume'],
            rate: node.attrs['rate'],
          },
          0,
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type }) {
    return attrs => {
      if (attrs['volume'] || attrs['rate']) {
        return updateMark(type, attrs);
      }

      return removeMark(type);
    };
  }
}
