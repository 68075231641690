<template>
  <div class="post-type">
    <Content class="chart" :title="contentTitle">
      <div class="data">
        <div>
          <div v-for="(keyStr, index) in chartData.labels" :key="keyStr" class="stats">
            <div class="stat-data">
              <span class="marker" :style="{ backgroundColor: getColor(index) }"></span>
              <p class="item">{{ keyStr }}</p>
            </div>
            <div>
              <span class="count">{{ values[index] }}</span>
            </div>
          </div>
        </div>
        <div class="doughnut-chart">
          <canvas ref="chart" class="sdf"></canvas>
        </div>
      </div>
    </Content>
    <Content class="summary" title="">
      <div class="total_post">
        <div class="total_title">
          Total Post
        </div>
        <div class="total_count">
          <div class="count_wrapper">
            <div class="count">{{ totalValue }}</div>
          </div>
          <div class="count_percentage">0%</div>
        </div>
        <div class="total_footer">
          Total Post
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
import ChartJs from 'chart.js';
import { fill, reduce, isEqual } from 'lodash';
import Content from '~/components/Reports/partials/Content';

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    contentTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
      colors: ['#FF4C61', '#FFB800', '#93A2F5', '#33D69F', '#3F51B5', '#93A2F5'],
    };
  },
  components: {
    Content,
  },
  computed: {
    type() {
      return this.chartData.type;
    },
    totalValue() {
      return reduce(this.chartData.data, (prev, data) => (isNaN(data) || !data ? 0 : data) + prev, 0);
    },
    values() {
      let values = [];
      for (let key in this.chartData.data) {
        values.push(this.chartData.data[key]);
      }
      return values;
    },
  },

  async mounted() {
    await this.$nextTick();
    this.reloadChartJs();
  },

  beforeDestroy() {
    this.destroyChartJs();
  },

  watch: {
    values: {
      handler(curr, prev) {
        if (this.chartInstance && isEqual(curr, prev)) return;
        this.reloadChartJs();
      },
    },
  },

  methods: {
    getColor(index) {
      let bgColor = '';
      for (let i = 0; i < this.colors.length; i++) {
        if (i === index) bgColor = this.colors[i];
      }
      return bgColor;
    },
    destroyChartJs() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },
    reloadChartJs() {
      const { chart: chartEl } = this.$refs;
      if (!chartEl) return;
      this.destroyChartJs();

      const deliveredData = this.totalValue
        ? {
            labels: this.chartData.labels,
            datasets: [
              {
                data: this.values,
                backgroundColor: this.colors,
                hoverBackgroundColor: this.colors,
                borderWidth: fill(new Array(this.chartData.labels.length), 0),
              },
            ],
          }
        : {
            labels: ['No Data'],
            datasets: [
              {
                data: [100],
                backgroundColor: ['#c4c4c4'],
                hoverBackgroundColor: ['#c4c4c4'],
                borderWidth: [0],
              },
            ],
          };

      const deliveredOpt = {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        animation: {
          animationRotate: true,
          duration: 2000,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: !!this.totalValue,
        },
      };

      this.chartInstance = new ChartJs(chartEl, {
        type: 'doughnut',
        data: deliveredData,
        options: deliveredOpt,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-type {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  margin: 10px auto;

  .chart {
    width: 66%;
    .data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .stats {
        border: 2px solid #e5e5e5;
        border-radius: 10px;
        padding: 2px 14px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px;
        font-size: 14px;
        width: 100%;
        .stat-data {
          display: inline-flex;
          align-items: center;
          align-content: center;
          justify-content: center;
        }
        .marker {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          border-radius: 100%;
          &.videos,
          &.viral,
          &.retweet {
            background: #ff4c61;
          }
          &.paid,
          &.photos,
          &.likes {
            background: #ffb800;
          }
          &.organic,
          &.links {
            background: #93a2f5;
          }
          &.text {
            background: #33d69f;
          }
        }
        .item {
          text-transform: capitalize;
          margin-top: 14px;
        }
      }
      .sdf {
        width: 400px !important;
      }
    }
  }

  .summary {
    width: 32%;
    .total_post {
      width: 100%;
      text-align: center;
      color: #000000;
      .count_percentage,
      .total_title {
        font-size: 22px;
        text-transform: capitalize;
        margin: 4px auto;
      }
      .total_count {
        padding: 20px;
        margin: 10px;
        border: 2px solid #e5e5e5;
        border-radius: 10px;
        text-align: center;
        .count_wrapper {
          display: flex;
          align-content: center;
          justify-content: center;
          .count {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: #d8e2ff;
            text-align: center;
            line-height: 80px;
            vertical-align: middle;
            color: #3f51b5;
            font-size: 40px;
            font-weight: bold;
            text-transform: capitalize;
          }
        }
      }
      .total_footer {
        font-size: 12px;
        color: #404040;
      }
    }
  }
}
</style>
