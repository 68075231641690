<template>
  <div
    class="social-account-box"
    :class="{ active: selected, disabled: disabled || !publishAccount.type.isPublishable }"
    @click="handleToggle"
  >
    <b-avatar class="avatar" size="4.5rem" />

    <div class="account-name">{{ publishAccount.name }}</div>
    <div class="account-type">{{ publishAccount.type.displayName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    publishAccount: {
      type: Object,
      required: false,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleToggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.social-account-box {
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid $gray-200;
  border-radius: 0.5rem;
  width: 160px;
  height: 150px;
  position: relative;
  user-select: none;
  box-shadow: 0 1px 5px $gray-200;
  background: $secondary;
  color: $primary;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    border-color: $primary;
  }

  .social-account-box-img {
    padding: 3px;
  }

  .account-name {
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-type {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
    font-weight: lighter;
    font-size: 14px;
  }

  &.active {
    border-color: $primary;
    border-width: 2px;

    &::after {
      content: '';
      border-right: 15px solid $primary;
      border-left: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-top: 15px solid $primary;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
    }
    &::before {
      position: absolute;
      top: -3px;
      right: 2px;
      content: url('~@/assets/images/check.svg');
      width: 10px;
      height: 10px;
      z-index: 10;
    }
  }
}
</style>
