<template>
  <b-container class="position-relative">
    <EditorFabs
      v-if="isUserAdminInWorkspace() || isUserCollaboratorInWorkspace()"
      allowVideos
      @insertUpload="handleAddUpload"
      @insertVideoLink="handleVideoLiinkSelect"
      @insertMeme="handleMemeSelect"
    />

    <b-row class="mt-4">
      <b-col md="8" offset-md="2">
        <div class="social-composer-wrap bg-white">
          <PostEditor
            :value="selectedContent ? selectedContent.body : ''"
            :selectedImages="selectedImages"
            @input="updateContent"
            @imageSelect="handleImageSelect"
            @suggestedImageSelect="handleSuggestedImageSelect"
            @videoLinkSelect="handleVideoLiinkSelect"
            @uploadSelect="handleUploadSelect"
            @gifSelect="handleGifSelect"
            @memeSelect="handleMemeSelect"
            @quoteSelect="handleQuoteSelect"
            @articleSelect="handleArticleSelect"
            @removeSelectedImage="handleRemoveSelectedImage"
          ></PostEditor>
        </div>
      </b-col>
    </b-row>

    <EditorRightFabs :post-id="postId" />
  </b-container>
</template>
<style lang="scss" scoped>
.social-composer-wrap {
  border-radius: 1rem;
  overflow: hidden;
}
</style>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import EditorFabs from '~/components/EditorFabs';
import EditorRightFabs from '~/components/EditorRightFabs';
import PostEditor from '~/components/PostEditor';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  props: {
    postId: {
      type: Number,
      required: true,
    },
  },

  components: {
    EditorFabs,
    PostEditor,
    EditorRightFabs,
  },

  computed: {
    ...mapState('createPost', {
      selectedContent: state => state.selectedContent,
      selectedImages: state => state.selectedImages,
    }),
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),
  },

  methods: {
    updateContent(value) {
      this.$store.dispatch('createPost/updateSelectedContent', {
        body: value,
      });
    },

    handleImageSelect(attachment) {
      if (!this.selectedImages.find(img => img == attachment)) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages,
          {
            ...attachment,
            type: 'image',
            platform: attachment.platform,
          },
        ]);
      }

      this.closeFabPanel();
    },

    handleSuggestedImageSelect(attachment) {
      if (!this.selectedImages.find(img => img == attachment)) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages,
          {
            ...attachment,
            type: 'image',
            platform: 'link',
          },
        ]);
      }

      this.closeFabPanel();
    },

    handleUploadSelect(attachment) {
      if (!this.selectedImages.find(img => img == attachment)) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages,
          {
            ...attachment,
            platform: 'self',
          },
        ]);
      }

      this.closeFabPanel();
    },

    handleAddUpload(attachment) {
      if (!this.selectedImages.find(upload => upload == attachment)) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages,
          {
            ...attachment,
            platform: 'self',
          },
        ]);
      }

      this.closeFabPanel();
    },

    handleRemoveSelectedImage(i) {
      if (this.selectedImages[i]) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages.slice(0, i),
          ...this.selectedImages.slice(i + 1),
        ]);
      }
    },

    handleGifSelect(attachment) {
      if (!this.selectedImages.find(img => img == attachment)) {
        this.$store.dispatch('createPost/updateSelectedImages', [
          ...this.selectedImages,
          {
            id: attachment.id,
            thumb: attachment.thumb,
            url: attachment.url,
            type: 'gif',
            platform: 'giphy',
          },
        ]);
      }

      this.closeFabPanel();
    },

    handleMemeSelect(meme) {
      const seperator = this.selectedContent.body ? ' | ' : '';
      const prev = this.selectedContent.body || '';
      this.updateContent(`${prev}${seperator}${meme.title}`);

      const attachments = meme.isAlbum
        ? meme.images.map(img => ({
            url: img.url,
            thumb: img.type === 'video' ? null : img.url,
            type: img.type,
            platform: 'imgur',
          }))
        : {
            url: meme.url,
            thumb: meme.type === 'video' ? null : meme.url,
            type: meme.type,
            platform: 'imgur',
          };

      this.$store.dispatch('createPost/updateSelectedImages', [...this.selectedImages, ...attachments]);

      this.closeFabPanel();
    },

    handleQuoteSelect(quote) {
      this.$store.dispatch('createPost/updateSelectedContent', {
        body: `${quote.body} <br /><br />- ${quote.author}`,
      });

      this.closeFabPanel();
    },

    handleArticleSelect(article) {
      this.$store.dispatch('createPost/updateSelectedImages', []);

      this.$store.dispatch('createPost/updateSelectedContent', {
        body: `${article.title}: <a href="${article.url}">${article.url}</a>`,
        postLink: article.url,
      });

      this.closeFabPanel();
    },

    handleVideoLiinkSelect(video) {
      this.$store.dispatch('createPost/updateSelectedImages', []);

      this.$store.dispatch('createPost/updateSelectedContent', {
        body: `${video.title}: <a href="${video.url}">${video.url}</a>`,
        postLink: video.url,
      });

      this.$store.dispatch('createPost/fetchLinkPreview', video.url);

      this.closeFabPanel();
    },

    closeFabPanel() {
      this.$store.dispatch('postEditor/updateOpenFabPanel', null);
    },
  },
};
</script>
