<template>
  <div class="panel-scroll">
    <div class="panel-body tasks">
      <div class="pie-wrapper">
        <div class="title">
          task score
        </div>
        <div class="pie">
          <canvas ref="chart"></canvas>
          <span class="value">{{ completedTaskPercentage }}%</span>
        </div>
      </div>
      <div class="header">
        <div class="title">
          Task List
        </div>
        <button class="delete" v-if="hasTasks" @click="handleDeleteAllTask">
          Delete All
        </button>
      </div>
      <div class="tasks" v-if="hasTasks || hasNewTask">
        <div class="task" v-for="task in tasks" :key="task.id">
          <span class="check" @click="updateTaskState(task)">
            <svg-icon class="icon" name="check" v-if="task.isComplete" />
          </span>
          <input
            type="input"
            v-model="editing.value"
            v-if="isEditing(task)"
            @blur="updateTaskText(task)"
            class="input"
          />
          <span class="text" v-if="!isEditing(task)">
            {{ task.text }}
          </span>
          <span class="action" v-if="task.canChange">
            <button
              class="edit"
              v-b-tooltip="{ title: 'edit', variant: 'outline-danger' }"
              v-if="!isEditing(task)"
              @click.stop="editTaskText(task)"
            >
              <svg-icon class="icon" name="pencil" />
            </button>
            <button
              class="delete"
              v-b-tooltip="{ title: 'delete', variant: 'outline-danger' }"
              @click.stop="deleteTask(task)"
            >
              <svg-icon class="icon" name="trash" />
            </button>
          </span>
        </div>
        <div class="task" v-if="hasNewTask">
          <span class="check"></span>
          <input type="input" v-model="editing.value" @blur="addTask" class="input" />
          <span class="action">
            <button
              class="delete"
              v-b-tooltip="{ title: 'delete', variant: 'outline-danger' }"
              @click.stop="removeNewTask"
            >
              <svg-icon class="icon" name="trash" />
            </button>
          </span>
        </div>
      </div>
      <div v-else-if="isLoadingTasks" class="text-center loader">
        <EditorFabTasksSkeleton />
      </div>
      <div class="actions">
        <button class="add" @click="addNewTask" :disabled="hasNewTask || isLoadingTasks">
          Add Task
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ChartJs from 'chart.js';
import { capitalize, isArray, round, debounce, isNull, isString, forEach } from 'lodash';
import { numFormat } from '~/utils/helpers';
import { POST_TASKS_WITH_ANALYTICS_QUERY } from '~/graphql/queries';
import {
  ADD_POST_TASK_MUTATION,
  DELETE_POST_TASK_MUTATION,
  EDIT_POST_TASK_MUTATION,
  DELETE_ALL_POST_TASK_MUTATION,
} from '~/graphql/mutations';
import EditorFabTasksSkeleton from './EditorFabTasksSkeleton';

ChartJs.defaults.RoundedDoughnut = ChartJs.helpers.clone(ChartJs.defaults.doughnut);
ChartJs.controllers.RoundedDoughnut = ChartJs.controllers.doughnut.extend({
  draw: function(ease) {
    var ctx = this.chart.ctx;
    var easingDecimal = ease || 1;
    var arcs = this.getMeta().data;
    ChartJs.helpers.each(arcs, function(arc, i) {
      arc.transition(easingDecimal).draw();

      var pArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
      var pColor = pArc._view.backgroundColor;

      var vm = arc._view;
      var radius = (vm.outerRadius + vm.innerRadius) / 2;
      var thickness = (vm.outerRadius - vm.innerRadius) / 2;
      var startAngle = Math.PI - vm.startAngle - Math.PI / 2;
      var angle = Math.PI - vm.endAngle - Math.PI / 2;

      ctx.save();
      ctx.translate(vm.x, vm.y);

      ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
      ctx.beginPath();
      ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);
      ctx.fill();

      ctx.fillStyle = vm.backgroundColor;
      ctx.beginPath();
      ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
      ctx.fill();

      ctx.restore();
    });
  },
});

export default {
  name: 'editor-fabs-tasks-panel',

  props: {
    postId: {
      type: Number || String,
      required: true,
    },
  },

  components: {
    EditorFabTasksSkeleton,
  },

  data() {
    return {
      chartInstance: null,
      tasks: [],
      hasMore: true,
      apiRoot: process.env.VUE_APP_API_ROOT,
      editTaskId: 0,
      totalTask: 0,
      completedTask: 0,
      editing: {
        id: 0,
        value: null,
      },
      hasNewTask: false,
    };
  },

  apollo: {
    tasks: {
      query: POST_TASKS_WITH_ANALYTICS_QUERY,
      variables() {
        return {
          postId: this.postId,
        };
      },
      update({ postTasksWithAnalytics: { postTasks, analytics } }) {
        this.hasMore = !postTasks.length;
        this.completedTask = analytics.complete;
        this.totalTask = analytics.total;
        return postTasks;
      },
    },
  },

  computed: {
    isEditing() {
      return task => {
        if (!task.id) return false;
        return task.id === this.editing.id;
      };
    },
    isLoadingTasks() {
      return this.$apollo.queries.tasks.loading;
    },
    hasTasks() {
      return !this.isLoadingTasks && isArray(this.tasks) && this.tasks.length > 0;
    },
    completedTaskPercentage() {
      if (this.totalTask === 0 || this.completedTask === 0) return 0;
      return round((this.completedTask / this.totalTask) * 100);
    },
  },

  async mounted() {
    await this.$nextTick();
    this.reloadChartJs();
  },

  beforeDestroy() {
    this.destroyChartJs();
    window.Echo.private(`post.${this.postId}.activity`)
      .stopListening('made_new_task')
      .stopListening('deleted_task')
      .stopListening('updated_task_text')
      .stopListening('made_task_complete')
      .stopListening('made_task_incomplete');
  },

  created() {
    this.$apollo.queries.tasks.refetch({
      postId: this.postId,
    });

    window.Echo.private(`post.${this.postId}.activity`)
      .listen('made_new_task', taskData => {
        this.tasks.push(taskData);
      })
      .listen('deleted_task', ({ id }) => {
        this.tasks = this.tasks.filter(({ id: taskId }) => String(taskId) !== String(id));
      })
      .listen('updated_task_text', ({ id, text }) => {
        const task = this.tasks.find(({ id: taskId }) => String(taskId) === String(id));
        task.text = text;
      })
      .listen('made_task_complete', ({ id }) => {
        const task = this.tasks.find(({ id: taskId }) => String(taskId) === String(id));
        task.isComplete = true;
      })
      .listen('made_task_incomplete', ({ id }) => {
        const task = this.tasks.find(({ id: taskId }) => String(taskId) === String(id));
        task.isComplete = false;
      });

    this.addTask = debounce(
      async () => {
        const newTaskText = this.editing.value;
        const isTextNull = isNull(newTaskText);
        const isTextString = isString(newTaskText);

        if (!isTextNull || isTextString) {
          if (isTextString) {
            if (!newTaskText.length) return;
          }

          this.hasNewTask = false;
          this.editing.value = null;

          if (isTextNull) return;
        }

        try {
          await this.$apollo.mutate({
            mutation: ADD_POST_TASK_MUTATION,
            variables: {
              postId: this.postId,
              text: newTaskText,
            },
            refetchQueries: [
              {
                query: POST_TASKS_WITH_ANALYTICS_QUERY,
                variables: {
                  postId: this.postId,
                },
              },
            ],
          });
        } catch ({ graphQLErrors: errors }) {
          this.hasNewTask = true;
          this.editing.value = newTaskText;
          let errorText = '';

          if (isArray(errors)) {
            forEach(errors, error => {
              if (error.message === 'validation') {
                forEach(error.extensions.validation, err => {
                  errorText = err;
                  return false;
                });
              }

              if (error.message === 'ERROR_ADDING_POST_TASK') {
                errorText = 'A server error occurred adding post task';
              }

              return false;
            });
          }

          Swal.fire({
            type: 'error',
            title: 'Error',
            text: errorText || 'An error occurred while saving post',
          });
        }
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    );

    this.updateTaskText = debounce(
      async task => {
        const taskText = this.editing.value;
        const taskId = this.editing.id;
        const isTextNull = isNull(taskText);
        const isTextString = isString(taskText);

        if (!isTextNull || isTextString) {
          if (isTextString) {
            if (!taskText.length) return;
          }

          this.editing.value = null;
          this.editing.id = 0;

          if (isTextNull) return;
        }

        try {
          await this.updateTask({
            ...task,
            text: taskText,
          });
        } catch (e) {
          this.editing.id = taskId;
          this.editing.value = taskText;
        }
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    );
  },

  watch: {
    completedTaskPercentage: {
      handler(curr, prev) {
        if (this.chartInstance && curr === prev) return;
        this.reloadChartJs();
      },
    },
    totalTask: {
      handler(val) {
        this.$emit('update-tasks-count', val);
      },
      immediate: true,
    },
  },

  methods: {
    numFormat,
    capitalize,
    getOffset() {
      return this.tasks.length;
    },

    async deleteAllTask() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ALL_POST_TASK_MUTATION,
          variables: {
            postId: this.postId,
          },
          refetchQueries: [
            {
              query: POST_TASKS_WITH_ANALYTICS_QUERY,
              variables: {
                postId: this.postId,
              },
            },
          ],
        });
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'ERROR_DELETING_ALL_POST_TASK') {
              errorText = 'A server error occurred while deleting all task';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while deleting all task',
        });
      }
    },

    async deleteTask(task) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_POST_TASK_MUTATION,
          variables: {
            postId: this.postId,
            id: task.id,
          },
          refetchQueries: [
            {
              query: POST_TASKS_WITH_ANALYTICS_QUERY,
              variables: {
                postId: this.postId,
              },
            },
          ],
        });
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'ERROR_DELETING_POST_TASK') {
              errorText = 'A server error occurred deleting post task';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while deleting post task',
        });
      }
    },

    updateTask(task) {
      try {
        this.$apollo.mutate({
          mutation: EDIT_POST_TASK_MUTATION,
          variables: {
            postId: this.postId,
            id: task.id,
            data: {
              isComplete: task.isComplete,
              text: task.text,
            },
          },
          refetchQueries: [
            {
              query: POST_TASKS_WITH_ANALYTICS_QUERY,
              variables: {
                postId: this.postId,
              },
            },
          ],
        });
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'ERROR_UPDATING_POST_TASK') {
              errorText = 'A server error occurred updating post task';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while saving post',
        });

        throw new Error('an error occurred');
      }
    },

    async updateTaskState(task) {
      const isComplete = task.isComplete;
      task.isComplete = !isComplete;

      try {
        await this.updateTask(task);
      } catch (e) {
        task.isComplete = !task.isComplete;
      }
    },

    editTaskText(task) {
      this.editing.id = task.id;
      this.editing.value = task.text;
    },

    removeNewTask() {
      this.editing.id = 0;
      this.editing.value = null;
      this.hasNewTask = false;
    },

    destroyChartJs() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },

    reloadChartJs() {
      const { chart: chartEl } = this.$refs;
      if (!chartEl) return;
      this.destroyChartJs();

      const deliveredData = {
        labels: ['Value'],
        datasets: [
          {
            data: [this.completedTaskPercentage, 100 - this.completedTaskPercentage],
            backgroundColor: ['#f9bc39', '#e9e9e9'],
            hoverBackgroundColor: ['#f9bc39', '#e9e9e9'],
            borderWidth: [0, 0],
          },
        ],
      };

      const deliveredOpt = {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        animation: {
          animationRotate: true,
          duration: 2000,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      };

      this.chartInstance = new ChartJs(chartEl, {
        type: 'RoundedDoughnut',
        data: deliveredData,
        options: deliveredOpt,
      });
    },

    addNewTask() {
      this.editing.id = 0;
      this.editing.value = null;
      this.hasNewTask = true;
    },

    async handleDeleteAllTask() {
      const { isConfirmed: deleteAll } = await Swal.fire({
        type: 'info',
        title: 'Continue !!!',
        text: 'Are you sure you want to delete all tasks ?',
        showCancelButton: true,
        confirmButtonText: `Continue`,
      });

      if (deleteAll) {
        return this.deleteAllTask();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
$red: #f47070;
$red-hover: #f33131;
$yellow: #f9bc39;
$yellow-hover: #ca931b;

.panel-body.tasks {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  color: $secondary;

  > * {
    display: flex;
    width: 100%;
  }

  > .pie-wrapper {
    background-color: #fafafa;
    border: 0.5px solid #f0f0f0;
    border-radius: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    padding: 10px;

    > .title {
      display: flex;
      width: 100%;
      color: #c9c9c9;
      font-size: 12px;
      text-transform: capitalize;
    }

    > .pie {
      display: flex;
      width: 180px;
      height: 90px;
      margin: 15px 0 30px 0;
      flex-wrap: wrap;
      position: relative;
      justify-content: center;
      align-items: center;

      > .value {
        background-color: initial;
        display: inline-flex;
        flex-wrap: nowrap;
        color: $yellow;
        font-size: 14px;
        position: absolute;
      }
    }
  }

  > .header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-transform: capitalize;
    margin: 15px 0 0 0;
    padding: 0;

    > .delete {
      color: $red;
      padding: 0;
      margin: 0;
      border: none;
      outline: initial;
      background-color: initial;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $red-hover;
      }
    }
  }

  > .tasks {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 100%;

    > .task {
      display: flex;
      align-items: center;
      margin: 15px 0 0 0;
      width: 100%;

      > .check {
        display: inline-flex;
        width: 25px;
        height: 25px;
        border: 1px solid #dedede;
        border-radius: initial;
        background-color: initial;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        > .icon {
          width: 18px;
          height: 14px;
          color: $secondary;
          fill: $secondary;
        }
      }

      > .text {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 0 10px 0 15px;
        font-size: 14px;
      }

      > .input {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 0 10px 0 15px;
        border: 1px solid #dedede;
        font-size: 14px;
        flex-grow: 1;
      }

      > .action {
        display: none;
        margin: 0 0 0 auto;
        flex-wrap: nowrap;

        > * {
          display: inline-flex;
          margin: 0;
          padding: 0;
          border: none;
          outline: initial;
          background-color: initial;

          > .icon {
            color: $red;
            fill: $red;
            width: 14px;
            height: 14px;
            padding: 0;
            margin: 0;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $red-hover;
              fill: $red-hover;
            }
          }

          &:not(:first-child) {
            margin: 0 0 0 13px;
          }
        }
      }

      &:hover {
        > .action {
          display: inline-flex;
        }
      }
    }
  }

  > .loader {
    width: 100%;
    margin: 20px 0 0 0;
    display: block;
  }

  > .actions {
    display: flex;
    margin: 40px 0 0 0;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;

    > .add {
      display: flex;
      width: 95px;
      height: 35px;
      border-radius: 10px;
      background-color: $yellow;
      text-transform: capitalize;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      border: 1px solid #dedede;
      outline: initial;
      transition: all 0.3s ease-in-out;

      &:not(:disabled):hover {
        background-color: $yellow-hover;
      }

      &:disabled {
        pointer-events: none;
        background-color: rgba(100, 100, 100, 0.4);
        color: rgb(50, 50, 50);
      }
    }
  }
}
</style>
