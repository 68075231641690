<template>
  <PostComposer :type="type" :id="id" @saved="saved" @close="close" />
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import store from '~/store/index';
import PostComposer from '~/components/PostComposer';
import NProgress from 'nprogress';

export default {
  components: {
    PostComposer,
  },

  computed: {
    id() {
      return parseInt(this.$route.params.id, 10);
    },
    type() {
      return this.$route.params.type;
    },
    ...mapState({
      postStatus: state => state.createPost.postStatus,
    }),
  },

  async beforeRouteEnter(to, from, next) {
    const id = parseInt(to.params.id, 10);
    const currentWorkspace = store.state.workspace.current;
    const type = to.params.type;

    if (!id && currentWorkspace && currentWorkspace.id && type && ['blog', 'social'].includes(type)) {
      try {
        const post = await store.dispatch('createPost/savePost', {
          workspace: currentWorkspace.id,
          id: 0,
          type: type,
          status: 'draft',
          publishNow: false,
        });

        const query = to.query || {};
        const params = to.params || {};
        const newRouteData = {
          name: to.name,
          params: {
            ...params,
            id: post.id,
          },
          query: {
            ...query,
          },
        };

        if (!query.action) {
          newRouteData.query.action = 'createNew';
        }

        next(newRouteData);
      } catch (e) {
        console.dir(e);
        NProgress.done();
        Swal.fire({
          type: 'error',
          title: 'OOPS!',
          text: 'An error occurred while initializing composer.',
        });

        next(false);
      }
    } else {
      next();
    }
  },

  async beforeRouteUpdate(to, from, next) {
    const id = parseInt(to.params.id, 10);
    const currentWorkspace = store.state.workspace.current;
    const type = to.params.type;

    if (!id && currentWorkspace && currentWorkspace.id && type && ['blog', 'social'].includes(type)) {
      try {
        const post = await store.dispatch('createPost/savePost', {
          workspace: currentWorkspace.id,
          id: 0,
          type: type,
          status: 'draft',
          publishNow: false,
        });

        const query = to.query || {};
        const params = to.params || {};
        const newRouteData = {
          name: to.name,
          params: {
            ...params,
            id: post.id,
          },
          query: {
            ...query,
          },
        };

        if (!query.action) {
          newRouteData.query.action = 'createNew';
        }

        next(newRouteData);
      } catch (e) {
        NProgress.done();
        Swal.fire({
          type: 'error',
          title: 'OOPS!',
          text: 'An error occurred while initializing composer..',
        });

        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    close() {
      this.$router.push({ name: 'dashboard' });
    },

    saved(saved) {
      let message;
      switch (this.postStatus) {
        case 'publish':
          message = 'Your post is now being processed.';
          break;
        case 'scheduled':
          message = 'Your post has been scheduled successfully.';
          break;
        case 'draft':
          message = 'Your post has been saved successfully.';
          break;
      }

      if (saved) {
        Swal.fire({
          type: 'success',
          title: 'Awesome!',
          text: message,
          confirmButtonText: 'Okay',
        }).then(() => {
          this.$router.push({ name: 'post.view', params: { id: saved.id } });
        });
      }
    },
  },
};
</script>
