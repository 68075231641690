<template>
  <div class="editor-page-wrap">
    <b-container class="my-5 px-4">
      <b-row>
        <b-col md="12">
          <h4 class="font-weight-bold">Topics</h4>
          <p>Explore And Follow Relevant Topics Topics</p>

          <div v-if="hasContents" class="topics-wrap py-2">
            <div v-for="(topic, i) in curatedTopics" :key="topic.id + i" class="topic-item shadow-sm">
              <div class="details-wrap">
                <div class="title mb-2" :title="topic.title">{{ topic.title }}</div>
                <b-button variant="outline-primary" size="sm" class="px-4" pill>Follow</b-button>
              </div>
              <div class="photo-wrap"><img :src="topic.photo" alt="" /></div>
            </div>
          </div>
          <div v-if="$apollo.queries.curatedTopics.loading" class="topics-wrap">
            <div v-for="num in 15" :key="num" class="topic-item shadow-sm skeleton">
              <div class="d-flex">
                <div class="details-wrap">
                  <skeleton-screen width="147px" height="25px" class="mb-3 rounded mt-1" />
                  <skeleton-screen width="100px" height="27px" class="rounded-pill" />
                </div>
                <div class="photo-wrap">
                  <skeleton-screen width="150px" class="rounded shadow-sm" height="80px" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!$apollo.queries.curatedTopics.loading && !hasContents"
            class="bg-white rounded-sm shadow-sm text-center p-5 mt-4"
          >
            <img class="pt-3" width="200" src="@/assets/images/no_data.svg" alt="" />
            <h5 class="pb-4 pt-3">No Curated topics yet.</h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CURATED_TOPICS_QUERY } from '~/graphql/queries';

export default {
  data() {
    return {
      curatedTopics: [],
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    hasContents() {
      return this.curatedTopics && this.curatedTopics && this.curatedTopics.length > 0;
    },
  },

  apollo: {
    curatedTopics: {
      query: CURATED_TOPICS_QUERY,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.topics-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
}

.topic-item {
  width: 345px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 0.2rem;
  display: flex;
  background-color: $white;

  .photo-wrap {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    img {
      height: 85px;
      width: 150px;
      border-radius: 10px;
      transition: margin-right 0.3s cubic-bezier(0.52, 0.28, 0.47, 1.15);
      box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);
    }
  }

  .details-wrap {
    width: 60%;
    padding: 10px;

    .title {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: bold;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
    }

    .btn {
      font-weight: bold;
    }
  }

  &:hover {
    .photo-wrap {
      img {
        margin-right: -25px;
      }
    }
  }

  &:not(.skeleton) {
    background: white;
  }
}
</style>
