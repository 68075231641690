<template>
  <div class="post-status-chooser mt-5">
    <div class="post__status mt-5">
      <div
        class="post__status-items"
        @click="handlePostStatusChange('publish')"
        :class="{ active: postStatus == 'publish' }"
      >
        <div class="items-marker">
          <svg-icon v-if="postStatus === 'publish'" name="check-circle-success" size="md" />
          <span v-else class="marker-1"></span>
        </div>
        <span class="design design-1">
          <img src="@/assets/icons/wave-4.svg" alt="icon" />
        </span>
        <img src="@/assets/icons/launch.svg" alt="icon" />
        <h5 class="font-weight-bold mt-3">Publish Now</h5>
        <b-form-radio v-model="localStatus" :state="true" class="d-none" name="status" value="publish" />
      </div>

      <div
        class="post__status-items"
        @click="handlePostStatusChange('scheduled')"
        :class="{ active: postStatus == 'scheduled' }"
      >
        <div class="items-marker">
          <svg-icon v-if="postStatus === 'scheduled'" name="check-circle-success" size="md" />
          <span v-else class="marker-1"></span>
        </div>
        <img src="@/assets/icons/schedule.svg" alt="icon" />
        <h5 class="font-weight-bold mt-3">Post Schedule</h5>
        <b-form-radio v-model="localStatus" :state="true" class="d-none" name="status" value="scheduled" />
      </div>

      <div
        class="post__status-items"
        @click="handlePostStatusChange('draft')"
        :class="{ active: postStatus == 'draft' }"
      >
        <div class="items-marker">
          <svg-icon v-if="postStatus === 'draft'" name="check-circle-success" size="md" />
          <span v-else class="marker-1"></span>
        </div>
        <img src="@/assets/icons/draft.svg" alt="icon" />
        <h5 class="font-weight-bold mt-3">Save Draft</h5>
        <span class="design design-2">
          <img src="@/assets/icons/wave-4.svg" alt="icon" />
        </span>
        <b-form-radio v-model="localStatus" :state="true" class="d-none" name="status" value="draft" />
      </div>
    </div>

    <div v-show="postStatus == 'scheduled'" class="scheduler bg-white rounded-lg p-4 mb-5 mx-auto">
      <PostScheduleChooser />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PostScheduleChooser from '~/components/PostScheduleChooser';

export default {
  components: {
    PostScheduleChooser,
  },

  data() {
    return {
      localStatus: 'publish',
    };
  },

  computed: {
    ...mapState('createPost', {
      postStatus: state => state.postStatus,
    }),
  },

  created() {
    this.localStatus = this.postStatus;
  },

  methods: {
    handlePostStatusChange(postStatus) {
      this.localStatus = postStatus;
      this.$store.dispatch('createPost/updatePostStatus', postStatus);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.scheduler {
  width: 75%;
}
.post__status {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 75%;
  margin: 15px auto;
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) 0.1s;
  position: relative;
  // z-index: 100;
  &-items {
    background: $white;
    border-radius: 10px;
    color: $primary;
    margin: 20px;
    height: 250px;
    display: inline-block;
    text-align: center;
    padding: 40px;
    width: 100%;
    cursor: pointer;
    position: relative;
    .design {
      position: absolute;
      z-index: 1;
      &.design-1 {
        top: -35px;
        left: -40px;
      }
      &.design-2 {
        bottom: -35px;
        right: -40px;
        transform: rotate(190deg);
        -webkit-transform: rotate(190deg);
      }
    }
    &.active {
      background: $secondary;
      border: 1px solid $primary;
    }
    .items-marker {
      position: absolute;
      top: 10px;
      right: 10px;
      .marker-1 {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $primary;
      }
    }
  }
}
</style>
