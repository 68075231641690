<template>
  <div class="campaigns-page-wrap">
    <b-container class="mt-5 px-5">
      <b-row>
        <b-col>
          <div class="campaigns-wrap">
            <div class="w-100 d-flex justify-content-between">
              <div class="campaign-label">Campaigns</div>

              <div class="campaign-fabs">
                <button @click="$refs.createModal.show()" class="fab-button pulse">
                  <svg-icon name="plus" class="button-icon" />
                </button>
              </div>
            </div>

            <div v-if="$apollo.queries.getCampaigns.loading" class="text-center pt-2 pb-5">
              <spinner size="4" />
            </div>

            <div
              v-if="campaigns && campaigns.length > 0 && !$apollo.queries.getCampaigns.loading"
              class="campaign-items-wrap"
            >
              <div v-for="campaign in campaigns" :key="campaign.id" class="campaign-item">
                <div class="px-3 py-3 d-flex align-items-center">
                  <router-link
                    tag="div"
                    :to="{ name: 'campaign.posts', params: { id: campaign.id } }"
                    class="campaign-item-title content-ellipsis"
                    >{{ campaign.title }}</router-link
                  >
                  <div class="campaign-item-status">
                    <svg-icon
                      v-if="campaign.isActive"
                      v-b-tooltip
                      title="Campaign is active"
                      class="active-icon"
                      name="check-circle-success"
                    />
                    <svg-icon
                      v-else
                      v-b-tooltip
                      title="Campaign is inactive"
                      class="inactive-icon"
                      name="check-circle"
                    />
                  </div>
                  <div class="campaign-post-count">
                    <div class="count-number">{{ campaign.postCount }}</div>
                    <div class="count-label">Posts</div>
                  </div>
                  <div class="campaign-date">
                    <b-badge class="px-3" variant="primary">{{ campaign.createdAt }}</b-badge>
                  </div>
                  <div class="campaign-actions ml-auto">
                    <b-button
                      :to="{ name: 'campaign.posts', params: { id: campaign.id } }"
                      class="open-btn rounded-sm mr-2"
                      variant="clear"
                    >
                      <svg-icon name="link" /> Open Campaign
                    </b-button>
                    <b-button @click="confirmDeleteCampaign(campaign)" class="delete-btn rounded-sm" variant="clear">
                      <svg-icon name="trash" /> Delete
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="campaigns && campaigns.length === 0 && !$apollo.queries.getCampaigns.loading"
              class="p-5 text-center"
            >
              <img class="pt-3" width="250" src="@/assets/images/blank_canvas.svg" alt="" />
              <h5 class="py-5">You have no Campaigns yet.</h5>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal ref="deleteModal" hide-footer hide-header>
        <div v-if="isDeleting" class="text-center py-5">
          <spinner size="4" />
        </div>
        <div v-else class="text-center py-5">
          <h4>Delete campaign?</h4>
          <p>Are you sure you would like to delete this campaign?</p>

          <b-button variant="light" class="mr-2" @click="closeConfirmDeleteCampaign">Cancel</b-button>
          <b-button :disabled="isDeleting" @click="actuallyDeleteCampaign">Delete</b-button>
        </div>
      </b-modal>

      <b-modal ref="createModal" class="create-modal" centered hide-header hide-footer>
        <div class="px-5 py-4">
          <h4>Create new campaign</h4>
          <b-form @submit.prevent="createCampaign" class="w-100">
            <div class="d-flex mt-4">
              <b-form-input
                v-model="campaignTitle"
                type="text"
                placeholder="Campaign title"
                class="material-input mr-2"
              />
            </div>

            <div class="mt-5 text-right">
              <b-button variant="transparent" class="px-4 mr-2" @click="$refs.createModal.hide()" :disabled="isCreating"
                >Close</b-button
              >
              <b-button variant="primary" class="rounded-pill px-4" type="submit" :disabled="isCreating">
                <b-spinner v-if="isCreating" small type="grow" />
                Next
                <svg-icon name="arrow-right" />
              </b-button>
            </div>
          </b-form>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { capitalize } from 'lodash';
import { ALL_CAMPAIGNS_QUERY } from '~/graphql/queries';
import { CREATE_CAMPAIGN_MUTATION, DELETE_CAMPAIGN_MUTATION } from '~/graphql/mutations';
import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

export default {
  name: 'Campaigns',

  mixins: [InfiniteScrollMixin],

  metaInfo() {
    return { title: 'Campaigns' };
  },

  data: function() {
    return {
      isCreating: false,
      isDeleting: false,
      campaignTitle: '',
      getCampaigns: [],
      campaignToDelete: null,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    campaigns() {
      return this.getCampaigns ? this.getCampaigns.campaigns : [];
    },
  },

  apollo: {
    getCampaigns: {
      query: ALL_CAMPAIGNS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          offset: 0,
        };
      },
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (isRockBottom && this.campaigns && this.campaigns.length && this.loadMoreEnabled) {
        this.loadMore();
      }
    },
  },

  mounted() {
    if (this.$apollo.queries.getCampaigns) {
      this.$apollo.queries.getCampaigns.refetch();
    }
  },

  methods: {
    async createCampaign() {
      if (!this.campaignTitle) {
        return;
      }

      this.isCreating = true;

      const {
        data: { saved },
      } = await this.$apollo.mutate({
        mutation: CREATE_CAMPAIGN_MUTATION,
        variables: {
          workspace: this.workspace.id,
          title: this.campaignTitle,
        },
      });
      this.isCreating = false;
      this.$refs.createModal.hide();
      this.$router.push({ name: 'campaign.posts', params: { id: saved.id } });
    },

    async loadMore() {
      this.offset = this.offset + 15;
      this.loadingMore = true;

      // Fetch more data and transform the original result
      await this.$apollo.queries.getCampaigns.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newContents = fetchMoreResult.getCampaigns.campaigns;
          const hasMore = fetchMoreResult.getCampaigns.hasMore;
          const total = fetchMoreResult.getCampaigns.total;

          this.loadMoreEnabled = hasMore;

          return {
            getCampaigns: {
              __typename: previousResult.getCampaigns.__typename,
              // Merging the tag list
              campaigns: [...previousResult.getCampaigns.campaigns, ...newContents],
              total,
              hasMore,
            },
          };
        },
      });

      this.loadingMore = false;
    },

    confirmDeleteCampaign(campaign) {
      this.campaignToDelete = campaign;

      this.$refs.deleteModal.show();
    },

    closeConfirmDeleteCampaign() {
      this.campaignToDelete = null;

      this.$refs.deleteModal.hide();
    },

    async actuallyDeleteCampaign() {
      this.isDeleting = true;
      await this.$apollo.mutate({
        mutation: DELETE_CAMPAIGN_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.campaignToDelete.id],
        },
      });

      this.isDeleting = false;
      this.closeConfirmDeleteCampaign();
      this.$apollo.queries.getCampaigns.refetch();
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.campaigns-wrap {
  background-color: $white;
  margin-bottom: 30px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.2rem;
  padding-top: 20px;
  min-height: 400px;

  .campaign-label {
    width: 170px;
    border: 1px solid $gray-200;
    padding: 7px 10px;
    text-align: center;
    position: relative;
    left: -15px;
    background-color: white;
    border-radius: 0.4rem;
    margin-bottom: 20px;
    font-size: 1.1rem;
    align-self: center;
  }

  .campaign-fabs {
    top: -5px;
    position: relative;

    .fab-button {
      background: $secondary;
      margin-right: 10px;
      height: 60px;
      width: 60px;

      .button-icon {
        fill: #fff;
      }
    }
  }

  .campaign-item {
    border-top: 1px solid $gray-200;

    &:last-of-type {
      border-bottom: 1px solid $gray-200;
    }

    .campaign-item-title {
      cursor: pointer;
      font-size: 1.2rem;
      padding: 10px 20px;
      width: 25%;
    }

    .campaign-item-status {
      width: 5%;

      .icon {
        height: 2rem;
        width: 2rem;

        &.inactive-icon {
          fill: #c9d7e7;
        }
      }
    }

    .campaign-post-count {
      text-align: center;
      width: 15%;

      .count-number {
        color: $primary;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1;
      }
      .count-label {
        font-size: 0.8rem;
        color: $gray-500;
      }
    }

    .campaign-date {
      border-left: 1px solid $gray-200;
      border-right: 1px solid $gray-200;
      width: 20%;
      text-align: center;
    }

    .campaign-post-action {
      width: 40%;
    }

    &:hover {
      background-color: #fcfcfd;
    }
  }

  .open-btn {
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  .delete-btn {
    border: 1px solid $gray-500;
    color: $gray-500;

    &:hover {
      background-color: $light;
    }
  }
}

.campaign-name {
  color: $gray-700;

  &:hover {
    text-decoration: none;
    color: $gray-700;
  }
}
.content-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.create-modal {
  .material-input {
    font-size: 1rem;
  }
}
</style>
