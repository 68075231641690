import { render, staticRenderFns } from "./PublishAccountsSkeleton.vue?vue&type=template&id=1386b239&scoped=true"
import script from "./PublishAccountsSkeleton.vue?vue&type=script&lang=js"
export * from "./PublishAccountsSkeleton.vue?vue&type=script&lang=js"
import style0 from "./PublishAccountsSkeleton.vue?vue&type=style&index=0&id=1386b239&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1386b239",
  null
  
)

export default component.exports