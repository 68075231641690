<template>
  <div class="automation-campaign-schedule auto-box shadow-sm rounded-sm">
    <div class="auto-box-inner">
      <h5 class="mb-5">Schedule and Finalize</h5>

      <b-row class="mt-4">
        <b-col align-self="center" md="3"> <label for="post-age">Post Every</label> </b-col>
        <b-col md="4" class="d-flex">
          <b-form-input
            :value="recurringValue"
            @change="handleRecurringValueChange"
            id="recurringValue"
            type="number"
            class="mr-2"
          />
          <b-form-select :value="recurringUnit" @change="handleRecurringUnitChange" id="recurringUnit">
            <option value="hour">Hour(s)</option>
            <option value="day">Day(s)</option>
            <option value="week">Week(s)</option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-form-group label="Schedule options" class="mt-4">
        <b-form-radio-group
          id="schedule-options"
          :checked="scheduleType"
          @change="handleScheduleTypeChange"
          name="schedule-options"
        >
          <b-form-radio value="continuous">Run this campaign continuously starting today</b-form-radio>
          <b-form-radio value="fixed">Set a start and end date/time</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div v-if="scheduleType === 'fixed'" class="d-flex align-items-center mt-4">
        <date-range-picker
          opens="center"
          :ranges="false"
          :locale-data="null"
          :minDate="minDate"
          :maxDate="maxDate"
          :singleDatePicker="true"
          :timePicker="true"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          :dateRange="startDate"
          :linkedCalendars="true"
          class="mr-2"
          @update="handleUpdateStartDate"
        >
          <div slot="input" slot-scope="picker" class="px-3">
            <svg-icon name="calendar" class="mr-1" />
            {{ picker.startDate | dateTime }}
          </div>
        </date-range-picker>

        <date-range-picker
          opens="center"
          :ranges="false"
          :locale-data="null"
          :minDate="minDate"
          :maxDate="maxDate"
          :singleDatePicker="true"
          :timePicker="true"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          :dateRange="endDate"
          :linkedCalendars="true"
          @update="handleUpdateEndDate"
        >
          <div slot="input" slot-scope="picker" class="px-3">
            <svg-icon name="calendar" class="mr-1" />
            {{ picker.startDate | dateTime }}
          </div>
        </date-range-picker>

        <div class="run-days ml-2">{{ runDays }} Day(s)</div>
      </div>

      <div class="mt-4">
        Fetch posts for the next
        <input
          :value="prefetchDaysLimit"
          @change="handlePreFetchDaysLimitChange"
          type="number"
          class="prefetch-count"
        />
        days in advance
      </div>

      <b-form-checkbox
        id="reviewPosts"
        :checked="reviewPosts"
        @input="handleReviewPostsChange"
        name="reviewPosts"
        class="mt-4"
      >
        I’d like to review content before it is published
      </b-form-checkbox>

      <template v-if="isEditing">
        <b-row no-gutters>
          <b-col md="10">
            <b-alert variant="info" class="campaign-alert mt-4" show
              >Note: New posts will be scheduled for your accounts on the next refill.</b-alert
            >
          </b-col>
        </b-row>

        <b-form-checkbox
          id="removeExisting"
          :checked="removeExistingPosts"
          @input="handleRemoveExistingPostsChange"
          name="removeExisting"
        >
          Remove existing scheduled posts
        </b-form-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'automation-schedule-step',

  components: {
    DateRangePicker,
  },

  data() {
    return {
      minDate: moment().format('YYYY-MM-DD'),
      maxDate: null,
    };
  },

  computed: {
    isEditing() {
      return this.id !== 0;
    },

    runDays() {
      const startDate = moment(this.startDate.startDate);
      const endDate = moment(this.endDate.startDate);

      return endDate.diff(startDate, 'days') + 1;
    },

    ...mapState('createAutomation', {
      id: state => parseInt(state.id, 10),
      recurringValue: state => (!state.recurringValue ? undefined : parseInt(state.recurringValue, 10)),
      recurringUnit: state => state.recurringUnit,

      startDate: state => state.startDate,
      endDate: state => state.endDate,

      scheduleType: state => state.scheduleType,

      reviewPosts: state => state.reviewPosts,
      prefetchDaysLimit: state => (!state.prefetchDaysLimit ? undefined : parseInt(state.prefetchDaysLimit, 10)),
      removeExistingPosts: state => state.removeExistingPosts,
    }),
  },

  filters: {
    dateTime(value) {
      if (!value) return '';

      return moment(value).format('MMMM D, YYYY, HH:mm A');
    },
  },

  created() {
    this.$store.dispatch('createAutomation/updatePrefetchDaysLimit', 2);
  },

  methods: {
    handleRecurringValueChange(value) {
      this.$store.dispatch('createAutomation/updateRecurringValue', parseInt(value, 10));
    },

    handleRecurringUnitChange(value) {
      this.$store.dispatch('createAutomation/updateRecurringUnit', value);
    },

    handleScheduleTypeChange(value) {
      this.$store.dispatch('createAutomation/updateScheduleType', value);
    },

    handleUpdateStartDate(newStartDate) {
      const newEndDate = {
        startDate: moment(newStartDate.startDate)
          .add(this.runDays, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment(newStartDate.startDate)
          .add(this.runDays, 'days')
          .format('YYYY-MM-DD'),
      };

      this.$store.dispatch('createAutomation/updateEndDate', newEndDate);

      this.$store.dispatch('createAutomation/updateStartDate', newStartDate);
    },

    handleUpdateEndDate(newEndDate) {
      this.$store.dispatch('createAutomation/updateEndDate', newEndDate);
    },

    handleReviewPostsChange(isReviewPosts) {
      this.$store.dispatch('createAutomation/updateReviewPosts', isReviewPosts);
    },

    handlePreFetchDaysLimitChange(e) {
      this.$store.dispatch('createAutomation/updatePrefetchDaysLimit', parseInt(e.target.value, 10));
    },

    handleRemoveExistingPostsChange(isRemoveExistingPosts) {
      this.$store.dispatch('createAutomation/updateRemoveExistingPosts', isRemoveExistingPosts);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.automation-campaign-schedule {
  .prefetch-count {
    width: 100px;
    height: 40px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: 2px 7px;
    color: $gray-600;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  #schedule-options {
    font-size: 0.9rem;
  }

  .campaign-alert {
    font-size: 0.9rem;
  }
}
</style>
