export default [
  {
    code: 'world',
    name: 'World',
  },
  {
    code: 'al',
    name: 'Albania',
  },
  {
    code: 'au',
    name: 'Australia',
  },
  {
    code: 'at',
    name: 'Austria',
  },
  {
    code: 'be',
    name: 'Belgium',
  },
  {
    code: 'ba',
    name: 'Bosnia & Herzegovina',
  },
  {
    code: 'br',
    name: 'Brazil',
  },
  {
    code: 'ca',
    name: 'Canada',
  },
  {
    code: 'cn',
    name: 'China',
  },
  {
    code: 'hr',
    name: 'Croatia',
  },
  {
    code: 'cz',
    name: 'Czech Republic',
  },
  {
    code: 'dk',
    name: 'Denmark',
  },
  {
    code: 'do',
    name: 'Dominican Republic',
  },
  {
    code: 'eg',
    name: 'Egypt',
  },
  {
    code: 'et',
    name: 'Ethiopia',
  },
  {
    code: 'fi',
    name: 'Finland',
  },
  {
    code: 'fr',
    name: 'France',
  },
  {
    code: 'de',
    name: 'Germany',
  },
  {
    code: 'gh',
    name: 'Ghana',
  },
  {
    code: 'gr',
    name: 'Greece',
  },
  {
    code: 'hu',
    name: 'Hungary',
  },
  {
    code: 'in',
    name: 'India',
  },
  {
    code: 'id',
    name: 'Indonesia',
  },
  {
    code: 'ie',
    name: 'Ireland',
  },
  {
    code: 'il',
    name: 'Israel',
  },
  {
    code: 'it',
    name: 'Italy',
  },
  {
    code: 'ke',
    name: 'Kenya',
  },
  {
    code: 'mk',
    name: 'Macedonia',
  },
  {
    code: 'mw',
    name: 'Malawi',
  },
  {
    code: 'my',
    name: 'Malaysia',
  },
  {
    code: 'mx',
    name: 'Mexico',
  },
  {
    code: 'nl',
    name: 'Netherlands',
  },
  {
    code: 'nz',
    name: 'New Zealand',
  },
  {
    code: 'ng',
    name: 'Nigeria',
  },
  {
    code: 'no',
    name: 'Norway',
  },
  {
    code: 'pk',
    name: 'Pakistan',
  },
  {
    code: 'ph',
    name: 'Philippines',
  },
  {
    code: 'pl',
    name: 'Poland',
  },
  {
    code: 'pt',
    name: 'Portugal',
  },
  {
    code: 'ro',
    name: 'Romania',
  },
  {
    code: 'ru',
    name: 'Russia',
  },
  {
    code: 'rs',
    name: 'Serbia',
  },
  {
    code: 'sg',
    name: 'Singapore',
  },
  {
    code: 'sk',
    name: 'Slovakia',
  },
  {
    code: 'si',
    name: 'Slovenia',
  },
  {
    code: 'za',
    name: 'South Africa',
  },
  {
    code: 'es',
    name: 'Spain',
  },
  {
    code: 'se',
    name: 'Sweden',
  },
  {
    code: 'ch',
    name: 'Switzerland',
  },
  {
    code: 'tr',
    name: 'Turkey',
  },
  {
    code: 'ua',
    name: 'Ukraine',
  },
  {
    code: 'gb',
    name: 'United Kingdom',
  },
  {
    code: 'us',
    name: 'United States',
  },
  {
    code: 'vn',
    name: 'Vietnam',
  },
];
