<template>
  <div class="sub-wrap">
    <div ref="content" :id="`sub-${uuid}`" class="sub"></div>
    <b-tooltip :target="`sub-${uuid}`" variant="primary" triggers="hover" placement="bottom">
      Sub: {{ node.attrs.alias }}
    </b-tooltip>
  </div>
</template>

<script>
let uuid = 0;

export default {
  props: ['node', 'updateAttrs', 'view', 'options', 'getPos', 'selected'],

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.sub-wrap {
  display: inline-block;
}

.sub {
  display: inline-block;
  border-bottom: 1px dashed $info;
}
</style>
