<template>
  <div class="download-report-entry">
    <h5 class="p-2">Download Report</h5>
    <div class="py-2" v-if="items.length">
      <b-table
        :busy="isDataLoading"
        :items="items"
        :fields="fields"
        borderless
        selectable
        hover
        responsive="sm"
        ref="downloadsTable"
        select-mode="multi"
        class="downloads-table"
      >
        <template #head()="scope">
          <span class="">{{ scope.label.toUpperCase() }}</span>
        </template>
        <!-- A custom formatted column -->
        <template #cell(image)="data">
          <b-avatar :src="data.image" size="2rem" />
        </template>
        <!-- {{ quote.body | truncate(100, '...') }} -->
        <!-- <template #cell(name)="data">
          <div class="text-truncate">{{ getFullName(data) | truncate(20, '...') }}</div>
        </template> -->
        <template #cell(accounts)="data">
          <div class="accounts">
            <div class="account-item" v-for="(account, i) in data.value" :key="i">
              <b-avatar class="avatar" size="2.2rem" :src="account.avatar" />
              <div :class="['account-icon', account.type]">
                <svg-icon class="icon" :name="account.type" v-if="account.type"></svg-icon>
              </div>
            </div>
          </div>
        </template>
        <template #cell(period)="data">
          <div class="text-truncate">{{ data.value.from }} - {{ data.value.to }}</div>
        </template>
        <template #cell(format)="data">
          <div class="text-truncate">{{ data.value.toUpperCase() }}</div>
        </template>
        <template #cell(id)="data">
          <div class="d-flex align-center">
            <div>
              <b-button id="download-button" href="#" variant="clear" tabindex="0">
                <svg-icon name="download" size="sm" /> {{ data.id }}
              </b-button>
              <b-tooltip target="download-button" title="Download Report" triggers="hover">
                Download Report
              </b-tooltip>
            </div>
            <div>
              <b-button id="delete-button" href="#" variant="clear" tabindex="0">
                <svg-icon name="trash" size="sm" />
              </b-button>
              <b-tooltip target="delete-button" title="Delete Report" triggers="hover">
                Delete Report {{ data.id }}
              </b-tooltip>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div v-else class="no-result-wrap text-center">
      <img class="pt-3 mb-5" width="250" src="@/assets/images/blank_canvas.svg" alt="" />
      <h5 class="">You have not exported any report yet.</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadReport',

  data() {
    return {
      isDataLoading: false,
      fields: [
        { key: 'accounts', label: 'Accounts' },
        'type',
        'format',
        'period',
        { key: 'creation_data', label: 'Creation Date' },
        { key: 'id', label: 'Actions' },
      ],
      items: [
        {
          id: 1,
          accounts: [
            { type: 'facebook', avatar: '', name: 'facebook' },
            { type: 'facebook', avatar: '', name: 'facebook' },
            { type: 'twitter', avatar: '', name: 'twitter' },
          ],
          type: 'demo',
          format: 'pdf',
          period: { from: '12/10/2020', to: '12/10/2021' },
          creation_data: '20/08/2021',
        },
        {
          id: 2,
          accounts: [
            { type: 'facebook', avatar: '', name: 'facebook' },
            { type: 'twitter', avatar: '', name: 'twitter' },
          ],
          type: 'demo',
          format: 'pdf',
          period: { from: '12/10/2020', to: '12/10/2021' },
          creation_data: '20/08/2021',
        },
        {
          id: 3,
          accounts: [{ type: 'twitter', avatar: '', name: 'twitter' }],
          type: 'Group Page',
          format: 'pdf',
          period: { from: '12/10/2020', to: '12/10/2021' },
          creation_data: '20/08/2021',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
.download-report-entry {
  width: 100%;
  .downloads-table {
    background: $white;
    border-radius: 8px;
    width: 100%;
  }
  .accounts {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    .account-item {
      position: relative;
      flex-wrap: wrap;
      margin: 2px;
      .account-icon {
        display: inline-flex;
        position: absolute;
        bottom: -6px;
        right: -6px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        padding: 0 0 4px 2px;
        > .icon {
          width: 14px;
          height: 14px;
          margin: 4px 0 0 0;
          color: $white;
        }
        &.facebook {
          background-color: #3b5998;

          > .icon {
            width: 13px;
            height: 13px;
            margin: 3px 0 0 0;
          }
        }

        &.twitter {
          background-color: #3d82b6;
        }
        &.linkedin {
          background-color: #46e1c4;
        }
        &.wordpress {
          background-color: #ae7c31;
        }

        &.medium {
          background-color: #9e3192;
        }
        > .icon {
          display: inline-flex;
          transform: scale(0.8);
        }
      }
    }
  }
  .no-result-wrap {
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
    background: $white;
    padding: 5rem 0;
  }
}
</style>
