import { Mark } from 'tiptap';
import { removeMark, updateMark } from 'tiptap-commands';

import ViewComponent from './View.vue';

export default class LangMark extends Mark {
  get name() {
    return 'lang';
  }

  get schema() {
    return {
      attrs: {
        'xml:lang': {
          default: null,
        },
      },
      inclusive: false,
      excludes: '_',
      parseDOM: [
        {
          tag: 'lang',
          getAttrs: dom => ({
            'xml:lang': dom.getAttribute('xml:lang'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'lang',
          {
            'xml:lang': node.attrs['xml:lang'],
          },
          0,
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type }) {
    return attrs => {
      if (attrs['xml:lang']) {
        return updateMark(type, attrs);
      }

      return removeMark(type);
    };
  }
}
