<template>
  <transition name="sidebar-slide">
    <b-col md="2" class="leads-settings-container">
      <div class="leads-settings-sidebar">
        <div class="text-center px-4">
          <img src="@/assets/icons/list-lead.svg" alt="list" />
          <p class="f-12">
            Lists allow you to easily organize your contacts.
          </p>
        </div>
        <div class="leads__list-container">
          <div class="text-center mb-4">
            <b-button class="px-4 py-1 rounded" variant="outline-primary" @click="createList">
              <svg-icon name="add-circle" class="mx-2" />
              Create a list
            </b-button>
          </div>
          <div class="leads__list-items pl-2">
            <div>
              <input
                type="checkbox"
                id="all-list"
                class="circle_checkbox"
                v-model="allSelected"
                :indeterminate="indeterminate"
                aria-describedby="workspace"
                aria-controls="workspace"
                @change="toggleAll"
              />
              <label for="all-list">All List</label>
            </div>
            <simplebar class="side-nav-wrap">
              <spinner size="2" v-if="$apollo.queries.lists.loading" style="display: flex; margin: 10px auto 0;" />
              <template v-else>
                <div class="list-items" v-for="(list, i) in lists" :key="i">
                  <input
                    type="checkbox"
                    :id="list.id"
                    class="circle_checkbox"
                    v-model="selectedList"
                    :key="list.id"
                    :value="list.id"
                    :name="list.name"
                  />
                  <label :for="list.id">{{ list.name }}</label>
                </div>
              </template>
            </simplebar>
            <ul class="side-nav mt-2">
              <li>
                <a @click="hasHistory() ? $router.go(-1) : $router.push('/workspaces')" active-class="active" exact>
                  <svg-icon name="dashboard" />
                  Go Back
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-col>
  </transition>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { FETCH_FOLDER_QUERY } from '~/graphql/queries';

const accessControlNamespace = createNamespacedHelpers('accessControl');
export default {
  name: 'Sidebar',

  components: { simplebar },

  data() {
    return {
      allSelected: true,
      indeterminate: false,
      selectedList: [],
      lists: [],
      hasMore: false,
    };
  },

  apollo: {
    lists: {
      query: FETCH_FOLDER_QUERY,
      variables() {
        return {
          purpose: 'lead',
        };
      },
      update({ fetchFolder }) {
        this.hasMore = !fetchFolder.length;
        return fetchFolder;
      },
    },
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
      'isUserClientInWorkspace',
    ]),
  },

  watch: {
    selectedList(newValue) {
      this.sortList(newValue);
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.lists.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    toggleAll(checked) {
      this.selectedList = checked ? this.lists.map(a => a.id) : [];
    },
    createList() {
      this.$emit('create-list');
    },
    sortList(list) {
      this.$emit('sort-list', list);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.leads-settings-container {
  position: relative;

  .leads-settings-sidebar {
    position: relative;
    top: 20px;
    z-index: 100;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 20px 20px 0;
    border-radius: 0 30px 30px 0;
    overflow: visible;

    .leads__list-container {
      padding: 14px 8px;
      background: $white;
      border-radius: 10px;
      font-size: 12px;
    }

    .side-nav-wrap {
      max-height: 100vh;

      .simplebar-scrollbar {
        width: 5px;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        /* When hovered, remove all transitions from drag handle */
        opacity: 1;
        transition: opacity 0s linear;
      }
    }

    .side-nav {
      list-style: none;
      padding: 0;

      li {
        a {
          display: block;
          padding: 8px 10px;
          color: #000;
          font-size: 12px;

          &:hover {
            color: #fff;
            text-decoration: none;
          }
          &.active {
            border-right: 8px solid #42c589;
          }
          &:hover,
          &.active {
            background-color: #2d6a4f;
            border-radius: 20px 0 0 20px;
          }
          .icon {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.sidebar-slide-enter-active {
  animation: sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.sidebar-slide-leave-active {
  animation: sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
