<template>
  <div class="profile__settings">
    <b-row class="profile__settings-user-details">
      <b-col>
        <h5 class="mb-4 pb-2 title">Update Your Profile</h5>
        <form @submit.prevent="handleUpdateProfile" @keydown="form.onKeydown($event)">
          <b-form-group label-cols="8" label-cols-lg="4" label="First Name" label-for="first-name">
            <b-form-input v-model="form.firstName" id="first-name" autocomplete="off"></b-form-input>
            <has-error :form="form" field="firstName" />
          </b-form-group>
          <b-form-group label-cols="8" label-cols-lg="4" label="Last Name" label-for="last-name">
            <b-form-input v-model="form.lastName" id="last-name" autocomplete="off"></b-form-input>
            <has-error :form="form" field="lastName" />
          </b-form-group>
          <b-form-group label-cols="8" label-cols-lg="4" label="Email Address" label-for="email">
            <b-form-input v-model="form.email" id="email" autocomplete="off" disabled></b-form-input>
            <has-error :form="form" field="email" />
          </b-form-group>
          <b-row>
            <b-col></b-col>
            <b-col cols="8">
              <b-button variant="primary" class="px-4 py-2 user-btn" type="submit" :disabled="isUpdatingProfile">
                <b-spinner v-if="isUpdatingProfile" small class="mr-2" />
                <svg-icon v-else name="check" size="md" class="mr-2" />
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-col>
      <b-col class="">
        <div class="avatar__container">
          <div class="avatar__container-avatar">
            <div class="profile-avatar">
              <b-avatar class="avatar photo-preview" :src="url || user.photoUrl_150" size="9rem" />
              <div class="file-upload">
                <b-button variant="clear" class="" size="md" rounded @click="$refs.Avatar.click()">
                  <svg-icon name="camera" size="md" />
                </b-button>
                <input id="avatar" type="file" ref="Avatar" class="file-upload__input" @change="handleFileUpload()" />
              </div>
            </div>
          </div>
          <div class="avatar__container-content">
            <div class="title">{{ user.firstName }} {{ user.lastName }}</div>
            <span class="email">{{ user.email }}</span>
          </div>
        </div>
        <div class="upload-info">
          <p class="small mb-2">Recommended size: Less than 2MB (130 x 130)</p>
          <b-button variant="outline-primary" size="sm" class="px-3" @click="$refs.Avatar.click()">
            Upload image
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="profile__settings-password">
        <h5 class="mb-4 pb-2 title">Update Your Password</h5>
        <form @submit.prevent="handleUpdatePassword" @keydown="passwordForm.onKeydown($event)">
          <b-row>
            <b-col>
              <b-form-group label-cols="8" label-cols-lg="4" label="Old Password" label-for="old-password">
                <b-form-input
                  v-model="passwordForm.password"
                  id="old-password"
                  name="password"
                  type="password"
                  placeholder="Enter your old password"
                  autocomplete="off"
                ></b-form-input>
                <has-error :form="passwordForm" field="password" />
              </b-form-group>

              <b-form-group
                label-cols="8"
                label-cols-lg="4"
                label="Retype New Password"
                label-for="new-password-confirm"
              >
                <b-form-input
                  v-model="passwordForm.new_password_confirmation"
                  id="new-password-confirm"
                  type="password"
                  placeholder="Retype your new password"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols="8" label-cols-lg="4" label="New Password" label-for="new-password">
                <b-form-input
                  v-model="passwordForm.new_password"
                  id="new-password"
                  name="new_password"
                  type="password"
                  placeholder="Enter your new password"
                  autocomplete="off"
                ></b-form-input>
                <has-error :form="passwordForm" field="new_password" />
              </b-form-group>
              <div class="mt-4">
                <b-button variant="primary" class="px-4 py-2 user-btn" type="submit" :disabled="isUpdatingPassword">
                  <b-spinner v-if="isUpdatingPassword" small class="mr-2" />
                  <svg-icon v-else name="check" size="md" class="mr-2" />
                  Save changes
                </b-button>
              </div>
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Form from 'vform';
import { mapState, mapActions } from 'vuex';
import { UPDATE_USER_PROFILE_MUTATION, UPDATE_USER_PASSWORD_MUTATION } from '~/graphql/mutations';

export default {
  name: 'edit-proile-modal',

  data() {
    return {
      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        avatar: null,
      }),
      passwordForm: new Form({
        password: '',
        new_password: '',
        new_password_confirmation: '',
      }),
      url: null,
      isUpdatingProfile: false,
      isUpdatingPassword: false,
      apiUrl: process.env.VUE_APP_API_ROOT,
    };
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
  },

  created() {
    this.form.keys().forEach(key => {
      this.form[key] = this.user[key];
    });
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    async handleUpdateProfile() {
      this.isUpdatingProfile = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_PROFILE_MUTATION,
          variables: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            avatar: this.form.avatar,
          },
        })
        .then(({ data }) => {
          this.isUpdatingProfile = false;

          this.updateUser({ user: data.updateUserProfile });

          this.$notify({
            group: 'main',
            type: 'success',
            title: 'Success',
            text: 'Your profile has been updated successfully',
          });
        })
        .catch(error => {
          this.isUpdatingProfile = false;

          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              const errors = err.extensions.validation;
              for (let key in errors) {
                const fieldErrors = errors[key];

                this.$notify({
                  group: 'main',
                  type: 'error',
                  title: `Invalid ${key}`,
                  text: fieldErrors.join('<br />'),
                });
              }
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Unable to update',
              text: 'An error occurred while processing your request.',
            });
          }

          this.url = null;
        });
    },

    async handleUpdatePassword() {
      this.isUpdatingPassword = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_PASSWORD_MUTATION,
          variables: {
            ...this.passwordForm.data(),
          },
        })
        .then(() => {
          this.isUpdatingPassword = false;
          this.$notify({
            group: 'main',
            type: 'success',
            title: 'Success',
            text: 'Your password has been updated successfully',
          });

          this.passwordForm.reset();
        })
        .catch(error => {
          this.isUpdatingPassword = false;

          const validations = error.graphQLErrors.filter(err => err.message == 'validation');
          const wrongPassword = error.graphQLErrors.find(err => err.message == 'WrongPassword');

          if (wrongPassword) {
            this.passwordForm.errors.set('password', 'Incorrect password');
          } else if (validations.length) {
            validations.forEach(err => {
              this.passwordForm.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Unable to update',
              text: 'An error occurred while processing your request.',
            });
          }

          this.url = null;
        });
    },

    handleFileUpload: function() {
      const file = this.$refs.Avatar.files[0];

      if (!file) return;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);

      this.form.avatar = file;

      if (!this.isValidFile(file)) return;
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `Please upload a valid image`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 5240) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 5MB',
        });
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.profile__settings {
  margin: 2px 10px;
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  .user-btn {
    height: 40px;
  }
  &-user-details {
    background: $secondary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary;
    padding: 40px;
    border-radius: 20px;
    .form-control {
      height: 40px;
      &::placeholder {
        font-size: 13px;
        color: $gray-500;
      }
    }
    .avatar__container {
      background: $primary;
      padding: 20px;
      margin-left: 60px;
      position: relative;
      color: white;
      border-radius: 10px;
      &-avatar {
        position: absolute;
        top: -30px;
        left: -40px;
        .profile-avatar {
          position: relative;
          .avatar {
            border: 2px solid #52b788;
          }
          .file-upload {
            position: absolute;
            display: block;
            cursor: pointer;
            bottom: -15px;
            right: 43px;
            padding: 4px 2px;
            background-color: $primary;
            border-radius: 50%;
            svg {
              fill: $secondary;
            }
          }

          .file-upload__input {
            display: none;
          }
        }
      }
      &-content {
        text-align: left;
        padding-left: 100px;
        color: $secondary;
        .title {
          text-transform: capitalize;
          font-size: 18px;
        }
        .email {
          text-transform: lowercase;
          font-size: 12px;
        }
      }
    }
    .upload-info {
      margin-left: 180px;
      margin-top: 6px;
    }
  }
  &-password {
    background: $white;
    border-radius: 20px;
    padding: 40px;
  }
}
</style>
