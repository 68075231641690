<template>
  <div class="campaign-choosen-accounts mb-3">
    <div v-if="accounts && accounts.length > 0">
      <div
        v-for="publishAccount in accounts"
        :key="publishAccount.id"
        class="selected-account-item d-flex align-items-center"
        :class="{
          active: activeAccount && activeAccount.id === publishAccount.id,
          'has-error': hasError(publishAccount.id),
        }"
        @click="$emit('item-click', publishAccount)"
      >
        <PublishAccountImage :account="publishAccount" class="mr-2" width="40" height="40" />
        <div class="text-truncate">{{ publishAccount.name }}</div>

        <template v-if="hasWarning(publishAccount.id)">
          <span :id="`warn${publishAccount.id}`" class="warning" :class="{ 'mr-2': hasError(publishAccount.id) }">
            <svg-icon name="info-alt" />
          </span>
          <b-tooltip
            variant="warning"
            :target="`warn${publishAccount.id}`"
            triggers="hover"
            customClass="validation-tooltip"
          >
            <ul class="tooltip-validations" v-html="getAccountWarnings(publishAccount.id)" />
          </b-tooltip>
        </template>

        <template v-if="hasError(publishAccount.id)">
          <span :id="`err${publishAccount.id}`" class="error" :class="{ 'ml-auto': !hasWarning(publishAccount.id) }">
            <svg-icon name="danger-alt" />
          </span>
          <b-tooltip
            variant="danger"
            placement="top"
            :target="`err${publishAccount.id}`"
            triggers="hover"
            customClass="validation-tooltip"
          >
            <ul class="tooltip-validations" v-html="getAccountErrors(publishAccount.id)" />
          </b-tooltip>
        </template>
      </div>
    </div>
    <div v-else-if="accounts && accounts.length < 1 && !loading" class="mb-3 p-4 text-center">
      <p text-muted>You have not selected any account yet.</p>
    </div>
    <div v-else class="text-center"><b-spinner variant="primary" /></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PublishAccountImage from '~/components/PublishAccountImage';

export default {
  components: { PublishAccountImage },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    accounts: {
      type: Array,
      default: () => [],
    },

    activeAccount: {
      default: null,
    },
  },

  computed: mapGetters('validation', {
    hasError: 'hasError',
    hasWarning: 'hasWarning',
  }),

  methods: {
    getAccountErrors(accountId) {
      const errors = this.$store.getters['validation/accountErrors'](accountId);
      return errors.map(message => `<li>${message}</li>`).join('');
    },
    getAccountWarnings(accountId) {
      const warning = this.$store.getters['validation/accountWarnings'](accountId);
      return warning.map(message => `<li>${message}</li>`).join('');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.campaign-choosen-accounts {
  background: $secondary;
  border-radius: 10px;
}

.selected-account-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid $gray-100;
  position: relative;

  &:first-of-type {
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover {
    border-radius: 0.3rem;
    background-color: #f7f7f7;
  }

  .chosen-account-img {
    box-shadow: 0px 0px 9px -3px #607d8b;
    padding: 2px;
  }

  .error {
    display: inline-block;
    svg {
      fill: $danger;
    }
  }

  .warning {
    margin-left: auto;
    svg {
      fill: darken(orange, 1);
    }
  }

  &.active {
    background-color: $primary;
    color: #fff;
    border-radius: 0.3rem;

    .chosen-account-img {
      box-shadow: 0px 0px 9px 0 #ccc;
      background-color: #fff;
    }

    &:after {
      content: '';
      border-top: 15px solid transparent;
      border-left: 15px solid $primary;
      border-bottom: 15px solid transparent;
      position: absolute;
      right: -15px;
    }

    .warning {
      svg {
        fill: $white;
      }
    }
  }

  &.has-error {
    &.active {
    }
  }
}

.validation-tooltip {
  .tooltip-validations {
    text-align: left;
    padding: 15px 20px;
    font-size: 0.8rem;
    margin-bottom: 0;

    li {
      margin-bottom: 10px;
    }
  }
}
</style>
