<template>
  <div class="d-inline-block">
    <button @click="openWriter" class="btn node-btns py-1 px-2 btn-clear">
      &#x1F4A1; <span class="fs-12 text-bold">Use AI</span>
    </button>
    <transition name="zoom-in">
      <div class="ai-box-wrapper" v-show="open">
        <div class="ai-box">
          <div class="ai-box-close">
            <button class="btn" @click="closeWriter">
              <svg-icon name="close" size="md" />
            </button>
          </div>

          <div class="ai-box-bot ">
            <svg-icon name="robot" class="bouncing-tag" size="xl" />
          </div>
          <div class="ai-chat-box mb-3" ref="listContainer">
            <div class="ai-chat-msg-bubbles">
              <transition-group name="fade-slide">
                <chat-bubble v-for="(chat, idx) in chats" :key="chat.id" v-bind="chat" @change="selectChat(idx)" />
              </transition-group>
            </div>
          </div>
          <div class="d-flex g-2">
            <div class="ai-chat-form w-100">
              <textarea rows="2" v-model="enquiry" @keypress.enter="askAI"></textarea>
              <button class="btn" @click="askAI" :disabled="loading">
                <span v-show="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span v-show="loading" class="sr-only">Loading...</span>
                <svg-icon v-show="!loading" name="message" sm />
              </button>
            </div>
            <div :class="['d-inline-flex', selection.length ? 'w-auto' : 'w-0']">
              <button class="btn btn-check" @click="pushPostMessages">
                <svg-icon name="checked" sm />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatBubble from './ChatBubble.vue';
import { apolloClient } from '~/plugins/apollo';
import { parse } from 'marked';
import { AI_POST_MESSAGE_MUTATION } from '~/graphql/mutations';
export default {
  components: { ChatBubble },
  name: 'AiPostWriter',
  emits: ['post'],
  props: {
    context: {
      type: String,
      default: '',
    },
    postType: {
      type: String,
    },
    userName: {
      type: String,
    },
  },
  data: () => ({
    loading: false,
    chats: [],
    enquiry: '',
    selection: [],
    open: false,
  }),
  mounted() {},
  methods: {
    selectChat(idx) {
      this.chats[idx].isSelected = !this.chats[idx].isSelected;
      const chatSelectionIdx = this.selection.findIndex(e => e.id === this.chats[idx].id);
      if (chatSelectionIdx !== -1) {
        this.selection.splice(chatSelectionIdx, 1);
      } else {
        this.selection.push({
          message: this.chats[idx].message,
          id: this.chats[idx].id,
        });
      }
    },

    addChat({ message, mine, isIntro }) {
      this.chats.push({
        message: parse(message || ''),
        unParsedMessage: message,
        id: this.chats.length + Math.random(),
        timestamp: Date.now(),
        isSelected: false,
        isIntro: isIntro || false,
        mine,
      });

      this.$nextTick(() => {
        this.$refs.listContainer.scrollTo({
          top: this.$refs.listContainer.scrollHeight,
          behavior: 'smooth',
        });
      });
    },

    async addIntroConversation() {
      const chats = [
        {
          mine: false,
          message: 'Hi ',
          isIntro: true,
        },
        {
          mine: false,
          isIntro: true,
          message: 'What kind of post do you want me to help you out with? ',
        },
      ];

      for (const chat of chats) {
        await this.delay(800);
        this.addChat(chat);
      }
    },

    async delay(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },

    clearSelection() {
      this.selection = [];
      this.chats.forEach(e => {
        e.isSelected = false;
      });
    },

    closeWriter() {
      this.open = false;
      this.clearChat();
    },

    clearChat() {
      this.clearSelection();
    },

    openWriter() {
      this.open = true;
      this.clearSelection();
      this.addIntroConversation();
    },

    async askAI() {
      try {
        this.loading = true;

        this.addChat({
          message: this.enquiry,
          mine: true,
        });

        const context = this.truncateMessages(this.chats, 3000);
        const reply = await this.aiAgent({
          message: this.enquiry,
          postType: this.postType,
          context: this.sanitizeMessages(context),
        });

        this.addChat({
          message: reply?.aiPost.response || '...',
          mine: false,
        });

        this.enquiry = '';
      } catch (error) {
        alert('something went wrong');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async aiAgent({ message, postType, context }) {
      const data = await apolloClient.mutate({
        mutation: AI_POST_MESSAGE_MUTATION,
        variables: {
          message,
          context,
          postType,
        },
      });

      return data?.data;
    },

    sanitizeMessages(messages = []) {
      const newMessages = [];

      for (let chat of messages) {
        if (chat.isIntro) {
          continue;
        }

        newMessages.push({
          message: chat.unParsedMessage,
          mine: chat.mine,
        });
      }

      newMessages.pop();

      return newMessages;
    },

    truncateMessages($messages, limit) {
      const messages = [...$messages];
      let currentWordCount = 0;
      let index = messages.length - 1;

      while (currentWordCount <= limit && index >= 0) {
        const message = messages[index].unParsedMessage;
        const words = message.split(/\s+/);
        currentWordCount += words.length;
        index--;
      }

      if (currentWordCount > limit) {
        index++;
      }

      return messages.slice(index + 1);
    },

    pushPostMessages() {
      const messages = this.selection.map(e => e.message + '<br/>');
      messages.unshift('<div class="bubble" id="bubble">');
      messages.push(`</div>`);
      this.$emit('push-post', messages);
      this.$emit('post', messages);
      this.clearSelection();
      this.closeWriter();
    },
  },
};
</script>

<style lang="scss">
$br: 0.625rem;
$btn-clr: #efefef;

.ai {
  &-chat {
    &-box {
      min-height: 18.75rem;
      max-height: 28.125rem;
      max-width: 30rem;
      min-width: 25rem;
      width: 100%;
      background: #efefeffb;
      border-radius: $br;
      overflow-x: hidden;
    }
    &-form {
      display: flex;
      flex-direction: row;

      & textarea {
        border-radius: $br 0 0 $br;
        outline: 1px solid $btn-clr;
        -webkit-appearance: none;
        max-height: $br * 5;
        font-size: 0.875rem;
        resize: none;
        appearance: none;
        padding: 8px;
        border: 0;
        width: 100%;
      }

      & button {
        border-radius: 0 $br $br 0;
        outline: 1px solid $btn-clr;
        background: $btn-clr;
        border: 0;
        appearance: none !important;
        -webkit-appearance: none;
        padding: 8px 14px;
      }
    }
  }
  &-box {
    max-width: 28.125rem;
    background: #576f7f;
    border-radius: 1rem;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 500px 100px rgba(0, 0, 0, 0.2);

    &-bot {
      position: absolute;
      top: -40px;
      left: 15px;
    }

    &-close {
      display: inline-flex;
      position: absolute;
      top: -25px;
      right: -25px;

      & > * {
        width: 24px;
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        justify-content: center;
        aspect-ratio: 1/1;
        border-radius: 50%;
        & > svg {
          margin-top: 1px;
          margin-left: 1px;
        }
      }
    }

    &-wrapper {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      // position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 1000;

      & * {
        transition: 0.3s all ease-in-out;
      }

      & .g-2 {
        gap: 10px;
      }

      & .w-0 {
        max-width: 0px !important;
        overflow: hidden;
        margin-left: -10px;
      }

      & .w-auto {
        max-width: 100%;
        // overflow: hidden;
      }

      & .btn.btn-check {
        background: #1b4332;
        color: white;
        border-radius: $br;
        // outline: 1px solid #1b4332;
      }
    }
  }
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: transform 0.2s ease-in-out;
}

.zoom-in-enter, .zoom-in-leave-to /* .zoom-in-leave-active in <2.1.8 */ {
  transform: scale(0) translate(20%);
}

.fade-slide-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
