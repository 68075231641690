<template>
  <div class="misc-settings-page-wrap">
    <div class="bg-white shadow-sm rounded p-4">
      <b-row class="">
        <b-col cols="3">
          <div class="tracking-title">UTM Tracking</div>
        </b-col>
        <b-col>
          <p class="box-desc text-muted">
            With UTM parameters attached to your links, you will have the ability to track your campaign’s performance
            in Google Analytics. This will also allow you to see how much traffic are you receiving from the links you
            shared on different social channels.
          </p>
        </b-col>
      </b-row>

      <b-row class="my-4">
        <b-col>
          <b-form-checkbox
            :checked="workspace.utmStatus"
            name="check-button"
            switch
            @change="handleAutoAttachUtmChange"
          >
            Automatically attach default UTM parameter with shared links
            <p class="small m-0">You can select default UTM parameters from the list below.</p>
          </b-form-checkbox>
        </b-col>
        <b-col cols="4">
          <div class="text-right">
            <b-button variant="primary" size="sm" class="font-weight-bold py-2 px-4" @click="handleAddUtm">
              Connect
            </b-button>
          </div>
        </b-col>
      </b-row>

      <div class="search-wrap">
        <b-form-input
          v-model="utmSearchInput"
          placeholder="Search by campaign name..."
          autocomplete="off"
          class="border-0 bg-light shadow-sm"
        />
        <b-button variant="link" class="search-btn p-0"> <svg-icon name="search" /> </b-button>
      </div>

      <div class="mt-4 border bg-secondary border-primary rounded">
        <div v-if="utmQueryLoading" class="text-center p-3"><spinner size="2" /></div>
        <b-table v-else-if="!utmQueryLoading && filteredUtms.length > 0" :fields="utmFields" :items="filteredUtms">
          <template v-slot:cell(default)="data">
            <b-form-checkbox
              v-model="data.item.default"
              name="check-button"
              switch
              :disabled="data.item.default"
              @change.native="handleChangeDefaultUtm($event, data.item)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(source)="data">
            <em v-if="data.item.autoAddSocialChannel">automatic</em> <span v-else>{{ data.item.source }}</span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button variant="link" size="sm" class="text-dark p-0 mr-2" @click="invokeEditUtm(data.item)">
              <svg-icon name="edit" />
            </b-button>
            <b-button variant="link" size="sm" class="text-dark p-0" @click="invokeRemoveUtm(data.item)">
              <svg-icon name="trash" />
            </b-button>
          </template>
        </b-table>
        <div v-else-if="!utmQueryLoading && filteredUtms.length < 1 && utmSearchInput" class="text-center p-4">
          No UTMs found for your search query, please try again
        </div>
        <div v-else class="text-center p-4">You do not have any UTMs</div>
      </div>
    </div>

    <div class="bg-white shadow-sm rounded p-4 mt-5">
      <b-row class="">
        <b-col cols="3">
          <div class="tracking-title">Saved Hashtags</div>
        </b-col>
        <b-col>
          <p class="box-desc text-muted">
            You can save a set of hashtags that you use most often and then use it quickly at the time of composing your
            posts to save time.
          </p>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between my-4">
        <div class="search-wrap">
          <b-form-input
            v-model="hashtagSearchInput"
            placeholder="Search by hashtag name..."
            autocomplete="off"
            class="border-0 bg-light shadow-sm"
          />
          <b-button variant="link" class="search-btn p-0"> <svg-icon name="search" /> </b-button>
        </div>
        <b-button variant="primary" size="sm" class="font-weight-bold py-2 px-4" @click="handleAddHashtag">
          Connect
        </b-button>
      </div>

      <div class="mt-4 border bg-secondary border-primary rounded">
        <div v-if="hashtagQueryLoading" class="text-center p-3"><spinner size="2" /></div>
        <b-table
          v-else-if="!hashtagQueryLoading && filteredHashtags.length > 0"
          :fields="hashtagFields"
          :items="filteredHashtags"
        >
          <template v-slot:cell(hashtags)="data">
            <span v-for="tag in data.item.items" :key="tag" class="t-hastag-item">{{ tag }}</span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button variant="link" size="sm" class="text-dark p-0 mr-2" @click="invokeEditHashtag(data.item)">
              <svg-icon name="edit" />
            </b-button>
            <b-button variant="link" size="sm" class="text-dark p-0" @click="invokeRemoveHashtag(data.item)">
              <svg-icon name="trash" />
            </b-button>
          </template>
        </b-table>
        <div
          v-else-if="!hashtagQueryLoading && filteredHashtags.length < 1 && hashtagSearchInput"
          class="text-center p-4"
        >
          We can't find any saved hashtags for your search query, please try again
        </div>
        <div v-else class="text-center p-4">You do not have any Hashtags</div>
      </div>
    </div>

    <b-modal
      id="AddUTMModal"
      modal-class="add-modal"
      content-class="rounded-sm"
      size="md"
      hide-header
      hide-footer
      centered
      @hide="onHideEditUTMModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide"> <svg-icon name="close" /> </b-button>
        <div class="px-4 py-3">
          <h5 v-if="utmForm.id !== 0" class="font-weight-bold mb-4">Edit your UTM parameters</h5>
          <h5 v-else class="font-weight-bold mb-4">Add your UTM parameters</h5>
          <b-form @submit.prevent="handleUtmFormSubmit" @keydown="utmForm.onKeydown($event)">
            <b-form-group label="Campaign Name" label-for="campaign-name">
              <b-form-input
                v-model="utmForm.name"
                id="campaign-name"
                placeholder="Product, or promo code (e.g. mega_sale)"
                autocomplete="off"
                trim
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Campaign Medium" label-for="campaign-medium">
              <b-form-input
                v-model="utmForm.medium"
                id="campaign-medium"
                placeholder="Marketing Medium (e.g. social)"
                autocomplete="off"
                trim
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label-for="auto-channel" class="mb-2">
              <b-form-checkbox v-model="utmForm.autoAddSocialChannel" size="sm" id="auto-channel" name="auto_channel">
                Automatically add social channel's name as a source
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Campaign Source" label-for="campaign-source">
              <b-form-input
                v-model="utmForm.source"
                id="campaign-source"
                placeholder="The referrer (e.g. facebook, twitter)"
                autocomplete="off"
                trim
                required
                :disabled="utmForm.autoAddSocialChannel"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" class="shadow px-4" :disabled="addingUtm">
              <b-spinner v-if="addingUtm" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="AddHashtagModal"
      modal-class="add-modal"
      content-class="rounded-sm"
      size="md"
      hide-header
      hide-footer
      centered
      @hide="onHideEditHashtagModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide"> <svg-icon name="close" /> </b-button>
        <div class="px-4 py-3">
          <h5 v-if="hashtagForm.id !== 0" class="font-weight-bold mb-4">Edit your hashtags</h5>
          <h5 v-else class="font-weight-bold mb-4">Add a new set of hashtags</h5>
          <b-form @submit.prevent="handleHashtagFormSubmit" @keydown="hashtagForm.onKeydown($event)">
            <b-form-group label="Name" label-for="hashtag-name">
              <b-form-input
                v-model="hashtagForm.name"
                id="hashtag-name"
                placeholder="Enter hashtag name..."
                autocomplete="off"
                trim
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Hashtags" label-for="hashtag-medium">
              <b-form-input
                v-model="hashtagInput"
                id="hashtag-medium"
                placeholder="Press 'Enter' to add hashtags such as, ‘#DigitalMarketing"
                autocomplete="off"
                @keydown="handleHashtagInputKeydown"
              ></b-form-input>

              <div class="hashtag-items d-flex flex-wrap">
                <div
                  v-for="(hash, i) in hashtagForm.items"
                  :key="i + 1"
                  class="hashtag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
                >
                  {{ hash }}
                  <span @click="removeHashtagItem(i)" class="hash-item-remove small"> <svg-icon name="close" /> </span>
                </div>
              </div>
            </b-form-group>

            <b-form-group class="count-input">
              <label for="" class="mb-0">Randomly choose</label>
              <input v-model="hashtagForm.insertCount" id="insert-count" type="number" min="1" max="5" class="w-auto" />
              <label for="" class="mb-0">hashtag(s) for each post.</label>
            </b-form-group>

            <b-form-group label="Placement">
              <b-form-radio v-model="hashtagForm.insertType" name="some-radios" value="Append"> Append </b-form-radio>
              <b-form-radio v-model="hashtagForm.insertType" name="some-radios" value="Replace">
                Replace (if keyword is matched)
              </b-form-radio>
            </b-form-group>
            <b-button type="submit" variant="primary" class="shadow px-4" :disabled="addingHashtag">
              <b-spinner v-if="addingHashtag" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="RemoveUtmModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideRemoveUtmModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!removingUtm">
          <h4 class="font-weight-bold mb-3">Remove UTM</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="handleDeleteUtm">Remove</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>

    <b-modal
      id="RemoveHashtagModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideRemoveHashtagModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!removingHashtag">
          <h4 class="font-weight-bold mb-3">Remove Hashtag</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="handleDeleteHashtag">Remove</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';
import { debounce } from 'lodash';

import { UTM_PRESETS_QUERY, HASHTAG_PRESETS_QUERY, FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import {
  SAVE_UTM_PRESET_MUTATION,
  DELETE_UTM_PRESET_MUTATION,
  CHANGE_DEFAULT_UTM_MUTATION,
  SAVE_HASHTAG_PRESET_MUTATION,
  DELETE_HASHTAG_PRESET_MUTATION,
  CHANGE_ATTACH_UTM_STATUS_MUTATION,
} from '~/graphql/mutations';

export default {
  data() {
    return {
      utmFields: [
        {
          key: 'default',
          label: 'SET AS DEFAULT',
        },
        {
          key: 'name',
          label: 'CAMPAIGN NAME',
          class: 'text-center',
        },
        {
          key: 'medium',
          label: 'CAMPAIGN MEDIUM',
          class: 'text-center',
        },
        {
          key: 'source',
          label: 'CAMPAIGN SOURCE',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          class: 'text-right',
        },
      ],
      hashtagFields: [
        {
          key: 'name',
          label: 'NAME',
          class: 'w-25',
        },
        {
          key: 'hashtags',
          label: 'HASHTAGS',
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          class: 'text-right',
        },
      ],

      utmPresets: [],
      hashtagPresets: [],

      utmSearchInput: '',
      utmForm: new Form({
        id: 0,
        name: '',
        medium: '',
        autoAddSocialChannel: false,
        source: '',
      }),
      addingUtm: false,
      removingUtm: false,
      utmInvokedForDelete: null,
      utmInvokedForEdit: null,

      hashtagSearchInput: '',
      hashtagInput: '',
      hashtagForm: new Form({
        id: 0,
        name: '',
        items: [],
        insertCount: 1,
        insertType: 'Append',
      }),
      addingHashtag: false,
      removingHashtag: false,
      hashtagInvokedForDelete: null,
      hashtagInvokedForEdit: null,
    };
  },

  apollo: {
    utmPresets: {
      query: UTM_PRESETS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
    hashtagPresets: {
      query: HASHTAG_PRESETS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    utmQueryLoading() {
      return this.$apollo.queries.utmPresets.loading;
    },

    hashtagQueryLoading() {
      return this.$apollo.queries.hashtagPresets.loading;
    },

    filteredUtms() {
      if (this.utmSearchInput) {
        return this.utmPresets.filter(utm => utm.name.toLowerCase().includes(this.utmSearchInput.toLowerCase()));
      } else {
        return this.utmPresets;
      }
    },

    filteredHashtags() {
      if (this.hashtagSearchInput) {
        return this.hashtagPresets.filter(utm =>
          utm.name.toLowerCase().includes(this.hashtagSearchInput.toLowerCase()),
        );
      } else {
        return this.hashtagPresets;
      }
    },
  },

  methods: {
    handleAutoAttachUtmChange: debounce(function(checked) {
      this.$apollo
        .mutate({
          mutation: CHANGE_ATTACH_UTM_STATUS_MUTATION,
          variables: { workspace: this.workspace.id, status: checked },
          update: (store, { data: { changeAttachUtmStatus: workspace } }) => {
            this.updateWorkspace(store, workspace);
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace updated successfully',
          });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    }, 1000),

    handleChangeDefaultUtm(e, utmPreset) {
      if (!e.target.checked) {
        return e.preventDefault();
      }

      const store = this.$apollo.getClient();
      try {
        const data = store.readQuery({
          query: UTM_PRESETS_QUERY,
          variables: {
            workspace: this.workspace.id,
          },
        });

        data.utmPresets = data.utmPresets.map(u => ({
          ...u,
          default: u.id === utmPreset.id,
        }));

        // Write our data back to the cache.
        store.writeQuery({
          query: UTM_PRESETS_QUERY,
          variables: {
            workspace: this.workspace.id,
          },
          data,
        });
      } catch (e) {
        // Do something with this error
      }

      debounce(() => {
        this.$apollo
          .mutate({
            mutation: CHANGE_DEFAULT_UTM_MUTATION,
            variables: { workspace: this.workspace.id, utm: utmPreset.id },
          })
          .then(() => {
            this.$notify({
              group: 'main',
              type: 'native',
              text: 'Default UTM updated successfully',
            });
          })
          .catch(() => {
            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });
      }, 1000)();
    },

    updateWorkspace(store, workspace) {
      try {
        const data = store.readQuery({
          query: FETCH_WORKSPACES_QUERY,
        });

        const spaceIndex = data.fetchWorkspaces.findIndex(w => w.id === this.workspace.id);
        if (spaceIndex !== -1) {
          data.fetchWorkspaces.splice(spaceIndex, 1, workspace);

          this.updateCurrentWorkspace(workspace);
        }

        // Write our data back to the cache.
        store.writeQuery({
          query: FETCH_WORKSPACES_QUERY,
          data,
        });
      } catch (e) {
        // Do something with this error
      }
    },

    handleAddUtm() {
      this.$bvModal.show('AddUTMModal');
    },

    handleUtmFormSubmit() {
      this.addingUtm = true;

      this.$apollo
        .mutate({
          mutation: SAVE_UTM_PRESET_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.utmForm.id,
            name: this.utmForm.name,
            medium: this.utmForm.medium,
            source: this.utmForm.source,
            autoAddSocialChannel: this.utmForm.autoAddSocialChannel,
          },
          update: (store, { data: { saved } }) => {
            try {
              const data = store.readQuery({
                query: UTM_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
              });

              const index = data.utmPresets.findIndex(u => u.id === saved.id);
              if (index !== -1) {
                data.utmPresets.splice(index, 1, saved);
              } else {
                data.utmPresets.push(saved);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: UTM_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$bvModal.hide('AddUTMModal');

          this.addingUtm = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'UTM saved successfully',
          });
        })
        .catch(() => {
          this.addingUtm = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    invokeEditUtm(utm) {
      this.utmInvokedForEdit = utm;

      this.utmForm.keys().forEach(key => {
        this.utmForm[key] = utm[key];
      });

      this.$bvModal.show('AddUTMModal');
    },

    invokeRemoveUtm(utm) {
      this.utmInvokedForDelete = utm;

      this.$bvModal.show('RemoveUtmModal');
    },

    onHideEditUTMModal() {
      this.utmInvokedForEdit = null;
      this.utmForm.reset();
    },

    handleDeleteUtm() {
      this.removingUtm = true;

      this.$apollo
        .mutate({
          mutation: DELETE_UTM_PRESET_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.utmInvokedForDelete.id,
          },
          update: (store, { data: { deleted } }) => {
            try {
              const data = store.readQuery({
                query: UTM_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
              });

              const index = data.utmPresets.findIndex(u => u.id === deleted.id);
              if (index !== -1) {
                data.utmPresets.splice(index, 1);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: UTM_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$bvModal.hide('RemoveUtmModal');

          this.removingUtm = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'UTM removed successfully',
          });
        })
        .catch(() => {
          this.removingUtm = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideRemoveUtmModal() {
      this.removingUtm = false;
      this.utmInvokedForDelete = null;
    },

    handleAddHashtag() {
      this.$bvModal.show('AddHashtagModal');
    },

    handleHashtagInputKeydown(e) {
      if (e.code == 'Comma' || e.code == 'Enter') {
        e.preventDefault();

        let hashtag = this.hashtagInput;
        if (hashtag.substring(0, 1) !== '#') {
          hashtag = `#${hashtag}`;
        }
        this.hashtagInput = '';

        if (!this.hashtagForm.items.find(h => h === hashtag)) {
          this.hashtagForm.items = [...this.hashtagForm.items, hashtag];
        }
      }
    },

    removeHashtagItem(index) {
      this.hashtagForm.items = [...this.hashtagForm.items.slice(0, index), ...this.hashtagForm.items.slice(index + 1)];
    },

    handleHashtagFormSubmit() {
      this.addingHashtag = true;

      this.$apollo
        .mutate({
          mutation: SAVE_HASHTAG_PRESET_MUTATION,
          variables: {
            workspace: parseInt(this.workspace.id, 10),
            id: parseInt(this.hashtagForm.id, 10),
            name: String(this.hashtagForm.name),
            hashtags: this.hashtagForm.items,
            insertCount: parseInt(this.hashtagForm.insertCount, 10),
            insertType: String(this.hashtagForm.insertType),
          },
          update: (store, { data: { saved } }) => {
            try {
              const data = store.readQuery({
                query: HASHTAG_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
              });

              const index = data.hashtagPresets.findIndex(h => h.id === saved.id);
              if (index !== -1) {
                data.hashtagPresets.splice(index, 1, saved);
              } else {
                data.hashtagPresets.push(saved);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: HASHTAG_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$bvModal.hide('AddHashtagModal');

          this.addingHashtag = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Hashtags saved successfully',
          });
        })
        .catch(() => {
          this.addingHashtag = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    invokeEditHashtag(hashtag) {
      this.hashtagInvokedForEdit = hashtag;

      this.hashtagForm.keys().forEach(key => {
        this.hashtagForm[key] = hashtag[key];
      });

      this.$bvModal.show('AddHashtagModal');
    },

    invokeRemoveHashtag(hashtag) {
      this.hashtagInvokedForDelete = hashtag;

      this.$bvModal.show('RemoveHashtagModal');
    },

    onHideEditHashtagModal() {
      this.hashtagInvokedForEdit = null;
      this.hashtagForm.reset();
    },

    handleDeleteHashtag() {
      this.removingHashtag = true;

      this.$apollo
        .mutate({
          mutation: DELETE_HASHTAG_PRESET_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.hashtagInvokedForDelete.id,
          },
          update: (store, { data: { deleted } }) => {
            try {
              const data = store.readQuery({
                query: HASHTAG_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
              });

              const index = data.hashtagPresets.findIndex(u => u.id === deleted.id);
              if (index !== -1) {
                data.hashtagPresets.splice(index, 1);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: HASHTAG_PRESETS_QUERY,
                variables: { workspace: this.workspace.id },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$bvModal.hide('RemoveHashtagModal');

          this.removingHashtag = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Hashtag removed successfully',
          });
        })
        .catch(() => {
          this.removingHashtag = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideRemoveHashtagModal() {
      this.removingHashtag = false;
      this.hashtagInvokedForDelete = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.misc-settings-page-wrap {
  margin-bottom: 50px;

  .tracking-title {
    width: 100%;
    background: $secondary;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .box-desc {
    font-size: 14px;
  }

  .search-wrap {
    position: relative;
    width: 450px;

    .form-control {
      padding-right: 30px;
      font-size: 14px;
      height: 40px;
      &::placeholder {
        color: $gray-500;
      }
    }

    .search-btn {
      position: absolute;
      right: 5px;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      .icon {
        margin: 0;
      }
    }
  }

  .table {
    margin-bottom: 0;

    thead th {
      vertical-align: bottom;
      border-bottom: 1px solid #dee2e6;
      border-top: 0;
      color: #989eb5;
      font-size: 14px;
    }

    td {
      color: #989eb5;
    }

    .t-hastag-item {
      background-color: $gray-200;
      color: $gray-600;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 2px 7px;
      font-size: 15px;
      border-radius: 5px;
    }
  }

  .plan-header {
    padding: 10px 20px;
    border-bottom: 1px solid $gray-200;
  }

  .plan-body {
    padding: 20px;
  }
}
.add-modal {
  .close-btn {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  p {
    margin: 0;
  }

  label {
    margin-bottom: 0;
  }

  .custom-checkbox {
    label {
      line-height: 1.7;
    }
  }

  .form-control {
    font-size: 0.9rem;

    &::placeholder {
      color: $gray-500;
    }
  }

  .count-input {
    input {
      font-size: 0.9rem;
      padding: 10px;
      color: #3a4557;
      border-radius: 5px;
      border: 1px solid rgba(152, 158, 181, 0.5);
      background: transparent;
      margin-right: 5px;
      margin-left: 5px;

      &:focus {
        border-color: $primary;
        outline: 0;
      }
    }
    label {
      font-size: 15px;
    }
  }

  .hashtag-items {
    .hashtag-item {
      font-size: 0.8rem;

      .hash-item-remove {
        cursor: pointer;
        font-weight: bold;
        margin-left: 0.3rem;

        .icon {
          width: 0.5rem;
          height: 0.5rem;
          margin-top: 0;
          fill: $gray-800;
        }
      }
    }
  }
}
</style>
