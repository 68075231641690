<template>
  <div class="w-wrap">
    <div ref="content" :id="`w-${uuid}`" class="w"></div>
    <b-tooltip :target="`w-${uuid}`" variant="primary" triggers="hover" placement="bottom">
      Role: {{ selectedRole.label }}
    </b-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';

let uuid = 0;

export default {
  props: ['node'],

  computed: {
    ...mapState({
      roles: state => state.voiceEditor.wRoles,
    }),

    selectedRole() {
      const name = this.node.attrs.role;

      return this.roles.find(o => o.name === name);
    },
  },

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.w-wrap {
  display: inline-block;
}

.w {
  display: inline-block;
  border-bottom: 1px dashed $info;
}
</style>
