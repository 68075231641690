<template>
  <div class="a-domain-wrap" title="Speech style">
    <div class="a-domain-input-wrap">
      <b-form-select
        v-model="styleValue"
        :options="styles"
        size="sm"
        class="a-domain-input"
        @change="updateStyle"
      ></b-form-select>
    </div>
    <div ref="content" :id="`adomain-${uuid}`" class="a-domain-content"></div>
  </div>
</template>

<script>
let uuid = 0;

export default {
  props: ['node', 'updateAttrs', 'view', 'options', 'getPos', 'selected'],

  data() {
    return {
      styleValue: this.node.attrs.name,

      styles: [
        {
          value: 'news',
          text: 'Newscaster',
        },
        {
          value: 'conversational',
          text: 'Conversational',
        },
      ],
    };
  },

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },

  methods: {
    updateStyle() {
      this.updateAttrs({
        name: this.styleValue,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.a-domain-wrap {
  display: block;
  background-color: $gray-100;
  padding: 10px;
  padding-top: 4px;
  padding-right: 4px;
  border-radius: 4px;
  margin-top: 5px;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075);
}

.a-domain-input-wrap {
  width: 150px;
  margin-left: auto;

  .a-domain-input {
    box-shadow: none;
    border-color: $gray-200;
    background-color: $gray-100;
    font-size: 0.8rem;
  }
}

.a-domain-content {
}
</style>
