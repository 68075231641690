<template>
  <div :class="['chat-bubble', mine ? 'mine' : 'agent', isSelected && 'selected']">
    <div class="chat-bubble__wrapper" @click="$emit('change')">
      <span class="timestamp">
        {{ $timestamp }}
      </span>
      <div class="bubble" id="bubble" v-html="message"></div>
      <span> </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatBubble',
  props: {
    isSelected: Boolean,
    message: String,
    mine: Boolean,
    timestamp: [String, Number],
  },

  computed: {
    $timestamp() {
      return this.timestamp ? new Date(this.timestamp).toLocaleString() : 'not sent';
    },
  },
};
</script>

<style lang="scss" scoped>
$chatbox-max-width: 25rem;
$wall-margin: 10px;

.chat-bubble {
  --mine-outline: #95d5b2;
  --agent-outline: #c5c5c5;
  --p-darkness: 80%;

  * {
    transition: 0.3s all;
  }

  display: flex;
  margin-bottom: 0.625rem;
  margin-right: $wall-margin;
  margin-left: $wall-margin;

  &.selected .bubble {
    outline-width: 3px;
    outline-style: solid;
    outline-offset: 2px;
    position: relative;
    margin-top: 5px;

    &::after {
      position: absolute;
      content: '\2611';
      top: -8px;

      font-size: 20px;
      font-weight: 600;
    }
  }
  &.mine {
    justify-content: flex-end;

    &.selected .bubble {
      outline-color: var(--mine-outline);
      &::after {
        right: 100%;
        color: brightness(var(--mine-outline), var(--p-darkness));
        margin-right: 10px;
      }
    }

    & .bubble {
      // text-align: right;
      background: #fff;
    }
  }
  &.agent {
    justify-content: flex-start;

    &.selected .bubble {
      outline-color: brightness(var(--agent-outline), var(--p-darkness));

      &::after {
        left: 100%;
        margin-left: 10px;
      }
    }

    & .bubble {
      background: #95d5b2;
    }
  }
  &__wrapper {
    display: inline-block;
    max-width: 100%;
    // max-width: calc(#{$chatbox-max-width} * (2 / 3));

    & .bubble {
      width: 100%;
      border-radius: 6px;
      padding: 6px 10px;
    }
  }
  & .timestamp {
    font-size: 0.7rem;
    padding-bottom: 10px;
  }
}
</style>
