<template>
  <b-container class="position-relative">
    <b-row class="mt-4">
      <b-col md="8" offset-md="2">
        <AiPostWriter post-type="blog" @push-post="getPushedPost" class="bg-white chat" />
        <div class="editor-wrap bg-white p-5 shadow-sm">
          <RichTextEditor
            :content="selectedContent ? selectedContent.body : ''"
            @input="updateSelectedContent"
            :showFabs="showFabs"
            placeholder="Create great content"
            @imageSelect="handleImageSelect"
            @uploadSelect="handleUploadSelect"
            @gifSelect="handleGifSelect"
            @quoteSelect="handleQuoteSelect"
            @articleSelect="handleArticleSelect"
          />
        </div>
      </b-col>
      <EditorRightFabs :post-id="postId" />
    </b-row>
  </b-container>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import RichTextEditor from '~/components/RichTextEditor';
import EditorRightFabs from '~/components/EditorRightFabs';
import AiPostWriter from './Ai/AiPostWriter.vue';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  props: {
    postId: {
      type: Number,
      required: true,
    },
  },

  components: {
    RichTextEditor,
    EditorRightFabs,
    AiPostWriter,
  },

  computed: {
    ...mapState('createPost', {
      selectedContent: state => state.selectedContent,
    }),
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),
    showFabs() {
      return this.isUserCollaboratorInWorkspace() || this.isUserAdminInWorkspace();
    },
  },

  methods: {
    updateSelectedContent(content) {
      this.$store.dispatch('createPost/updateSelectedContent', {
        ...this.selectedContent,
        body: content,
      });
    },

    getPushedPost(selected = []) {
      this.updateSelectedContent(selected.join(''));
    },

    handleImageSelect(attachment) {
      this.updateSelectedContent(
        `${this.selectedContent.body} <img class="text-center" src="${attachment.url}" alt="" /><p><br /></p>`,
      );

      this.closeFabPanel();
    },

    handleUploadSelect(attachment) {
      this.updateSelectedContent(
        `${this.selectedContent.body} <img class="text-center" src="${attachment.url}" alt="" /><p><br /></p>`,
      );
      this.closeFabPanel();
    },

    handleGifSelect(attachment) {
      this.updateSelectedContent(
        `${this.selectedContent.body} <img class="text-center" src="${attachment.url}" alt="" /><p><br /></p>`,
      );
      this.closeFabPanel();
    },

    handleQuoteSelect(quote) {
      this.updateSelectedContent(
        `${this.selectedContent.body} <blockquote>${quote.body}<br /><br />- ${quote.author}</blockquote><p><br /></p>`,
      );
      this.closeFabPanel();
    },

    handleArticleSelect(article) {
      this.updateSelectedContent(article.content);
      this.closeFabPanel();
    },

    closeFabPanel() {
      this.$store.dispatch('postEditor/updateOpenFabPanel', null);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.editor-wrap {
  min-height: 100vh;
  border-radius: 15px;
  border-top-left-radius: 0;
}
.chat {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px -5px 40px rgba(90, 115, 103, 0.086);
  }
}
</style>
