<template>
  <div class="voice-composer-wrap">
    <TopWizard
      :sequence="sequence"
      :currentStep="currentStep"
      :goBackText="goBackText"
      :goBackHandler="goBackHandler"
      :sidebar="false"
    >
      <template v-slot:wizard-link="{ step }">
        <a
          @click.prevent="setCurrentStep(step.number)"
          class="wiz-step-item"
          :class="{ active: currentStep >= step.number }"
          href="#"
          ><span class="marker"></span>{{ step.name }}</a
        >
      </template>

      <div class="action-buttons" slot="buttons">
        <span id="next-btn-wrap" tabindex="0">
          <b-button @click="moveToNext" variant="primary" class="px-4 m-0 act-button next" :disabled="isNextDisabled">
            {{ isLastStep ? 'Save' : 'Next' }}
            <b-spinner v-if="finalizing" class="ml-1" small />
            <svg-icon
              v-else
              :name="isLastStep ? 'check' : 'arrow-right-alt'"
              class="ml-1"
              :class="{ 'mt-0': !isLastStep }"
            />
          </b-button>
        </span>
        <b-tooltip
          v-if="isNextDisabled"
          triggers="hover"
          target="next-btn-wrap"
          variant="info"
          placement="bottom"
          custom-class="error-tip"
          :title="error"
        ></b-tooltip>
      </div>
    </TopWizard>

    <VoiceEditorFabs v-if="currentStep === 1 && (isUserCollaboratorInWorkspace() || isUserAdminInWorkspace())" />

    <transition name="editor-slide">
      <div v-if="currentStep === 1" key="content">
        <VoiceEditor
          :speech="speech"
          :voice-id="voiceId"
          :disabled="isProcessing"
          @change="handleSpeechChange"
          @voiceChange="handleVoiceChange"
        />

        <VoicePlayer
          :text="speech"
          :char-count="charCount"
          :voice-id="voiceId"
          :audio-url="audioUrl"
          :is-processing="isProcessing"
          :should-regenerate="shouldRegenerateAudio"
          @generatingChange="handleGeneratingChange"
          @saved="handleSaved"
          @failed="handleSaveFailed"
        />
      </div>
      <div v-if="currentStep === 2" key="accounts" class="single-posts-content-wrap">
        <b-container>
          <AudioAccountChooser :selectedAccounts="accountPostContents" @sync="syncAccountPostContents" />
        </b-container>
      </div>
      <div v-if="currentStep === 3" key="customize" class="audio-customize-wrap">
        <VoicePostCustomizer :accountPostContents="accountPostContents" @change="handleContentsChange" />
      </div>
      <section v-if="currentStep === 4" key="schedule" class="schedule-page-wrap">
        <b-container>
          <div v-if="canNotChangeStatus" class="scheduler bg-white rounded-lg p-4 mb-5 mx-auto">
            <PostScheduleChooser />
          </div>
          <PostStatusChooser v-else />
        </b-container>
      </section>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';

import app from '~/main';

import TopWizard from '~/components/TopWizard';
import VoiceEditorFabs from '~/components/EditorFabs/VoiceEditorFabs';
import VoiceEditor from '~/components/Voice/VoiceEditor';
import VoicePlayer from '~/components/Voice/VoicePlayer';

import AudioAccountChooser from '~/components/AudioAccountChooser';
import VoicePostCustomizer from '~/components/Voice/Create/VoicePostCustomizer';

import PostStatusChooser from '~/components/PostStatusChooser';
import PostScheduleChooser from '~/components/PostScheduleChooser';

import { FETCH_POST_QUERY } from '~/graphql/queries';
import { FINALIZE_AUDIO_POST_MUTATION } from '~/graphql/mutations';

import { can } from '~/utils/helpers';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  components: {
    TopWizard,
    VoiceEditor,
    VoicePlayer,
    VoiceEditorFabs,
    AudioAccountChooser,
    VoicePostCustomizer,
    PostStatusChooser,
    PostScheduleChooser,
  },

  async beforeRouteEnter(to, from, next) {
    const user = app.$store.state.auth.user;
    const workspace = app.$store.state.workspace.current;

    const canView = user && can(user, 'bypass-subscription');
    const hasPodSub = !!workspace.permissions.find(p => p == 'article-to-podcast-agency');

    if (!canView && !hasPodSub) {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text: 'Feature not included in your current plan. Please upgrade to access this feature.',
      });

      const randomnumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;
      return next({ name: 'dashboard', query: { rf: randomnumber } });
    }

    if (to.params.id != 0) {
      const workspace = app.$store.state.workspace.current;
      const postId = parseInt(to.params.id, 10);

      await app.$apollo
        .query({
          query: FETCH_POST_QUERY,
          variables: {
            workspace: workspace.id,
            id: postId,
          },
          skip() {
            return !this.postId;
          },
        })
        .then(({ data }) => {
          next(vm => {
            vm.savedSpeech = data.fetchPost.masterContent;
            vm.speech = data.fetchPost.masterContent;
            vm.audioUrl = data.fetchPost.audioUrl;
            vm.voiceId = data.fetchPost.voiceId || 'Golden';
            vm.savedVoiceId = data.fetchPost.voiceId || 'Golden';
            vm.isFailed = data.fetchPost.processStatus == 'failed';
            vm.isProcessing = data.fetchPost.processStatus == 'processing';

            vm.synSavedContents(data.fetchPost.accountPostContents);

            app.$store.commit('createPost/UPDATE_POST_STATUS', data.fetchPost.status || '');

            app.$store.commit(
              'createPost/UPDATE_SCHEDULE_TIMEZONE',
              data.fetchPost.scheduleTimezone || moment.tz.guess(),
            );

            const date =
              data.fetchPost.scheduleDate ||
              moment()
                .add(1, 'day')
                .format('YYYY-MM-DDTHH:mm:ss');
            app.$store.commit('createPost/UPDATE_SCHEDULE_DATE', date);
          });
        })
        .catch(() => {
          app.$notify({
            group: 'main',
            type: 'native-error',
            text: 'Error fetching post.',
          });

          next(false);
        });
    }

    next();
  },

  data() {
    return {
      error: null,
      savedSpeech: '',
      speech: '',
      charCount: 0,
      audioUrl: '',
      isProcessing: false,
      voiceId: 'Golden',
      savedVoiceId: 'Golden',

      finalizing: false,
      currentStep: 1,
      accountPostContents: [],
      parentType: null,
      isFailed: false,
    };
  },

  computed: {
    id() {
      return parseInt(this.$route.params.id, 10);
    },
    ...mapState({
      postStatus: state => state.createPost.postStatus,
    }),

    goBackText() {
      return this.currentStep === 1 ? 'Dashboard' : 'Go Back';
    },

    shouldRegenerateAudio() {
      // Trim ALL Whitespace
      const saved = this.savedSpeech.replace(/\s+/g, '');
      const speech = this.speech.replace(/\s+/g, '');

      return this.$route.params.id == 0 || saved !== speech || this.savedVoiceId !== this.voiceId || this.isFailed;
    },

    sequence() {
      if (this.isUserCollaboratorInWorkspace() || this.isUserAdminInWorkspace()) {
        return [
          {
            number: 1,
            name: 'Write Speech',
            to: '',
          },
          {
            number: 2,
            name: 'Select Accounts',
            to: '',
          },
          {
            number: 3,
            name: 'Customize',
            to: '',
          },
          {
            number: 4,
            name: this.canNotChangeStatus ? 'Schedule' : 'Publish',
            to: '',
          },
        ];
      }

      return [
        {
          number: 1,
          name: 'View Speech',
          to: '',
        },
      ];
    },

    isNextDisabled() {
      return this.stepDisabled(this.currentStep);
    },

    isLastStep() {
      return this.currentStep === this.sequence.length;
    },

    canNotChangeStatus() {
      return (
        (this.parentType && this.parentType == 'automation') ||
        (this.parentType && this.parentType == 'campaign') ||
        this.isUserCollaboratorInWorkspace()
      );
    },

    ...mapState({
      workspace: state => state.workspace.current,
    }),

    ...mapGetters('validation', {
      hasError: 'hasError',
    }),

    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),
  },

  watch: {
    '$route.params.id'(id) {
      if (id == 0) {
        this.savedSpeech = '';
        this.speech = '';
        this.audioUrl = '';
        this.voiceId = 'Golden';
        this.isProcessing = false;
        this.savedVoiceId = 'Golden';
      } else if (id !== '0') {
        this.isProcessing = false;
        window.location.reload();
      }
    },
  },

  beforeDestroy() {
    // Not called on page reload. Good!
    this.$store.dispatch('createPost/reset');
    this.$store.dispatch('validation/reset');
  },

  methods: {
    close() {
      this.$router.push({ name: 'dashboard' });
    },

    stepDisabled(step) {
      switch (step) {
        case 1:
          return this.shouldRegenerateAudio || this.isProcessing || this.charCount > 10000;
        case 2: {
          this.error = 'Please choose at least one account';
          return this.accountPostContents.length < 1;
        }
        case 3:
          this.error = 'Please fix all content errors, or go back and deselect accounts with errors';
          return this.hasError();
        default:
          return false;
      }
    },

    goBackHandler() {
      if (this.currentStep === 1) {
        return this.$router.push({ name: 'dashboard' });
      }

      this.setCurrentStep(this.currentStep - 1);
      window.scrollTo(0, 0);
    },

    /**
     * Moves to the next step by incrementing currentStep by 1
     *
     * @return {void}
     */
    async moveToNext() {
      if (this.isLastStep) {
        return this.savePost();
      }

      this.setCurrentStep(this.currentStep + 1);
      window.scrollTo(0, 0);
    },

    /**
     * Sets the current step
     *
     * @param  {Int} step A step number to move to
     * @return {void}
     */
    setCurrentStep(stepNumber) {
      if (stepNumber < this.currentStep || (!this.isNextDisabled && !this.stepDisabled(stepNumber - 1))) {
        this.currentStep = stepNumber;
      }
    },

    handleSpeechChange({ count, speech }) {
      this.speech = speech;
      this.charCount = count;
    },

    handleVoiceChange(voiceId) {
      this.voiceId = voiceId;
    },

    handleGeneratingChange(isGenerating) {
      this.isProcessing = isGenerating;
    },

    handleSaved(post) {
      this.isFailed = false;
      this.savedSpeech = post.masterContent;
      this.savedVoiceId = post.voiceId;

      this.$router.push({ params: { id: post.id } }, () => {});
    },

    handleSaveFailed() {
      this.isFailed = true;
    },

    syncAccountPostContents(params) {
      const { account } = params;

      const index = this.accountPostContents.findIndex(con => con.accountId === account.id);

      if (index === -1) {
        this.accountPostContents = [
          ...this.accountPostContents,
          {
            accountId: account.id,
            content: '',
            title: '',
          },
        ];
      } else {
        this.$store.dispatch('validation/dropError', account.id);
        this.$store.dispatch('validation/dropWarning', account.id);

        this.accountPostContents = [
          ...this.accountPostContents.slice(0, index),
          ...this.accountPostContents.slice(index + 1),
        ];
      }
    },

    handleContentsChange(accountPostContents) {
      this.accountPostContents = accountPostContents;
    },

    synSavedContents(accountPostContents) {
      if (accountPostContents && accountPostContents.length > 0) {
        let postContents = [];

        accountPostContents.forEach(postContent => {
          const attachments = postContent.attachments.map(attachment => ({ ...attachment, saved: true }));
          // We need to cherry-pick the properties I need so I don't send
          // invalid argument to GraphQL
          const {
            publishAccount,
            title,
            content,
            postLink,
            platform,
            publishVariant,
            publishAuthorId,
            publishCategoryIds,
            publishStatus,
          } = postContent;

          const contentItem = {
            accountId: publishAccount.id,
            title,
            content,
            postLink,
            platform,
            attachments,
            publishVariant,
            publishAuthorId,
            publishCategoryIds,
            publishStatus,
          };
          postContents.push(contentItem);

          const account = postContent.publishAccount;
          account &&
            this.$store.dispatch(
              'validation/validate',
              {
                account,
                postContent: contentItem,
              },
              { root: true },
            );
        });

        this.accountPostContents = postContents;
      }
    },

    savePost() {
      const { postStatus, scheduleTimezone, scheduleDate } = this.$store.state.createPost;

      // Let's manually add the CORRECT UTC offset.
      // This is super important
      const offset = moment(scheduleDate)
        .tz(scheduleTimezone)
        .format('Z');
      const momentTime = moment(`${scheduleDate}${offset}`).tz(scheduleTimezone);

      this.finalizing = true;
      this.$store.dispatch('layout/updateRestless', true);

      return this.$apollo
        .mutate({
          mutation: FINALIZE_AUDIO_POST_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: parseInt(this.$route.params.id, 10),
            status: postStatus,
            scheduleTime: momentTime.format(),
            scheduleTimezone,
            accounts: this.accountPostContents,
            publishNow: true,
          },
        })
        .then(({ data: { finalizeAudioPost: saved } }) => {
          this.finalizing = false;
          this.$store.dispatch('layout/updateRestless', false);

          Swal.fire({
            type: 'success',
            title: 'Awesome!',
            text: 'Your post has been saved successfully.',
            confirmButtonText: 'Okay',
          }).then(() => {
            this.$router.push({ name: 'post.view', params: { id: saved.id } });
          });
        })
        .catch(() => {
          this.finalizing = false;
          this.$store.dispatch('layout/updateRestless', false);

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Something went wrong while processing your request. Please try again.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.voice-composer-wrap {
  padding-top: $topbar-height;
  min-height: 100vh;

  .single-posts-content-wrap,
  .audio-customize-wrap {
    margin: 20px auto;
  }

  .single-posts-content-wrap {
    min-height: 80vh;
  }
  .audio-customize-wrap {
    min-height: 100vh;
  }

  .schedule-page-wrap {
    min-height: 100vh;
    margin-top: 50px;
  }
}
</style>
