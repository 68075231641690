<template>
  <div>
    <template v-if="campaigns.length > 0 && !$apollo.queries.campaignsQuery.loading">
      <simplebar class="tabs-scroll campaign-items-wrap">
        <div v-for="campaign in campaigns" :key="campaign.id" class="campaign-item">
          <div class="campaign-main">
            <div class="campaign-post-count">{{ campaign.postCount }}</div>
            <router-link :to="{ name: 'campaign.posts', params: { id: campaign.id } }" class="campaign-detail">
              <h4 class="campaign-name text-truncate">{{ campaign.title }}</h4>
              <p class="campaign-time">{{ getTimeAgo(campaign.createdAtIso) }}</p>
            </router-link>
          </div>
          <div class="campaign-status-wrap">
            <b-badge v-if="campaign.status === 'active'" variant="success"> In Progress </b-badge>
            <b-badge v-else variant="warning"> Inactive </b-badge>
          </div>
        </div>
      </simplebar>
      <div class="tab-footer">
        <router-link :to="{ name: 'campaigns' }">View All</router-link>
        <div v-if="campaigns" class="tab-info">First {{ campaigns.length }} of {{ workspace.campaignsCount }}</div>
      </div>
    </template>
    <div
      v-else-if="campaigns.length === 0 && !$apollo.queries.campaignsQuery.loading"
      class="tabs-scroll no-result-wrap text-center"
    >
      <img class="pt-3" width="150" src="@/assets/images/blank_canvas.svg" alt="" />
      <h5 class="pt-5">You have no Campaigns yet.</h5>
      <b-button :to="{ name: 'campaigns' }" class="mt-3 px-4" size="sm">
        <svg-icon name="plus" class="mr-1" />
        Create Some
      </b-button>
    </div>
    <div v-else class="tabs-scroll no-result-wrap text-center p-5">
      <div class="mt-5"><spinner size="4" /></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { DASHBOARD_CAMPAIGNS_QUERY } from '~/graphql/queries';

export default {
  components: { simplebar },

  data() {
    return {
      campaigns: [],
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  apollo: {
    campaignsQuery: {
      query: DASHBOARD_CAMPAIGNS_QUERY,
      manual: true,
      variables() {
        return {
          workspace: this.workspace.id,
          campaignLimit: 5,
        };
      },
      result({ data, loading }) {
        if (!loading) {
          this.campaigns = data.getCampaigns.campaigns;
        }
      },
    },
  },

  mounted() {
    if (this.$apollo.queries.campaignsQuery) {
      this.$apollo.queries.campaignsQuery.refetch();
    }
  },

  methods: {
    getTimeAgo(time) {
      return moment(time).fromNow();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.campaigns-tab {
  .campaign-item {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;

    &:last-of-type {
      border-bottom: 1px solid $gray-100;
    }

    .campaign-main {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      border-top: 1px solid $gray-100;
      width: 70%;

      .campaign-detail {
        text-decoration: none;
      }

      .campaign-post-count {
        margin-right: 15px;
        border: 3px solid white;
        box-shadow: 0px 2px 10px 1px $gray-200;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $primary;
        font-size: 1.2rem;
        background-color: $light;
      }

      .campaign-name {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-600;
        max-width: 190px;
      }

      .campaign-time {
        margin-bottom: 0;
        color: $gray-500;
      }
    }
    .campaign-status-wrap {
      width: 30%;
      display: flex;
      align-self: center;
      justify-content: flex-end;

      .badge {
        padding: 15px;
        font-weight: bold;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          border-bottom: 8px solid transparent;
          border-top: 8px solid transparent;
          left: -8px;
          top: 14px;
        }

        &.badge-warning {
          color: #fb7933;
          background-color: #ffe8da;

          &::before {
            border-right: 8px solid #ffe8da;
          }
        }
        &.badge-success {
          color: #4ead67;
          background-color: #e3f3e9;

          &::before {
            border-right: 8px solid #e3f3e9;
          }
        }
      }
    }

    &:hover {
      background-color: $light;
    }
  }
}
</style>
