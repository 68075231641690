<template>
  <div class="business_settings">
    <b-row class="business_settings-user-details">
      <b-col>
        <h5 class="mb-4 pb-2 title">Update Business Details</h5>
        <form @submit.prevent="handleUpdateBusiness" @keydown="form.onKeydown($event)">
          <b-form-group label-cols="8" label-cols-lg="4" label="Business Name" label-for="business_name">
            <b-form-input v-model="form.name" id="business_name" autocomplete="off"></b-form-input>
            <has-error :form="form" field="business_name" />
          </b-form-group>
          <b-form-group label-cols="8" label-cols-lg="4" label="Business Address" label-for="business_address">
            <b-form-input v-model="form.address" id="business_address" autocomplete="off"></b-form-input>
            <has-error :form="form" field="business_address" />
          </b-form-group>
          <b-form-group label-cols="8" label-cols-lg="4" label="Contact Number" label-for="business_phone">
            <vue-tel-input id="business_phone-field" v-model="form.phone" v-bind="bindPhoneProps" />
            <has-error :form="form" field="business_phone" />
          </b-form-group>
          <b-row>
            <b-col class="text-right">
              <b-button variant="primary" class="px-4 py-2 biz-btn" type="submit" :disabled="isUpdatingBusiness">
                <b-spinner v-if="isUpdatingBusiness" small class="mr-2" />
                <svg-icon v-else name="check" size="md" class="mr-2" />
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-col>
      <b-col class="">
        <div class="avatar__container">
          <div class="avatar__container-avatar">
            <div class="profile-avatar">
              <b-avatar class="avatar photo-preview" :src="url || business.photoUrl" size="9rem" />
              <div class="file-upload">
                <b-button variant="clear" class="" size="md" rounded @click="$refs.Avatar.click()">
                  <svg-icon name="camera" size="md" />
                </b-button>
                <input id="avatar" type="file" ref="Avatar" class="file-upload__input" @change="handleFileUpload()" />
              </div>
            </div>
          </div>
          <div class="avatar__container-content">
            <div class="title">{{ business.name }}</div>
            <div class="email">{{ business.slug }}</div>
          </div>
        </div>
        <div class="upload-info">
          <p class="small mb-2">Recommended size: Less than 2MB (130 x 130)</p>
          <!-- <b-button variant="outline-primary" size="sm" class="px-3" @click="$refs.Avatar.click()">
            Upload image
          </b-button> -->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Form from 'vform';
import { mapState, mapActions } from 'vuex';
import { SAVE_BUSINESS_MUTATION, UPLOAD_WORKSPACE_LOGO_MUTATION } from '~/graphql/mutations';
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'edit-proile-modal',
  components: { VueTelInput },

  data() {
    return {
      form: new Form({
        name: '',
        address: '',
        phone: '',
        logo: '',
      }),
      brandLogoUrl: '',
      bindPhoneProps: {
        mode: 'international',
        defaultCountry: 'US',
        disabledFetchingCountry: false,
        disabled: false,
        placeholder: 'Biz Phone',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        autocomplete: 'off',
        name: 'phone',
        wrapperClasses: 'form-input',
      },
      url: null,
      isUpdatingBusiness: false,
    };
  },

  computed: {
    ...mapState('business', {
      business: state => state.current,
    }),
  },

  watch: {
    $route() {
      // React to route/business switch...
      this.syncForm();
    },
  },

  created() {
    this.syncForm();
  },

  methods: {
    ...mapActions({
      updateCurrentBusiness: 'business/updateCurrent',
    }),

    syncForm() {
      this.form.keys().forEach(key => {
        this.form[key] = this.business[key];
      });
    },

    // Default Workspace Logo
    async handleBrandLogoUpload() {
      if (this.brandLogoUrl) {
        return this.brandLogoUrl;
      }

      const file = this.$refs.Avatar.files[0];
      if (!this.isValidFile(file)) return;

      const {
        data: { uploadWorkspaceLogo },
      } = await this.$apollo.mutate({
        mutation: UPLOAD_WORKSPACE_LOGO_MUTATION,
        variables: { logo: file },
      });

      const brandLogo = uploadWorkspaceLogo.url;
      this.brandLogoUrl = brandLogo;
      this.form.logo = brandLogo;

      return this.brandLogoUrl;
    },

    async handleUpdateBusiness() {
      this.isUpdatingBusiness = true;
      const logoUrl = await this.handleBrandLogoUpload();

      this.$apollo
        .mutate({
          mutation: SAVE_BUSINESS_MUTATION,
          variables: {
            id: this.business.id,
            ...this.form.data(),
          },
        })
        .then(({ data }) => {
          this.isUpdatingBusiness = false;

          this.updateCurrentBusiness(data.saveBusiness);
          this.$router.push({ params: { business: data.saveBusiness.uuid } });

          this.$notify({
            group: 'main',
            type: 'success',
            text: 'Business has been updated successfully',
          });
        })
        .catch(error => {
          this.isUpdatingBusiness = false;
          this.showValidationErrors(error);

          this.url = null;
        });
    },

    handleFileUpload: function() {
      const file = this.$refs.Avatar.files[0];

      if (!file) return;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);

      this.url = file;

      if (!this.isValidFile(file)) return;
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `Please upload a valid image`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 5240) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 5MB',
        });
        return false;
      }

      return true;
    },

    showValidationErrors({ graphQLErrors }) {
      const validations = graphQLErrors.filter(err => err.message == 'validation');

      if (validations.length) {
        validations.forEach(err => {
          const errors = err.extensions.validation;
          for (let key in errors) {
            const fieldErrors = errors[key];

            this.$notify({
              group: 'main',
              type: 'error',
              title: `Invalid ${key}`,
              text: fieldErrors.join('<br />'),
            });
          }
        });
      } else {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'An error occurred while processing your request.',
        });
      }
    },

    validateForm() {
      if (!this.form.name || this.business.name.length < 3) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Business Name must be at least 3 characters',
        });

        return false;
      }
      if (!this.form.address) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Form Field cannot be blank',
        });

        return false;
      }
      if (!this.form.phone) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Form Field cannot be blank',
        });

        return false;
      }
      return true;
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>;
<style lang="scss">
@import '~@/scss/variables';

.business_settings {
  margin: 2px 10px;
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  .biz-btn {
    height: 40px;
  }
  &-user-details {
    background: $secondary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary;
    padding: 40px;
    border-radius: 20px;
    .form-control {
      height: 40px;
      &::placeholder {
        font-size: 13px;
        color: $gray-500;
      }
    }
    .avatar__container {
      background: $primary;
      padding: 20px;
      margin-left: 60px;
      position: relative;
      color: white;
      border-radius: 10px;
      &-avatar {
        position: absolute;
        top: -30px;
        left: -40px;
        .profile-avatar {
          position: relative;
          .avatar {
            border: 2px solid #52b788;
          }
          .file-upload {
            position: absolute;
            display: block;
            cursor: pointer;
            bottom: -15px;
            right: 43px;
            padding: 4px 2px;
            background-color: $primary;
            border-radius: 50%;
            svg {
              fill: $secondary;
            }
          }

          .file-upload__input {
            display: none;
          }
        }
      }
      &-content {
        text-align: left;
        padding-left: 100px;
        color: $secondary;
        .title {
          text-transform: capitalize;
          font-size: 18px;
        }
        .email {
          text-transform: lowercase;
          font-size: 12px;
        }
      }
    }
    .upload-info {
      margin-left: 180px;
      margin-top: 6px;
    }
    .vti__input {
      border: none !important;
      height: 40px;
      background: transparent !important;
    }
    .vue-tel-input:focus-within,
    .vti__dropdown:hover {
      border-color: $primary !important;
      background: $white !important;
    }
    .vue-tel-input {
      border: 1.5px solid #b7e4c7 !important;
      border-radius: 8px !important;
      color: $primary !important;
      background: $white !important;
    }
  }
}
</style>
