<template>
  <table class="table table-borderless">
    <thead>
      <tr>
        <th class="post-table-title" style="width: 400px;">{{ name }}</th>
        <th class="post-table-title">Status</th>
        <th class="post-table-title" v-if="showStats" style="width: 100px; text-align: center;">Statistics</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="content in contents" :key="content.accountId">
        <td>
          <div class="profile_picture d-flex align-items-center">
            <PublishAccountImage :account="content.publishAccount" class="mr-3" width="30" />
            <div class="view-post-account-name">{{ content.publishAccount.name }}</div>
          </div>
        </td>
        <td>
          <b-badge class="post-status-text px-3" :variant="getStatusVariant(content.status)">{{
            content.status === 'publish' ? 'processing' : content.status
          }}</b-badge>
          <span
            v-if="content.status === 'failed' && content.statusMessage"
            class="post-status-message"
            v-html="content.statusMessage"
          ></span>
          <span v-if="content.status === 'published'">
            [<a :href="content.liveLink" target="_blank">Live Link</a><svg-icon name="external-link" class="ml-1" />]
          </span>
        </td>
        <td v-if="showStats">
          <div class="post-stats d-flex align-items-center justify-content-end">
            <div v-b-tooltip title="Likes" class="post-stat-item">
              <svg-icon name="thumbs-up" />
              <span class="post-stat-text">0</span>
            </div>
            <div v-b-tooltip title="Shares" class="post-stat-item">
              <svg-icon name="share" />
              <span class="post-stat-text">0</span>
            </div>
            <div v-b-tooltip title="Comments" class="post-stat-item">
              <svg-icon name="comments" />
              <span class="post-stat-text">0</span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PublishAccountImage from '~/components/PublishAccountImage';

export default {
  components: { PublishAccountImage },
  props: {
    name: {
      type: String,
      default: 'Account',
    },
    contents: {
      type: Array,
      required: true,
    },
    showStats: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getStatusVariant(status) {
      if (status === 'published') {
        return 'success';
      } else if (status === 'scheduled') {
        return 'warning';
      } else if (status === 'failed') {
        return 'danger';
      } else {
        return 'primary';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.post-table-title {
  text-transform: uppercase;
  color: $gray-500;
  font-size: 0.9rem;
  letter-spacing: 2px;
}
</style>
