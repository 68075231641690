<template>
  <div class="container-fluid">
    <b-row class="welcome__box-info justify-content-between py-5 shadow-sm align-items-center">
      <b-col cols="6" v-if="user" class="welcome__box-welcome">
        <div class="d-flex align-items-center flex-row">
          <div class="w-100 pl-4">
            <h3 class="name">Hi {{ user.firstName }}</h3>
            <p style="font-weight: 300;" class="m-0 p-0 desc">Remember, Content is fire, social media is gasoline.</p>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <!---<div class="i-box">
          <img src="@/assets/icons/welcome.svg" />
        </div>-->
        <div class="d-flex justify-content-end">
          <Stats class="pl-2" />
        </div>
      </b-col>
      <!-- <b-col class="welcome__box-stats"> -->

      <!-- </b-col> -->
    </b-row>

    <Banner />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Banner from '~/components/General/Banner.vue';
import Stats from './Stats';

export default {
  data() {
    return {
      stats: {},
    };
  },
  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      user: state => state.auth.user,
    }),
  },
  components: { Banner, Stats },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.i-box {
  max-width: 400px;
  margin: -30px 0px -100px -100px;
  overflow: clip;
  max-height: auto;
  & img {
    width: 300px;
    object-fit: contain;
  }
}
.welcome__box {
  &-info {
    overflow: clip;
    color: $primary;
    background: white;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px -30px 50px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
  }
  &-welcome {
    padding: 15px 0px;
    .name {
      font-weight: bold;
      font-size: 25px;
    }
    .desc {
      font-size: 16px;
    }
  }
  &-stats {
    background: #f2f2f2;
    color: #000000;
    border-radius: 15px;
    padding: 10px;
    text-align: right;
  }
}

.w-32 {
  max-width: 100px;
  position: relative;
}
</style>
