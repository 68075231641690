<template>
  <div class="mx-5 px-5 py-3">
    <b-row class="account-contents">
      <b-col md="4" sm="12">
        <div class="mb-2 text-primary border border-primary rounded p-2 text-center">
          Customize content to suite your audience
        </div>
        <ChosenPublishAccounts
          :accounts="chosenAccounts"
          :activeAccount="activeAccount"
          @item-click="switchActiveTab"
        />
      </b-col>
      <b-col md="8" sm="12">
        <div v-if="activeAccount" class="active-content-area bg-light p-5 rounded-sm">
          <div class="d-flex align-items-center mb-3">
            <img
              class="mr-2 bg-white shadow-sm p-1 rounded-circle"
              :src="activeAccount.imageUrl"
              alt=""
              style="height: 40px; width: 40px;"
            />
            <div class="font-weight-bold text-truncate">{{ activeAccount.name }}</div>
          </div>

          <b-form-input
            v-if="supportsTitle"
            v-model="activeAccountTitle"
            @input="activeContentChange"
            type="text"
            class="rounded-0 mb-3 border-0 shadow-sm"
            placeholder="Post title"
          />

          <PostEditor
            v-model="activeAccountContent"
            :selectedImages="activeAccountImages"
            @input="activeContentChange"
            :charLimit="activeAccountCharLimit"
            @removeSelectedImage="handleRemoveSelectedImage"
          ></PostEditor>

          <b-form-input
            v-if="supportsPostLink"
            v-model="activeAccountPostLink"
            class="rounded-0 mt-3 border-0 shadow-sm"
            type="text"
            placeholder="Post URL"
          />
        </div>
        <div v-else class="px-4 bg-light p-5 text-center"><h4>No accounts selected.</h4></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PostEditor from '~/components/PostEditor';
import EditPostContentMixin from '~/mixins/EditPostContentMixin';
import ChosenPublishAccounts from '~/components/ChosenPublishAccounts';

export default {
  mixins: [EditPostContentMixin],

  components: {
    PostEditor,
    ChosenPublishAccounts,
  },

  methods: {
    handleRemoveSelectedImage(attachementIndex) {
      if (this.activeAccountImages[attachementIndex]) {
        this.activeAccountImages = [
          ...this.activeAccountImages.slice(0, attachementIndex),
          ...this.activeAccountImages.slice(attachementIndex + 1),
        ];

        // Trigger re-validation
        this.activeContentChange();
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.active-content-area {
  background: $white;
  border-radius: 10px;
}
</style>
