<template>
  <div class="automation-campaign-schedule auto-box shadow-sm rounded-sm">
    <div class="auto-box-inner">
      <h5 class="mb-5">Schedule Options</h5>

      <b-form-group label="Schedule time" class="mt-4">
        <b-form-radio-group
          id="schedule-options"
          :checked="scheduleType"
          @change="handleScheduleTypeChange"
          name="schedule-options"
        >
          <b-form-radio value="slots">Custom time-slots</b-form-radio>
          <b-form-radio value="interval">Regular Intervals</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <template v-if="scheduleType === 'slots'">
        <b-form-group label="Which date format have you used in the file?" class="">
          <b-form-select :value="csvDateFormat" @change="handleCsvDateFormatChange" id="csvDateFormat" class="w-auto">
            <option value="m/d/Y H:i">mm/dd/yyyy hh:mm</option> <option value="d/m/Y H:i">dd/mm/yyyy hh:mm</option>
          </b-form-select>
        </b-form-group>

        <div class="font-weight-bold">Timezone: UTC</div>

        <div class="time-formats mt-4 d-flex align-items-start">
          <div class="left w-50">
            <p class="title">Select your date format</p>
            <ul v-if="csvDateFormat === 'm/d/Y H:i'">
              <li>05/23/2020 7:05</li>
              <li>05/24/2020 17:05</li>
              <li>05/25/2020 7:05</li>
              <li>05/26/2020 17:05</li>
            </ul>
            <ul v-else>
              <li>23/05/2020 7:05</li>
              <li>24/05/2020 17:05</li>
              <li>25/05/2020 7:05</li>
              <li>26/05/2020 17:05</li>
            </ul>
          </div>

          <div class="right w-50">
            <p class="title">How we interpret this date</p>
            <ul>
              <li>May 23, 2020 7:05 AM</li>
              <li>May 24, 2020 5:05 PM</li>
              <li>May 25, 2020 7:05 AM</li>
              <li>May 26, 2020 5:05 PM</li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else-if="scheduleType === 'interval'">
        <b-row class="mt-4">
          <b-col align-self="center" md="3"> <label for="post-age">Post Every</label> </b-col>
          <b-col md="4" class="d-flex">
            <b-form-input
              :value="recurringValue"
              @change="handleRecurringValueChange"
              id="recurringValue"
              type="number"
              min="1"
              max="24"
              class="mr-2"
            />
            <b-form-select :value="recurringUnit" @change="handleRecurringUnitChange" id="recurringUnit">
              <option value="hour">Hour(s)</option> <option value="day">Day(s)</option>
              <option value="week">Week(s)</option>
            </b-form-select>
          </b-col>
        </b-row>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'automation-schedule-step',

  computed: {
    isEditing() {
      return this.id !== 0;
    },

    ...mapState('createAutomation', {
      id: state => state.id,
      recurringValue: state => state.recurringValue,
      recurringUnit: state => state.recurringUnit,
      scheduleType: state => state.scheduleType,
      reviewPosts: state => state.reviewPosts,
      maxPostCount: state => state.maxPostCount,
      removeExistingPosts: state => state.removeExistingPosts,
      csvDateFormat: state => state.csvDateFormat,
    }),
  },

  mounted() {
    if (this.scheduleType === 'continuous') {
      this.$store.dispatch('createAutomation/updateScheduleType', 'slots');
    }
  },

  methods: {
    handleCsvDateFormatChange(input) {
      this.$store.dispatch('createAutomation/updateCsvDateFormat', input);
    },

    handleScheduleTypeChange(value) {
      this.$store.dispatch('createAutomation/updateScheduleType', value);
    },

    handleRecurringValueChange(value) {
      this.$store.dispatch('createAutomation/updateRecurringValue', value);
    },

    handleRecurringUnitChange(value) {
      this.$store.dispatch('createAutomation/updateRecurringUnit', value);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.automation-campaign-schedule {
  .time-formats {
    border: 1px solid rgba(152, 158, 181, 0.5);
    border-radius: 5px;
    background-clip: padding-box;
    padding: 15px;

    .title {
      color: $gray-700;
      margin-bottom: 8px;
      font-size: 14px;
    }

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      color: $gray-500;
      font-size: 13px;
      margin-top: 3px;
    }
  }

  #schedule-options {
    font-size: 0.9rem;
  }

  .campaign-alert {
    font-size: 0.9rem;
  }
}
</style>
