<template>
  <form class="menububble__sayas-form" @submit.prevent="update">
    <div class="inputs-wrap">
      <span class="bubble-sub-label">Alias:</span>
      <b-form-input
        v-model="aliasValue"
        ref="subInput"
        size="sm"
        type="text"
        class="bubble-sub-input"
        placeholder="What to say instead..."
      />
    </div>

    <b-button type="submit" variant="primary" size="sm"> <svg-icon name="check" /> </b-button>
  </form>
</template>

<script>
export default {
  props: {
    alias: {
      default: null,
    },

    command: {
      required: true,
    },
  },

  data() {
    return {
      aliasValue: this.alias,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.subInput.focus();
    });
  },

  methods: {
    update() {
      this.command({ alias: this.aliasValue });

      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menububble__sayas-form {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
  display: flex;

  .btn {
    box-shadow: none;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .inputs-wrap {
    // padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
  }

  .bubble-sub-label {
    font-weight: bold;
    font-size: 0.95rem;
    margin-right: 10px;
  }

  .bubble-sub-input {
    &::placeholder {
      color: $gray-500;
    }
  }
}
</style>
