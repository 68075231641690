<template>
  <div class="ds-day-picker border border-primary rounded-lg py-3">
    <div class="ds-week-header text-center mb-1 px-2">
      <b-button
        v-if="hasPrev"
        variant="clear"
        size="sm"
        v-b-tooltip.html.bottom
        :title="labels.prevMonth"
        @click="prev"
      >
        <svg-icon name="chevron-left" />
      </b-button>

      <div class="subtitle font-weight-bold py-1 pl-2">{{ currentMonth }}</div>

      <b-button variant="clear" size="sm" v-b-tooltip.html.bottom :title="labels.nextMonth" @click="next">
        <svg-icon name="chevron-right" />
      </b-button>
    </div>

    <div class="pt-2">
      <div class="ds-week-subheader ds-light-forecolor">
        <div class="ds-weekday mb-2" v-for="weekday in weekdays" :key="weekday">
          <span v-b-tooltip.html.bottom :title="weekday">{{ weekday.substring(0, 2) }}</span>
        </div>
      </div>

      <div class="ds-week">
        <div class="ds-day-pick" v-for="day in month.days" :key="day.dayIdentifier">
          <b-button
            size="sm"
            variant="transparent"
            class="rounded-circle my-1"
            @click="pick(day)"
            :class="{
              'btn-clear': !day.currentDay,
              'btn-outline-primary': day.currentDay,
              'ds-light-forecolor': !day.inCalendar,
              'btn-primary': isHighlighted(day),
            }"
          >
            {{ day.dayOfMonth }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Day, DaySpan, Calendar } from 'dayspan';

export default {
  name: 'PostCalendar',

  props: {
    date: {
      type: String,
      required: true,
    },

    highlightSpan: {
      type: Boolean,
      default: true,
    },

    weekdays: {
      type: Array,
      default() {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      },
    },

    labels: {
      default() {
        return {
          prevMonth: 'Previous month',
          nextMonth: 'Next month',
        };
      },
    },
  },

  data() {
    return {
      month: this.getMonth(),
      day: Day.fromString(this.date),
    };
  },

  computed: {
    span: {
      get() {
        return new DaySpan(this.day, this.day);
      },
    },

    hasPrev() {
      return Day.today().month < this.month.end.month;
    },

    currentMonth() {
      return this.month ? this.month.summary(false, false, false, false) : '';
    },
  },

  watch: {
    date(date) {
      this.day = Day.fromString(date);
    },
  },

  methods: {
    isHighlighted(day) {
      return this.highlightSpan && this.span.matchesDay(day);
    },

    getMonthStart() {
      return this.span && this.span.start ? this.span.start : this.day;
    },

    getMonth() {
      return Calendar.months(1, this.getMonthStart(), 0, { fill: true, minimumSize: 42 });
    },

    pick(day) {
      // We need to manually get and reset the previously set schedule time
      const date = moment(this.date);
      const time = day.asTime();
      time.set({
        ...time,
        hour: date.hour(),
        minute: date.minute(),
      });

      const newDay = day.withTime(time);
      this.$emit('pick', newDay.format('YYYY-MM-DDTHH:mm:ss'));
    },

    prev() {
      this.month.prev();
    },

    next() {
      this.month.next();
    },
  },
};
</script>

<style scoped lang="scss">
.ds-day-picker {
  background: #f2f2f2;
  color: #40916c;
  .ds-week-header {
    display: flex;
    // D8F3DC
    > div {
      flex: 1;
    }

    .tooltip {
      flex: 1;
      text-align: center;
    }

    .subtitle {
      flex: 5;
    }
  }

  .ds-week-subheader {
    display: flex;
    text-align: center;
  }

  .ds-week {
    display: flex;
    text-align: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ds-weekday {
    flex: 1;
  }

  .ds-day-pick {
    flex: 1 0 14%;

    .btn {
      height: 30px;
      width: 30px;
      font-size: 0.8rem;

      &.btn-secondary {
        border-color: transparent;
        color: white;
      }
    }
  }
}
</style>
