<template>
  <div class=" loader-container">
    <div>
      <div class="loader-span-icon">
        <span class="loader"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
};
</script>
<style lang="scss">
@import '~@/scss/variables';

.loader-container {
  background-color: white;
  min-height: 50%;
  width: calc(16px * 6);
  display: inline-block;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .loader-span-icon {
    display: flex;
    justify-content: center;
    height: 150px;
    transform: translate(-5%, 60%);

    span {
      transform: translateX(-160%);
    }
  }
}

.loader {
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: -50px auto 0;
  position: relative;
  color: green;
  transform: translateY(30px);
  box-sizing: border-box;
  animation: animloader 2s ease infinite;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  color: green;
  position: absolute;
  left: 30px;
  top: 0;
  animation: animloader 2s 0.2s ease infinite;
}
.loader::before {
  animation-delay: 0.4s;
  left: 60px;
}

@keyframes animloader {
  0% {
    top: 0;
    color: green;
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color: $primary;
  }
}
</style>
