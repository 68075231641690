<template>
  <b-dropdown
    ref="hashtagDropdown"
    class="hashtag_dropdown"
    toggle-class="px-0"
    size="sm"
    variant="clear"
    no-caret
    no-flip
  >
    <b-button slot="button-content" id="inserHashtag" variant="clear" class="node-btns py-1 px-2">
      <svg-icon name="hash" />
    </b-button>
    <b-tooltip target="inserHashtag" triggers="hover">Add Hashtag</b-tooltip>
    <b-dropdown-form @submit.prevent="saveHashtagPreset" @keydown="hashtagForm.onKeydown($event)" class="py-0">
      <div class="utm-wrap py-3">
        <b-form-group label="Select from Saved Hashtags" label-for="hashtags">
          <b-form-select id="hashtags" v-model="hashtagForm.id" @change="handleHashtagPresetSelect">
            <option :value="0">Create New</option>
            <template v-if="hashtagPresets && hashtagPresets.length">
              <option v-for="hashtagPreset in hashtagPresets" :key="hashtagPreset.id" :value="hashtagPreset.id">{{
                hashtagPreset.name
              }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-if="isEditingHashtagPreset">
          <b-form-group label="Name" label-for="hashtagName">
            <b-form-input
              v-model="hashtagForm.name"
              id="hashtagName"
              placeholder="Enter hashtag name..."
              autocomplete="off"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Hashtags" label-for="hashtags-input">
            <b-form-input
              v-model="hashtagInput"
              id="hashtags-input"
              placeholder="Press 'Enter' to add hashtags such as, ‘#DigitalMarketing"
              @keydown="handleHashtagInputKeydown"
            ></b-form-input>

            <div class="hashtag-items d-flex flex-wrap">
              <div
                v-for="(hash, i) in hashtagForm.items"
                :key="i + 1"
                class="hashtag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
              >
                {{ hash }}
                <span @click="removeHashtagItem(i)" class="hash-item-remove small">
                  <svg-icon name="close" />
                </span>
              </div>
            </div>
          </b-form-group>

          <div class="d-flex mb-3 align-items-center">
            <label for="" class="mb-0" style="min-width: 150px;">Randomly choose</label>
            <input
              v-model="hashtagForm.insertCount"
              type="number"
              min="1"
              max="5"
              class="w-auto"
              style="max-width: 170px;"
              required
            />
          </div>

          <b-form-group label="Hashtag(s) for each post." label-for="utmsource">
            <b-form-radio v-model="hashtagForm.insertType" name="insert_type" value="Append" required>
              Append
            </b-form-radio>
            <b-form-radio v-model="hashtagForm.insertType" name="insert_type" value="Replace" required>
              Replace (if keyword is matched)
            </b-form-radio>
          </b-form-group>

          <div class="text-center">
            <b-button :disabled="hashtagForm.busy || !hashtagForm.items.length" type="submit" variant="primary">
              Save & Apply
            </b-button>
          </div>
        </template>
        <div v-else>
          <div class="border rounded">
            <div class="p-3">
              <div class="hashtag-items d-flex flex-wrap">
                <div
                  v-for="(hash, i) in selectedHashtag.items"
                  :key="i + 1"
                  class="hashtag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
                >
                  {{ hash }}
                </div>
              </div>

              <ul class="hashtag-desc-list">
                <li class="mt-2">{{ selectedHashtag.insertCount }} hashtag(s) from above will be randomly chosen</li>
                <li class="mt-2">
                  Hashtag(s) will be {{ selectedHashtag.insertType == 'Append' ? 'appended' : 'replaced' }}
                </li>
              </ul>
            </div>
            <div class="text-right">
              <b-button @click="editSelectedHashtag" class="border" variant="light" size="sm">Edit</b-button>
            </div>
          </div>

          <div class="text-center mt-3">
            <b-button variant="primary" @click="applySelectedHashtag">Apply</b-button>
          </div>
        </div>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';
import { shuffle } from 'lodash';

import { HASHTAG_PRESETS_QUERY } from '~/graphql/queries';
import { SAVE_HASHTAG_PRESET_MUTATION } from '~/graphql/mutations';

export default {
  data() {
    return {
      hashtagPresets: [],
      isEditingHashtagPreset: true,
      hashtagInput: '',
      hashtagForm: new Form({
        id: 0,
        name: '',
        items: [],
        insertCount: 1,
        insertType: 'Append',
      }),
    };
  },

  apollo: {
    hashtagPresets: {
      query: HASHTAG_PRESETS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
        };
      },
    },
  },

  computed: {
    selectedHashtag() {
      const defaultHashtag = {
        id: 0,
        items: [],
        insertCount: 1,
        insertType: 'Append',
      };

      if (!this.hashtagPresets.length) {
        return defaultHashtag;
      }
      const selectedHashtag = this.hashtagPresets.find(hashtag => hashtag.id === this.hashtagForm.id);
      return selectedHashtag ? selectedHashtag : defaultHashtag;
    },

    ...mapState({
      workspace: state => state.workspace.current,
      selectedContent: state => state.createPost.selectedContent,
    }),
  },

  methods: {
    handleHashtagPresetSelect(id) {
      this.hashtagForm.keys().forEach(key => {
        this.hashtagForm[key] = this.selectedHashtag[key] !== undefined ? this.selectedHashtag[key] : '';
      });

      if (id === 0) {
        this.isEditingHashtagPreset = true;
      } else {
        this.isEditingHashtagPreset = false;
      }
    },

    handleHashtagInputKeydown(e) {
      if (e.code == 'Comma' || e.code == 'Enter') {
        e.preventDefault();

        let hashtag = this.hashtagInput;
        if (hashtag.substring(0, 1) !== '#') {
          hashtag = `#${hashtag}`;
        }
        this.hashtagInput = '';

        if (!this.hashtagForm.items.find(h => h === hashtag)) {
          this.hashtagForm.items = [...this.hashtagForm.items, hashtag];
        }
      }
    },

    removeHashtagItem(index) {
      this.hashtagForm.items = [...this.hashtagForm.items.slice(0, index), ...this.hashtagForm.items.slice(index + 1)];
    },

    applySelectedHashtag() {
      this.insertHashtagIntoEditor();
    },

    async saveHashtagPreset() {
      if (this.hashtagForm.items.length < 1) {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Please add at least one hashtag. Remember press enter or add a comma after each hashtag.',
          duration: 7000,
        });
      }

      this.hashtagForm.busy = true;

      await this.$apollo.mutate({
        mutation: SAVE_HASHTAG_PRESET_MUTATION,
        variables: {
          workspace: parseInt(this.workspace.id, 10),
          id: parseInt(this.hashtagForm.id, 10),
          name: String(this.hashtagForm.name),
          hashtags: this.hashtagForm.items,
          insertCount: parseInt(this.hashtagForm.insertCount, 10),
          insertType: String(this.hashtagForm.insertType),
        },
      });

      this.hashtagForm.busy = false;

      this.insertHashtagIntoEditor();
    },

    insertHashtagIntoEditor() {
      const hashtags = shuffle(this.hashtagForm.items);
      const insertType = this.hashtagForm.insertType;

      const limitedHashtags = hashtags.slice(0, this.hashtagForm.itemCount);
      const content = this.selectedContent && this.selectedContent.body ? this.selectedContent.body : '';

      let update = this.selectedContent;
      if (insertType === 'Append') {
        update = `${content} ${limitedHashtags.map(hash => `<span class="hashtag">${hash}</span>`).join(' ')}`;
      } else if (insertType === 'Replace') {
        update = limitedHashtags.reduce((accumulator, hashtag) => {
          const hashword = hashtag.replace('#', '');
          const searchTerm = new RegExp(`\\b${hashword}\\b`, 'i');

          if (accumulator.search(searchTerm) === -1) {
            return `${accumulator} <span class="hashtag">${hashtag}</span>`;
          } else {
            return accumulator.replace(searchTerm, `<span class="hashtag">${hashtag}</span>`);
          }
        }, content);
      }

      this.$emit('insert', update);

      this.$refs.hashtagDropdown.hide();

      this.$apollo.queries.hashtagPresets.refetch();

      this.isEditingHashtagPreset = true;
      this.hashtagForm.reset();
    },

    editSelectedHashtag() {
      this.isEditingHashtagPreset = true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.hashtag-items {
  .hashtag-item {
    font-size: 0.8rem;
    margin-bottom: 8px;

    .hash-item-remove {
      cursor: pointer;
      font-weight: bold;
      margin-left: 0.3rem;

      .icon {
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0;
        fill: $gray-800;
      }
    }
  }
}

.hashtag-desc-list {
  padding: 0;
  list-style: none;

  li {
    position: relative;
    font-size: 13px;
    color: #3a4557;
    padding-left: 15px;

    &::before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-clip: padding-box;
      background: #989eb5;
      left: 0;
      top: 6px;
    }
  }
}
</style>
