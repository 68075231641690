<template>
  <div class="linkedin-report-entry">Linkedin</div>
</template>

<script>
export default {
  name: 'LinkedinReport',
};
</script>

<style lang="scss" scoped>
.linkedin-report-entry {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>
