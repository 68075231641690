<template>
  <div class="automation-campaign-schedule auto-box shadow-sm rounded-sm">
    <div class="auto-box-inner">
      <h5 class="mb-5">Schedule and Finalize</h5>

      <b-row class="mt-4" noGutters>
        <b-col align-self="center" md="3"> <label for="post-age">Update Frequency</label> </b-col>
        <b-col md="5" class="">
          <b-form-select :value="recurringValue" @change="handleRecurringValueChange" id="recurringValue">
            <option value="5m">Every 5 minutes</option><option value="15m">Every 15 minutes</option
            ><option value="30m">Every 30 minutes</option><option value="1h">Every hour</option
            ><option value="3h">Every 3 hours</option><option value="6h">Every 6 hours</option
            ><option value="12h">Every 12 hours</option><option value="24h">Once a day</option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-form-group label="When new posts are found in this feed" class="mt-4">
        <b-form-radio-group
          id="schedule-options"
          :checked="scheduleType"
          @change="handleScheduleTypeChange"
          name="schedule-options"
        >
          <b-form-radio value="post">Post immediately</b-form-radio>
          <b-form-radio value="review">Save for review</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div class="mt-4">
        Max posts per feed update
        <input
          :value="maxPostCount"
          @change="handleMaxPostCountChange"
          type="number"
          class="prefetch-count ml-2"
          min="1"
          max="10"
        />
      </div>

      <template v-if="isEditing">
        <b-row no-gutters>
          <b-col md="10">
            <b-alert variant="info" class="campaign-alert mt-4" show
              >Note: New posts will be scheduled for your accounts on the next refill.</b-alert
            >
          </b-col>
        </b-row>

        <b-form-checkbox
          id="removeExisting"
          :checked="removeExistingPosts"
          @input="handleRemoveExistingPostsChange"
          name="removeExisting"
        >
          Remove existing scheduled posts
        </b-form-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'automation-schedule-step',

  computed: {
    isEditing() {
      return this.id !== 0;
    },

    ...mapState('createAutomation', {
      id: state => state.id,
      recurringValue: state => {
        const dict = { minute: 'm', hour: 'h' };
        return `${state.recurringValue}${dict[state.recurringUnit]}`;
      },

      scheduleType: state => state.scheduleType,

      reviewPosts: state => state.reviewPosts,
      maxPostCount: state => state.maxPostCount,
      removeExistingPosts: state => state.removeExistingPosts,
    }),
  },

  mounted() {
    if (this.scheduleType === 'continuous') {
      this.$store.dispatch('createAutomation/updateScheduleType', 'post');
    }
  },

  methods: {
    handleRecurringValueChange(input) {
      const dict = {
        '5m': {
          value: 5,
          unit: 'minute',
        },
        '15m': {
          value: 15,
          unit: 'minute',
        },
        '30m': {
          value: 30,
          unit: 'minute',
        },
        '1h': {
          value: 1,
          unit: 'hour',
        },
        '3h': {
          value: 3,
          unit: 'hour',
        },
        '6h': {
          value: 6,
          unit: 'hour',
        },
        '12h': {
          value: 12,
          unit: 'hour',
        },
        '24h': {
          value: 24,
          unit: 'hour',
        },
      };

      if (dict[input]) {
        const { value, unit } = dict[input];

        this.$store.dispatch('createAutomation/updateRecurringValue', value);
        this.$store.dispatch('createAutomation/updateRecurringUnit', unit);
      }
    },

    handleScheduleTypeChange(value) {
      if (value === 'post') {
        this.$store.dispatch('createAutomation/updateReviewPosts', false);
      } else {
        this.$store.dispatch('createAutomation/updateReviewPosts', true);
      }
      this.$store.dispatch('createAutomation/updateScheduleType', value);
    },

    handleMaxPostCountChange(e) {
      this.$store.dispatch('createAutomation/updateMaxPostCount', e.target.value);
    },

    handleRemoveExistingPostsChange(isRemoveExistingPosts) {
      this.$store.dispatch('createAutomation/updateRemoveExistingPosts', isRemoveExistingPosts);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.automation-campaign-schedule {
  .prefetch-count {
    width: 100px;
    height: 40px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: 2px 7px;
    color: $gray-600;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  #schedule-options {
    font-size: 0.9rem;
  }

  .campaign-alert {
    font-size: 0.9rem;
  }
}
</style>
