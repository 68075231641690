<template>
  <div>
    <div class="workspace__setting">
      <div class="workspace__setting-title">
        Brand Settings
      </div>
      <b-row class="workspace__setting-detail m-4">
        <b-col class="">
          <div class="detail-form">
            <form @submit.prevent="handleUpdateBrand" @keydown="form.onKeydown($event)">
              <b-form-group label="Brand name" label-for="name">
                <b-form-input v-model="form.name" id="name" name="name" autocomplete="off"></b-form-input>
                <has-error :form="form" field="name" />
              </b-form-group>
              <b-form-group label="Brand slug" label-for="slug">
                <b-form-input
                  v-model="form.slug"
                  id="slug"
                  name="slug"
                  autocomplete="off"
                  @keyup="handleBusinessTaglineKeyUp"
                ></b-form-input>
                <has-error :form="form" field="slug" />
              </b-form-group>
              <b-form-group label="Timezone" label-for="timezone">
                <SearchableSelect id="timezone" v-model="form.timezone" name="timezone" :options="timezones">
                </SearchableSelect>
                <has-error :form="form" field="timezone" />
              </b-form-group>
              <b-form-group label="Internal notes" label-for="notes">
                <b-form-textarea
                  v-model="form.notes"
                  id="notes"
                  rows="4"
                  placeholder="Add notes, instructions or SOPs for your team here..."
                ></b-form-textarea>
                <has-error :form="form" field="notes" />
              </b-form-group>
              <div class="text-right">
                <b-button variant="primary" class="px-4 py-2 user-btn" type="submit" :disabled="isUpdatingBrand">
                  <b-spinner v-if="isUpdatingBrand" small class="mr-2" />
                  <svg-icon v-else name="check" size="md" class="mr-2" />
                  Save changes
                </b-button>
              </div>
            </form>
          </div>
        </b-col>
        <b-col class="">
          <div class="avatar__container">
            <div class="avatar__container-avatar">
              <div class="profile-avatar">
                <b-avatar
                  v-if="url || workspace"
                  class="avatar photo-preview"
                  size="9rem"
                  :src="url || workspace.logo"
                />
                <div class="file-upload">
                  <b-button variant="clear" class="" size="md" rounded @click="$refs.Avatar.click()">
                    <svg-icon name="camera" size="md" />
                  </b-button>
                  <input id="avatar" type="file" ref="Avatar" class="file-upload__input" @change="handleFileUpload()" />
                </div>
              </div>
            </div>
            <div class="avatar__container-content">
              <div class="title text-truncate" style="max-width: 250px;">{{ form.name }}</div>
            </div>
          </div>
          <div class="ml-auto text-right">
            <div class="upload-info">
              <p class="small mb-2">Recommended size: Less than 2MB (130 x 130)</p>
              <b-button variant="outline-primary" size="sm" class="px-3" @click="$refs.Avatar.click()">
                Upload image
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import slugify from 'slugify';
import SearchableSelect from '~/components/SearchableSelect';

import { SAVE_WORKSPACE_MUTATION, UPLOAD_WORKSPACE_LOGO_MUTATION } from '~/graphql/mutations';

export default {
  components: { SearchableSelect },

  data() {
    return {
      form: new Form({
        name: '',
        slug: '',
        timezone: '',
        logo: '',
        notes: '',
      }),
      url: null,
      brandLogoUrl: null,
      isUpdatingBrand: false,
    };
  },

  computed: {
    ...mapState('workspace', {
      workspace: state => state.current,
    }),

    timezones() {
      const timezones = moment.tz.names();
      const timezoneNames = timezones
        .map(timezone => ({
          value: timezone,
          text: `${timezone} (GMT ${moment.tz(timezone).format('Z')})`,
        }))
        .sort();

      return [{ value: null, text: 'Select Timezone' }, ...timezoneNames];
    },
  },

  watch: {
    $route() {
      // React to route/workspace switch...
      this.syncForm();
    },
  },

  created() {
    this.syncForm();
  },

  methods: {
    ...mapActions({
      updateCurrentWorkspace: 'workspace/updateCurrent',
    }),

    syncForm() {
      this.form.keys().forEach(key => {
        this.form[key] = this.workspace[key];
      });
    },

    handleBusinessTaglineKeyUp() {
      const escaped = this.form.slug.replace(/[&/\\#@,+()$~%.!'":*?<>{}]/g, '-');
      this.form.slug = slugify(escaped).toLowerCase();
    },

    // Default Workspace Logo
    async handleBrandLogoUpload() {
      if (this.brandLogoUrl) {
        return this.brandLogoUrl;
      }

      const file = this.$refs.Avatar.files[0];
      if (!file || !this.isValidFile(file)) return;

      const {
        data: { uploadWorkspaceLogo },
      } = await this.$apollo.mutate({
        mutation: UPLOAD_WORKSPACE_LOGO_MUTATION,
        variables: { logo: file },
      });

      const brandLogo = uploadWorkspaceLogo.url;
      this.brandLogoUrl = brandLogo;
      this.form.logo = brandLogo;

      return this.brandLogoUrl;
    },

    async handleUpdateBrand() {
      this.isUpdatingBrand = true;
      await this.handleBrandLogoUpload();

      this.$apollo
        .mutate({
          mutation: SAVE_WORKSPACE_MUTATION,
          variables: {
            id: this.workspace.id,
            ...this.form.data(),
          },
        })
        .then(({ data }) => {
          this.isUpdatingBrand = false;

          this.updateCurrentWorkspace(data.saveWorkspace);
          this.$router.push({ params: { workspace: data.saveWorkspace.slug } });

          this.$notify({
            group: 'main',
            type: 'success',
            text: 'Workspace has been updated successfully',
          });
        })
        .catch(error => {
          this.isUpdatingBrand = false;
          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              this.form.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Unable to update',
              text: 'An error occurred while processing your request.',
            });
          }

          this.url = null;
        });
    },

    handleFileUpload: function() {
      const file = this.$refs.Avatar.files[0];

      if (!file) return;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);

      this.url = file;

      if (!this.isValidFile(file)) return;
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `Please upload a valid image`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 5240) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 5MB',
        });
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.workspace__setting {
  background: #dfe7f3;
  color: $primary;
  padding: 40px;
  border-radius: 20px;
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  &-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-control {
    &::placeholder {
      font-size: 0.9rem;
      color: $gray-500;
    }
  }
  .searchable-dropdown-wrap .searchable-dropdown-input {
    background: $white;
  }
  &-title {
    font-weight: bold;
    font-size: 18px;
  }
  .avatar__container {
    background: $primary;
    padding: 20px;
    margin-left: 60px;
    position: relative;
    color: white;
    border-radius: 8px;
    &-avatar {
      position: absolute;
      top: -40px;
      right: -10px;
      .profile-avatar {
        position: relative;
        .avatar {
          border: 2px solid #52b788;
        }
        .file-upload {
          position: absolute;
          display: block;
          cursor: pointer;
          bottom: -15px;
          right: 43px;
          padding: 4px 2px;
          background-color: $primary;
          border-radius: 50%;
          svg {
            fill: $secondary;
          }
        }

        .file-upload__input {
          display: none;
        }
      }
    }
    &-content {
      text-align: left;
      padding-right: 10px;
      color: $secondary;
      .title {
        text-transform: capitalize;
        font-size: 18px;
      }
      .email {
        text-transform: lowercase;
        font-size: 12px;
      }
    }
  }

  .upload-info {
    width: 160px;
    text-align: center;
    margin-top: 60px;
    float: right;
    margin-right: -14px;
  }
}
</style>
