<template>
  <div ref="uploadsScroll" class="panel-scroll uploads-panel">
    <div class="panel-body">
      <h6 class="mb-3 mt-2">My Uploads</h6>

      <div @click="$refs.ImageUpload.click()" class="dropzone-wrap" :class="{ disabled: newUpload.type }">
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M12 12L12 6 11 6 11 12 5 12 5 13 11 13 11 19 12 19 12 13 18 13 18 12z" />
        </svg>
        <div class="dropzone-label">Upload an Image</div>
        <input
          ref="ImageUpload"
          :accept="allowedFileTypes.join(',')"
          @change="handleImageUpload"
          type="file"
          style="display: none"
        />
      </div>

      <div class="images-wrap mt-4">
        <div v-if="newUpload.type" class="d-flex align-items-center uploading-wrap rounded my-3 shadow-sm p-3">
          <div class="mr-2"><b-spinner variant="secondary" type="grow" /></div>
          <div :style="{ backgroundImage: `url(${newUpload.url})` }" class="new-image"></div>
          <div class="text-truncate">{{ newUpload.name }}</div>
        </div>

        <div v-if="queryLoading">
          <skeleton-screens class="images-grid skeleton" itemClass="image-item" times="9" width="85px" height="85px" />
        </div>
        <div v-if="uploads.length && !queryLoading">
          <div class="images-grid user-uploads">
            <div
              v-for="uploadImage in uploads"
              :key="uploadImage.id"
              class="image-item"
              @click="handleClick(uploadImage)"
            >
              <b-dropdown variant="clear" offset="5" class="options-btn" size="sm" right no-caret>
                <template v-slot:button-content>
                  <b-icon icon="three-dots" aria-hidden="true"></b-icon>
                </template>
                <b-dropdown-item-button variant="danger" @click.stop="invokeDeleteUpload(uploadImage)">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>

              <LazyImage :src="uploadImage.thumb" class="image-img" skeleton-color="light" alt="" />
            </div>
          </div>
        </div>
        <div class="w-100 shadow-sm p-5 text-center" v-else-if="!queryLoading && !hasUploads">
          <h5>No uploads yet</h5>
        </div>
      </div>
    </div>

    <b-modal
      id="DeleteUploadModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideDeleteUploadModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!isDeleting">
          <h4 class="font-weight-bold mb-3">Delete Upload?</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="deleteUpload">Delete</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LazyImage from '~/components/LazyImage';

import { USER_UPLOADS_QUERY } from '~/graphql/queries';
import { SAVE_USER_UPLOAD_MUTATION, DELETE_USER_UPLOAD_MUTATION } from '~/graphql/mutations';

import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

export default {
  name: 'design-fabs-uploads-panel',

  mixins: [InfiniteScrollMixin],

  components: { LazyImage },

  data() {
    return {
      userUploads: {},
      newUpload: {},
      offset: 1,
      limit: 10,
      imageFormats: ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/webp'],

      uploadInvokedForDelete: null,
      isDeleting: false,
    };
  },

  apollo: {
    userUploads: {
      query: USER_UPLOADS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: 'image',
          limit: this.limit,
        };
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    queryLoading() {
      return this.$apollo.queries.userUploads.loading;
    },

    allowedFileTypes() {
      return this.imageFormats;
    },
    uploads() {
      return this.userUploads.uploads || [];
    },
    hasUploads() {
      return this.uploads.length > 0;
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (
        isRockBottom &&
        this.userUploads &&
        this.userUploads.uploads &&
        this.userUploads.uploads.length &&
        this.loadMoreEnabled &&
        !this.loadingMore
      ) {
        this.loadMoreUploads();
      }
    },
  },

  mounted() {
    this.$refs.uploadsScroll.addEventListener('scroll', this.scrollEventCallback);
  },

  beforeDestroy() {
    this.$refs.uploadsScroll.removeEventListener('scroll', this.scrollEventCallback);
  },

  methods: {
    scrollEventCallback() {
      this.isRockBottom = this.isBottomOf(this.$refs.uploadsScroll);
    },

    handleClick(upload) {
      this.$emit('uploadSelect', upload);
    },

    async loadMoreUploads() {
      this.offset = this.offset + 1;
      this.loadingMore = true;

      try {
        // Fetch more data and transform the original result
        await this.$apollo.queries.userUploads.fetchMore({
          // New variables
          variables: {
            workspace: this.workspace.id,
            offset: this.offset,
            limit: this.limit,
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newContents = fetchMoreResult.userUploads.uploads;
            const filtered = newContents.filter(img => {
              const existingIds = this.userUploads.uploads.map(exImg => exImg.id);
              return !existingIds.includes(img.id);
            });

            if (filtered.length < 1) {
              this.loadMoreEnabled = false;
              return {
                ...previousResult,
              };
            }

            const hasMore = fetchMoreResult.userUploads.hasMore;
            const total = fetchMoreResult.userUploads.total;

            this.loadMoreEnabled = hasMore;

            return {
              userUploads: {
                __typename: previousResult.userUploads.__typename,
                // Merging to the list
                uploads: [...previousResult.userUploads.uploads, ...filtered],
                total,
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        console.warn(e.message);
      }

      this.loadingMore = false;
    },

    handleImageUpload(event) {
      const file = event.target.files[0];

      if (!this.isValidFile(file)) return;

      if (this.imageFormats.includes(file.type)) {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = async fileLoadedEvent => {
          const srcData = fileLoadedEvent.target.result;

          this.newUpload = {
            type: 'image',
            url: srcData,
            name: file.name,
          };
        };
      } else {
        return;
      }

      this.$apollo
        .mutate({
          mutation: SAVE_USER_UPLOAD_MUTATION,
          variables: { workspace: this.workspace.id, upload: file },
          update: (store, { data: { saveUserUpload } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: USER_UPLOADS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'image',
                limit: this.limit,
              },
            });

            data.userUploads.uploads.unshift(saveUserUpload);

            // Write our data back to the cache.
            store.writeQuery({
              query: USER_UPLOADS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'image',
                limit: this.limit,
              },
              data,
            });
          },
        })
        .then(({ data: { saveUserUpload } }) => {
          this.newUpload = {};

          if (saveUserUpload.status === 'processing') {
            this.$notify({
              group: 'main',
              type: 'native',
              title: 'Upload is being processed',
              text: 'This may take a moment... Consider grabbing a cup of tea.',
            });
          }
        })
        .catch(error => {
          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              const errors = err.extensions.validation;
              for (let key in errors) {
                const fieldErrors = errors[key];

                this.$notify({
                  group: 'main',
                  type: 'error',
                  title: `Invalid ${key}`,
                  text: fieldErrors.join('<br />'),
                });
              }
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Unable to upload',
              text: 'An error occurred while processing your upload.',
            });
          }

          this.newUpload = {};
        });
    },

    isValidFile(file) {
      const inValidType = !this.imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `You can only upload an image`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (this.imageFormats.includes(file.type) && size > 51200) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 50MB',
        });
        return false;
      }

      return true;
    },

    invokeDeleteUpload(upload) {
      this.uploadInvokedForDelete = upload;

      this.$bvModal.show('DeleteUploadModal');
    },

    deleteUpload() {
      this.isDeleting = true;

      this.$apollo
        .mutate({
          mutation: DELETE_USER_UPLOAD_MUTATION,
          variables: { workspace: this.workspace.id, ids: [this.uploadInvokedForDelete.id] },
          update: (store, { data: { deleted } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: USER_UPLOADS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'image',
                limit: 10,
              },
            });
            if (deleted) {
              const index = data.userUploads.uploads.findIndex(a => this.uploadInvokedForDelete.id === a.id);

              if (index !== -1) {
                data.userUploads.uploads.splice(index, 1);
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: USER_UPLOADS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'image',
                limit: 10,
              },
              data,
            });
          },
        })
        .then(() => {
          this.isDeleting = false;
          this.uploadInvokedForDelete = null;

          this.$bvModal.hide('DeleteUploadModal');
        })
        .catch(() => {
          this.isDeleting = false;
          this.uploadInvokedForDelete = null;

          this.$bvModal.hide('DeleteUploadModal');

          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Upload could not be deleted',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideDeleteUploadModal() {
      this.isDeleting = false;
      this.uploadInvokedForDelete = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.uploads-panel {
  .panel-body {
    width: 280px;
    margin: auto;
    padding: 20px 0 !important;
  }

  .dropzone-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    border: 1px dashed rgba($secondary, 0.4);
    cursor: pointer;
    font-size: 14px;
    color: $secondary;
    margin: 0;
    margin-top: 20px;

    &:hover {
      background-color: rgba($secondary, 0.2);
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    .dropzone-label {
      line-height: 24px;
      text-align: center;
    }
  }

  .images-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;

    .image-item {
      width: 85px;
      height: 85px;
      margin-bottom: 10px;
      margin-right: 10px;
      border-radius: 4px;
      border: 1px solid $gray-100;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      position: relative;

      .options-btn {
        position: absolute;
        right: 1px;
        top: 4px;
        opacity: 0;

        .dropdown-toggle {
          width: 25px;
          height: 15px;
          padding: 0;
          border-radius: 20px;
          line-height: 0.1;
          color: $white;
          background-color: rgba($secondary, 0.7);

          svg {
            margin-top: -2px;
          }

          &:hover {
            color: $white;
            background-color: rgba($secondary, 0.7);
          }
        }

        .dropdown-menu {
          min-width: 8rem;
          border: none;
          border-radius: 8px;
          box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
          font-size: 14px;
          line-height: 18px;
          color: #162d3d;
          background: #fff;
          margin-top: 10px;

          .dropdown-item {
            padding: 0.25rem 1.2rem;
          }

          &::before {
            content: '';
            transform: rotateZ(-135deg);
            right: 15px;
            top: -4px;
            margin-left: 8px;
            margin-right: 8px;
            border-width: 4px;
            border-color: transparent #fff #fff transparent;
            margin: 0;
            width: 8px;
            height: 8px;
            background: 0 0;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
            border-style: solid;
            position: absolute;
          }
        }
      }

      .image-img {
        width: 85px;
        height: 85px;
        border-radius: 4px;
        background-size: cover;
        cursor: pointer;
      }

      &:hover {
        .options-btn {
          opacity: 1;
        }
      }
    }

    &.skeleton {
      .image-item {
        border: 0;
      }
    }
  }

  .uploading-wrap {
    .new-image {
      width: 30px;
      height: 30px;
      background-size: cover;
      margin-right: 10px;
    }

    .video-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      fill: $secondary;
    }
  }
}
.view-upload-modal {
  .modal-dialog {
    max-width: 700px;
  }
}
</style>
