import { Node, Plugin } from 'tiptap';

import ViewComponent from './View.vue';

export default class BreakNode extends Node {
  get name() {
    return 'break';
  }

  get schema() {
    return {
      attrs: {
        strength: {
          default: 'medium',
        },
        time: {
          default: null,
        },
      },
      inline: true,
      group: 'inline',
      selectable: false,
      parseDOM: [
        {
          tag: 'break',
          getAttrs: dom => ({
            strength: dom.getAttribute('strength'),
            time: dom.getAttribute('time'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'break',
          {
            strength: node.attrs.strength,
            time: node.attrs.time,
          },
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type }) {
    return attrs => (state, dispatch) =>
      dispatch(
        state.tr.replaceSelectionWith(
          type.create({
            strength: attrs.strength,
            time: attrs.time,
          }),
        ),
      );
  }

  get plugins() {
    return [
      new Plugin({
        filterTransaction: (tr, state) => {
          let nodesInSelection = [];

          tr.steps.forEach(step => {
            // If this is an 'AddMarkStep' it will have a 'mark' property
            if (typeof step.mark === 'object') {
              const selected = tr.selection.content();

              if (selected.size > 0) {
                nodesInSelection = [...nodesInSelection, ...this.findNodesInSelection(selected)];
              }
            }
          });

          if (nodesInSelection.length) {
            return false;
          }

          return state;
        },
      }),
    ];
  }

  findNodesInSelection(selected) {
    let nodesInSelection = [];
    selected.content.forEach(node => {
      node.forEach(child => {
        if (!child.isText && child.content.size > 0) {
          nodesInSelection = [...nodesInSelection, ...this.findNodesInSelection(node)];
        } else if (!child.isText && child.content.size < 1) {
          nodesInSelection = [...nodesInSelection, child];
        }
      });
    });

    return nodesInSelection;
  }
}
