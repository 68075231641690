<template>
  <form class="menububble_w-form" @submit.prevent="update">
    <div class="inputs-wrap">
      <span class="bubble-w-label">Role:</span>
      <b-form-select
        v-model="roleValue"
        ref="langInput"
        :options="roles"
        value-field="name"
        text-field="label"
        size="sm"
        class="bubble-w-input"
      />
    </div>

    <b-button type="submit" variant="primary" size="sm"> <svg-icon name="check" /> </b-button>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    role: {
      default: null,
    },

    command: {
      required: true,
    },
  },

  data() {
    return {
      roleValue: this.role,
    };
  },

  computed: {
    ...mapState({
      roles: state => [{ name: null, label: 'Select' }, ...state.voiceEditor.wRoles],
    }),
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.langInput.focus();
    });
  },

  methods: {
    update() {
      this.command({ role: this.roleValue });

      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menububble_w-form {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
  display: flex;

  .btn {
    box-shadow: none;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .inputs-wrap {
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
  }

  .bubble-w-label {
    font-weight: bold;
    font-size: 0.95rem;
    margin-right: 10px;
  }

  .bubble-w-input {
    &::placeholder {
      color: $gray-500;
    }
  }
}
</style>
