<template>
  <transition name="sidebar-slide">
    <b-col md="2" class="setting-sidebar-container">
      <div class="settings-sidebar shadow-sm">
        <simplebar class="side-nav-wrap">
          <ul class="side-nav">
            <li>
              <router-link :to="{ name: 'workspaces' }" active-class="active" exact>
                <svg-icon name="dashboard" />
                Homepage
              </router-link>
            </li>
          </ul>
          <hr class="side-nav__divider" />
          <div class="sidebar-subheader mt-2">
            <span class="mx-1"><img src="@/assets/icons/new/dot.svg" alt="dot"/></span>
            Account Settings
          </div>
          <ul class="side-nav">
            <li>
              <router-link :to="{ name: 'settings.profile' }" active-class="active" exact>
                <svg-icon name="user" />
                Personal Profile
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'settings.business' }" active-class="active" exact>
                <svg-icon name="business-alt" />
                Business Profile
              </router-link>
            </li> -->
            <li>
              <router-link :to="{ name: 'settings.plan' }" active-class="active" exact>
                <svg-icon name="plan" />
                Plan
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'settings.integrations' }" active-class="active" exact>
                <svg-icon name="integration" />
                Integration
              </router-link>
            </li> -->
            <li>
              <router-link :to="{ name: 'settings.leads' }" active-class="active" exact>
                <svg-icon name="leads" />
                Leads
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'settings.billing' }" active-class="active" exact>
                <svg-icon name="card" />
                Billing
              </router-link>
            </li>
          </ul>
        </simplebar>
      </div>
    </b-col>
  </transition>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  name: 'Sidebar',

  components: { simplebar },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
      'isUserClientInWorkspace',
    ]),
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.setting-sidebar-container {
  position: relative;

  .settings-sidebar {
    position: relative;
    top: 20px;
    z-index: 100;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 20px 20px 0;
    background: $primary;
    color: #ffffff;
    border-radius: 0 30px 30px 0;
    overflow: visible;

    .side-nav-wrap {
      max-height: 100vh;
      padding: 20px 2px;

      .simplebar-scrollbar {
        width: 5px;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        /* When hovered, remove all transitions from drag handle */
        opacity: 1;
        transition: opacity 0s linear;
      }
    }

    .side-nav {
      list-style: none;
      padding: 0;
      &__divider {
        background: #52b788;
        padding: 0;
        margin: 0 18px 5px 18px;
      }

      li {
        a {
          margin-bottom: 5px;
          display: block;
          padding: 8px 20px;
          color: #ffffff;
          font-size: 12px;
          border-left: 4px solid transparent;

          &:hover {
            text-decoration: none;
          }
          &.active {
            border-right: 8px solid #42c589;
          }
          &:hover,
          &.active {
            background-color: #2d6a4f;
            border-radius: 20px 0 0 20px;
            font-size: 13px;
          }

          .icon {
            margin-right: 4px;
          }
        }
      }
    }

    .sidebar-subheader {
      padding: 0 20px;
      margin-top: 1rem;
      margin-bottom: 5px;
      font-weight: bold;
      color: #52b788;
      cursor: default;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
    }
  }
}

.sidebar-slide-enter-active {
  animation: sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.sidebar-slide-leave-active {
  animation: sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
