<template>
  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
    <div class="menubar" :class="{ perched }">
      <b-button
        v-b-tooltip.hover
        title="Switch speech style"
        variant="light"
        :class="{ 'is-active': isActive.amazon_domain() }"
        @click="commands.amazon_domain"
        class="menubar__button"
      >
        <svg-icon name="radio" class="mt-0" />
      </b-button>

      <b-dropdown
        v-b-tooltip.hover
        title="Add a pause"
        variant="clear"
        size="sm"
        class="pause-dropdown"
        :toggle-class="{ menubar__button: true, 'is-active': isActive.break() }"
        offset="20"
        no-caret
        right
        :disabled="markIsActive(isActive)"
      >
        <template v-slot:button-content>
          <svg-icon name="pause" class="mt-0" />
        </template>

        <simplebar data-simplebar-auto-hide="false" class="menu-scroll">
          <b-dropdown-item
            v-for="strength in strengths"
            :key="strength.name"
            @click="addPause(commands.break, strength.name)"
          >
            <div class="option-title">{{ strength.label }}</div>
            <p class="option-description">{{ strength.description }}</p>
          </b-dropdown-item>
        </simplebar>
      </b-dropdown>

      <b-form-select
        v-b-tooltip.hover
        :value="voiceId"
        :options="voices"
        size="sm"
        class="voices-input"
        title="Speech Voice"
        @change="handleVoiceChange"
      ></b-form-select>

      <b-button v-b-tooltip.hover title="Undo" variant="light" @click="commands.undo" class="menubar__button">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>

      <b-button v-b-tooltip.hover title="Redo" variant="light" @click="commands.redo" class="menubar__button">
        <b-icon icon="arrow-clockwise" />
      </b-button>

      <b-badge
        v-b-tooltip.hover
        title="Character limit"
        class="char-count"
        :variant="charCount > 10000 ? 'danger' : 'white'"
        pill
      >
        <span :class="{ exceeded: charCount > 10000 }">{{ charCount > 99999 ? '100k+' : formatNum(charCount) }}</span> /
        10,000
      </b-badge>
    </div>
  </editor-menu-bar>
</template>

<script>
import { mapState } from 'vuex';
import { EditorMenuBar } from 'tiptap';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: { EditorMenuBar, simplebar },

  props: {
    editor: {
      required: true,
    },
    voiceId: {
      type: String,
      required: true,
    },
    perched: {
      default: false,
    },
  },

  data() {
    return {
      voices: [
        {
          value: 'Golden',
          text: 'Golden (Female)',
        },
        {
          value: 'Godswill',
          text: 'Godswill (Male)',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      strengths: state => state.voiceEditor.pauseStrengths,
    }),

    charCount() {
      return this.editor.state.doc.textContent.length;
    },
  },

  methods: {
    formatNum(number) {
      return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(number);
    },

    markIsActive(isActive) {
      const marks = Object.keys(this.editor.marks);
      return marks.some(mark => isActive[mark]());
    },

    addPause(command, strength) {
      command({ strength });
    },

    handleVoiceChange(value) {
      this.$emit('voiceChange', value);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.menubar {
  position: fixed;
  top: 140px;
  left: 50%;
  width: 600px;
  background-color: $secondary;
  padding: 5px 20px;
  border-radius: 10px;
  margin: 10px auto;
  box-shadow: 0 0.12rem 0.15rem rgba(0, 0, 0, 0.075);
  z-index: 2;
  transform: translateX(-50%);
  transition: all 0.4s ease-out;
  text-align: center;

  .char-count {
    cursor: default;

    .exceeded {
      animation: blinking 1.2s infinite;
    }
  }

  @keyframes blinking {
    0% {
      color: rgba($white, 1);
    }
    49% {
      color: rgba($white, 1);
    }
    60% {
      color: rgba($white, 0.4);
    }
    99% {
      color: rgba($white, 0.4);
    }
    100% {
      color: rgba($white, 1);
    }
  }

  &.perched {
    width: 750px;
    top: 102px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    font-size: 1rem;
    background: transparent;
    border: 0;
    color: $black;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    background: #f2f2f2;
    margin: 0 4px;

    &:hover,
    &.is-active {
      background: $primary;
      svg {
        fill: $secondary;
      }
    }
  }

  .voices-input {
    display: inline-block;
    width: 155px;
    margin: 0 4px;
    box-shadow: none;
    border-color: transparent;
    background: #f2f2f2;
    border-radius: 6px;
  }

  .pause-dropdown {
    .dropdown-toggle {
      box-shadow: none;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
      background-color: #fff;
      border: 1px solid rgba(102, 107, 133, 0.05);
      min-width: 220px;
      border-radius: 5px;
      background-clip: padding-box;

      .menu-scroll {
        max-height: 300px;
        overflow: auto;

        .simplebar-scrollbar:before {
          border-radius: 5px;
          background: $gray-600;
        }
      }

      .dropdown-item {
        line-height: 1.9;
        padding: 0.4rem 0.9rem;

        .option-title {
          font-size: 0.8rem;
          font-weight: bold;
          color: $gray-600;
        }
        .option-description {
          font-size: 0.7rem;
          white-space: normal;
          margin: 0;
          line-height: 1.4;
        }

        &:active {
          background-color: $gray-100;
          color: $gray-800;
        }
      }

      li {
        &.active {
          .dropdown-item {
            background-color: $gray-100;
          }
        }
        &:first-child {
          > a {
            border-radius: 10px 10px 0 0;
          }
        }

        &:last-child {
          > a {
            border-radius: 0 0 10px 10px;
          }
        }
      }
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}
</style>
