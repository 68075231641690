import { toggleWrap } from 'tiptap-commands';
import { Node } from 'tiptap';

import ViewComponent from './View.vue';

export default class AmazonDomain extends Node {
  get name() {
    return 'amazon_domain';
  }

  get schema() {
    return {
      attrs: {
        name: {
          default: 'news',
        },
      },
      content: 'block*',
      group: 'block',
      defining: true,
      draggable: false,
      parseDOM: [
        {
          tag: 'amazon\\:domain', // This is a CSS selector, so escape column
          getAttrs: dom => ({
            name: dom.getAttribute('name'),
          }),
        },
      ],
      toDOM: node => {
        return [
          'amazon:domain',
          {
            name: node.attrs['name'],
          },
          0,
        ];
      },
    };
  }

  get view() {
    return ViewComponent;
  }

  commands({ type, schema }) {
    return () => toggleWrap(type, schema.nodes.paragraph);
  }
}
