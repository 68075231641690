const AutomationMetaMixin = {
  computed: {
    meta() {
      if (this.$route.params.type == 'social' && this.$route.params.recipe == 'articles') {
        return {
          title: 'Article Links to Social Media',
          description: 'Share article links on your social media profiles, pages and groups',
        };
      } else if (this.$route.params.type == 'social' && this.$route.params.recipe == 'videos') {
        return {
          title: 'Videos to Social Media',
          description: 'Share videos on your social media profiles, pages and groups with smart rules and filters.',
        };
      } else if (this.$route.params.type == 'blog' && this.$route.params.recipe == 'articles') {
        return {
          title: 'Article to Blog',
          description: 'Share articles on your blog with smart rules and filters.',
        };
      } else if (this.$route.params.type == 'blog' && this.$route.params.recipe == 'videos') {
        return {
          title: 'Videos to Blog',
          description: 'Share videos on your blog with smart rules and filters.',
        };
      } else if (this.$route.params.type == 'social' && this.$route.params.recipe == 'rss') {
        return {
          title: 'RSS Feed to Social Media',
          description: 'Share RSS feeds content on your social media',
        };
      } else if (this.$route.params.type == 'social' && this.$route.params.recipe == 'csv') {
        return {
          title: 'Bulk Uploader',
          description: 'Schedule multiple messages to your social media channels via CSV data import.',
        };
      }

      return { title: 'Automations' };
    },
  },
};

export default AutomationMetaMixin;
