<template>
  <div>
    <div class="bg-white shadow-sm rounded plan__container">
      <div class="plan__container-header">
        <div class="header-title my-2">
          <div class="title">Plan Details</div>
        </div>
      </div>
      <b-row v-if="subscription" class="plan__container-body mt-3">
        <b-col>
          <div class="container-left">
            <div class="left-header">
              <img src="~@/assets/images/new/trophy.png" width="100" class="mb-3" alt="" />
              <h1>{{ subscription.plan.name }}</h1>
            </div>
            <b-row class="left-content">
              <b-col class="left-content-image"></b-col>
              <b-col cols="8" class="left-content-data">
                <div class="quote">
                  Hello {{ user.firstName }}, You are subscribed to out
                  <span> {{ subscription.plan.name }}</span>
                </div>
                <div class="action-center">
                  <template v-if="user.onGracePeriod">
                    <b-button :to="{ name: 'upgrade' }" variant="primary" class="px-4 shadow">
                      Resubscribe
                    </b-button>
                    <div>
                      <small class="text-danger">
                        Your subscription ends on <strong>{{ subscriptionEndsAt }}</strong>
                      </small>
                    </div>
                  </template>
                  <template v-else>
                    <b-button variant="primary" class="my-3 shadow" @click="upgradeFixedPlan">
                      <svg-icon name="send" class="mr-2" size="md" />
                      Upgrade Plan
                    </b-button>
                    <!-- <b-button
                      v-if="subscription.plan.slug == 'agency' || subscription.plan.slug == 'g_agency'"
                      href="https://docs.google.com/document/d/1n7RdDebCj9fCh_G-ebQFy42Ep8j2dv9OSAyG44anyzw/edit"
                      target="_blank"
                      variant="outline-primary"
                      class="px-4 shadow"
                    >
                      Access Agency Kit
                    </b-button> -->
                    <br />
                    <b-button
                      v-if="subscription.plan.variant === 'standard'"
                      v-b-modal.CancelPlanModal
                      variant="link"
                      class="px-4 my-3 text-danger"
                    >
                      Cancel Subscription
                    </b-button>
                  </template>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col>
          <div class="container-right">
            <div class="right-header">
              Account Limits
            </div>
            <div class="additonal_info">
              <div class="limits-usage mt-3">
                <div class="progress-item">
                  <label>
                    Workspaces
                    <span>
                      {{ subscription.limitsUsage.workspaces }} of
                      {{
                        subscription.plan.limits.workspaces === '*' ? '&infin;' : subscription.plan.limits.workspaces
                      }}
                    </span>
                  </label>
                  <b-progress
                    variant="success"
                    height="8px"
                    :value="getProgressValue(subscription.limitsUsage.workspaces)"
                    :max="getProgressMax(subscription.plan.limits.workspaces)"
                  />
                </div>
                <div class="progress-item">
                  <label>
                    Social Accounts
                    <span>
                      {{ subscription.limitsUsage.socialAccounts }} of
                      {{
                        subscription.plan.limits.socialAccounts === '*'
                          ? '&infin;'
                          : subscription.plan.limits.socialAccounts
                      }}
                    </span>
                  </label>
                  <b-progress
                    variant="success"
                    height="8px"
                    :value="getProgressValue(subscription.limitsUsage.socialAccounts)"
                    :max="getProgressMax(subscription.plan.limits.socialAccounts)"
                  />
                </div>
                <div class="progress-item">
                  <label>
                    Blogs
                    <span>
                      {{ subscription.limitsUsage.blogs }} of
                      {{ subscription.plan.limits.blogs === '*' ? '&infin;' : subscription.plan.limits.blogs }}
                    </span>
                  </label>
                  <b-progress
                    variant="success"
                    height="8px"
                    :value="getProgressValue(subscription.limitsUsage.blogs)"
                    :max="getProgressMax(subscription.plan.limits.blogs)"
                  />
                </div>
                <div class="progress-item">
                  <label>
                    Team Members
                    <span>
                      {{ subscription.limitsUsage.members }} of
                      {{ subscription.plan.limits.members === '*' ? '&infin;' : subscription.plan.limits.members }}
                    </span>
                  </label>
                  <b-progress
                    variant="success"
                    height="8px"
                    :value="getProgressValue(subscription.limitsUsage.members)"
                    :max="getProgressMax(subscription.plan.limits.members)"
                  />
                </div>
                <div class="progress-item">
                  <label>
                    Automation Campaigns
                    <span>
                      {{ subscription.limitsUsage.automations }} of
                      {{
                        subscription.plan.limits.automations === '*' ? '&infin;' : subscription.plan.limits.automations
                      }}
                    </span>
                  </label>
                  <b-progress
                    variant="success"
                    height="8px"
                    :value="getProgressValue(subscription.limitsUsage.automations)"
                    :max="getProgressMax(subscription.plan.limits.automations)"
                  />
                </div>
              </div>

              <div v-if="addonSubs.length" class="right-header my-3">
                Additional Access
              </div>
              <div class="additional_info-list">
                <p v-for="(addon, i) in addonSubs" :key="`${addon.id}${i}`">
                  <svg-icon name="plane" class="mr-2" size="md" />
                  {{ addon.name }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-else class="p-5 text-center">
        <img class="my-4" width="250" src="@/assets/images/plans.svg" alt="" />

        <h5>You do not have an active subscription</h5>
        <b-button variant="primary" class="px-4 mt-4 shadow" @click="upgradeFixedPlan">Subscribe Now</b-button>
      </div>
    </div>

    <b-modal
      id="FixedPlanUpgradeModal"
      content-class="rounded-sm"
      body-class="text-center p-5"
      hide-header
      hide-footer
      centered
    >
      <template v-slot:default="{ hide }">
        <div v-if="canceling" class="text-center p-4"><spinner variant="success" size="5" /></div>
        <template v-else>
          <b-button variant="link" size="sm" class="modal-close-btn" @click="hide()">
            <svg-icon name="close" />
          </b-button>
          <h4 class="font-weight-bold mb-3">Upgrade your Plan</h4>
          <p>Upgrade your plan to access more features</p>

          <!-- TODO: update hrefs -->
          <div class="mt-4">
            <b-button
              v-if="!subscription"
              href="https://socialagency360.com/join/"
              target="_blank"
              variant="primary"
              class="font-weight-bold px-4 mt-3"
              block
              >SocialMediaAgency360 FE</b-button
            >
            <template v-else>
              <b-button
                v-for="upgrade in upgrades"
                :key="upgrade.slug"
                v-if="subscription && upgrade.slug !== 'fe'"
                :href="upgrade.link"
                variant="primary"
                target="_blank"
                class="font-weight-bold px-4 mt-3"
                block
                >{{ upgrade.name }}</b-button
              >
            </template>
          </div>
        </template>
      </template>
    </b-modal>

    <b-modal
      id="CancelPlanModal"
      size="md"
      content-class="rounded-sm"
      body-class="text-center p-5"
      hide-header
      hide-footer
    >
      <template v-slot:default="{ hide }">
        <div v-if="canceling" class="text-center p-4"><spinner variant="success" size="5" /></div>
        <template v-else>
          <b-button variant="link" size="sm" class="modal-close-btn" @click="hide()">
            <svg-icon name="close" />
          </b-button>
          <h4 class="font-weight-bold mb-3">Here is a better way</h4>
          <p>You can downgrade to another plan that suits your needs.</p>

          <b-form-checkbox v-model="cancelConfirmed">I really want to cancel my subscription</b-form-checkbox>

          <div class="mt-4">
            <b-button v-show="cancelConfirmed" variant="outline-danger" class="mr-2" @click="cancelSubscription">
              Cancel Subscription
            </b-button>
            <b-button :to="{ name: 'upgrade' }" variant="primary" class="px-4">Downgrade</b-button>
          </div>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';

import { CANCEL_SUBSCRIPTION_MUTATION } from '~/graphql/mutations';

export default {
  data() {
    return {
      cancelConfirmed: false,
      canceling: false,
      onGracePeriod: false,
      upgrades: [
        {
          name: 'SocialAgency360 FE',
          link: 'https://socialagency360.com/join',
          slug: 'fe'
        },
        {
          name: 'SocialAgency360 Pro Unlimited',
          link: 'https://socialagency360.com/join/pro-unlimited/',
          slug: 'pro_unlimited'
        },
        {
          name: 'SocialAgency360 Template Club',
          link: 'https://socialagency360.com/join/template-club/',
          slug: 'template_club'
        },
        {
          name: 'SocialAgency360 Reseller',
          link: 'https://socialagency360.com/join/reseller',
          slug: 'reseller'
        },
        {
          name: 'SocialAgency360 Article to Podcast',
          link: 'https://socialagency360.com/join/podcast-agency',
          slug: 'podcast_agency'
        },
        {
          name: 'SocialAgency360 Fast Pass',
          link: 'https://socialagency360.com/join/fast-pass',
          slug: 'fast_pass'
        },
        
      ],

      // subscription: {
      //   plan: {
      //     name: 'lorem ipsum',
      //     slug: 'agency',
      //     variant: 'standard',
      //     limits: {
      //       workspaces: '10',
      //       socialAccounts: '*',
      //       blogs: '50',
      //       members: '2',
      //       automations: '2',
      //     },
      //   },
      //   endsAt: '2021-05-13T18:03:46+00:00',
      //   limitsUsage: {
      //     workspaces: '50',
      //     socialAccounts: '50',
      //     blogs: '50',
      //     members: '10',
      //     automations: '10',
      //   },
      //   addons: [
      //     {
      //       name: 'trial',
      //     },
      //     {
      //       name: 'testing',
      //     },
      //   ],
      // },
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      subscription: state => state.auth.user.subscription,
    }),

    addonSubs() {
      const sub = this.subscription;
      return sub.addons;
    },

    subscriptionEndsAt() {
      return moment(this.user.subscription.endsAt).format('Do MMM YYYY');
    },
  },

  created() {
    if (this.$route.query.upgrade) {
      this.$bvModal.show('FixedPlanUpgradeModal');
    }
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    getProgressValue(value) {
      return parseInt(value);
    },

    getProgressMax(value) {
      if (value === '*') {
        return Infinity;
      }

      return parseInt(value);
    },

    upgradeFixedPlan() {
      this.$bvModal.show('FixedPlanUpgradeModal');
    },

    cancelSubscription() {
      this.canceling = true;

      this.$apollo
        .mutate({
          mutation: CANCEL_SUBSCRIPTION_MUTATION,
        })
        .then(({ data }) => {
          if (data.cancelSubscription) {
            this.updateUser({ user: data.cancelSubscription });
          }
          this.$bvModal.hide('CancelPlanModal');

          Swal.fire({
            type: 'success',
            title: 'Done!',
            text: 'Your subscription has been canceled successfully. You are welcome to re-subscribe anytime.',
          });

          this.canceling = false;
        })
        .catch(() => {
          this.canceling = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.plan__container {
  padding: 30px;
  &-header {
    .header-title {
      overflow: hidden;
      text-align: left;
      color: #d8f3dc;
      display: flex;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: $primary;
        background: $secondary;
        padding: 10px 15px;
        border-radius: 10px;
        // width: 13%;
        text-align: center;
      }
      &:after {
        background-color: $secondary;
        content: '';
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 100%;
        left: 0.5em;
        top: 23px;
        margin-right: -20%;
      }
    }
  }
  &-body {
    .container-left {
      background: $secondary;
      border-radius: 10px;
      padding: 20px 20px 0 20px;
      .left-header {
        padding: 40px 0 10px 0;
        text-align: center;
      }
      .left-content {
        display: flex;
        align-items: center;
        &-image {
          background-image: url('~@/assets/images/new/boy_illustration.png');
          background-position: bottom center;
          background-repeat: no-repeat;
          width: 100%;
          height: 350px;
          margin-right: -165px;
          z-index: 99;
        }
        &-data {
          .quote {
            background: #b7e4c7;
            padding: 40px;
            position: relative;
            border-radius: 10px;
            span {
              font-weight: bold;
              color: $primary;
            }
            &:before {
              content: '';
              background-color: transparent;
              position: absolute;
              border-bottom: 28px solid transparent;
              border-right: 28px solid #b7e4c7;
              border-left: 28px solid transparent;
              border-top: 28px solid transparent;
              top: 32%;
              left: -56px;
            }
            &::after {
              content: '';
              background-color: transparent;
            }
          }
          .action-center {
            display: flex;
            flex-direction: column;
            button {
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .container-right {
      background: #f2f2f2;
      border-radius: 10px;
      padding: 20px;
      color: $primary;
      .right-header {
        color: $primary;
        font-weight: bold;
        font-size: 18px;
      }
      .additional_info {
        .limits-usage {
          .progress-item {
            margin-bottom: 10px;

            .progress-bar {
              border-radius: 10px !important;
            }

            label {
              font-size: 14px;
              color: $gray-600;
              display: flex;
              justify-content: space-between;
              align-items: center;

              span {
                font-size: 12px;
              }
            }
          }
        }
        &-list {
          background: $secondary;
          border-radius: 10px;
          padding: 20px 40px;
          color: $primary;
          font-size: 14px;
        }
      }
    }
  }
}
.plan-body {
  display: flex;

  .plan-details {
    border-right: 1px solid $gray-200;
    padding: 50px;
    text-align: center;
    width: 50%;
  }

  .plan-limits {
    padding: 30px 25px;
    width: 50%;
  }
}
.modal-close-btn {
  color: $gray-600;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
