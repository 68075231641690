<template>
  <section class="view-post-wrap mt-5">
    <b-container fluid>
      <div class="bg-white posts rounded-sm p-5">
        <div v-if="$apollo.queries.fetchPost.loading" class="text-center py-5"><spinner /></div>
        <template v-else-if="fetchPost">
          <h4 class="overline mb-4">{{ capitalize(fetchPost.type) }} post</h4>
          <ViewPostComponent :post="fetchPost" :open="true" @delete="confirmDeletePost" />
          <EditorRightFabs :post-id="postId" />
        </template>
      </div>
    </b-container>

    <b-modal ref="DeleteViewModal" centered hide-footer hide-header>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete post?</h4>
        <p>Are you sure you would like to delete this post?</p>

        <b-button variant="light" class="mr-2" @click="cancelDeletePost">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="actuallyDeletePost">Delete</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { capitalize } from 'lodash';
import { FETCH_POST_QUERY } from '~/graphql/queries';
import { DELETE_POST_MUTATION } from '~/graphql/mutations';
import ViewPostComponent from '~/components/ViewPostComponent';
import EditorRightFabs from '~/components/EditorRightFabs';

export default {
  components: { ViewPostComponent, EditorRightFabs },

  data() {
    return {
      fetchPost: {},
      isDeleting: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    postId() {
      return parseInt(this.$route.params.id, 10) || 0;
    },
  },

  apollo: {
    fetchPost: {
      query: FETCH_POST_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: this.postId,
        };
      },
    },
  },

  methods: {
    goBackHandler() {
      this.$router.push({ name: 'dashboard' });
    },

    confirmDeletePost() {
      this.$refs.DeleteViewModal.show();
    },

    cancelDeletePost() {
      this.$refs.DeleteViewModal.hide();
    },

    async actuallyDeletePost() {
      this.isDeleting = true;
      await this.$apollo.mutate({
        mutation: DELETE_POST_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.fetchPost.id],
        },
        update: (store, { data: { deleted } }) => {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: FETCH_POST_QUERY,
            variables: {
              workspace: this.workspace.id,
              id: this.postId,
            },
          });
          if (deleted) {
            data.fetchPost = null;
          }
          // Write our data back to the cache.
          store.writeQuery({
            query: FETCH_POST_QUERY,
            variables: {
              workspace: this.workspace.id,
              id: this.postId,
            },
            data,
          });
        },
      });

      this.$router.push({ name: 'dashboard' });
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.view-post-wrap {
  padding-top: $topbar-height;

  .overline {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    color: $gray-500;
  }
}
</style>

<style lang="scss" scoped>
.posts {
  margin-right: 125px !important;
}
</style>
