<template>
  <b-dropdown
    variant="clear"
    text="Select account(s)"
    toggle-class="shadow-sm bg-white px-4"
    class="custom-dropdown social-dropdown mb-2 mr-2"
    no-flip
    right
  >
    <div class="account-chooser-dropdown-wrap">
      <div class="search-input-wrap">
        <b-form-input v-model="searchInput" size="sm" placeholder="Search by name"></b-form-input>
      </div>
      <div class="search-check-wrap">
        <div class="d-flex justify-content-between">
          <label for="checkAllAccounts">Select All</label>
          <b-form-checkbox
            :checked="selectedIds.length === publishAccounts.length"
            @change="handleCheckAllChange"
            id="checkAllAccounts"
          />
        </div>
      </div>
      <div v-if="$apollo.queries.publishAccounts.loading" class="p-5 text-center"><b-spinner variant="primary" /></div>
      <simplebar
        v-if="publishAccounts.length && !$apollo.queries.publishAccounts.loading"
        class="account-chooser-accounts-wrap"
      >
        <li v-for="account in filteredAccounts" :key="account.id" class="social-dd-list-item">
          <label :for="`acc${account.id}`" role="button" class="w-100 d-flex align-items-center">
            <img :src="account.imageUrl" width="30" class="rounded-circle shadow-sm p-1 mr-2" alt="" />
            <div>{{ account.name }}</div>
          </label>
          <b-form-checkbox v-model="selectedIds" :value="account.id" :id="`acc${account.id}`" />
        </li>
      </simplebar>
      <div v-else-if="!publishAccounts.length && !$apollo.queries.publishAccounts.loading" class="p-5 text-center">
        <p class="text-center w-75 mx-auto text-muted">You have not added any account</p>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';

export default {
  name: 'dropdown-account-chooser',

  components: { simplebar },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchInput: '',
      publishAccounts: [],
      selectedIds: [],
    };
  },

  watch: {
    selectedIds(ids) {
      this.$emit('change', ids);
    },
  },

  apollo: {
    publishAccounts: {
      query: PUBLISH_ACCOUNTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: this.type,
        };
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    filteredAccounts() {
      if (this.searchInput) {
        return this.publishAccounts.filter(account =>
          account.name.toLowerCase().includes(this.searchInput.toLowerCase()),
        );
      } else {
        return this.publishAccounts;
      }
    },
  },

  methods: {
    handleCheckAllChange(isChecked) {
      if (isChecked) {
        this.selectedIds = this.publishAccounts.map(account => account.id);
      } else {
        this.selectedIds = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.social-dropdown {
  button {
    font-size: 0.97rem;
  }
  .dropdown-menu {
    min-width: 300px;

    .account-chooser-accounts-wrap {
      max-height: 300px;
      overflow-y: auto;

      .simplebar-scrollbar {
        width: 5px;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        /* When hovered, remove all transitions from drag handle */
        opacity: 1;
        transition: opacity 0s linear;
      }
    }

    .search-input-wrap {
      padding: 15px 10px;
      border-bottom: 1px solid rgba(152, 158, 181, 0.2);
      margin-bottom: 10px;

      input {
        font-size: 13px;
        border: 1px solid rgba(152, 158, 181, 0.25);
        border-radius: 20px;
        padding-right: 40px;

        &:focus {
          border-color: $primary;
        }
      }
    }

    .search-check-wrap {
      margin-bottom: 0;
      padding: 4px 15px;
      border-bottom: 1px solid rgba(152, 158, 181, 0.2);

      label {
        width: 100%;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .social-dd-list-item {
      padding: 7px 15px;
      display: block;
      color: $gray-600;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
