<template>
  <div class="prosody-wrap">
    <div ref="content" :id="`prosody-${uuid}`" class="prosody"></div>
    <b-tooltip :target="`prosody-${uuid}`" variant="primary" triggers="hover" placement="bottom">
      Volume: {{ node.attrs.volume }}
      <span v-if="node.attrs.rate">, Rate: {{ node.attrs.rate }}</span>
    </b-tooltip>
  </div>
</template>

<script>
let uuid = 0;

export default {
  props: ['node'],

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.prosody-wrap {
  display: inline-block;
}

.prosody {
  display: inline-block;
  border-bottom: 1px dashed $info;
}
</style>
