<template>
  <div>
    <div class="bg-white shadow-sm rounded p-3">
      <div class="">
        <b-button variant="secondary" class="font-weight-bold text-primary px-4 py-2">
          <svg-icon name="bell-alt" size="md" class="mr-2" />
          Notification Settings
        </b-button>
        <div class="my-5 w-50 d-flex justify-content-around align-items-center">
          <b-form-checkbox v-model="newsletter_notification" size="lg" name="newsletter_notification" switch>
            <span class="font-weight-bold">Monthly Newsletter</span>
          </b-form-checkbox>
          <b-form-checkbox v-model="comment_notification" size="lg" name="comment_notification" switch>
            <span class="font-weight-bold">Comment Notification</span>
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';
import { CANCEL_SUBSCRIPTION_MUTATION } from '~/graphql/mutations';

export default {
  data() {
    return {
      cancelConfirmed: false,
      canceling: false,
      comment_notification: false,
      newsletter_notification: true,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      subscription: state => state.auth.user.subscription,
    }),

    subscriptionEndsAt() {
      return moment(this.user.subscription.endsAt).format('Do MMM YYYY');
    },
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),
    getProgressValue(value) {
      return parseInt(value);
    },

    getProgressMax(value) {
      if (value === '*') {
        return Infinity;
      }

      return parseInt(value);
    },

    cancelSubscription() {
      this.canceling = true;

      this.$apollo
        .mutate({
          mutation: CANCEL_SUBSCRIPTION_MUTATION,
        })
        .then(({ data }) => {
          if (data.cancelSubscription) {
            this.updateUser({ user: data.cancelSubscription });
          }
          this.$bvModal.hide('CancelPlanModal');

          Swal.fire({
            type: 'success',
            title: 'Done!',
            text: 'Your subscription has been canceled successfully. You are welcome to re-subscribe anytime.',
          });

          this.canceling = false;
        })
        .catch(() => {
          this.canceling = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.plan-header {
  padding: 20px;
  border-bottom: 1px solid $gray-200;
}
.plan-body {
  display: flex;

  .plan-details {
    border-right: 1px solid $gray-200;
    padding: 50px;
    text-align: center;
    width: 50%;
  }

  .plan-limits {
    padding: 30px 25px;
    width: 50%;

    .limits-usage {
      .progress-item {
        margin-bottom: 10px;

        .progress-bar {
          border-radius: 10px;
        }

        label {
          font-size: 14px;
          color: $gray-600;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
