<template>
  <div class="planner-account-item shadow-sm">
    <div v-if="post.campaignId" class="planner-post-campaign-indicator">
      <div class="campaign-label text-truncate" :title="`Campaign: ${post.campaign.title}`">
        <svg-icon name="megaphone" class="mr-1" /> {{ post.campaign.title }}
      </div>
    </div>
    <div class="planner-account-photos">
      <span
        class="planner-account-photo"
        v-for="publishAccount in formatPublishAccounts(post.publishAccounts)"
        :key="publishAccount.id"
      >
        <img v-if="!publishAccount.indicator" :src="publishAccount.imageUrl" width="40" class="rounded-circle" />
        <span class="planner-more-account-photos" v-else-if="publishAccount.indicator">{{ publishAccount.more }}</span>
      </span>
    </div>
    <div class="planner-account-item-excerpt">{{ stripTags(post.masterContent) }}</div>

    <div class="planner-post-action-btns mt-2">
      <b-button variant="light" size="sm" :to="getPostRoute(post)"><svg-icon name="view-file"/></b-button>
      <b-button variant="light" size="sm" @click="deletePost(post)"><svg-icon name="trash"/></b-button>
    </div>
  </div>
</template>

<script>
import { stripTags } from '~/utils/helpers';

export default {
  props: {
    post: {
      type: Object,
    },
  },

  methods: {
    formatPublishAccounts(publishAccounts) {
      if (publishAccounts.length > 4) {
        // Cut out 3 and add more indicator
        const slicedOut = publishAccounts.slice(0, 3);
        return [
          ...slicedOut,
          {
            id: 'more',
            indicator: true,
            more: `+${publishAccounts.length - slicedOut.length}`,
          },
        ];
      }

      return publishAccounts;
    },

    getPostRoute(post) {
      if (post.campaignId) {
        return { name: 'campaign.posts', params: { id: post.campaign.id } };
      } else {
        return { name: `post.view`, params: { id: post.id } };
      }
    },

    deletePost(post) {
      this.$store.dispatch('planner/updatePostToDelete', post);

      this.$bvModal.show('PlannerDeleteModal');
    },

    stripTags,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.planner-account-item {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $secondary;
  padding: 34px 10px 10px;
  border-radius: 3px;
  margin-bottom: 15px;
  width: 166px;
  position: relative;

  .planner-post-campaign-indicator {
    position: absolute;
    left: 20px;
    top: 7px;
    font-size: 0.8rem;

    .campaign-label {
      max-width: 135px;
      color: $info;
    }
  }

  .planner-account-item-photo {
    margin-right: 5px;
  }
  .planner-account-item-excerpt {
    font-size: 0.7rem;
    margin-top: 5px;

    max-width: 150px;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }

  .planner-account-photos {
    max-width: 160px;
    display: flex;
    justify-content: center;
  }

  .planner-account-photo {
    display: inline-block;
    height: 40px;
    width: 40px;
    margin-left: -10px;
    background-color: white;
    border-radius: 50%;

    &:first-of-type {
      margin-left: 0;
    }

    img {
      border: 1px solid $gray-200;
      padding: 2px;
      box-shadow: 0px 0px 10px rgba($gray-300, 0.54);
    }

    .planner-more-account-photos {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background-color: rgba($primary, 0.5);
      color: $white;
      border-radius: 50%;
      border: 2px solid $white;
    }
  }

  .planner-post-action-btns {
    text-align: center;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0.1s;

    .btn {
      padding: 0.2rem 0.25rem;
      margin-right: 5px;
      line-height: 1;
      border: 1px solid $gray-200;

      &:focus {
        box-shadow: none;
      }

      .icon {
        margin-top: -2px;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  &:hover {
    .planner-post-action-btns {
      opacity: 1;
    }
  }
}
</style>
