<template>
  <div class="team-settings-page-wrap">
    <div class="team__settings">
      <div class="team__settings-header">
        <div class="title">
          Team Members
        </div>
        <div class="add__member">
          <b-button variant="primary" class="px-4 py-2" @click="$bvModal.show('AddMemberModal')">
            <svg-icon name="add-user" size="md" class="mr-2" />
            Add Team Members
          </b-button>
        </div>
      </div>
      <div class="team__settings-list">
        <b-row class="list-container" v-for="member in workspace.members" :key="member.id">
          <b-col cols="5" class="bordered-left">
            <div class="d-flex align-items-center user_data">
              <b-avatar class="mr-3" :src="member.user.photoUrl" size="4rem"></b-avatar>
              <div class="mr-auto">
                <h5 class="font-weight-bold m-0">{{ member.firstName }} {{ member.lastName }}</h5>
                <p
                  class="m-0 role"
                  :class="{
                    red: member.role === 'super_admin',
                    orange: member.role === 'admin',
                    green: member.role === 'collaborator',
                    teal: member.role === 'approver',
                  }"
                >
                  {{ getRoleText(member.role) }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="5" class="bordered-left">
            <div class="user-email">
              <p>
                <svg-icon name="mail" class="mr-3" size="md" />
                {{ member.email }}
              </p>
            </div>
          </b-col>
          <b-col class="">
            <div class="user_action" v-if="member.role !== 'super_admin' || member.user.id === user.id">
              <b-button variant="outline-primary" size="sm" class="mr-2 p-2" @click="invokeEditMember(member)">
                <svg-icon name="edit" size="md" />
              </b-button>
              <b-button variant="outline-danger" size="sm" class="p-2" @click="invokeRemoveMember(member)">
                <svg-icon name="trash" size="md" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      id="AddMemberModal"
      modal-class="add_member_modal"
      hide-header hide-footer
      centered size="lg"
      @hidden="onHideEditTeamMemberModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide()"> <svg-icon name="close" /> </b-button>
        <div class="p-4">
          <h5 class="font-weight-bold mb-0 pb-0">
            Add Team Members
            <svg-icon name="wink" size="sm" />
          </h5>
          <span class="m-0 p-0 f-12">Please fill in the details below</span>

          <b-form @submit.prevent="handleOnFormSubmit" @keydown="form.onKeydown($event)">
            <b-row class="mt-3">
              <b-col>
                <b-form-group>
                  <label for="first_name-field" class="f-14">First Name</label>
                  <b-form-input
                    id="first_name-field"
                    v-model="form.firstName"
                    type="text"
                    autocomplete="off"
                    trim
                    required
                    :readonly="!!memberInvokedForEdit"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="last_name-field" class="f-14">Last Name</label>
                  <b-form-input
                    id="last_name-field"
                    v-model="form.lastName"
                    type="text"
                    autocomplete="off"
                    trim
                    required
                    :readonly="!!memberInvokedForEdit"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col>
                <b-form-group class="">
                  <label for="email-field" class="f-14">Email Address</label>
                  <b-form-input
                    id="email-field"
                    v-model="form.email"
                    type="email"
                    autocomplete="off"
                    trim
                    required
                    :readonly="!!memberInvokedForEdit"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col>
                <b-form-group>
                  <label for="type-field" class="f-14">User Type</label>
                  <b-form-select v-model="form.type" id="user-type" size="sm" value="admin">
                    <option value="team">Team</option> <option value="client">Client</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="role-field" class="f-14">User Role</label>
                  <b-form-select v-model="form.role" id="member-role" size="sm" value="admin">
                    <option value="admin">Administrator</option> <option value="collaborator">Collaborator</option>
                    <option value="approver">Approver</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button type="submit" class="team__members-btn shadow mt-4" :disabled="addingMember">
              <b-spinner v-if="addingMember" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="RemoveMemberModal"
      modal-class="remove_member_modal"
      content-class="text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideRemoveTeamMemberModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!removingMember">
          <h4 class="font-weight-bold mb-3">Remove Team Member</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="removeTeamMember">Remove</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState, mapActions } from 'vuex';

import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import {
  ADD_TEAM_MEMBER_MUTATION,
  UPDATE_TEAM_MEMBER_MUTATION,
  REMOVE_TEAM_MEMBER_MUTATION,
} from '~/graphql/mutations';

export default {
  name: 'AccountSettings',

  metaInfo() {
    return { title: 'Account Settings' };
  },

  data: function() {
    return {
      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        role: 'admin',
        type: 'team',
      }),
      addingMember: false,
      removingMember: false,
      memberInvokedForRemoval: null,
      memberInvokedForEdit: null,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      roles: state => state.workspace.roles,
      workspace: state => state.workspace.current,
    }),
  },

  created() {
    this.$store.dispatch('publishAccount/updateQueryType', 'blog');
  },

  watch: {
    'form.type': {
      handler(type, prevType) {
        if (type === 'client' && prevType !== 'client') {
          this.form.role = 'admin';
        }
      },
    },
  },

  methods: {
    ...mapActions({
      updateCurrentWorkspace: 'workspace/updateCurrent',
    }),

    getRoleText(role) {
      const foundRole = this.roles.find(r => r.id === role);

      return foundRole ? foundRole.name : '';
    },

    handleOnFormSubmit() {
      if (this.memberInvokedForEdit) {
        this.updateTeamMember();
      } else {
        this.addTeamMember();
      }
    },

    addTeamMember() {
      const limit = parseInt(this.workspace.limits.members || '');
      const limitCount = isNaN(limit) ? Infinity : limit;

      const memberCount = this.workspace.members.length;
      const check = memberCount >= limitCount;

      if (check) {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          title: 'Please Upgrade',
          text: `You have exceeded the maximum number of members allowed in this workspace.
          Please upgrade your subscription to be able to add more team members.`,
          duration: 5500,
        });
      }

      this.addingMember = true;

      this.$apollo
        .mutate({
          mutation: ADD_TEAM_MEMBER_MUTATION,
          variables: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            role: this.form.role,
            type: this.form.type,
            workspaces: [this.workspace.id],
          },
          update: (store, { data: { addTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              const spaceIndex = data.fetchWorkspaces.findIndex(w => w.id === this.workspace.id);

              if (spaceIndex !== -1) {
                const workspace = data.fetchWorkspaces[spaceIndex];
                workspace.members.push(...addTeamMember);

                this.updateCurrentWorkspace(workspace);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.addingMember = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Team member has been added successfully.',
          });
          
          this.$bvModal.hide('AddMemberModal');

        })
        .catch(() => {
          this.addingMember = false;
        });
    },

    invokeEditMember(member) {
      this.memberInvokedForEdit = member;

      this.form.keys().forEach(key => {
        this.form[key] = member[key];
      });

      this.$bvModal.show('AddMemberModal');
    },

    updateTeamMember() {
      this.addingMember = true;

      this.$apollo
        .mutate({
          mutation: UPDATE_TEAM_MEMBER_MUTATION,
          variables: {
            id: this.memberInvokedForEdit.id,
            role: this.form.role,
            type: this.form.type,
            workspace: this.workspace.id,
          },
          update: (store, { data: { updateTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              const workspace = data.fetchWorkspaces.find(w => w.id === this.workspace.id);
              if (workspace) {
                const memberIndex = workspace.members.findIndex(m => m.user.id === this.memberInvokedForEdit.user.id);

                if (memberIndex !== -1) {
                  workspace.members.splice(memberIndex, 1, updateTeamMember);
                }

                this.updateCurrentWorkspace(workspace);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.addingMember = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Team member has been updated successfully.',
          });
          this.$bvModal.hide('AddMemberModal');

        })
        .catch(() => {
          this.addingMember = false;
        });
    },

    onHideEditTeamMemberModal() {
      this.memberInvokedForEdit = null;
      this.form.reset();
    },

    invokeRemoveMember(member) {
      this.memberInvokedForRemoval = member;

      this.$bvModal.show('RemoveMemberModal');
    },

    removeTeamMember() {
      this.removingMember = true;
      const userId = this.memberInvokedForRemoval.user.id;

      this.$apollo
        .mutate({
          mutation: REMOVE_TEAM_MEMBER_MUTATION,
          variables: {
            userId,
            workspaceIds: [this.workspace.id],
          },
          update: (store, { data: { removeTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });
              const updated = removeTeamMember[0];
              const workIndex = data.fetchWorkspaces.findIndex(w => w.id === updated.id);

              if (workIndex !== -1) {
                data.fetchWorkspaces.splice(workIndex, 1, updated);

                this.updateCurrentWorkspace(updated);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Team member has been removed successfully.',
          });

          this.$bvModal.hide('RemoveMemberModal');
        })
        .catch(() => {
          this.removingMember = false;
        });
    },

    onHideRemoveTeamMemberModal() {
      this.removingMember = false;
      this.memberInvokedForRemoval = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.team__settings {
  background: $white;
  padding: 30px 50px;
  border-radius: 10px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px 20px;
    .title {
      font-weight: bold;
      color: $primary;
      font-size: 18px;
    }
    .add__member {
      color: $secondary;
    }
  }
  &-list {
    border: 1px solid $secondary;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    .list-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      color: #000;
      margin-bottom: 6px;
      .user_data {
        .role {
          color: #989eb5;
          padding: 4px;
          border-radius: 3px;
          background-clip: padding-box;
          font-size: 14px;
          vertical-align: middle;
          margin-left: 3px;
          margin-top: -8px;

          &.orange {
            color: #ff9100;
          }
          &.red {
            color: #dd5050;
          }
          &.green {
            color: #52b788;
          }
          &.green {
            color: #1b4332;
          }
        }
      }
      .bordered-left {
        border-right: 1px solid #95d5b2;
        height: 80px;
        width: 100%;
      }
      .user-email {
        line-height: 65px;
        vertical-align: middle;
      }
      .user_action {
        display: none;
      }
      &:hover {
        background: $secondary;
        border: 1px solid #b7e4c7;
        border-radius: 10px;
        .user_action {
          display: flex;
          align-items: center;
        }
        .user_email {
          svg {
            fill: $primary;
          }
        }
      }
    }
  }
}
.team-settings-page-wrap {
  .box_content {
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;

    .team_box {
      width: 25%;
      padding-bottom: 20px;
      padding-right: 10px;
      padding-left: 10px;
      min-height: 340px;

      .box_inner {
        min-height: 340px;
        border-radius: 10px;
        background-clip: padding-box;
        background-color: #fff;
        box-shadow: 0 2px 16px 0 rgba(184, 189, 209, 0.7);

        .t_head {
          padding: 20px 0;
          padding-right: 20px;

          .tag p {
            margin-left: 0;
            font-size: 12px;
          }

          .icons {
            opacity: 0;
            transition: all 0.2s linear !important;
            margin-left: auto;

            .icon {
              cursor: pointer;
              font-size: 12px;
              color: #b7bbcb;
            }
          }
        }

        .content {
          text-align: center;
          padding: 20px;
          padding-top: 0;

          .img {
            background-size: cover;
            margin-bottom: 10px;
            display: inline-block;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-clip: padding-box;
            overflow: hidden;

            .icon {
              font-size: 32px;
              color: #b7bbcb;
            }
          }

          .name {
            min-height: 43px;

            h3 {
              color: #3a4557;
              font-size: 18px;
              font-weight: 500;

              span {
                font-weight: 400;
              }
            }
          }

          .email {
            min-height: 37px;
            margin-top: 10px;

            span {
              color: #989eb5;
              font-weight: 500;
              font-size: 12px;
              display: block;
              word-break: break-word;
              word-wrap: break-word;
            }
          }

          .pending {
            margin-top: 5px;
            color: #f36;
            font-weight: 500;
            font-size: 12px;
          }

          .bottom_icon {
            opacity: 0;
            transition: all 0.2s linear;
            margin-top: 10px;

            .icon {
              cursor: pointer;
              font-size: 16px;
              color: #b7bbcb;
            }
          }
        }

        &:hover {
          .icons,
          .bottom_icon {
            opacity: 1;
          }
        }
      }

      &.create_team {
        .box_inner {
          .t_head {
            text-align: center;
            padding: 20px;
            margin-bottom: 50px;

            h3 {
              color: rgba(152, 158, 181, 0.7);
              font-size: 16px;
              font-weight: 500;
            }
          }

          .img {
            margin: 0 auto;
            box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.5);
          }

          .bottom_icon {
            margin-top: 80px;
            opacity: 1;

            .btn {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.add_member_modal,
.remove_member_modal {
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal-body {
    background: #f8f8f8;
    border-radius: 8px;
    border: none;
    input,
    select {
      border: 1.5px solid #b7e4c7;
      border-radius: 10px;
      height: 40px;
    }
  }
}

// .add-member-modal {
//   .close-btn {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//   }

//   p {
//     margin: 0;
//   }

//   label {
//     margin-bottom: 0;
//   }

//   .form-control {
//     &::placeholder {
//       color: $gray-500;
//     }
//   }
// }
</style>
