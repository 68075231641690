<template>
  <div class="planner-wrap shadow-sm">
    <div class="planner-calendar-header" :class="{ 'shadow-sm stick': isScrollPassed, 'mb-2': !isScrollPassed }">
      <div class="planner-month-header py-2 px-3" :class="{ 'border-bottom': isScrollPassed, 'mb-1': !isScrollPassed }">
        <div class="planner-calendar-left">
          <b-button variant="clear" size="sm" class="p-0 m-0 font-weight-bold" @click="setToday"> TODAY </b-button>

          <p class="m-0 p-0 text-left">
            <b-badge variant="clear" class="rounded-pill py-2">
              <b-spinner v-if="isLoading" small />
              <span v-else>{{ plannerPosts.length }} posts this {{ isDay ? 'day' : isWeek ? 'week' : 'month' }}</span>
            </b-badge>
          </p>
        </div>

        <div class="d-flex align-items-center ml-5 pl-5">
          <b-button variant="clear" class="mr-5" size="sm" @click="prev">
            <svg-icon name="chevron-left" />
          </b-button>

          <div class="subtitle font-weight-bold py-1">{{ summary }}</div>

          <b-button variant="clear" class="ml-5" size="sm" @click="next">
            <svg-icon name="chevron-right" />
          </b-button>
        </div>

        <div class="planner-calendar-switch-wrap">
          <div
            @click="setCurrentType('M')"
            class="planner-calendar-switch-item month"
            :class="{ active: currentType.id === 'M' }"
          >
            Month View
          </div>
          <div
            @click="setCurrentType('W')"
            class="planner-calendar-switch-item week"
            :class="{ active: currentType.id === 'W' }"
          >
            Week View
          </div>
          <div
            @click="setCurrentType('D')"
            class="planner-calendar-switch-item week"
            :class="{ active: currentType.id === 'D' }"
          >
            Day View
          </div>
        </div>
      </div>

      <div v-if="isWeek || isDay" class="planner-weekview-header">
        <template v-for="(day, i) in calendar.days">
          <div
            :key="i + 1"
            class="week-planner-day p-2"
            :class="{
              'week-planner-day-today': day.currentDay,
              'week-planner-day-past': day.currentOffset < 0,
              'week-planner-day-future': day.currentOffset > 0,
            }"
          >
            <div class="planner-week-weekday">{{ day.format('dddd') }}</div>
          </div>
        </template>
      </div>
      <div v-else class="planner-month-subheader planner-light-forecolor">
        <div class="planner-monthday py-2" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
      </div>
    </div>

    <template v-if="isDay">
      <PlannerDaysView :loading="isLoading" :posts="posts" :calendar="calendar" />
    </template>

    <template v-else-if="isWeek">
      <PlannerWeeksView :loading="isLoading" :posts="posts" :calendar="calendar" />
    </template>

    <div v-else class="planner-month">
      <div
        class="planner-day"
        v-for="day in calendar.days"
        :key="day.dayIdentifier"
        :class="{ highlighted: isHighlighted(day) }"
      >
        <PlannerDayHeader :day="day" :canAddFrom="canAddFrom" />

        <PlannerDayContainer :class="{ loading: isLoading }">
          <div v-if="getDayPosts(day).length < 1" class="planner-empty-day">{{ day.dayOfMonth }}</div>
          <PlannerCalendarPost v-for="dayPost in getDayPosts(day)" :key="dayPost.id" :post="dayPost" />
        </PlannerDayContainer>
      </div>
    </div>

    <b-modal id="PlannerDeleteModal" centered hide-footer hide-header>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete post?</h4>
        <p>Are you sure you would like to delete this post?</p>

        <b-button variant="light" class="mr-2" @click="cancelDeletePost">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="actuallyDeletePost">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { PLANNER_POSTS_QUERY } from '~/graphql/queries';
import { DELETE_POST_MUTATION } from '~/graphql/mutations';
import PlannerDaysView from '~/components/PlannerDaysView';
import PlannerWeeksView from '~/components/PlannerWeeksView';
import PlannerDayHeader from '~/components/PlannerDayHeader';
import PlannerDayContainer from '~/components/PlannerDayContainer';
import PlannerCalendarPost from '~/components/PlannerCalendarPost';
import { Day, DaySpan, Calendar, Units, Sorts } from 'dayspan';

export default {
  name: 'Planner',

  components: { PlannerDaysView, PlannerWeeksView, PlannerDayContainer, PlannerCalendarPost, PlannerDayHeader },

  props: {
    day: {
      type: Day,
      default: () => Day.today(),
    },

    types: {
      type: Array,
      default() {
        return [
          {
            id: 'M',
            label: 'Month',
            shortcut: 'M',
            type: Units.MONTH,
            size: 1,
            focus: 0.4999,
            repeat: true,
            listTimes: false,
            updateRows: true,
            schedule: false,
          },
          {
            id: 'W',
            label: 'Week',
            shortcut: 'W',
            type: Units.WEEK,
            size: 1,
            focus: 0.4999,
            repeat: true,
            listTimes: true,
            updateRows: true,
            schedule: false,
          },
          {
            id: 'D',
            label: 'Day',
            shortcut: 'D',
            type: Units.DAY,
            size: 1,
            focus: 0.4999,
            repeat: true,
            listTimes: true,
            updateRows: true,
            schedule: false,
          },
        ];
      },
    },

    weekdays: {
      type: Array,
      default() {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      },
    },
  },

  data() {
    return {
      span: new DaySpan(this.day, this.day),
      plannerPosts: [],
      scrollY: 0,
      calendar: this.getCalendar(),
      isLoading: false,
      isDeleting: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      postToDelete: state => state.planner.postToDelete,
    }),

    posts() {
      return this.plannerPosts.length ? this.plannerPosts : [];
    },

    summary() {
      return this.calendar.summary(false, false, false, false);
    },

    currentType: {
      get() {
        return (
          this.types.find(type => type.type === this.calendar.type && type.size === this.calendar.size) || this.types[0]
        );
      },
      set(type) {
        this.rebuild(this.day, true, type);
      },
    },

    isScrollPassed() {
      return this.scrollY > 217;
    },

    isDay() {
      return this.currentType.type === Units.DAY;
    },

    isWeek() {
      return this.currentType.type === Units.WEEK;
    },

    canAddFrom() {
      return Day.today()
        .toDate()
        .getTime();
    },

    startDate() {
      return this.calendar.span.start.format('YYYY-MM-DDTHH:mm:ss');
    },

    endDate() {
      return this.calendar.span.end.format('YYYY-MM-DDTHH:mm:ss');
    },
  },

  watch: {
    day: {
      handler(day) {
        this.span = new DaySpan(day, day);
      },
    },
    span: {
      deep: true,
      handler: 'resetMonth',
    },
  },

  mounted() {
    this.fetchPosts();

    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    fetchPosts() {
      this.isLoading = true;

      return this.$apollo
        .query({
          query: PLANNER_POSTS_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            workspace: parseInt(this.workspace.id, 10) || 0,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then(({ data }) => {
          this.isLoading = false;

          this.plannerPosts = data.plannerPosts;
        })
        .catch(err => {
          this.isLoading = false;

          console.warn(err);
        });
    },

    setCurrentType(id) {
      const type = this.types.find(t => t.id === id);
      if (type) {
        this.currentType = type;

        this.fetchPosts();
      }
    },

    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    getDayPosts(day) {
      const dayMonth = day.format('YYYY MM DD');

      const posts = this.posts.filter(post => {
        const postDay = moment(post.utcScheduleDate).format('YYYY MM DD');
        return postDay === dayMonth;
      });
      return posts || [];
    },

    isHighlighted(day) {
      return this.span.matchesDay(day);
    },

    getCalendar() {
      return Calendar.months();
    },

    next() {
      this.calendar.unselect().next();

      this.fetchPosts();
    },

    prev() {
      this.calendar.unselect().prev();

      this.fetchPosts();
    },

    isType(type, aroundDay) {
      let cal = this.calendar;

      return cal.type === type.type && cal.size === type.size && (!aroundDay || cal.span.matchesDay(aroundDay));
    },

    setState(state) {
      state.eventSorter = state.listTimes ? Sorts.List([Sorts.FullDay, Sorts.Start]) : Sorts.Start;

      this.calendar.set(state);
    },

    rebuild(aroundDay, force, forceType) {
      let type = forceType || this.currentType || this.types[1];

      if (this.isType(type, aroundDay) && !force) {
        return;
      }

      let input = {
        type: type.type,
        size: type.size,
        around: aroundDay,
        eventsOutside: true,
        preferToday: false,
        listTimes: type.listTimes,
        updateRows: type.updateRows,
        updateColumns: type.listTimes,
        fill: !type.listTimes,
        otherwiseFocus: type.focus,
        repeatCovers: type.repeat,
      };

      this.setState(input);
    },

    setToday() {
      this.rebuild(this.day);
    },

    cancelDeletePost() {
      this.$store.dispatch('planner/updatePostToDelete', null);

      this.$bvModal.hide('PlannerDeleteModal');
    },

    async actuallyDeletePost() {
      this.isDeleting = true;

      if (!this.postToDelete) {
        return;
      }

      await this.$apollo.mutate({
        mutation: DELETE_POST_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.postToDelete.id],
        },
      });

      const postIndex = this.plannerPosts.findIndex(post => post.id === this.postToDelete.id);

      if (postIndex !== -1) {
        this.plannerPosts = [...this.plannerPosts.slice(0, postIndex), ...this.plannerPosts.slice(postIndex + 1)];
      }

      this.isDeleting = false;
      this.cancelDeletePost();

      Swal.fire({
        type: 'success',
        title: 'Success!',
        text: 'Post deleted successfully',
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.planner-weekview-header {
  flex: none;
  display: flex;
  background-color: $primary;
  color: $white;

  .week-planner-day {
    border-right: 1px solid #ddd;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      border-right: 0;
    }
  }
}

.week-planner-day {
  flex: 1 1 auto;
  width: 0;

  .planner-week-weekday {
    padding-left: 8px;
    user-select: none;
  }

  &.week-planner-day-today {
    .planner-week-weekday {
      color: $secondary;
    }
  }

  &.week-planner-day-past {
    .planner-week-weekday {
    }
  }

  &.week-planner-day-future {
  }
}
// -----------------------------------------------------

.planner-wrap {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;

  .planner-calendar-header {
    &.stick {
      position: fixed;
      top: $navbar-height;
      width: 97.8%;
      z-index: 1001;
      animation: slide-down 0.2s steps(50) reverse;
    }
  }

  .planner-calendar-left {
    // position: absolute;
    // left: 10px;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
  }

  .planner-calendar-switch-wrap {
    // position: absolute;
    // right: 10px;
    display: flex;
    border-radius: 5px;

    .planner-calendar-switch-item {
      padding: 5px 25px;
      border-radius: 5px;
      cursor: pointer;
      color: $primary;

      &.active {
        background: #95d5b2;
        margin: -1px;
      }
    }
  }

  .planner-month-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d8f3dc;
    // position: relative;

    .btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .planner-month-subheader {
    display: flex;
    text-align: center;
    color: $white;
    background-color: $primary;
  }

  .planner-month {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -20px;
    margin-right: -1px;
  }

  .planner-monthday {
    flex: 1;
    border-right: 1px solid $gray-200;

    &:last-of-type {
      border-right: 0;
    }
  }

  .planner-day {
    flex: 1 0 14%;
    border-right: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    background-color: $white;
    margin-bottom: 20px;
    padding-bottom: 15px;

    &.highlighted {
    }

    .planner-empty-day {
      font-size: 3em;
      line-height: 1.4;
      color: $gray-200;
      font-weight: lighter;
      position: absolute;
      right: 5px;
      bottom: 0;
      user-select: none;
    }

    &:hover {
      .planner-day-header-btn {
        opacity: 1;
      }
    }
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
</style>
