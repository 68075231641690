<template>
  <div class="post-comment">
    <span class="avatar">
      <img v-if="comment.user.avatar" :src="comment.user.avatar" :alt="`${comment.user.firstName} avatar`" />
      <svg-icon name="user" class="icon" />
    </span>
    <span class="data">
      <span class="row">
        <span class="name">
          {{ fullName(comment.user) }}
        </span>
        <span class="date">
          {{ date }}
        </span>
      </span>
      <span class="row">
        <span class="text">
          {{ comment.message }}
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'post-comment',
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      date: null,
    };
  },
  created() {
    this.interval = setInterval(() => (this.date = moment.unix(this.comment.date).fromNow()), 1000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  computed: {
    fullName() {
      return ({ firstName, lastName }) => {
        return `${firstName} ${lastName}`;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
.post-comment {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  padding: 15px 10px;
  margin: 20px 0 0 0;
  border: 1px solid #dedede;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;

  > .avatar {
    display: inline-flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #c4c4c4;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;

    > img {
      display: inline-flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > .icon {
      width: 50%;
      height: 50%;
      color: #e9e9e9;
      fill: #e9e9e9;
    }
  }

  > .data {
    display: inline-flex;
    flex-grow: 1;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 0 20px;

    > .row {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;
      flex-wrap: wrap;
      align-content: flex-start;

      &::after,
      &::before {
        display: none;
      }

      > * {
        display: inline-flex;
        width: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $secondary;
      }

      > .name {
        text-transform: capitalize;
      }

      > .date {
        color: #c9c9c9;
        text-transform: lowercase;
        margin: 0 0 0 20px;
      }

      > .text {
        margin: 10px 0 0 0;
        font-weight: normal;
        flex-wrap: wrap;
        align-content: flex-start;
        word-break: break-all;
      }
    }
  }
}
</style>
