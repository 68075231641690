<template>
  <div class="audience-growth">
    <Content class="chart" :title="contentTitle">
      <canvas ref="audience-chart-el"></canvas>
    </Content>
  </div>
</template>

<script>
import { get } from 'lodash';
import Content from '~/components/Reports/partials/Content';
import ChartJs from 'chart.js';

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    contentTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: {
        instance: null,
      },
      colors: ['255,76,97', '255,184,0', '147,162,245', '51,214,159', '63,81,181', '147,162,245'],
    };
  },
  components: {
    Content,
  },

  computed: {
    getData() {
      return (key, defaultVal) => {
        return get(this, key, defaultVal);
      };
    },

    chartComputedData() {
      const dataObject = [];
      for (let i = 0; i < this.chartData.data.length; i++) {
        for (let key in this.chartData.data[i]) {
          const random = Math.floor(Math.random() * this.colors.length);
          let dam = {
            label: key,
            data: this.chartData.data[i][key],
            fill: 'origin',
            borderColor: 'rgb(' + this.getColor(random) + ')',
            backgroundColor: 'rgb(' + this.getColor(random) + ',0.3)',
            lineTension: 0.4,
          };
          dataObject.push(dam);
        }
      }

      return {
        labels: this.getData('chartData.labels', []),
        datasets: dataObject,
      };
    },
  },

  async mounted() {
    await this.$nextTick();
    this.reloadChartJs();
  },
  beforeDestroy() {
    this.destroyChart();
  },

  watch: {
    chartData: {
      handler() {
        this.reloadChartJs();
      },
      deep: true,
    },
  },
  methods: {
    getColor(index) {
      let bgColor = '';
      for (let i = 0; i < this.colors.length; i++) {
        if (i === index) bgColor = this.colors[i];
      }
      return bgColor;
    },
    destroyChart() {
      if (this.chart.instance) this.chart.instance.destroy();
      this.chart.instance = null;
    },
    reloadChartJs() {
      const { 'audience-chart-el': chartCanvasEl } = this.$refs;
      if (!chartCanvasEl) return;
      this.destroyChart();

      const deliveredData = this.chartData
        ? this.chartComputedData
        : {
            labels: ['No Data'],
            datasets: [
              {
                data: [100],
                backgroundColor: ['#c4c4c4'],
                hoverBackgroundColor: ['#c4c4c4'],
                borderWidth: [0],
              },
            ],
          };

      const deliveredOpt = {
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
      this.chart.instance = new ChartJs(chartCanvasEl, {
        type: 'bar',
        data: deliveredData,
        options: deliveredOpt,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-growth {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  margin: 10px auto;
}
</style>
