<script>
import AddBlogAccountBox from '~/components/AddBlogAccountBox';
import AddSocialAccountBox from '~/components/AddSocialAccountBox';

export default {
  props: {
    tag: {
      type: String,
      default: 'b-button',
    },
    platformType: {
      type: String,
      default: 'social',
    },
    queryType: {
      type: String,
      default: 'all',
    },
  },

  methods: {
    showAddAccountModal() {
      this.$store.dispatch('publishAccount/updateQueryType', this.queryType);
      this.$store.dispatch('publishAccount/updatePlatformTypeToAdd', this.platformType);

      this.$bvModal.show('ConnectAccountsModal');
    },
  },

  render: function(createElement) {
    let component;
    if (this.tag === 'box') {
      component = this.platformType == 'blog' ? AddBlogAccountBox : AddSocialAccountBox;
    } else {
      component = this.tag;
    }

    const data = {
      on: {
        click: this.showAddAccountModal,
      },
    };

    return createElement(component, data, this.$slots.default);
  },
};
</script>
