<template>
  <div :id="computedId" class="checkselect-wrap">
    <div class="checkselect" :class="dropdownOpen ? 'rounded-top' : 'rounded'" @click="toggleDropdownOpen">
      <div class="checkselect-placeholder" v-if="!selectedOptions.length">
        <span>{{ placeholder }}</span> <span class="chevron"> <svg-icon name="chevron-down" class="mt-0"/></span>
      </div>
      <div
        v-for="choice in selectedOptions"
        :key="choice.id"
        :title="!choice.isMore ? choice.name : ''"
        class="checkselect-item text-truncate"
        :class="{ more: choice.isMore }"
      >
        {{ choice.name }}
      </div>
    </div>
    <div v-show="dropdownOpen" class="checkselect-dropdown" v-click-outside="hideDropdown">
      <simplebar v-if="options.length > 0" class="checkselect-dropdow-inner">
        <div v-for="option in options" :key="option.id" class="checkselect-option">
          <label :for="`${computedId}-option-${option.id}`" class="mb-0 w-100 text-truncate">{{ option.name }}</label>
          <b-form-checkbox
            :id="`${computedId}-option-${option.id}`"
            :checked="selected.includes(option.id)"
            @change="handleOptionChange($event, option.id)"
          >
          </b-form-checkbox>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: { simplebar },

  props: {
    id: {
      type: String,
    },

    placeholder: {
      type: String,
      default: 'Select...',
    },

    options: {
      type: Array,
      default: () => [],
    },

    selected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dropdownOpen: false,
    };
  },

  computed: {
    selectedOptions() {
      if (!this.options || (this.options && !this.options.length)) {
        return [];
      }
      const truncatedOptions = this.selected.map(id => {
        return this.options.find(opt => opt.id == id);
      });

      if (truncatedOptions.length > 1) {
        return [
          truncatedOptions[0],
          {
            name: `+${truncatedOptions.length - 1}`,
            isMore: true,
          },
        ];
      }

      return truncatedOptions;
    },

    computedId() {
      return this.id || `checkselect-${Math.floor(Math.random() * (9999 - 10) + 10)}`;
    },
  },

  methods: {
    toggleDropdownOpen() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    hideDropdown(event) {
      const isClickOnSelect = event.target.closest('.checkselect');
      const isClickOnDropdown = event.target.closest('.checkselect-dropdown');

      if (!isClickOnSelect && !isClickOnDropdown) {
        this.dropdownOpen = false;
      }
    },

    handleOptionChange(checked, id) {
      let ids = [];

      if (checked) {
        ids = [...this.selected, id];
      } else {
        const index = this.selected.findIndex(i => id == i);
        if (index !== -1) {
          ids = [...this.selected.slice(0, index), ...this.selected.slice(index + 1)];
        }
      }

      this.$emit('change', ids);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.checkselect-wrap {
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.checkselect-dropdown {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 1px 5px 10px $gray-200;
  border-radius: 0 0 4px 4px;
  border: 1px solid $gray-200;
  border-top: 0;
  z-index: 100;

  .checkselect-dropdow-inner {
    max-height: 200px;
    overflow-y: auto;

    .simplebar-scrollbar {
      width: 7px;
    }

    .simplebar-scrollbar:before {
      border-radius: 7px;
      background: darken($gray-700, 1);
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      opacity: 1;
      transition: opacity 0s linear;
    }
  }

  .checkselect-option {
    display: flex;
    justify-content: space-between;
    padding: 5px 5px 5px 10px;

    label {
      cursor: pointer;
      max-width: 85%;
    }

    .custom-control-input {
      display: none;
    }

    &:hover {
      background-color: $gray-100;
    }
  }
}

.checkselect {
  border: 1px solid $gray-300;
  padding: 4px 5px;
  display: flex;
  align-items: center;
}

.checkselect-item {
  padding: 0 7px;
  background-color: $primary;
  border: 1px solid darken($primary, 5);
  border-radius: 2px;
  color: $white;
  margin-right: 5px;
  max-width: 130px;

  &.more {
    background-color: $light;
    border-color: darken($light, 5);
    color: $gray-600;
    font-size: 14px;
  }
}

.checkselect-placeholder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chevron {
    margin-right: 5px;
    .icon {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}
</style>
